import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { HistoryTypeEnum } from 'models/helpers/history-helper'
import { useClientMattersPrefetch } from 'models/queries/use-client-matters-query'
// Harvey specific
import { useHistoryPollingQuery_Prefetch } from 'models/queries/use-history-polling-query'
import { useSettingsClientMattersPrefetch } from 'models/queries/use-settings-client-matters-query'
import { UserInfo } from 'models/user-info'
import { ResearchArea } from 'openapi/models/ResearchArea'
import useTabFocusTracking from 'services/honey-comb/use-tab-focus-tracking'

import { isSentryLoggingEnabled } from 'utils/env'
import { createRouteComponent } from 'utils/routing'
import { SentryAssignees } from 'utils/sentry'
import { site, environment } from 'utils/server-data'

import AppLayout from 'components/app-layout'
import { BaseAppPath, SettingsPath } from 'components/base-app-path'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import RedirectWithId from 'components/common/redirects/redirect-with-id'
import Compare from 'components/compare/compare-page'
import Dashboard from 'components/dashboard/dashboard-page'
import History from 'components/history/history-page'
import LibraryExamples from 'components/library/examples-page'
import Library from 'components/library/library-page'
import Prompts from 'components/library/prompts-page'
import ResearchIndex from 'components/research/pages/research-index'
import SettingsExperimentManager from 'components/settings/experiment/settings-experiment-management-page'
import Permissions from 'components/settings/permissions/permissions-page'
import UserInspector from 'components/settings/permissions/user-inspector'
import ProfilePage from 'components/settings/profile/profile-page'
import PWCTaxFeedbackExport from 'components/settings/pwc/pwc-tax-feedback-export-page'
import SettingsUserManagement from 'components/settings/user-management/settings-user-management-page'
import AddEditWorkspace from 'components/settings/workspace/workspace-details/add-edit-workspace-page'
import WorkspaceDetails from 'components/settings/workspace/workspace-details/workspace-details-page'
import WorkspaceInfo from 'components/settings/workspace/workspace-info-page'
import Workspaces from 'components/settings/workspace/workspaces-page'

import AssistantIndex from './assistant/assistant-index'
import AuthenticationGuard from './auth/auth-guard'
import { AuthContext } from './common/auth-context'
import NotAuthorizedScreen from './common/error/not-authorized-screen'
import { useHistoryMetadataStore } from './history/history-metadata-store'
import { useHistoryStore } from './history/history-store'
import { HISTORY_BATCH_SIZE } from './history/history-table'
import WorkspaceHistory from './history/workspace-history-page'
import Artifact from './research/artifact/artifact-page'
import UsaCaseLaw from './research/usacaselaw/usacaselaw-page'
import SettingsClientMatters from './settings/client-matters/settings-client-matters-page'
import ExperimentManagement from './settings/experiment/experiment-management-page'
import NewExperiment from './settings/experiment/new-experiment-page'
import UserEvaluation from './settings/experiment/user-evaluation/user-evaluation-page'
import SettingsIncidentManagement from './settings/incident-management/settings-incident-management'
import IntegrationsPage from './settings/integrations/integrations-page'
import InternalAdminLayout from './settings/internal-admin/internal-admin-layout'
import SettingsLibraryEventsManager from './settings/library/settings-library-events-manager'
import SettingPermissionUsers from './settings/permissions/settings-permission-users-page'
import WorkspaceRolesInspector from './settings/roles/workspace-roles-inspector-page'
import SettingsIndex from './settings/settings-index'
import { SettingsSharing } from './settings/sharing/settings-sharing-page'
import WorkspaceUsers from './settings/workspace/workspace-users-page'
import { useStatusBarFetcher } from './use-status-bar-refech'
import VaultIndex from './vault/vault-index'
import { getWorkflowSpecificRouting } from './workflows/workflow-definitions'
import WorkflowContainer from './workflows/workflow/workflow-container'
import WorkflowsPage from './workflows/workflows-page'

export interface AppRouterProps {
  userInfo: UserInfo
  logoutHandler: () => void
}

const LoginCompletionRedirect = () => {
  const navigate = useNavigate()
  React.useEffect(() => {
    // We set replace so browser history remembers Harvey, not the login URL.
    navigate('/', { replace: true })
  }, [navigate])

  return null
}

// Component
const AppRouter: React.FC<AppRouterProps> = ({ userInfo, logoutHandler }) => {
  const updateLastUpdatedTime = useHistoryStore((s) => s.updateLastUpdatedTime)

  let baseComponent = null
  let basePath = '/'
  if (userInfo.IsAssistantV2User) {
    baseComponent = <AssistantIndex />
    basePath = BaseAppPath.Assistant
  } else if (userInfo.IsVaultUser) {
    baseComponent = <VaultIndex />
    basePath = BaseAppPath.Vault
  } else if (userInfo.IsHarveyV1ResearchUser) {
    baseComponent = <ResearchIndex />
    basePath = BaseAppPath.Research
  } else if (userInfo.IsHarveyV1WorkflowUser) {
    baseComponent = <WorkflowsPage />
    basePath = BaseAppPath.Workflows
  } else if (userInfo.IsHistoryUser) {
    baseComponent = <History />
    basePath = BaseAppPath.History
  } else if (userInfo.IsLibraryUser) {
    baseComponent = <Library />
    basePath = BaseAppPath.Library
  } else if (userInfo.isSettingsTabVisible) {
    baseComponent = <SettingsIndex />
    basePath = BaseAppPath.Settings
  } else if (!userInfo.IsAuthorized) {
    ;<NotAuthorizedScreen logoutHandler={logoutHandler} userInfo={userInfo} />
  } else {
    baseComponent = <ErrorPage />
  }

  // prefetch history first page, but _only_ if the user has the right permissions as a history user
  useHistoryPollingQuery_Prefetch(
    {
      pageSize: HISTORY_BATCH_SIZE,
      currentPage: 1,
      historyType: HistoryTypeEnum.USER,
    },
    userInfo.IsHistoryUser,
    updateLastUpdatedTime
  )

  // prefetch the history metadata for the history page, but _only_ if the user
  // has the right permissions as a history user
  React.useEffect(() => {
    if (!userInfo.IsHistoryUser) {
      return
    }
    void useHistoryMetadataStore.getState().fetchData()
  }, [userInfo.IsHistoryUser])

  // status bar fetcher
  useStatusBarFetcher(userInfo.workspace.id)

  // prefetch the client matters with stats for settings
  useSettingsClientMattersPrefetch(userInfo, userInfo.isClientMattersReadUser)

  // prefetch the client matters for sidebar combobox
  useClientMattersPrefetch(userInfo.isClientMattersReadUser)

  // track user activity when tab is focused
  useTabFocusTracking()

  const SentryRoutes = isSentryLoggingEnabled
    ? Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes

  const routes: React.ReactNode[] = [
    // Catch a user who successfully logged in via a login slug, and redirect
    // them to the main app.
    createRouteComponent({
      path: `${BaseAppPath.Login}/*`,
      hasPerms: true,
      component: LoginCompletionRedirect,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Assistant}/*`,
      hasPerms: userInfo.IsAssistantUser,
      component: AssistantIndex,
      sentryAssignee: userInfo?.IsAssistantV2User
        ? SentryAssignees.ASSISTANT_V2
        : undefined,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Compare}/:id?`,
      hasPerms: userInfo.IsResponseComparisonUser,
      component: Compare,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Evaluations}/experiment/:slug?`,
      hasPerms: userInfo.IsResponseComparisonUser,
      component: UserEvaluation,
    }),
    createRouteComponent({
      path: BaseAppPath.History,
      hasPerms: userInfo.IsHistoryUser,
      component: History,
    }),
    createRouteComponent({
      path: BaseAppPath.Library,
      hasPerms: userInfo.IsLibraryUser,
      component: Library,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Library}/prompts`,
      hasPerms: userInfo.IsLibraryUser,
      component: Prompts,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Library}/examples`,
      hasPerms: userInfo.IsLibraryUser,
      component: LibraryExamples,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Vault}/*`,
      hasPerms: userInfo.IsVaultUser,
      component: VaultIndex,
      sentryAssignee: SentryAssignees.VAULT,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/*`,
      hasPerms: userInfo.IsHarveyV1ResearchUser,
      component: ResearchIndex,
      sentryAssignee: SentryAssignees.TAX,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/artifact/:id`,
      hasPerms: userInfo.IsHarveyV1ResearchUser,
      component: () => <Artifact basePath="research" />,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/sec-edgar/:id`,
      hasPerms: userInfo.IsHarveyV1ResearchUser,
      component: () => (
        <RedirectWithId path={`${BaseAppPath.Research}/edgar`} />
      ),
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/${ResearchArea.USACASELAW}/:id?`,
      hasPerms: userInfo.IsHarveyV1ResearchUser,
      component: UsaCaseLaw,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/${ResearchArea.USCASELAW}/:id?`,
      hasPerms: userInfo.IsHarveyV1ResearchUser,
      component: UsaCaseLaw,
    }),
    createRouteComponent({
      path: BaseAppPath.Workflows,
      hasPerms: userInfo.IsHarveyV1WorkflowUser,
      component: WorkflowsPage,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Workflows}/:workflow_id/:id?`,
      hasPerms: userInfo.IsHarveyV1WorkflowUser,
      component: WorkflowContainer,
    }),
    ...getWorkflowSpecificRouting(userInfo),
    createRouteComponent({
      path: BaseAppPath.Settings,
      hasPerms: userInfo.isSettingsTabVisible,
      component: SettingsIndex,
      sentryAssignee: SentryAssignees.PLATFORM,
      children: [
        createRouteComponent({
          path: SettingsPath.Workspace,
          hasPerms: userInfo.IsWorkspaceInfoViewer,
          component: WorkspaceInfo,
        }),
        createRouteComponent({
          path: SettingsPath.ClientMatters,
          component: SettingsClientMatters,
          hasPerms: userInfo.isClientMattersReadUser,
        }),
        createRouteComponent({
          path: SettingsPath.Sharing,
          component: SettingsSharing,
          hasPerms: userInfo.IsSharingManagementUser,
        }),
        createRouteComponent({
          path: SettingsPath.Profile,
          component: ProfilePage,
          hasPerms: true,
          sentryAssignee: SentryAssignees.ASSISTANT_V2,
        }),
        createRouteComponent({
          path: SettingsPath.Users,
          hasPerms: userInfo.IsClientAdminViewUsers,
          component: WorkspaceUsers,
        }),
        createRouteComponent({
          path: SettingsPath.Usage,
          hasPerms:
            userInfo.IsUsageDashboardViewer ||
            userInfo.IsUsageDashboardV2Viewer,
          component: Dashboard,
        }),
        createRouteComponent({
          path: SettingsPath.WorkspaceHistory,
          hasPerms: userInfo.IsWorkspaceHistoryReader && userInfo.IsHistoryUser,
          component: WorkspaceHistory,
        }),
        createRouteComponent({
          path: SettingsPath.Integrations,
          hasPerms: userInfo.isAnyIntegrationUser,
          component: IntegrationsPage,
        }),
        createRouteComponent({
          path: SettingsPath.InternalAdmin,
          hasPerms: userInfo.IsInternalAdminReader,
          component: InternalAdminLayout,
          componentProps: { site, env: environment },
          children: [
            createRouteComponent({
              path: SettingsPath.InternalAdminWorkspaces,
              hasPerms: userInfo.IsInternalAdminReader,
              component: Workspaces,
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminWorkspaces}/new`,
              hasPerms: userInfo.IsInternalAdminWriter,
              component: AddEditWorkspace,
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminWorkspaces}/:id/edit`,
              hasPerms: userInfo.IsInternalAdminWriter,
              component: AddEditWorkspace,
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminWorkspaces}/:id`,
              hasPerms: userInfo.IsInternalAdminReader,
              component: WorkspaceDetails,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminPermissions,
              hasPerms: userInfo.IsInternalAdminReader,
              component: Permissions,
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminPermissions}/users`,
              hasPerms: userInfo.IsInternalAdminReader,
              component: SettingPermissionUsers,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminUserManagement,
              hasPerms: userInfo.IsInternalAdminReader,
              component: SettingsUserManagement,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminExperimentManagement,
              hasPerms: userInfo.IsResponseComparisonAdmin,
              component: SettingsExperimentManager,
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminExperimentManagement}/new`,
              hasPerms: userInfo.IsResponseComparisonAdmin,
              component: NewExperiment,
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminExperimentManagement}/:slug`,
              hasPerms: userInfo.IsResponseComparisonAdmin,
              component: ExperimentManagement,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminUserInspector,
              hasPerms: userInfo.IsInternalAdminReader,
              component: UserInspector,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminRoleInspector,
              hasPerms: userInfo.IsInternalAdminReader,
              component: WorkspaceRolesInspector,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminPwcTaxExport,
              hasPerms: userInfo.IsPWCTaxFeedbackExportUser,
              component: PWCTaxFeedbackExport,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminLibraryEventsManager,
              hasPerms: userInfo.IsLibraryCreateHarveyItemsUser,
              component: SettingsLibraryEventsManager,
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminIncidentManagement,
              hasPerms: userInfo.IsInternalAdminWriter,
              component: SettingsIncidentManagement,
            }),
          ],
        }),
      ],
    }),
  ]

  return (
    <AuthContext.Provider value={{ userInfo }}>
      <SentryRoutes>
        <Route path="/" element={<AppLayout basePath={basePath} />}>
          <Route
            index
            element={
              <AuthenticationGuard hasPerms component={() => baseComponent} />
            }
          />
          {routes.map((r) => r)}
          <Route
            path="*"
            element={<ErrorPage title={ErrorPageTitle.PAGE_NOT_FOUND} />}
          />
        </Route>
      </SentryRoutes>
    </AuthContext.Provider>
  )
}

// Export default
export default AppRouter
