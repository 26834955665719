import React, { useState } from 'react'
import { useMount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { WorkflowListItem } from 'components/assistant/workflows/types'
import { loadWorkflows } from 'components/assistant/workflows/workflow-loader'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import Link from 'components/ui/link/link'

export const AssistantWorkflowsHomePage: React.FC = () => {
  const [workflows, setWorkflows] = useState<WorkflowListItem[]>([])

  const [reset] = useAssistantWorkflowStore(
    useShallow((state) => [state.reset])
  )

  useMount(() => {
    reset()
    loadWorkflows().then((workflows) => {
      setWorkflows(workflows)
    })
  })

  const getWorkflowDestination = (workflow: WorkflowListItem) => {
    return `/assistant/workflows/${workflow.slug}`
  }

  return (
    <AppMain hasContainer>
      <AppHeader title="Assistant Workflows" />
      <div className="grid grid-cols-1 gap-4 pb-4 md:grid-cols-2 xl:grid-cols-4">
        {workflows.map((workflow, i) => (
          <Link to={getWorkflowDestination(workflow)} key={i}>
            <Card>
              <CardHeader>
                <h2 className="text-lg">{workflow.name}</h2>
              </CardHeader>
              <CardContent>
                <p className="mt-1 text-xs">{workflow.description}</p>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </AppMain>
  )
}
