import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { ToBackendKeys } from 'utils/utils'

import { useAssistantWorkflowStreamHandler } from 'components/assistant/workflows/hooks/use-assistant-workflow'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { useAssistantWorkflowComponents } from 'components/assistant/workflows/use-workflow-components'
import { AssistantWorkflowLayout } from 'components/assistant/workflows/workflow-layout'
import { loadWorkflow } from 'components/assistant/workflows/workflow-loader'

export const AssistantWorkflowPage: React.FC = () => {
  const { id: workflowId, eventId: eventIdParam } = useParams()
  const { sendWorkflowChat, closeSocket } = useAssistantWorkflowStreamHandler()
  const [setCurrentWorkflow, workflowDefinition, currentEvent, reset] =
    useAssistantWorkflowStore(
      useShallow((state) => [
        state.setCurrentWorklow,
        state.workflowDefinition,
        state.currentEvent,
        state.reset,
      ])
    )
  const navigate = useNavigate()

  useUnmount(() => {
    closeSocket()
    reset()
  })

  const handleCurrentStepCompletion = (stepId: string, result: any) => {
    if (!workflowDefinition) {
      console.error('No workflow definition found')
      return
    }

    sendWorkflowChat({
      eventId: eventIdParam || currentEvent?.id || null,
      workflowId: workflowDefinition.id,
      workflowStepId: stepId,
      data: ToBackendKeys(result),
    })
  }

  const componentsToRender = useAssistantWorkflowComponents(
    handleCurrentStepCompletion
  )
  const { ThreadComponents, InputComponent } = componentsToRender

  useEffect(() => {
    if (
      !currentEvent?.eventId ||
      String(currentEvent?.eventId) === eventIdParam
    )
      return
    navigate(`/assistant/workflows/${workflowId}/${currentEvent?.eventId}`)
  }, [currentEvent, eventIdParam, navigate, workflowId])

  useEffect(() => {
    if (!workflowId) return

    loadWorkflow(workflowId, eventIdParam).then((workflow) => {
      setCurrentWorkflow(workflow.workflowDefinition, workflow.workflowEvent)
    })
  }, [workflowId, eventIdParam, setCurrentWorkflow])

  return (
    <AssistantWorkflowLayout
      input={InputComponent}
      thread={ThreadComponents}
      title={workflowDefinition?.name ?? 'Loading'}
    />
  )
}
