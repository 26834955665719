/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowFileUploadInputBlockOutput
 */
export interface WorkflowFileUploadInputBlockOutput {
    /**
     * 
     * @type {string}
     * @memberof WorkflowFileUploadInputBlockOutput
     */
    fileId: string;
}

/**
 * Check if a given object implements the WorkflowFileUploadInputBlockOutput interface.
 */
export function instanceOfWorkflowFileUploadInputBlockOutput(value: object): value is WorkflowFileUploadInputBlockOutput {
    if (!('fileId' in value) || value['fileId'] === undefined) return false;
    return true;
}

export function WorkflowFileUploadInputBlockOutputFromJSON(json: any): WorkflowFileUploadInputBlockOutput {
    return WorkflowFileUploadInputBlockOutputFromJSONTyped(json, false);
}

export function WorkflowFileUploadInputBlockOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowFileUploadInputBlockOutput {
    if (json == null) {
        return json;
    }
    return {
        
        'fileId': json['file_id'],
    };
}

export function WorkflowFileUploadInputBlockOutputToJSON(json: any): WorkflowFileUploadInputBlockOutput {
    return WorkflowFileUploadInputBlockOutputToJSONTyped(json, false);
}

export function WorkflowFileUploadInputBlockOutputToJSONTyped(value?: WorkflowFileUploadInputBlockOutput | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'file_id': value['fileId'],
    };
}

