import React from 'react'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { ScrollArea } from 'components/ui/scroll-area'

type Params = {
  title: string
  input: React.ReactNode
  thread: React.ReactNode[]
}

export const AssistantWorkflowLayout: React.FC<Params> = ({
  title,
  input,
  thread,
}) => {
  return (
    <AppMain>
      <AppHeader title="Assistant Workflows" subtitle={title} />
      <div className="flex h-full flex-col">
        <ScrollArea className="flex-grow">
          <div className="mx-auto w-full max-w-[1000px] space-y-4 py-4">
            {thread}
          </div>
        </ScrollArea>
        <div className="mx-auto mb-10 max-h-64 w-[1000px]">{input}</div>
      </div>
    </AppMain>
  )
}
