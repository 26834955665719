import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { SocketMessageResponse } from 'openapi/models/SocketMessageResponse'
import { Source } from 'openapi/models/Source'

import { FollowUpQAPair } from './diligence-store'
import { DiligenceTaxonomy } from './types'

export const SECTION_PARAM = 'section'

export const hasSomeSectionsWithAnswers = (sections: DiligenceSection[]) => {
  return sections.some((section) =>
    section.tasks.some((task) => task.answer?.response.length)
  )
}

export const taxonomiesWithTasks = (taxonomies: DiligenceTaxonomy[]) => {
  return taxonomies.filter((taxonomy) => !!taxonomy.rows.length)
}

export const sectionsToTaxonomy = (
  data: DiligenceSection[]
): DiligenceTaxonomy[] => {
  const transformedData: DiligenceTaxonomy[] = []

  data.forEach((section) => {
    const title = section.title
    const description = section.description
    const rows: string[] = []

    section.tasks.forEach((task) => {
      rows.push(task.title)
    })

    transformedData.push({
      title,
      description,
      rows,
    })
  })

  return transformedData
}

export const taxonomyToSections = (
  taxonomyData: DiligenceTaxonomy[],
  sections: DiligenceSection[]
): DiligenceSection[] => {
  const reconstructedData: DiligenceSection[] = []

  taxonomyData.forEach((item) => {
    const sectionTitle = item.title
    const foundSection = sections.find(
      (section) => section.title === sectionTitle
    )

    if (foundSection) {
      // Deep copy the found section to avoid mutating the original 'sections' variable
      const sectionCopy: DiligenceSection = JSON.parse(
        JSON.stringify(foundSection)
      )
      sectionCopy.tasks = sectionCopy.tasks.filter((task) =>
        item.rows.includes(task.title)
      )
      reconstructedData.push(sectionCopy)
    }
  })

  return reconstructedData
}

export const findSource = (
  sourceId: string,
  answer: Partial<SocketMessageResponse>
) => {
  return answer.sources?.find((s) => s.id === sourceId) as Source
}

export const generateSectionId = (section: DiligenceSection) =>
  `section-id-${section.title}`

const roundToTwoDecimals = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const getSizeInPercentage = (
  desiredPixelHeight: number,
  containerPixelHeight: number,
  defaultPercentageHeight: number = 5
) => {
  const percentSize = roundToTwoDecimals(
    (desiredPixelHeight / containerPixelHeight) * 100
  )
  if (percentSize > 100 || percentSize < 0) {
    return defaultPercentageHeight
  } else {
    return percentSize
  }
}

export interface SourceContext {
  sectionId: string
  taskId: string
  sourceId: string
}

export interface CitationContextMaps {
  sourceIdToTask: Map<string, SourceContext>
  taskIdToSources: Map<string, Source[]>
  sectionTitleToSources: Map<string, Source[]>
  documentIdToSources: Map<string, Source[]>
}

/**
 * Builds a set of useful hash maps to track relationships between citations, sections, tasks, and documents
 *
 * @param sections - Array of sections containing tasks with their associated sources
 * @param followUpQAPairs - Array of follow-up Q&A pairs with their associated sources
 * @returns {CitationContextMaps} Object containing four maps:
 *   - sourceToTask: Maps source IDs to their containing task/section context
 *   - taskToSources: Maps task IDs to all sources within that task
 *   - sectionToSources: Maps section IDs to all sources within that section
 *   - documentToSources: Maps document IDs to all sources that reference that document
 *
 * @example
 * const sections = [
 *   {
 *     title: "Section 1",
 *     tasks: [{
 *       identifier: "task1",
 *       answer: { sources: [{ id: "src1", documentId: "doc1" }] }
 *     }]
 *   }
 * ];
 * const qaPairs = [
 *   {
 *     clientSideId: "qa1",
 *     answer: { sources: [{ id: "src2", documentId: "doc1" }] }
 *   }
 * ];
 * const maps = buildCitationMaps(sections, qaPairs);
 *
 * // Get all sources for a document
 * const docSources = maps.documentToSources.get("doc1");
 *
 * // Get task context for a source
 * const sourceContext = maps.sourceToTask.get("src1");
 */
export const buildCitationMaps = (
  sections: DiligenceSection[],
  followUpQAPairs: FollowUpQAPair[]
): CitationContextMaps => {
  const sourceToTask = new Map<string, SourceContext>()
  const taskToSources = new Map<string, Source[]>()
  const sectionToSources = new Map<string, Source[]>()
  const documentToSources = new Map<string, Source[]>()

  // Process sections and their tasks
  sections.forEach((section) => {
    const sectionSources: Source[] = []

    section.tasks.forEach((task) => {
      const sources = task.answer?.sources || []
      taskToSources.set(task.identifier, sources)
      sectionSources.push(...sources)

      sources.forEach((source) => {
        sourceToTask.set(source.id, {
          sectionId: section.title,
          taskId: task.identifier,
          sourceId: source.id,
        })

        const documentId = source.documentId
        if (documentId) {
          const docSources = documentToSources.get(documentId) || []
          documentToSources.set(documentId, [...docSources, source])
        }
      })
    })

    sectionToSources.set(section.title, sectionSources)
  })

  // Process follow-up QA pairs
  const followUpSources: Source[] = []
  followUpQAPairs.forEach((pair) => {
    const sources = pair.answer?.sources || []
    taskToSources.set(pair.clientSideId, sources)
    followUpSources.push(...sources)

    sources.forEach((source) => {
      sourceToTask.set(source.id, {
        sectionId: 'followup',
        taskId: pair.clientSideId,
        sourceId: source.id,
      })

      // add to document maps
      const documentId = source.documentId
      if (documentId) {
        const docSources = documentToSources.get(documentId) || []
        documentToSources.set(documentId, [...docSources, source])
      }
    })
  })
  sectionToSources.set('followup', followUpSources)

  return {
    sourceIdToTask: sourceToTask,
    taskIdToSources: taskToSources,
    sectionTitleToSources: sectionToSources,
    documentIdToSources: documentToSources,
  }
}
