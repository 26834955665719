import * as React from 'react'

interface NumberedProgressProps {
  currentStep: number
  totalSteps: number
}

const NumberedProgress: React.FC<NumberedProgressProps> = ({
  currentStep,
  totalSteps,
}) => {
  if (typeof currentStep !== 'number' || typeof totalSteps !== 'number') {
    console.error('Current step and total steps must be numbers')
    return null
  }

  if (currentStep < 1) {
    console.error('Current step cannot be less than 1')
    return null
  }

  if (currentStep > totalSteps) {
    console.error('Current step cannot be greater than total steps')
    return null
  }

  const numDigits = totalSteps.toString().length
  const width = numDigits * 8

  return (
    <div className="flex items-center justify-center space-x-1 text-muted">
      <span className="text-right text-xs" style={{ width }}>
        {currentStep}
      </span>
      <span className="text-xs">of</span>
      <span className="text-xs" style={{ width }}>
        {totalSteps}
      </span>
    </div>
  )
}

export default NumberedProgress
