import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import { Profession } from 'openapi/models/Profession'

import { shouldShowTitle, shouldShowYoe } from 'utils/user-profile-helpers'

import Modal from './components/modal'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import { WelcomeStepType } from 'components/common/flows/profile-setup/welcome-step'
import ProfileProfessionForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
} from 'components/common/profile/profile-primary-profession-form'

import { ProfileSetupFlowType } from './profile-setup'

export type ProfessionStepType = Step<
  WelcomeStepType['stepOutput'],
  {
    primaryProfession: Profession
  }
>

const professionSchema = z.object({
  primaryProfession: z.nativeEnum(Profession),
})

export type ProfileSchema = z.infer<typeof professionSchema>

const ProfessionStep: React.FC = () => {
  const { goBack, navigateToStep } = useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<ProfessionStepType>()
  const form = useForm<ProfileSchema>({
    resolver: zodResolver(professionSchema),
  })
  const { trackEvent } = useAnalytics()

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_profession_step_viewed', preparedEventProperties)
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    if (shouldShowTitle(data.primaryProfession)) {
      navigateToStep('job-title-step', {
        primaryProfession: data.primaryProfession,
        stepCounter: stepInput.stepCounter + 1,
      })
    } else if (shouldShowYoe(data.primaryProfession)) {
      navigateToStep('years-exp-step', {
        primaryProfession: data.primaryProfession,
        stepCounter: stepInput.stepCounter + 1,
      })
    } else {
      throw new Error('Invalid condition')
    }
  })

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      <ProfileProfessionForm control={form.control} />
    </Modal>
  )
}

export default ProfessionStep
