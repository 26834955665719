import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import {
  Plus,
  SquareAsterisk,
  Table2,
  Waypoints,
  Scale,
  LucideIcon,
  ChevronDown,
  ChevronUp,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { SkeletonBlock } from 'components/ui/skeleton'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import VaultDeleteWorkflow from 'components/vault/workflows/vault-delete-workflow'
import VaultNewWorkflow from 'components/vault/workflows/vault-new-workflow'
import VaultPublishWorkflow from 'components/vault/workflows/vault-publish-workflow'
import VaultUnpublishWorkflow from 'components/vault/workflows/vault-unpublish-workflow'

import { useVaultWorkflows } from './use-vault-workflows'
import VaultCreateBlank from './vault-create-blank'
import VaultWorkflowBuilder from './vault-workflow-builder'
import VaultWorkflowSelector from './vault-workflow-selector'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

const VaultWorkflowList = () => {
  const { workflows, isFetching } = useVaultWorkflows()
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const [isShowingAll, setIsShowingAll] = useState(false)

  const [isShowingDraftWorkflows, setIsShowingDraftWorkflows] = useState(false)
  useHotkeys(
    'Control+h',
    () => {
      if (userInfo.IsVaultWorkflowAdminUser) {
        // Only admins can see draft workflows
        setIsShowingDraftWorkflows(!isShowingDraftWorkflows)
      }
    },
    [isShowingDraftWorkflows]
  )

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm font-semibold">Create new</p>
      <div
        className="grid gap-3 gap-x-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        id="vault-workflow-list"
      >
        <NewWorkflowCard />
        {isFetching &&
          !workflows &&
          [0, 1, 2].map((i) => <WorkflowCardSkeleton key={i} index={i} />)}
        {workflows &&
          workflows
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((workflow) => ({
              ...workflow,
              icon: SquareAsterisk,
            }))
            .map((workflow, index) => (
              <WorkflowCard
                key={workflow.id}
                index={index}
                workflow={workflow}
                isShowingAll={isShowingAll}
              />
            ))}
        {userInfo.IsVaultWorkflowAdminUser && isShowingDraftWorkflows && (
          <AllWorkflowsCard />
        )}
        <VaultWorkflowSelector />
        <VaultNewWorkflow />
        <VaultPublishWorkflow />
        <VaultUnpublishWorkflow />
        <VaultDeleteWorkflow />
        <VaultWorkflowBuilder />
        <VaultCreateBlank />
      </div>
      <div
        className={cn('flex w-full items-center', {
          hidden: workflows && workflows.length <= 1,
          'hidden sm:flex md:hidden': workflows && workflows.length > 1,
          'hidden md:flex lg:hidden': workflows && workflows.length > 2,
          'hidden lg:flex': workflows && workflows.length > 3,
        })}
      >
        <hr className="grow border-t-[0.5px]" />
        <Button
          variant="outline"
          size="sm"
          className="w-fit gap-2"
          onClick={() => {
            trackEvent('Vault Workflow Show More Clicked', {
              show_all: !isShowingAll,
            })
            setIsShowingAll(!isShowingAll)
          }}
        >
          <p className="text-xs">{isShowingAll ? 'Show less' : 'Show more'}</p>
          <Icon size="small" icon={isShowingAll ? ChevronUp : ChevronDown} />
        </Button>
        <hr className="grow border-t-[0.5px]" />
      </div>
    </div>
  )
}

const WorkflowCard = ({
  workflow,
  index,
  isShowingAll,
}: {
  workflow: ReviewWorkflow
  index: number
  isShowingAll: boolean
}) => {
  const { trackEvent } = useAnalytics()

  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )
  return (
    <Card
      className={cn(
        'h-28 w-full items-center justify-center rounded border-none bg-accent p-4 transition hover:cursor-pointer hover:bg-accent-hover',
        {
          'hidden sm:flex': index === 0 && !isShowingAll,
          'hidden md:flex': index === 1 && !isShowingAll,
          'hidden lg:flex': index === 2 && !isShowingAll,
          hidden: index > 2 && !isShowingAll,
        }
      )}
      onClick={() => {
        trackEvent('Vault Workflow Clicked', {
          workflow_id: workflow.id,
          workflow_name: workflow.name,
        })
        setSelectedWorkflow(workflow)
        setWorkflowModalState(WorkflowModalState.Builder)
      }}
    >
      <div className="flex size-full flex-col items-start justify-between gap-4">
        <div className="flex flex-col justify-start gap-1">
          <p className="line-clamp-1 text-xs font-medium">{workflow.name}</p>
          <p className="line-clamp-2 text-xs text-muted">
            {workflow.description}
          </p>
        </div>
        <div className="flex items-center gap-1">
          <Icon icon={Table2} size="small" className="text-muted" />
          <p className="text-2xs text-muted">
            {workflow.columns.length} columns
          </p>
        </div>
      </div>
    </Card>
  )
}

const WorkflowCardSkeleton = ({ index }: { index: number }) => {
  return (
    <Card
      className={cn(
        'h-28 w-full items-center justify-center rounded border bg-accent p-4 shadow-sm',
        {
          'hidden sm:flex': index === 0,
          'hidden md:flex': index === 1,
          'hidden lg:flex': index === 2,
        }
      )}
    >
      <div className="flex h-full w-full flex-col items-start justify-between gap-2">
        <div className="flex w-full flex-col justify-start gap-2">
          <SkeletonBlock className="h-4 w-1/2" />
          <SkeletonBlock className="h-4 w-full" />
        </div>
        <SkeletonBlock className="h-4 w-full" />
      </div>
    </Card>
  )
}

const NewWorkflowCard = () => {
  const { trackEvent } = useAnalytics()

  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )

  return (
    <Card
      onClick={() => {
        trackEvent('Vault Create Blank Clicked')
        setSelectedWorkflow(null)
        setWorkflowModalState(WorkflowModalState.CreateBlank)
      }}
      className="flex h-28 w-auto shrink-0 flex-col items-start justify-between rounded border p-4 transition hover:cursor-pointer hover:border-input-focused"
      id="vault-new-workflow-card"
    >
      <div className="flex flex-col justify-start gap-1">
        <p className="text-xs font-medium">Create new query</p>
        <p className="text-xs text-muted">Choose query type and files</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <Icon icon={Plus} className="text-muted" />
      </div>
    </Card>
  )
}

const AllWorkflowsCard = () => {
  const setWorkflowModalState = useVaultWorkflowStore(
    (state) => state.setWorkflowModalState
  )
  return (
    <Card
      className="flex h-28 w-auto shrink-0 items-center justify-center rounded border bg-accent p-2.5 shadow-sm transition hover:cursor-pointer hover:border-input-focused"
      onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
    >
      <div className="grid grid-cols-2 items-center gap-2">
        <WorkflowThumbnail icon={Waypoints} />
        <WorkflowThumbnail icon={Table2} />
        <WorkflowThumbnail icon={Scale} />
        <WorkflowThumbnail text="View All" />
      </div>
    </Card>
  )
}

const WorkflowThumbnail = ({
  icon,
  text,
}: {
  icon?: LucideIcon
  text?: string
}) => {
  return (
    <div className="flex h-10 w-20 items-center justify-center rounded border">
      {icon && <Icon icon={icon} size="large" />}
      {text && <p className="text-xs font-medium">{text}</p>}
    </div>
  )
}

export default VaultWorkflowList
