import React from 'react'

import { displayFileUploadError } from 'utils/dropzone'

export function usePasswordProtectedFiles() {
  const [passwordProtectedFiles, setPasswordProtectedFiles] = React.useState<
    File[]
  >([])
  const [fileToPasswordMap, setFileToPasswordMap] = React.useState<
    Record<string, string>
  >({})
  const [isPasswordCollectionDialogOpen, setIsPasswordCollectionDialogOpen] =
    React.useState(false)

  const handlePasswordProtectedFiles = React.useCallback((files: File[]) => {
    setPasswordProtectedFiles(files)
    setIsPasswordCollectionDialogOpen(true)
  }, [])

  const handlePasswordChange = React.useCallback(
    (fileName: string, password: string) => {
      setFileToPasswordMap((prev) => ({ ...prev, [fileName]: password }))
    },
    []
  )

  const handleFileRemove = React.useCallback((fileName: string) => {
    setPasswordProtectedFiles((prev) =>
      prev.filter((file) => file.name !== fileName)
    )
    setFileToPasswordMap((prev) => {
      const newMap = { ...prev }
      delete newMap[fileName]
      return newMap
    })
  }, [])

  const resetStatePasswordProtectedFiles = React.useCallback(() => {
    setPasswordProtectedFiles([])
    setFileToPasswordMap({})
    setIsPasswordCollectionDialogOpen(false)
  }, [])

  const handleCancel = React.useCallback(() => {
    const fileNames = passwordProtectedFiles.map((file) => file.name)

    resetStatePasswordProtectedFiles()

    displayFileUploadError(
      fileNames,
      'The following password protected files were not uploaded: ',
      false
    )
  }, [passwordProtectedFiles, resetStatePasswordProtectedFiles])

  return {
    passwordProtectedFiles,
    fileToPasswordMap,
    isPasswordCollectionDialogOpen,
    handlePasswordProtectedFiles,
    handlePasswordChange,
    handleFileRemove,
    handleCancel,
    resetStatePasswordProtectedFiles,
  }
}
