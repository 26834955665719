import { snakeToCamel } from 'utils/utils'

import { WorkflowBlockDefinition, WorkflowDefinition } from './types'

export const stepBlockIdToWorkflowBlockDefinition = (
  workflowDefinition: WorkflowDefinition | null,
  blockId: string
) => {
  const res = workflowDefinition?.steps.find((step) => step.id === blockId)

  if (!res) {
    throw new Error(`Could not find block definition for blockId: ${blockId}`)
  }
  return res
}

// TODO: Add a stepsToConsider param so we only consider the steps before the current step
export const getInputParamsForBlock = (
  block: WorkflowBlockDefinition,
  completedData: Record<string, any>
) => {
  const params: Record<string, any> = {}

  const isVariableParam = (param: unknown): param is string =>
    typeof param === 'string' && param.startsWith('[[') && param.endsWith(']]')

  const resolveNestedValue = (data: any, keys: string[]): any => {
    return keys.reduce((acc, key) => {
      // XXX: This is jank, need to figure out casing for this stuff
      const camelCasedKey = snakeToCamel(key)

      if (acc && (key in acc || camelCasedKey in acc)) {
        return acc[key] || acc[camelCasedKey]
      } else {
        throw new Error(`Key not found: ${key} in ${keys.join('.')}`)
      }
    }, data)
  }

  Object.entries(block.blockParams).forEach(([paramName, param]) => {
    if (!isVariableParam(param)) {
      params[paramName] = param
      return
    }

    const paramLabel = param.slice(2, -2) // Remove [[ and ]]
    const [outputLabel, ...nestedKeys] = paramLabel.split('.')

    if (completedData[outputLabel]?.length) {
      let data = completedData[outputLabel].slice(-1)[0] // get the last value
      if (nestedKeys.length > 0) {
        data = resolveNestedValue(data, nestedKeys)
      }
      params[paramName] = data
    } else {
      throw new Error(`Missing param ${paramName} in completedData`)
    }
  })

  return params
}
