import React from 'react'
import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import Services from 'services'

import { displayErrorMessage } from 'utils/toast'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { Dropzone } from 'components/common/dropzone/dropzone'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
  AssistantWorkflowInputComponent,
} from './assistant-workflow-block-layout'

const retrieveFile = async (fileId: string) =>
  await Services.Backend.Get<UploadedFile>(`file/${fileId}`, {
    throwOnError: true,
  })

interface BlockStoreState {
  uploadedFile: UploadedFile | null
  isLoading: boolean
}

interface BlockStoreActions {
  setUploadedFile: (file: UploadedFile | null) => void
  setIsLoading: (loading: boolean) => void
}

const initialState: BlockStoreState = {
  uploadedFile: null,
  isLoading: false,
}

// TODO: Need to somehow reset the store at certain points
const createBlockStore = () =>
  create(
    devtools(
      immer<BlockStoreState & BlockStoreActions>((set) => ({
        ...initialState,

        reset: () => set(() => initialState),
        setUploadedFile: (file) =>
          set((state) => {
            state.uploadedFile = file
          }),
        setIsLoading: (loading) =>
          set((state) => {
            state.isLoading = loading
          }),
      }))
    )
  )

// TODO: Can we make this zudstand stuff more generic for other blocks?
export const useBlockStore = (
  stepIdx: number
): ReturnType<typeof createBlockStore> => {
  const [blockStores, addBlockStore] = useAssistantWorkflowStore(
    useShallow((state) => [state.blockStores, state.addBlockStore])
  )

  if (!blockStores[stepIdx]) {
    const blockStore = createBlockStore()
    addBlockStore(stepIdx, blockStore)
    return blockStore
  }

  return blockStores[stepIdx]
}

export const AssistantWorkflowFileUploadThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({ blockParams, outputData, stepIdx }) => {
  const { headerText } = blockParams
  const [uploadedFile, setUploadedFile] = useBlockStore(stepIdx)(
    useShallow((state) => [state.uploadedFile, state.setUploadedFile])
  )

  useEffect(() => {
    if (uploadedFile === null && outputData !== null) {
      retrieveFile(outputData.fileId).then((file) => {
        setUploadedFile(file)
      })
    }
  }, [uploadedFile, outputData, setUploadedFile])

  if (outputData !== null) {
    if (!uploadedFile) {
      return <p>Loading file...</p>
    }
    return (
      <AssistantWorkflowThreadBlock>
        <AssistantWorkflowHarveyComponent>
          <AssistantWorkflowThreadText text={headerText} />
        </AssistantWorkflowHarveyComponent>

        <AssistantWorkflowYouComponent>
          <AssistantWorkflowThreadText text={uploadedFile.name} />
        </AssistantWorkflowYouComponent>
      </AssistantWorkflowThreadBlock>
    )
  }

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText text={headerText} />
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFileUploadInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({ onCompleted, stepIdx }) => {
  const [isLoading, setUploadedFile, setIsLoading] = useBlockStore(stepIdx)(
    useShallow((state) => [
      state.isLoading,
      state.setUploadedFile,
      state.setIsLoading,
    ])
  )

  const onFileDrop = (files: File[]) => {
    setIsLoading(true)
    uploadFile(files[0])
      .then((uploaded) => {
        const result = { fileId: uploaded.id }
        setUploadedFile(uploaded)
        onCompleted(result)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFileDrop,
    maxFiles: 1,
    onDropRejected: () => {
      displayErrorMessage('File rejected')
    },
  })

  // TODO: Construct actual input component
  return (
    <AssistantWorkflowInputComponent>
      <Dropzone
        isLoading={isLoading}
        description="Drop a file here or click to upload"
        dropzone={{ getRootProps, getInputProps }}
      />
    </AssistantWorkflowInputComponent>
  )
}
