import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'

import { FetchVaultFolderHistoryStats } from './vault-fetcher'

export const useVaultProjectHistoryStats = (projectId: string) => {
  const { data, isFetching } = useWrappedQuery({
    refetchOnWindowFocus: false,
    queryKey: [HarvQueryKeyPrefix.VaultHistoryStatsQuery, projectId],
    queryFn: () => FetchVaultFolderHistoryStats(projectId),
    enabled: !!projectId,
    refetchInterval: (query) => {
      // Poll for history stats updates every 10 seconds if there are any in progress
      if (
        query.state.status === 'success' &&
        query.state.data &&
        query.state.data.inProgressCount > 0
      ) {
        return 10_000
      }
      return false
    },
  })
  return { historyStats: data, isLoadingHistoryStats: isFetching && !data }
}
