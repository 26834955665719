import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import AskHarveyButton from 'components/common/ask-harvey-button'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { Textarea, TextareaProps } from 'components/ui/text-area'
import { emitScrollToBottomOfReport } from 'components/workflows/workflow/discovery/common/scroll-to-report-bottom-event'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

interface Props extends TextareaProps {
  inputRef?: React.MutableRefObject<HTMLTextAreaElement | null>
  onAsk: () => void
  onCancel: () => void
}

const FollowUpInput = ({ inputRef, onAsk, onCancel }: Props) => {
  const [
    currentFollowUpInput,
    setCurrentFollowUpInput,
    isStreamingFollowUp,
    isRunningProcessSections,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.currentFollowUpInput,
      s.setCurrentFollowUpInput,
      s.isStreamingFollowUp,
      s.isRunningProcessSections,
    ])
  )

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const mergedRefs = (ref: HTMLTextAreaElement) => {
    textareaRef.current = ref
    if (inputRef) inputRef.current = ref
  }

  const placeholder = 'Ask a follow-up question'

  const getAskHarveyTooltipText = () => {
    if (isRunningProcessSections) {
      return 'Processing sections, please wait'
    }
    if (isStreamingFollowUp) {
      return 'Please wait while the follow-up is being processed'
    }
    if (!currentFollowUpInput) {
      return 'Please enter a query'
    }
    return undefined
  }

  const handleAskClick = () => {
    emitScrollToBottomOfReport()
    onAsk()
  }

  const handleCancelClick = () => {
    onCancel()
  }

  const cancelButtonTooltipText =
    'Cancel the current query. Existing output will be preserved.'

  const CancelButton = isStreamingFollowUp ? (
    <Button
      className="ml-auto"
      variant="destructive"
      tooltip={cancelButtonTooltipText}
      onClick={handleCancelClick}
    >
      Cancel Query
    </Button>
  ) : null

  const askHarveyTooltipText = getAskHarveyTooltipText()

  const inputComponent = (
    <Card className="border-none">
      <CardContent className="relative rounded p-0 focus-within:ring-1 focus-within:ring-ring">
        <Label className="sr-only" htmlFor="follow-up-input">
          {placeholder}
        </Label>
        <Textarea
          id="follow-up-input"
          className="max-h-60 min-h-0 resize-none rounded-[3px] rounded-b-none border-b-0 p-6 pb-1 text-xs ring-inset placeholder:text-xs focus-visible:ring-0 disabled:cursor-default"
          disabled={isStreamingFollowUp}
          isFluid
          onChange={(e) => setCurrentFollowUpInput(e.target.value)}
          placeholder={placeholder}
          ref={mergedRefs}
          value={currentFollowUpInput}
        />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className="absolute right-2 -mt-4" />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="flex justify-end rounded-b border border-t-0 p-2 pt-5 ring-inset"
          onClick={() => textareaRef.current?.focus()}
        >
          {CancelButton}
          <AskHarveyButton
            className="pointer-events-auto ml-2 w-auto shrink-0 whitespace-nowrap text-xs font-semibold"
            handleSubmit={handleAskClick}
            tooltip={askHarveyTooltipText}
            disabled={!!askHarveyTooltipText}
            isLoading={isStreamingFollowUp}
            inputRef={textareaRef}
          />
        </div>
      </CardContent>
    </Card>
  )

  return <div id="discovery-follow-up">{inputComponent}</div>
}

export default FollowUpInput
