/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TranscriptType } from './TranscriptType';
import {
    TranscriptTypeFromJSON,
    TranscriptTypeFromJSONTyped,
    TranscriptTypeToJSON,
    TranscriptTypeToJSONTyped,
} from './TranscriptType';
import type { TranscriptsQuestionAnswerAnswer } from './TranscriptsQuestionAnswerAnswer';
import {
    TranscriptsQuestionAnswerAnswerFromJSON,
    TranscriptsQuestionAnswerAnswerFromJSONTyped,
    TranscriptsQuestionAnswerAnswerToJSON,
    TranscriptsQuestionAnswerAnswerToJSONTyped,
} from './TranscriptsQuestionAnswerAnswer';

/**
 * 
 * @export
 * @interface TranscriptsQuestionAnswer
 */
export interface TranscriptsQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof TranscriptsQuestionAnswer
     */
    question: string;
    /**
     * 
     * @type {TranscriptType}
     * @memberof TranscriptsQuestionAnswer
     */
    documentType: TranscriptType;
    /**
     * 
     * @type {TranscriptsQuestionAnswerAnswer}
     * @memberof TranscriptsQuestionAnswer
     */
    answer: TranscriptsQuestionAnswerAnswer;
    /**
     * 
     * @type {boolean}
     * @memberof TranscriptsQuestionAnswer
     */
    isLoading: boolean;
}



/**
 * Check if a given object implements the TranscriptsQuestionAnswer interface.
 */
export function instanceOfTranscriptsQuestionAnswer(value: object): value is TranscriptsQuestionAnswer {
    if (!('question' in value) || value['question'] === undefined) return false;
    if (!('documentType' in value) || value['documentType'] === undefined) return false;
    if (!('answer' in value) || value['answer'] === undefined) return false;
    if (!('isLoading' in value) || value['isLoading'] === undefined) return false;
    return true;
}

export function TranscriptsQuestionAnswerFromJSON(json: any): TranscriptsQuestionAnswer {
    return TranscriptsQuestionAnswerFromJSONTyped(json, false);
}

export function TranscriptsQuestionAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsQuestionAnswer {
    if (json == null) {
        return json;
    }
    return {
        
        'question': json['question'],
        'documentType': TranscriptTypeFromJSON(json['document_type']),
        'answer': TranscriptsQuestionAnswerAnswerFromJSON(json['answer']),
        'isLoading': json['is_loading'],
    };
}

export function TranscriptsQuestionAnswerToJSON(json: any): TranscriptsQuestionAnswer {
    return TranscriptsQuestionAnswerToJSONTyped(json, false);
}

export function TranscriptsQuestionAnswerToJSONTyped(value?: TranscriptsQuestionAnswer | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'question': value['question'],
        'document_type': TranscriptTypeToJSON(value['documentType']),
        'answer': TranscriptsQuestionAnswerAnswerToJSON(value['answer']),
        'is_loading': value['isLoading'],
    };
}

