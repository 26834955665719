/* tslint:disable */
/* eslint-disable */
/**
 * Vault API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReviewWorkflowKind = {
    LITIGATION: 'LITIGATION',
    FINANCE: 'FINANCE',
    MUNICIPAL: 'MUNICIPAL',
    INTERNATIONAL: 'INTERNATIONAL',
    REPRESENTATION_AND_WARRANTY_INSURANCE: 'REPRESENTATION_AND_WARRANTY_INSURANCE'
} as const;
export type ReviewWorkflowKind = typeof ReviewWorkflowKind[keyof typeof ReviewWorkflowKind];


export function instanceOfReviewWorkflowKind(value: any): boolean {
    for (const key in ReviewWorkflowKind) {
        if (Object.prototype.hasOwnProperty.call(ReviewWorkflowKind, key)) {
            if (ReviewWorkflowKind[key as keyof typeof ReviewWorkflowKind] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ReviewWorkflowKindFromJSON(json: any): ReviewWorkflowKind {
    return ReviewWorkflowKindFromJSONTyped(json, false);
}

export function ReviewWorkflowKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewWorkflowKind {
    return json as ReviewWorkflowKind;
}

export function ReviewWorkflowKindToJSON(value?: ReviewWorkflowKind | null): any {
    return value as any;
}

export function ReviewWorkflowKindToJSONTyped(value: any, ignoreDiscriminator: boolean): ReviewWorkflowKind {
    return value as ReviewWorkflowKind;
}

