/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Taxonomy for practice areas.
 * @export
 */
export const PracticeAreaTaxonomy = {
    TRANSACTIONAL: 'TRANSACTIONAL',
    LITIGATION: 'LITIGATION'
} as const;
export type PracticeAreaTaxonomy = typeof PracticeAreaTaxonomy[keyof typeof PracticeAreaTaxonomy];


export function instanceOfPracticeAreaTaxonomy(value: any): boolean {
    for (const key in PracticeAreaTaxonomy) {
        if (Object.prototype.hasOwnProperty.call(PracticeAreaTaxonomy, key)) {
            if (PracticeAreaTaxonomy[key as keyof typeof PracticeAreaTaxonomy] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PracticeAreaTaxonomyFromJSON(json: any): PracticeAreaTaxonomy {
    return PracticeAreaTaxonomyFromJSONTyped(json, false);
}

export function PracticeAreaTaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeAreaTaxonomy {
    return json as PracticeAreaTaxonomy;
}

export function PracticeAreaTaxonomyToJSON(value?: PracticeAreaTaxonomy | null): any {
    return value as any;
}

export function PracticeAreaTaxonomyToJSONTyped(value: any, ignoreDiscriminator: boolean): PracticeAreaTaxonomy {
    return value as PracticeAreaTaxonomy;
}

