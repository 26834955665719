/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying professions.
 * @export
 */
export const Profession = {
    LEGAL: 'LEGAL',
    TAX: 'TAX',
    FINANCE: 'FINANCE',
    ACCOUNTING: 'ACCOUNTING',
    CONSULTING: 'CONSULTING'
} as const;
export type Profession = typeof Profession[keyof typeof Profession];


export function instanceOfProfession(value: any): boolean {
    for (const key in Profession) {
        if (Object.prototype.hasOwnProperty.call(Profession, key)) {
            if (Profession[key as keyof typeof Profession] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProfessionFromJSON(json: any): Profession {
    return ProfessionFromJSONTyped(json, false);
}

export function ProfessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profession {
    return json as Profession;
}

export function ProfessionToJSON(value?: Profession | null): any {
    return value as any;
}

export function ProfessionToJSONTyped(value: any, ignoreDiscriminator: boolean): Profession {
    return value as Profession;
}

