/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PdfKitSource } from './PdfKitSource';
import {
    PdfKitSourceFromJSON,
    PdfKitSourceFromJSONTyped,
    PdfKitSourceToJSON,
    PdfKitSourceToJSONTyped,
} from './PdfKitSource';

/**
 * 
 * @export
 * @interface TranscriptsDocumentTopic
 */
export interface TranscriptsDocumentTopic {
    /**
     * 
     * @type {string}
     * @memberof TranscriptsDocumentTopic
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof TranscriptsDocumentTopic
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof TranscriptsDocumentTopic
     */
    witness: string;
    /**
     * 
     * @type {Array<PdfKitSource>}
     * @memberof TranscriptsDocumentTopic
     */
    sources?: Array<PdfKitSource>;
}

/**
 * Check if a given object implements the TranscriptsDocumentTopic interface.
 */
export function instanceOfTranscriptsDocumentTopic(value: object): value is TranscriptsDocumentTopic {
    if (!('topic' in value) || value['topic'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('witness' in value) || value['witness'] === undefined) return false;
    return true;
}

export function TranscriptsDocumentTopicFromJSON(json: any): TranscriptsDocumentTopic {
    return TranscriptsDocumentTopicFromJSONTyped(json, false);
}

export function TranscriptsDocumentTopicFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsDocumentTopic {
    if (json == null) {
        return json;
    }
    return {
        
        'topic': json['topic'],
        'summary': json['summary'],
        'witness': json['witness'],
        'sources': json['sources'] == null ? undefined : ((json['sources'] as Array<any>).map(PdfKitSourceFromJSON)),
    };
}

export function TranscriptsDocumentTopicToJSON(json: any): TranscriptsDocumentTopic {
    return TranscriptsDocumentTopicToJSONTyped(json, false);
}

export function TranscriptsDocumentTopicToJSONTyped(value?: TranscriptsDocumentTopic | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'topic': value['topic'],
        'summary': value['summary'],
        'witness': value['witness'],
        'sources': value['sources'] == null ? undefined : ((value['sources'] as Array<any>).map(PdfKitSourceToJSON)),
    };
}

