import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { LucideIcon } from 'lucide-react'

import { ResearchArea } from 'openapi/models/ResearchArea'

import { cn } from 'utils/utils'

import {
  DATABASE_SOURCE_TO_RESEARCH_AREA,
  RESEARCH_AREA_TO_DATABASE_SOURCE,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import {
  DialogContent,
  Dialog,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'

type Props = {
  title: React.ReactNode
  description: React.ReactNode
  area?: ResearchArea
  isLoading?: boolean
  isPendingRelease?: boolean
  icon: LucideIcon
}

export const ResearchAreaCard = ({
  title,
  description,
  area,
  isLoading,
  isPendingRelease,
  icon,
}: Props) => {
  const userInfo = useAuthUser()
  const location = useLocation()
  const navigate = useNavigate()
  const destination = {
    pathname: area ? `/research/${area}` : '/research',
    search: location.search, // Retains current query parameters
  }

  const isAreaInAssistantKS = () => {
    if (!area) return false
    const migratedResearchAreas = Object.values(
      DATABASE_SOURCE_TO_RESEARCH_AREA
    )

    if (
      area === ResearchArea.TAX &&
      userInfo.IsAssistantTaxKnowledgeSourceUser
    ) {
      return true
    } else if (
      userInfo.IsAssistantResearchKSUser &&
      migratedResearchAreas.includes(area)
    ) {
      return true
    }

    return false
  }

  const cardComponent = (
    <Card className="flex h-64 flex-col justify-between transition hover:border-input-focused">
      <CardHeader className="flex">
        {isPendingRelease && (
          <Badge className="z-20 h-fit" variant="outline">
            Coming soon
          </Badge>
        )}

        <Icon icon={icon} size="largeWithBackground" />
      </CardHeader>
      <CardContent>
        <h2 className="text-base">{title}</h2>
        <p className="mt-1 text-xs">{description}</p>
      </CardContent>
    </Card>
  )

  const navigateToResearchArea = () => {
    const knowledgeSource = area ? RESEARCH_AREA_TO_DATABASE_SOURCE[area] : null
    navigate(
      '/assistant',
      knowledgeSource ? { state: { knowledgeSource } } : undefined
    )
  }

  if (isAreaInAssistantKS()) {
    return (
      <Dialog>
        <DialogTrigger className="text-left">{cardComponent}</DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Query {title} in Assistant</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            {title} has moved to Assistant. To get an answer over this knowledge
            source, go to Assistant and choose {title}.
          </DialogDescription>
          <DialogFooter>
            <Button onClick={navigateToResearchArea} variant="default">
              Open Assistant
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Link
      to={destination}
      className={cn('rounded-lg', {
        'research-area-card': !isLoading,
        'cursor-disabled pointer-events-none opacity-50': isPendingRelease,
        'animate-pulse': isLoading,
      })}
    >
      {cardComponent}
    </Link>
  )
}
