import React from 'react'

import Lottie from 'lottie-react'

import attentionDotAnimation from './attention-dot.json'

interface AttentionDotProps {
  children: React.ReactNode
  isDisabled: boolean
}

const AttentionDot: React.FC<AttentionDotProps> = ({
  children,
  isDisabled,
}) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center justify-center">
        {children}
      </div>
      <Lottie
        loop={!isDisabled}
        disabled={isDisabled}
        animationData={attentionDotAnimation}
      />
    </div>
  )
}

export default AttentionDot
