import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import Modal from './components/modal'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import ProfileExperienceForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
  MAX_YOE,
  MIN_YOE,
} from 'components/common/profile/profile-yoe-form'

import { ProfessionStepType } from './profession-step'
import { ProfileSetupFlowType } from './profile-setup'

export type YearsExpStepType = Step<
  ProfessionStepType['stepOutput'],
  {
    yoe: number
  }
>

const yoeSchema = z.object({
  yoe: z.coerce.number().int().min(MIN_YOE).max(MAX_YOE),
})

export type YoeSchema = z.infer<typeof yoeSchema>

const YearsExpStep: React.FC = () => {
  const { goBack, navigateToStep } = useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<YearsExpStepType>()
  const form = useForm<YoeSchema>({
    resolver: zodResolver(yoeSchema),
  })
  const { trackEvent } = useAnalytics()

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_years_exp_step_viewed', preparedEventProperties)
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    navigateToStep('practice-area-step', {
      ...stepInput,
      yoe: data.yoe,
      stepCounter: stepInput.stepCounter + 1,
    })
  })

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      <ProfileExperienceForm control={form.control} />
    </Modal>
  )
}

export default YearsExpStep
