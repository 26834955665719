import React, { useEffect, useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  DATABASE_SOURCE_TO_RESEARCH_AREA,
  DatabaseSource,
  KnowledgeSourceItem,
  isResearchKnowledgeSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'
import Explorer from 'components/research/explorer'
import { getAllChildrenDeep } from 'components/research/research-helpers'
import {
  JURISDICTION_TITLE,
  TopLevelJurisdiction,
} from 'components/research/usacaselaw/constants'

type Props = {
  databaseSource: DatabaseSource
  onClose: () => void
  setAskHarveyDisabled: (disabled: boolean) => void
  defaultKnowledgeSource?: KnowledgeSourceItem
}

export const AssistantResearchKnowledgeSource = ({
  databaseSource,
  setAskHarveyDisabled,
}: Props) => {
  const userInfo = useAuthUser()
  const researchArea = DATABASE_SOURCE_TO_RESEARCH_AREA[databaseSource]
  const { taxonomy, isLoading: isTaxonomyLoading } = useResearchTaxonomyQuery(
    researchArea || null,
    userInfo.IsHarveyV1ResearchUser
  )

  const allFiltersFlattened = useMemo(() => {
    const researchFilters = taxonomy
    return researchFilters.concat(
      researchFilters.flatMap((researchFilter) =>
        getAllChildrenDeep(researchFilter)
      )
    )
  }, [taxonomy])

  const [knowledgeSource, setKnowledgeSource] = useAssistantStore(
    useShallow((s) => [s.knowledgeSource, s.setKnowledgeSource])
  )

  const selectedFilters = useMemo(() => {
    if (isResearchKnowledgeSource(knowledgeSource)) {
      const filterSet = new Set(knowledgeSource.filterIds)
      const filters = allFiltersFlattened.filter((filter) =>
        filterSet.has(filter.id)
      )
      return filters
    }
    return []
  }, [knowledgeSource, allFiltersFlattened])

  // Disable the "Ask Harvey" button if no filters are selected
  // and the knowledge source is a research knowledge source
  useEffect(() => {
    const isDisabled =
      isResearchKnowledgeSource(knowledgeSource) && !selectedFilters.length
    setAskHarveyDisabled(isDisabled)
  }, [selectedFilters, setAskHarveyDisabled, knowledgeSource])

  const onSetSelectedFilters = (filters: ResearchFilter[]) => {
    const filterIds = filters.flatMap((filter) => filter.id)

    setKnowledgeSource({
      ...knowledgeSource,
      type: databaseSource,
      filterIds,
    })
  }

  const areaTaxonomy = useMemo(() => {
    if (researchArea === ResearchArea.USCASELAW) {
      const federalCourts = formatCaseLawTaxonomy(
        TopLevelJurisdiction.Federal,
        taxonomy
      )
      const stateCourts = formatCaseLawTaxonomy(
        TopLevelJurisdiction.State,
        taxonomy
      )
      return [federalCourts, stateCourts]
    }
    return taxonomy
  }, [taxonomy, researchArea])

  if (!researchArea) return null

  return (
    <Explorer
      isOpen
      taxonomy={areaTaxonomy}
      selectedFilters={selectedFilters}
      setSelectedFilters={onSetSelectedFilters}
      isLoading={isTaxonomyLoading}
      researchArea={researchArea}
    />
  )
}

const formatCaseLawTaxonomy = (
  courtId: TopLevelJurisdiction,
  taxonomy: ResearchFilter[]
): ResearchFilter => {
  const children = taxonomy
    .filter((filter) => filter.id.includes(courtId))
    // Don't show additional filters, they will be selected by default
    .map((filter) => ({ ...filter, children: [] }))

  return {
    id: courtId,
    name: JURISDICTION_TITLE[courtId],
    exclusionTag: children[0]?.exclusionTag,
    children,
    parentId: null,
  }
}
