import { useCallback, useEffect, useRef } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useAuthUser } from 'components/common/auth-context'

import { SubscribeToVaultSharingUpdates } from './vault-fetcher'
import { fetchProjectMetadata } from './vault-helpers'
import { useVaultSharingStore } from './vault-sharing-store'
import { useVaultStore } from './vault-store'

export const useVaultShareUpdatesSubscription = () => {
  const userInfo = useAuthUser()
  const sharedProjectIds = useVaultStore(
    useShallow((state) => state.sharedProjectIds)
  )
  const allFoldersMetadata = useVaultStore(
    useShallow((state) => state.allFoldersMetadata)
  )
  const exampleProjectIds = useVaultStore((state) => state.exampleProjectIds)
  const isLayoutLoading = useVaultStore((state) => state.isLayoutLoading)
  const upsertVaultFolders = useVaultStore((state) => state.upsertVaultFolders)
  const deleteVaultFolders = useVaultStore((state) => state.deleteVaultFolders)
  const upsertVaultFiles = useVaultStore((state) => state.upsertVaultFiles)
  const deleteVaultFiles = useVaultStore((state) => state.deleteVaultFiles)
  const addToProjectsMetadata = useVaultStore(
    (state) => state.addToProjectsMetadata
  )
  const setPermissionsForProjectId = useVaultSharingStore(
    (state) => state.setPermissionsForProjectId
  )

  const lastUpdatedAt = useRef(new Date())
  const subscribeToVaultSharingUpdates = useCallback(async () => {
    const response = await SubscribeToVaultSharingUpdates(lastUpdatedAt.current)
    if (!response.hasSharingUpdates) {
      // No updates found, so we can skip the rest of the logic
      return
    }
    lastUpdatedAt.current = new Date()
    const deletedSharedProjectIds = Array.from(sharedProjectIds).filter(
      (projectId) =>
        !response.updatedSharedProjects?.some(
          (project) => project.id === projectId
        )
    )
    deleteVaultFolders(deletedSharedProjectIds, undefined)
    for (const projectId of deletedSharedProjectIds) {
      deleteVaultFiles(
        allFoldersMetadata[projectId]?.descendantFiles?.map(
          (file) => file.id
        ) ?? [],
        projectId
      )
      deleteVaultFolders(
        allFoldersMetadata[projectId]?.descendantFolders?.map(
          (folder) => folder.id
        ) ?? [],
        projectId
      )
      setPermissionsForProjectId({
        projectId,
        userId: userInfo.dbId,
        workspaceId: userInfo.workspace.id,
        permissions: null,
      })
    }

    const updatedSharedProjects = (response.updatedSharedProjects ?? []).filter(
      (folder) => !folder.deletedAt
    )

    for (const project of updatedSharedProjects) {
      upsertVaultFolders(
        [project],
        userInfo.dbId,
        exampleProjectIds.has(project.id),
        project.id
      )
    }
    await Promise.all(
      updatedSharedProjects.map(async (project) => {
        try {
          const projectsMetadata = await fetchProjectMetadata(project)
          addToProjectsMetadata(projectsMetadata)
          Object.values(projectsMetadata).forEach((projectMetadata) => {
            upsertVaultFiles(projectMetadata.descendantFiles ?? [], project.id)
            upsertVaultFolders(
              projectMetadata.descendantFolders ?? [],
              userInfo.dbId,
              exampleProjectIds.has(project.id),
              project.id
            )
          })
        } catch (e) {
          console.error('Error fetching project metadata', e)
        }
      })
    )
  }, [
    sharedProjectIds,
    setPermissionsForProjectId,
    userInfo.dbId,
    userInfo.workspace.id,
    upsertVaultFolders,
    allFoldersMetadata,
    deleteVaultFiles,
    deleteVaultFolders,
    addToProjectsMetadata,
    upsertVaultFiles,
    exampleProjectIds,
  ])

  // Track failures and stop polling after 5 consecutive failures
  const failureCount = useRef(0)
  const isPolling = useRef(false)
  useEffect(() => {
    if (!userInfo.IsVaultViewSharesUser) {
      // Don't poll if the user is not the vault view shares user
      return
    }
    if (isLayoutLoading) {
      // Don't poll if the vault home page is still loading
      return
    }

    const intervalId = setInterval(async () => {
      if (document.visibilityState !== 'visible') {
        // Don't poll if the tab is not visible
        return
      }
      if (!navigator.onLine) {
        // Don't poll if the network is offline
        return
      }
      if (!isPolling.current) {
        isPolling.current = true
        try {
          // This call might take up to 45 seconds to finish if no updates are found.
          await subscribeToVaultSharingUpdates()
        } catch (error) {
          console.error('Error subscribing to vault sharing updates', error)
          failureCount.current += 1
          if (failureCount.current >= 5) {
            console.info(
              'Stopping vault sharing updates polling due to repeated failures'
            )
            clearInterval(intervalId)
          }
        }
        isPolling.current = false
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [
    subscribeToVaultSharingUpdates,
    userInfo.IsVaultViewSharesUser,
    isLayoutLoading,
  ])
}
