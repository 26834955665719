import React, { RefObject, useState } from 'react'

import { Check, ThumbsDown, ThumbsUp } from 'lucide-react'

import { MessageFeedback } from 'openapi/models/MessageFeedback'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import ToolbarButton from './toolbar-button'

export const FEEDBACK_COMMENTS = [
  'Hallucination',
  'Incomplete response',
  'Poor formatting',
  'Something else',
]

export const FEEDBACK_WITH_DOCUMENTS_COMMENTS = [
  'Hallucination',
  'Incomplete response',
  'Citation error',
  'Poor formatting',
  'Something else',
]

export const FeedbackButton = ({
  hasDocuments,
  onSubmit,
  sentiment,
  sentimentValue,
  buttonSize = 'smIcon',
  iconSize = 'default',
  feedbackSpanRef,
}: {
  hasDocuments?: boolean
  onSubmit: (feedback: MessageFeedback) => Promise<boolean>
  sentiment: number
  sentimentValue: number
  buttonSize?: 'xsIcon' | 'smIcon'
  iconSize?: 'small' | 'default'
  feedbackSpanRef?: RefObject<HTMLSpanElement>
}) => {
  const isPositive = sentimentValue > 0

  const submitFeedback = (comments?: string[]) =>
    onSubmit({ sentiment: sentimentValue, selectedComments: comments ?? [] })
  const postSubmitMessage = isPositive
    ? 'We’re glad to hear the response was good'
    : () => (
        <FeedbackCommentPicker
          hasDocuments={hasDocuments}
          onSubmit={submitFeedback}
          feedbackSpanRef={feedbackSpanRef}
        />
      )
  const handleSubmit = async () => {
    const submitted = await submitFeedback()
    if (submitted) {
      displaySuccessMessage(
        postSubmitMessage,
        isPositive ? 2 : 20,
        'Thanks for sending us feedback'
      )
    } else {
      displayErrorMessage('Failed to submit feedback')
    }
  }

  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger asChild>
        <ToolbarButton
          aria-label={
            isPositive
              ? 'The response was good'
              : 'The response could be improved'
          }
          className={cn('disabled:opacity-100', {
            'bg-button-secondary disabled:hover:bg-button-secondary':
              sentiment === sentimentValue,
          })}
          size={buttonSize}
          disabled={sentiment === sentimentValue}
          onClick={handleSubmit}
          tooltip={
            isPositive
              ? 'The response was good'
              : 'The response could be improved'
          }
          data-testid={`feedback-button-${sentimentValue}`}
        >
          <Icon icon={isPositive ? ThumbsUp : ThumbsDown} size={iconSize} />
        </ToolbarButton>
      </TooltipTrigger>
      <TooltipContent>
        {isPositive
          ? 'The response was good'
          : 'The response could be improved'}
      </TooltipContent>
    </Tooltip>
  )
}

const FeedbackCommentPicker = ({
  hasDocuments,
  onSubmit,
  feedbackSpanRef,
}: {
  hasDocuments?: boolean
  onSubmit: (comments?: string[]) => Promise<boolean>
  feedbackSpanRef?: RefObject<HTMLSpanElement>
}) => {
  const [selectedComments, setSelectedComments] = useState<string[]>([])
  const handleSelectComment = async (comment: string) => {
    const selected = selectedComments.includes(comment)
      ? selectedComments.filter((c) => c !== comment)
      : [...selectedComments, comment]

    setSelectedComments(selected)
    const submitted = await onSubmit(selected)
    if (!submitted) {
      displayErrorMessage('Failed to choose the issue')
    }
  }

  const feedbackOptions = hasDocuments
    ? FEEDBACK_WITH_DOCUMENTS_COMMENTS
    : FEEDBACK_COMMENTS

  return (
    <>
      Please choose all that apply to describe the issue(s) with the response
      you received
      <span ref={feedbackSpanRef} className="mt-2.5 flex flex-wrap gap-1">
        {feedbackOptions.map((comment) => {
          const isSelected = selectedComments.includes(comment)
          return (
            <Button
              key={comment}
              onClick={() => handleSelectComment(comment)}
              size="sm"
              variant="outline"
            >
              {isSelected && (
                <Icon icon={Check} size="small" className="mr-1" />
              )}
              {comment}
            </Button>
          )
        })}
      </span>
    </>
  )
}
