import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { DatabaseSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'

export const useIsCuatrecasas = () => {
  const userInfo = useAuthUser()
  const knowledgeSource = useAssistantStore((s) => s.knowledgeSource)

  const isCuatrecasas = !!(knowledgeSource?.type === DatabaseSource.CUATRECASAS)
  const isInternalOrCuatrecasasUser =
    userInfo.IsInternalUser || userInfo.IsCuatrecasasUser

  return isCuatrecasas && isInternalOrCuatrecasasUser
}
