import * as React from 'react'
import { Control, Controller } from 'react-hook-form'

import { AccountingPracticeAreaOptions } from 'openapi/models/AccountingPracticeAreaOptions'
import { ConsultingPracticeAreaOptions } from 'openapi/models/ConsultingPracticeAreaOptions'
import { FinancePracticeAreaOptions } from 'openapi/models/FinancePracticeAreaOptions'
import { LegalPracticeAreaOptions } from 'openapi/models/LegalPracticeAreaOptions'
import { PracticeArea } from 'openapi/models/PracticeArea'
import { Profession } from 'openapi/models/Profession'
import { TaxPracticeAreaOptions } from 'openapi/models/TaxPracticeAreaOptions'

import { replaceAndWithAmpersand, toTitleCase } from 'utils/string'

import SelectableTag from './selectable-tag'

interface ProfilePracticeAreasFormProps {
  control: Control<any>
  primaryProfession: Profession
}

const OPTIONS = {
  [Profession.LEGAL]: Object.values(LegalPracticeAreaOptions).map(
    (option) => option as PracticeArea
  ),
  [Profession.FINANCE]: Object.values(FinancePracticeAreaOptions).map(
    (option) => option as PracticeArea
  ),
  [Profession.TAX]: Object.values(TaxPracticeAreaOptions).map(
    (option) => option as PracticeArea
  ),
  [Profession.ACCOUNTING]: Object.values(AccountingPracticeAreaOptions).map(
    (option) => option as PracticeArea
  ),
  [Profession.CONSULTING]: Object.values(ConsultingPracticeAreaOptions).map(
    (option) => option as PracticeArea
  ),
}

export const FORM_HEADING = 'What is your practice area?'
export const FORM_DESCRIPTION =
  'Content most relevant to your practice areas will be presented first in Harvey.'

const ProfilePracticeAreasForm: React.FC<ProfilePracticeAreasFormProps> = ({
  control,
  primaryProfession,
}) => {
  const practiceAreaOptions = OPTIONS[primaryProfession].map((area) => ({
    value: area,
    label: replaceAndWithAmpersand(toTitleCase(area)),
  }))

  return (
    <div>
      <div className="mb-2 text-xs text-secondary">Select up to 3.</div>
      <Controller
        name="practiceAreas"
        control={control}
        render={({ field }) => {
          // Ensure field.value is always an array
          const selectedAreas = field.value || []

          return (
            <div className="flex flex-wrap gap-2">
              {practiceAreaOptions.map((option) => {
                const checked = selectedAreas.includes(option.value)
                const toggle = () =>
                  field.onChange(
                    checked
                      ? // Already in value: Remove from value
                        selectedAreas.filter(
                          (id: PracticeArea) => id !== option.value
                        )
                      : // Not yet in value: Add to value
                        [...selectedAreas, option.value]
                  )
                return (
                  <SelectableTag
                    key={option.value}
                    text={option.label}
                    checked={checked}
                    setChecked={toggle}
                    disabled={
                      !selectedAreas.includes(option.value) &&
                      (field.disabled || selectedAreas.length >= 3)
                    }
                  />
                )
              })}
            </div>
          )
        }}
      />
    </div>
  )
}

export default ProfilePracticeAreasForm
