import React from 'react'

import { Plus } from 'lucide-react'

import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Icon } from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import { newProjectPath } from 'components/vault/utils/vault'
import { getVaultProjects } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface Props {
  className?: string
  height?: number
  size?: 'small' | 'default'
  source?: string // Source for analytics
}

export const VaultCreateProjectCard = ({
  className,
  height,
  size = 'default',
  source,
}: Props) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const allFolderIdToVaultFolder = useVaultStore(
    (s) => s.allFolderIdToVaultFolder
  )
  const rootVaultFolderIds = useVaultStore((s) => s.rootVaultFolderIds)

  const vaultProjects = getVaultProjects({
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    userId: userInfo.dbId,
  })
  const projectsCountLimit = userInfo.workspace.getVaultProjectsCountLimit(
    userInfo.vaultFeature
  )
  const projectFilesCountLimitDisplayText = `Each project can contain up to ${userInfo.workspace
    .getVaultFilesCountLimit(userInfo.vaultFeature)
    .toLocaleString()} files`
  if (vaultProjects.length >= projectsCountLimit) {
    return null
  }

  return (
    <div
      style={{
        height,
      }}
      className={cn(
        'relative flex w-full flex-col items-center justify-center rounded-lg border border-dashed p-6 shadow-sm hover:cursor-pointer hover:border-input-focused',
        className
      )}
    >
      <Icon icon={Plus} size="large" className="text-muted" />
      <Link
        to={`${BaseAppPath.Vault}${newProjectPath}`}
        onClick={() => {
          trackEvent('Vault New Project Button Clicked', {
            source: source ?? 'new-project-card',
          })
        }}
        className={cn('apply-click-on-parent mt-2', {
          'text-sm': size === 'small',
        })}
        data-testid="vault-new-project-card-link"
      >
        New project
      </Link>
      <p className="mt-1 text-center text-xs text-muted">
        {projectFilesCountLimitDisplayText}
      </p>
    </div>
  )
}
