import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import {
  ChevronDown,
  ChevronRight,
  Pencil,
  PlusIcon,
  Trash,
} from 'lucide-react'
import pluralize from 'pluralize'

import { PermBundle } from 'models/perms'
import {
  createWorkspaceRole,
  getWorkspaceRoleConfigs,
  getWorkspaceRoles,
  RoleConfig,
  updateWorkspaceRole,
  WorkspaceRole,
  WorkspaceRoleConfig,
} from 'models/roles'
import { Workspace } from 'models/workspace'
import { instanceOfDefaultRole } from 'openapi/models/DefaultRole'
import { PermissionCategory } from 'openapi/models/PermissionCategory'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import CreateRoleModal from 'components/settings/roles/create-role-modal'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Dialog } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import SearchInput from 'components/ui/search-input'

import WorkspaceDeleteRoleDialog from './workspace-delete-role-dialog'
import WorkspaceUpdateRoleDialog from './workspace-update-role-dialog'

/** Categories will be displayed in this order */
const PERMISSION_CATEGORIES = [
  PermissionCategory.ASSISTANT,
  PermissionCategory.VAULT,
  PermissionCategory.LIBRARY,
  PermissionCategory.CLIENT_MATTERS,
  PermissionCategory.ADD_INS,
  PermissionCategory.INTEGRATIONS,
  PermissionCategory.TAX_KNOWLEDGE_SOURCES,
  PermissionCategory.CASE_LAW_KNOWLEDGE_SOURCES,
  PermissionCategory.WORKFLOWS,
  PermissionCategory.HISTORY,
  PermissionCategory.BASE_USER_DEFAULTS,
  PermissionCategory.USER_MANAGEMENT,
  PermissionCategory.ADMIN_ONLY,
  PermissionCategory.HARVEY_EMPLOYEES_ONLY,
  PermissionCategory.ASSIGNED_BY_USER_OPS_ONLY,
]

interface Props {
  rows: RowProps[]
  workspace: Workspace
  fetchData: () => void
}

type CategoryRowProps = {
  type: 'category'
  name: PermissionCategory
  children: PermBundleRowProps[]
}

type PermBundleRowProps = {
  type: 'permBundle'
  permBundle: PermBundle
  roleConfigs: Partial<Record<string, RoleConfig>>
  children?: PermRowProps[]
}

type PermRowProps = {
  type: 'perm'
  permId: string
  isLast?: boolean
  children?: never
}

type RowProps = CategoryRowProps | PermBundleRowProps | PermRowProps

const Expander = <TData,>({ row }: { row: Row<TData> }) => (
  <div className="pl-2">
    <Button
      variant="ghost"
      size="smIcon"
      onClick={(e) => {
        e.stopPropagation()
        row.toggleExpanded()
      }}
      className="size-4 rounded"
    >
      <ChevronRight
        className={cn('size-4 transition-transform', {
          'rotate-90': row.getIsExpanded(),
        })}
      />
    </Button>
  </div>
)

const WorkspaceRolesTableV2: React.FC<Props> = ({ workspace }) => {
  const userInfo = useAuthUser()
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<SortingState>([])
  const [expanded, setExpanded] = useState<ExpandedState>(
    PERMISSION_CATEGORIES.reduce(
      (acc, category) => ({
        ...acc,
        [category]: true, // Set all categories to be expanded by default
      }),
      {}
    )
  )
  const [workspaceRoleConfigs, setWorkspaceRoleConfigs] = useState<
    WorkspaceRoleConfig[]
  >([])
  const [roles, setRoles] = useState<WorkspaceRole[]>([])
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] =
    useState<boolean>(false)
  const [updatingRole, setUpdatingRole] = useState<WorkspaceRole | null>(null)
  const [confirmingDeleteRole, setConfirmingDeleteRole] =
    useState<WorkspaceRole>()
  const [isLoading, setIsLoading] = useState(true)

  const fetchRoles = useCallback(async () => {
    try {
      setIsLoading(true)
      const roleConfigs = await getWorkspaceRoleConfigs(workspace.id)
      setWorkspaceRoleConfigs(roleConfigs)
      const roles = await getWorkspaceRoles(workspace.id)
      setRoles(roles.filter((role) => !role.deletedAt)) // TODO (ken): Move filter to API call
    } catch (error) {
      displayErrorMessage('Failed to fetch workspace roles')
    } finally {
      setIsLoading(false)
    }
  }, [workspace.id])

  useEffect(() => {
    void fetchRoles()
  }, [fetchRoles])

  const handleCreateRole = async (name: string, description: string) => {
    await createWorkspaceRole(workspace.id, {
      name,
      desc: description,
    })
    setIsCreateRoleModalOpen(false)
    void fetchRoles()
  }

  const handleUpdateRole = async ({
    rolePk,
    name,
    description,
  }: {
    rolePk: string
    name?: string
    description?: string
  }) => {
    try {
      const updatedRole = await updateWorkspaceRole(rolePk, {
        name: name ?? '',
        desc: description ?? '',
      })
      setRoles((roles) =>
        roles.map((role) => (role.rolePk === rolePk ? updatedRole : role))
      )
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to update role')
      }
    }
  }

  const handleDeleteRole = async () => {
    setConfirmingDeleteRole(undefined)
    void fetchRoles()
  }

  const handleEditTable = () => {
    // TODO (ken): Implement in follow up PR
    return
  }

  const columns = useMemo<ColumnDef<RowProps>[]>(
    () => [
      {
        id: 'expander',
        size: 20,
        cell: ({ row }) => {
          if (
            row.original.type === 'category' ||
            row.original.type === 'perm'
          ) {
            return null
          }
          return (
            // This is a bit of a hack to get the expander to align better with designs.
            <div className="-mx-4 -mr-6">
              <Expander row={row} />
            </div>
          )
        },
      },
      {
        accessorKey: 'permBundle',
        header: ({ column }) => (
          <DataTableSortHeader column={column} header="Permission" />
        ),
        maxSize: 100,
        cell: ({ row }) => {
          if (row.original.type === 'category') {
            return (
              <div className="-ml-8 w-80">
                {row.original.name}
                {row.subRows.length ? (
                  <span className="ml-2 text-muted">
                    {`(${row.subRows.length} ${pluralize(
                      'permission',
                      row.subRows.length
                    )})`}
                  </span>
                ) : null}
              </div>
            )
          }
          if (row.original.type === 'perm') {
            return (
              <div className="ml-3 flex items-center gap-4">
                <Checkbox disabled />
                <div>{row.original.permId}</div>
              </div>
            )
          }
          const permBundle = row.original.permBundle
          return (
            <div className="ml-3 w-80">
              {permBundle.name}
              <div className="text-muted">{permBundle.description}</div>
            </div>
          )
        },
      },
      ...roles.map<ColumnDef<RowProps>>((role) => ({
        accessorFn: (row) => {
          if (row.type === 'category' || row.type === 'perm') {
            return null
          }
          return row.roleConfigs[role.rolePk]
        },
        id: role.rolePk,
        header: () => {
          const baseSlug = role.roleId.slice(workspace.slug.length + 1)
          const controls = (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="ghost" size="sm" className="h-6 p-1">
                  <ChevronDown className="size-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-48">
                <div className="flex flex-col gap-1">
                  <Button
                    variant="ghost"
                    className="flex items-center justify-between gap-2"
                    onClick={() => setUpdatingRole(role)}
                  >
                    Edit role title
                    <Icon icon={Pencil} className="size-3" />
                  </Button>
                  <Button
                    variant="destructive"
                    className="flex items-center justify-between gap-2"
                    onClick={() => setConfirmingDeleteRole(role)}
                  >
                    Delete role
                    <Icon icon={Trash} className="size-3" />
                  </Button>
                </div>
              </PopoverContent>
            </Popover>
          )

          return (
            <div className="flex items-center gap-1">
              {role.name}
              {!instanceOfDefaultRole(baseSlug) && controls}
            </div>
          )
        },
        cell: ({ row }) => {
          if (
            row.original.type === 'category' ||
            row.original.type === 'perm'
          ) {
            return null
          }
          const roleConfig = row.original.roleConfigs[role.rolePk]
          let checkedState: ComponentProps<typeof Checkbox>['checked'] = false
          if (roleConfig) {
            if (roleConfig.missingPerms.length === 0) {
              checkedState = true
            } else if (
              roleConfig.permissions.length !== roleConfig.missingPerms.length
            ) {
              checkedState = 'indeterminate'
            }
          }
          return (
            <Button
              variant="ghost"
              size="smIcon"
              tooltip={
                roleConfig && roleConfig.missingPerms.length > 0
                  ? `Missing: ${roleConfig.missingPerms.join(', ')}`
                  : undefined
              }
            >
              <Checkbox checked={checkedState} disabled />
            </Button>
          )
        },
        enableSorting: false,
      })),
    ],
    [roles, workspace.slug]
  )

  const categoryRows: CategoryRowProps[] = useMemo(
    // Memo is needed to prevent infinite re-renders on expand/collapse
    () =>
      PERMISSION_CATEGORIES.map<CategoryRowProps>((category) => {
        return {
          type: 'category',
          name: category,
          children: workspaceRoleConfigs
            .filter((roleConfig) => roleConfig.permBundle.category === category)
            .map<PermBundleRowProps>((roleConfig) => ({
              type: 'permBundle',
              permBundle: roleConfig.permBundle,
              roleConfigs: roleConfig.roleConfigs.reduce(
                (acc, config) => ({
                  ...acc,
                  [config.roleId]: config,
                }),
                {}
              ),
              children: roleConfig.permBundle.permissions.map<PermRowProps>(
                (perm, index, array) => ({
                  type: 'perm',
                  permId: perm,
                  isLast: index === array.length - 1,
                })
              ),
            })),
        }
      }).filter((category) => category.children.length > 0), // Hide empty categories for now
    [workspaceRoleConfigs]
  )

  const table = useReactTable({
    data: categoryRows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getRowId: (row) => {
      switch (row.type) {
        case 'category':
          return row.name
        case 'perm':
          return row.permId
        default:
          return row.permBundle.id
      }
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.children,
    state: {
      sorting,
      globalFilter: filter,
      expanded,
    },
  })

  if (_.isNil(userInfo) || !userInfo.IsInternalAdminReader) return null

  return (
    <div className="flex flex-col gap-4 px-4">
      <div className="flex items-center justify-between gap-2 py-2">
        <p>Manage roles and permissions in this workspace.</p>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            onClick={() => setIsCreateRoleModalOpen(true)}
          >
            <Icon icon={PlusIcon} className="mr-1" />
            Create role
          </Button>
          <Button variant="outline" onClick={handleEditTable}>
            <Icon icon={Pencil} className="mr-1" />
            Edit table
          </Button>
        </div>
      </div>

      <div className="flex items-center justify-between gap-2">
        <SearchInput value={filter} setValue={setFilter} withIcon />
      </div>

      <DataTable
        table={table}
        isLoading={isLoading}
        tableRowClassName={(row) =>
          cn({
            'bg-secondary': row.original.type === 'category',
            'hover:bg-transparent border-t-0': row.original.type === 'perm',
            'border-b-0': row.original.type === 'perm' && !row.original.isLast,
          })
        }
      />
      <DataTableFooter table={table} />

      <CreateRoleModal
        open={isCreateRoleModalOpen}
        onOpenChange={setIsCreateRoleModalOpen}
        onAdd={handleCreateRole}
      />
      {updatingRole && (
        <WorkspaceUpdateRoleDialog
          isOpen
          onClose={() => setUpdatingRole(null)}
          onSubmit={async ({ name, description }) => {
            await handleUpdateRole({
              rolePk: updatingRole.rolePk,
              name,
              description,
            })
          }}
          initialName={updatingRole.name}
          initialDescription={updatingRole.desc}
        />
      )}
      {confirmingDeleteRole && (
        <Dialog open onOpenChange={() => setConfirmingDeleteRole(undefined)}>
          <WorkspaceDeleteRoleDialog
            isOpen
            onClose={() => setConfirmingDeleteRole(undefined)}
            onDelete={handleDeleteRole}
            role={confirmingDeleteRole}
            availableRoles={roles}
            workspaceSlug={workspace.slug}
          />
        </Dialog>
      )}
    </div>
  )
}

export default WorkspaceRolesTableV2
