import {
  DiligenceSection,
  instanceOfDiligenceSection,
} from 'openapi/models/DiligenceSection'
import {
  DiligenceTask,
  instanceOfDiligenceTask,
} from 'openapi/models/DiligenceTask'
import {
  SocketMessageResponse,
  instanceOfSocketMessageResponse,
} from 'openapi/models/SocketMessageResponse'
import { Source, instanceOfSource } from 'openapi/models/Source'

/**
 * @file This file contains utility functions for sanitizing data we want to log in the Diligence code,
 * so that we do not log customer data.
 */

const isObject = (value: unknown): value is object => {
  return typeof value === 'object' && value !== null
}

/**
 * Sanitizes primitive values to remove potential sensitive information
 */
const sanitizePrimitive = (value: unknown): string => {
  if (value === null || value === undefined) {
    return String(value)
  }

  switch (typeof value) {
    case 'string':
      return '[STRING CONTENT REMOVED]'
    case 'number':
      return '[NUMBER REMOVED]'
    case 'boolean':
      return '[BOOLEAN REMOVED]'
    case 'bigint':
      return '[BIGINT REMOVED]'
    case 'symbol':
      return '[SYMBOL REMOVED]'
    case 'function':
      return '[FUNCTION REMOVED]'
    default:
      return '[UNKNOWN TYPE REMOVED]'
  }
}

/**
 * Creates a safe-to-log version of a Source by removing potentially sensitive content
 */
const sanitizeSource = (source: Source): Source => {
  return {
    sourceType: source.sourceType,
    id: source.id,
    text: '[CONTENT REMOVED]',
    footnote: source.footnote,
    // Optional fields
    documentId: source.documentId ? '[CONTENT REMOVED]' : undefined,
    questionId: source.questionId ? '[CONTENT REMOVED]' : undefined,
    documentName: source.documentName ? '[CONTENT REMOVED]' : undefined,
    badges: undefined,
    metadata: source.metadata ? ['[CONTENT REMOVED]'] : undefined,
  }
}

/**
 * Creates a safe-to-log version of a SocketMessageResponse
 */
const sanitizeSocketMessage = (
  message: SocketMessageResponse
): SocketMessageResponse => {
  return {
    type: message.type,
    responseVersion: message.responseVersion,
    response: message.response ? '[CONTENT REMOVED]' : '',
    headerText: message.headerText ? '[CONTENT REMOVED]' : '',
    progress: message.progress,
    sources: message.sources.map(sanitizeSource),
    annotations: {}, // Required field, but empty for safety
    // Optional fields
    caption: message.caption ? '[CAPTION REMOVED]' : undefined,
    metadata: message.metadata ? {} : undefined,
    relatedQuestions: message.relatedQuestions ? [] : undefined,
  }
}

/**
 * Creates a safe-to-log version of a DiligenceTask
 */
const sanitizeDiligenceTask = (task: DiligenceTask): DiligenceTask => {
  return {
    identifier: task.identifier,
    title: task.title,
    // Optional fields
    dependentTasks: task.dependentTasks,
    answer: task.answer ? sanitizeSocketMessage(task.answer) : undefined,
  }
}

/**
 * Creates a safe-to-log version of a DiligenceSection
 */
const sanitizeDiligenceSection = (
  section: DiligenceSection
): DiligenceSection => {
  return {
    title: section.title,
    description: section.description,
    tasks: section.tasks.map(sanitizeDiligenceTask),
    // Optional fields
    isLoading: section.isLoading,
    executiveSummary: section.executiveSummary
      ? sanitizeSocketMessage(section.executiveSummary)
      : undefined,
  }
}

/**
 * Creates a safe-to-log version of any object that might contain sensitive data
 */
export const sanitizeForLoggingDiligence = (data: unknown): unknown => {
  // Handle null/undefined
  if (data === null || data === undefined) {
    return data
  }

  // Handle arrays
  if (Array.isArray(data)) {
    return data.map((item) => sanitizeForLoggingDiligence(item))
  }

  // Handle different types of objects
  if (isObject(data)) {
    // Handle DiligenceSection
    if (instanceOfDiligenceSection(data)) {
      return sanitizeDiligenceSection(data)
    }

    // Handle DiligenceTask
    if (instanceOfDiligenceTask(data)) {
      return sanitizeDiligenceTask(data)
    }

    // Handle Source
    if (instanceOfSource(data)) {
      return sanitizeSource(data)
    }

    // Handle SocketMessageResponse
    if (instanceOfSocketMessageResponse(data)) {
      return sanitizeSocketMessage(data)
    }

    // For unknown objects, return type information
    return '[OBJECT REMOVED]'
  }

  // For primitives, sanitize aggressively
  return sanitizePrimitive(data)
}
