import React from 'react'

import _ from 'lodash'
import { FileIcon, X } from 'lucide-react'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog'

interface PasswordCollectionDialogProps {
  isOpen: boolean
  passwordProtectedFiles: File[]
  fileToPasswordMap: Record<string, string>
  onPasswordChange: (fileName: string, password: string) => void
  onSubmit: (
    passwordProtectedFiles: File[],
    fileToPasswordMap: Record<string, string>
  ) => void
  onCancel: () => void
  onFileRemove: (fileName: string) => void
}

const PasswordCollectionDialog = ({
  isOpen,
  passwordProtectedFiles,
  fileToPasswordMap,
  onPasswordChange,
  onSubmit,
  onCancel,
  onFileRemove,
}: PasswordCollectionDialogProps) => {
  const [isLoadingUpload, setIsLoadingUpload] = React.useState(false)

  const handleSubmit = async () => {
    setIsLoadingUpload(true)
    try {
      await onSubmit(passwordProtectedFiles, fileToPasswordMap)
    } finally {
      setIsLoadingUpload(false)
    }
  }

  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[50%]" showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>Password-Protected Files Detected</DialogTitle>
          <DialogDescription>
            Enter passwords for the following files to continue upload
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col space-y-3">
          {!_.isEmpty(passwordProtectedFiles) &&
            passwordProtectedFiles.map((oneFile) => (
              <div
                key={oneFile.name}
                className="hover:bg-gray-50 rounded-lg border p-4 transition-colors"
              >
                <div className="grid grid-cols-[auto_1fr_auto_auto] items-start gap-4">
                  <FileIcon className="text-gray-500 mt-1 h-5 w-5 flex-shrink-0" />
                  <span className="break-words pr-2 text-sm">
                    {oneFile.name}
                  </span>
                  <input
                    type="password"
                    value={fileToPasswordMap[oneFile.name] || ''}
                    onChange={(e) =>
                      onPasswordChange(oneFile.name, e.target.value)
                    }
                    className="placeholder:text-muted-foreground h-9 w-48 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    placeholder="Enter password"
                  />
                  <button
                    className="text-gray-500 hover:text-gray-700 transition"
                    onClick={() => onFileRemove(oneFile.name)}
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))}
        </div>

        <DialogFooter className="mt-6 flex justify-end space-x-2">
          <Button variant="ghost" onClick={onCancel} disabled={isLoadingUpload}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              isLoadingUpload ||
              !passwordProtectedFiles.every(
                (oneFile) => fileToPasswordMap[oneFile.name]
              )
            }
          >
            {isLoadingUpload ? 'Uploading…' : 'Continue Upload'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default PasswordCollectionDialog
