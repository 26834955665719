import Services from 'services'

enum WelcomeScreenAction {
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
}

type UserWelcomeScreen = Record<string, WelcomeScreenAction>

interface UserSettings {
  v1ProductTourCompleted?: boolean
  v1WelcomeModalCompleted?: boolean
  productTourCompleted?: boolean
  welcomeScreens?: UserWelcomeScreen[]
  completedOnboarding?: boolean
}

const updateUserSettings = async (settings: UserSettings) => {
  return await Services.Backend.Post<boolean>('user_settings', settings)
}

// exports
export type { UserSettings }
export { updateUserSettings, WelcomeScreenAction }
