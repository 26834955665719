import React, { useState, useMemo, useCallback } from 'react'
import { useMount } from 'react-use'

import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { exportWordWithQuery } from 'utils/markdown'
import { requestIdleCallback } from 'utils/request-idle-callback'
import { TaskType } from 'utils/task'
import { displayErrorMessage, displayInfoMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from 'components/ui/dialog'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Switch } from 'components/ui/switch'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  exportExcelWithReviewState,
  exportWordWithReviewState,
  downloadFiles,
  ExportAnswerType,
} from 'components/vault/utils/vault-exporter'
import { useVaultStore } from 'components/vault/utils/vault-store'

enum ExportExtension {
  WORD = 'word',
  EXCEL = 'excel',
  CSV = 'csv',
}

enum RowType {
  ALL = 'all',
  FILTERED = 'filtered',
  SELECTED = 'selected',
}

interface GetExportExcelSubmenuOptions {
  key: string
  exportAll: boolean
  onlySelected: boolean
  extensionType: ExportExtension
  answerType: ExportAnswerType
}

const ExtensionTypeRadioGroup = ({
  extensionType,
  setExtensionType,
}: {
  extensionType: ExportExtension
  setExtensionType: (value: ExportExtension) => void
}) => {
  const options = [
    {
      label: 'Excel',
      id: ExportExtension.EXCEL,
      value: ExportExtension.EXCEL,
    },
    {
      label: 'Word',
      id: ExportExtension.WORD,
      value: ExportExtension.WORD,
    },
    {
      label: 'CSV',
      id: ExportExtension.CSV,
      value: ExportExtension.CSV,
    },
  ]

  return (
    <div>
      <div className="mb-3 text-xs font-medium text-muted">Extension</div>
      <RadioGroup value={extensionType} onValueChange={setExtensionType}>
        {options.map((option) => {
          return (
            <RadioGroupItem
              key={option.id}
              className="px-2 py-0.5"
              checked={extensionType === option.value}
              {...option}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

const AnswerTypeRadioGroup = ({
  exportAnswerType,
  setExportAnswerType,
}: {
  exportAnswerType: ExportAnswerType
  setExportAnswerType: (value: ExportAnswerType) => void
}) => {
  const options = [
    {
      label: 'Both',
      id: ExportAnswerType.BOTH,
      value: ExportAnswerType.BOTH,
    },
    {
      label: 'Short',
      id: ExportAnswerType.SHORT,
      value: ExportAnswerType.SHORT,
    },
    {
      label: 'Long',
      id: ExportAnswerType.LONG,
      value: ExportAnswerType.LONG,
    },
  ]
  return (
    <div>
      <div className="mb-3 text-xs font-medium text-muted">Answer Type</div>
      <RadioGroup value={exportAnswerType} onValueChange={setExportAnswerType}>
        {options.map((option) => {
          return (
            <RadioGroupItem
              key={option.id}
              className="px-2 py-0.5"
              checked={exportAnswerType === option.value}
              {...option}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

const RowTypeRadioGroup = ({
  rowType,
  setRowType,
  setFileIdsToDownload,
}: {
  rowType: RowType
  setRowType: (value: RowType) => void
  setFileIdsToDownload: (value: string[]) => void
}) => {
  const gridApi = useVaultStore(useShallow((s) => s.gridApi))
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const selectedRows = useVaultDataGridFilterStore(
    useShallow((s) => s.selectedRows)
  )
  const currentAppliedFilters = useVaultDataGridFilterStore(
    useShallow((s) => s.currentAppliedFilters)
  )

  const options = [
    {
      id: RowType.ALL,
      label: 'Full table',
      value: RowType.ALL,
      isHidden: false,
    },
    {
      id: RowType.FILTERED,
      label: 'Filtered rows',
      value: RowType.FILTERED,
      isHidden: currentAppliedFilters.length === 0,
    },
    {
      id: RowType.SELECTED,
      label: `Selected ${pluralize('row', selectedRows.length)} (${
        selectedRows.length
      } ${pluralize('file', selectedRows.length)})`,
      value: RowType.SELECTED,
      isHidden: selectedRows.length === 0,
    },
  ]

  const changeHandler = (value: RowType) => {
    setRowType(value)
    if (value === RowType.SELECTED) {
      setFileIdsToDownload(selectedRows)
      return
    }

    if (value === RowType.ALL) {
      const fileIds =
        currentProjectMetadata.descendantFiles?.map((file) => file.id) || []
      setFileIdsToDownload(fileIds)
      return
    }

    const fileIdsToDownload: string[] = []
    gridApi?.forEachNode((node) => {
      if (node.group || !node.displayed) return
      if (node.id) {
        fileIdsToDownload.push(node.id)
      }
    })
    setFileIdsToDownload(fileIdsToDownload)
  }

  useMount(() => {
    setFileIdsToDownload(
      currentProjectMetadata.descendantFiles?.map((file) => file.id) || []
    )
  })

  return (
    <div>
      <div className="mb-3 text-xs font-medium text-muted">Rows</div>
      <RadioGroup value={rowType} onValueChange={changeHandler}>
        {options
          .filter((option) => !option.isHidden)
          .map((option) => {
            return (
              <RadioGroupItem
                key={option.id}
                className="px-2 py-0.5"
                checked={rowType === option.value}
                {...option}
              />
            )
          })}
      </RadioGroup>
    </div>
  )
}

const ExportReviewForm = ({
  exportExtension,
  exportAnswerType,
  rowType,
  shouldDownloadFiles,
  setExportExtension,
  setExportAnswerType,
  setRowType,
  setFileIdsToDownload,
  setShouldDownloadFiles,
  longAnswerOnly,
}: {
  exportExtension: ExportExtension
  exportAnswerType: ExportAnswerType
  rowType: RowType
  shouldDownloadFiles: boolean
  setExportAnswerType: (value: ExportAnswerType) => void
  setRowType: (value: RowType) => void
  setExportExtension: (value: ExportExtension) => void
  setFileIdsToDownload: (value: string[]) => void
  setShouldDownloadFiles: (value: boolean) => void
  longAnswerOnly: boolean
}) => {
  const downloadLabel = 'Download files'
  return (
    <div className="space-y-4">
      <div>
        <div className="mb-3 text-xs font-medium text-muted">Download</div>
        <label
          className="flex items-center gap-2 px-2 py-0.5 text-sm"
          htmlFor={downloadLabel}
        >
          <Switch
            id={downloadLabel}
            checked={shouldDownloadFiles}
            onCheckedChange={setShouldDownloadFiles}
          />
          <p className="text-sm">{downloadLabel}</p>
        </label>
      </div>
      <ExtensionTypeRadioGroup
        extensionType={exportExtension}
        setExtensionType={setExportExtension}
      />
      {!longAnswerOnly && (
        <AnswerTypeRadioGroup
          exportAnswerType={exportAnswerType}
          setExportAnswerType={setExportAnswerType}
        />
      )}
      <RowTypeRadioGroup
        rowType={rowType}
        setRowType={setRowType}
        setFileIdsToDownload={setFileIdsToDownload}
      />
    </div>
  )
}

const ExportAskForm = ({
  shouldIncludeAnnotations,
  setShouldIncludeAnnotations,
}: {
  shouldIncludeAnnotations: boolean
  setShouldIncludeAnnotations: (value: boolean) => void
}) => {
  const label = 'Include citations'
  return (
    <div>
      <div className="mb-3 text-xs font-medium text-muted">Citations</div>
      <label
        className="flex justify-between px-2 py-0.5 text-sm"
        htmlFor={label}
      >
        {label}
        <Switch
          id={label}
          checked={shouldIncludeAnnotations}
          onCheckedChange={setShouldIncludeAnnotations}
        />
      </label>
    </div>
  )
}

const VaultExportDialog = () => {
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const gridApi = useVaultStore(useShallow((s) => s.gridApi))
  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  const queryId = useVaultStore((s) => s.queryId)
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const queryIdToReviewState = useVaultStore(
    useShallow((s) => s.queryIdToReviewState)
  )
  const isExportDialogOpen = useVaultStore(
    useShallow((s) => s.isExportDialogOpen)
  )

  const selectedRows = useVaultDataGridFilterStore(
    useShallow((s) => s.selectedRows)
  )
  const isShowingLongResponses = useVaultDataGridFilterStore(
    useShallow((s) => s.isShowingLongResponses)
  )
  const upsertVaultFiles = useVaultStore(useShallow((s) => s.upsertVaultFiles))
  const setIsExportDialogOpen = useVaultStore(
    useShallow((s) => s.setIsExportDialogOpen)
  )

  // Ask Query States
  const [shouldIncludeAnnotations, setShouldIncludeAnnotations] =
    useState(false)

  // Review Query States
  const [shouldDownloadFiles, setShouldDownloadFiles] = useState(false)
  const [rowType, setRowType] = useState<RowType>(RowType.ALL)
  const [exportAnswerType, setExportAnswerType] = useState<ExportAnswerType>(
    ExportAnswerType.BOTH
  )
  const [exportExtension, setExportExtension] = useState<ExportExtension>(
    ExportExtension.EXCEL
  )
  const [fileIdsToDownload, setFileIdsToDownload] = useState<string[]>([])

  const [isExporting, setIsExporting] = useState(false)

  const projectName = currentProject?.name || 'Untitled'
  const query = queryIdToState[queryId]?.query || ''
  const sources = queryIdToState[queryId]?.sources || []
  const response = queryIdToState[queryId]?.response || ''
  const taskType = queryIdToState[queryId]?.taskType
  const exportType = taskType === TaskType.VAULT_REVIEW ? 'download' : 'word'
  const longAnswerOnly =
    queryIdToReviewState[queryId]?.isWorkflowRepsWarranties ?? false

  const title = useMemo(() => {
    if (taskType === TaskType.VAULT) {
      return 'Export to Microsoft Word'
    }
    return 'Export'
  }, [taskType])

  const isExportDisabled = !queryId
    ? true
    : queryIdToState[queryId]?.isLoading
    ? true
    : false

  const disabledTooltip = !queryId
    ? 'Run a query first before exporting'
    : queryIdToState[queryId]?.isLoading
    ? 'Query is still running. Please wait for it to finish before exporting.'
    : undefined

  const closeHandler = () => {
    setIsExporting(false)
    setIsExportDialogOpen(false)
  }

  const handleExportWordInner = async () => {
    if (!taskType) return
    trackEvent('Vault Query Exported', {
      export_type: exportType,
      include_annotation: shouldIncludeAnnotations,
    })

    const titleText = `Vault - ${projectName}`
    // We don't want to include the document URL in the export since they are going to be expired
    // after a few hours and the user might not have access to the document in the export anymore.
    const sourcesWithoutDocumentUrl = shouldIncludeAnnotations
      ? sources.map((source) => ({
          ...source,
          documentUrl: '',
        }))
      : []

    await exportWordWithQuery({
      query,
      response,
      taskType,
      includeAnnotation: shouldIncludeAnnotations,
      queryId: String(queryId),
      sources: sourcesWithoutDocumentUrl,
      titleText,
    })
  }

  const handleExportInner = useCallback(
    async ({
      exportAll,
      onlySelected,
      answerType,
      extensionType,
    }: GetExportExcelSubmenuOptions) => {
      if (!taskType) return
      trackEvent('Vault Query Exported', {
        export_type: exportType,
        export_all: exportAll,
        export_only_selected: onlySelected,
        export_answer_type: answerType,
      })
      const sheetName = projectName
      const reviewState = queryIdToReviewState[queryId]
      if (!reviewState) {
        displayErrorMessage(
          'Sorry we could not export the excel file at this time. Please try again later.'
        )
        return
      }
      if (extensionType === ExportExtension.WORD) {
        await exportWordWithReviewState({
          taskType,
          gridApi,
          queryId,
          onlySelected,
          exportAll,
          answerType,
          reviewState,
          fileIdToVaultFile,
        })
      } else {
        await exportExcelWithReviewState({
          taskType: taskType,
          sheetName,
          queryId,
          reviewState,
          fileIdToVaultFile,
          gridApi,
          exportAll,
          isShowingLongResponses,
          onlySelected,
          answerType,
          isCSV: extensionType === 'csv',
        })
      }
    },
    [
      projectName,
      queryIdToReviewState,
      queryId,
      taskType,
      fileIdToVaultFile,
      gridApi,
      isShowingLongResponses,
      trackEvent,
      exportType,
    ]
  )

  const onSubmit = async () => {
    setIsExporting(true)
    // 1. Ask Query Export
    if (taskType === TaskType.VAULT) {
      await handleExportWordInner()
      closeHandler()
      return
    }

    // 2. Review Query Download Files
    if (shouldDownloadFiles) {
      void downloadFiles({
        fileIdsToDownload,
        fileIdToVaultFile,
        downloadFileName: projectName,
        upsertVaultFiles,
        projectId: currentProject?.id,
      })
    }

    const answerType = longAnswerOnly ? ExportAnswerType.LONG : exportAnswerType

    // 3. Review Query Export
    closeHandler()
    displayInfoMessage(
      'Your export is being prepared. This may take a while. It will automatically download when it is ready.'
    )

    // Use setTimeout to ensure state updates before calling the function
    // TODO: put this into a web-worker to avoid blocking the main thread
    requestIdleCallback(async () => {
      await handleExportInner({
        key: `export-${exportExtension}-${rowType}-${answerType}`,
        exportAll: rowType === RowType.ALL,
        onlySelected: rowType === RowType.SELECTED,
        extensionType: exportExtension,
        answerType: answerType,
      })
    })
  }

  return (
    <Dialog open={isExportDialogOpen} onOpenChange={setIsExportDialogOpen}>
      <DialogTrigger asChild>
        <Button
          disabled={isExportDisabled}
          tooltip={disabledTooltip}
          variant={userInfo.IsVaultV2User ? 'outline' : 'default'}
        >
          Export{' '}
          {selectedRows.length > 0 && (
            <Badge
              variant="secondary"
              className="ml-1.5 flex h-4 min-w-4 items-center justify-center p-0"
            >
              {selectedRows.length}
            </Badge>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent id="vault-export-dialog" className="max-w-80">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {taskType === TaskType.VAULT && (
          <ExportAskForm
            shouldIncludeAnnotations={shouldIncludeAnnotations}
            setShouldIncludeAnnotations={setShouldIncludeAnnotations}
          />
        )}
        {taskType === TaskType.VAULT_REVIEW && (
          <ExportReviewForm
            exportExtension={exportExtension}
            rowType={rowType}
            exportAnswerType={exportAnswerType}
            shouldDownloadFiles={shouldDownloadFiles}
            setExportAnswerType={setExportAnswerType}
            setRowType={setRowType}
            setExportExtension={setExportExtension}
            setShouldDownloadFiles={setShouldDownloadFiles}
            setFileIdsToDownload={setFileIdsToDownload}
            longAnswerOnly={longAnswerOnly}
          />
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button id="vault-export-dialog-cancel-button" variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <Button
            id="vault-export-dialog-export-button"
            variant="default"
            isLoading={isExporting}
            onClick={onSubmit}
          >
            Export
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultExportDialog
