import { useEffect, useRef, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { SafeRecord } from 'utils/safe-types'
import { getUniquePracticeAreaTaxonomies } from 'utils/user-profile-helpers'

import { useUserProfileStore } from 'components/common/user-profile-store'

import { LibraryItem } from './library-types'

interface PracticeAreaTaxonomiesProps {
  items: Record<string, LibraryItem>
  queryKey?: string
  filterValues?: SafeRecord<string, string>
  setFilterValue?: (key: string, value: string) => void
}

export const usePracticeAreaTaxonomies = ({
  items,
  queryKey,
  filterValues,
  setFilterValue,
}: PracticeAreaTaxonomiesProps) => {
  const [searchParams] = useSearchParams()
  const userProfile = useUserProfileStore((s) => s.userProfile)

  const paramValue = queryKey
    ? filterValues?.[queryKey] || searchParams.get(queryKey)
    : ''

  const defaultValuesRef = useRef<boolean>(false)

  const handleSetCurrentPracticeAreaTaxonomies = useCallback(
    (practiceAreaTaxonomies: string[]) => {
      if (queryKey && setFilterValue) {
        setFilterValue(queryKey, practiceAreaTaxonomies.join(','))
      }
    },
    [queryKey, setFilterValue]
  )

  useEffect(() => {
    // Once items is non-empty, we don't want to run this again, as it sets default values
    if (!defaultValuesRef.current && Object.keys(items).length) {
      defaultValuesRef.current = true

      const allPracticeAreas = new Set<string>()
      Object.values(items).forEach((item) => {
        item.practiceAreas?.forEach((p) => allPracticeAreas.add(p))
      })
      const practiceAreaTaxonomies = paramValue
        ? paramValue.split(',')
        : getUniquePracticeAreaTaxonomies(userProfile, allPracticeAreas)

      handleSetCurrentPracticeAreaTaxonomies(practiceAreaTaxonomies)
    }
  }, [handleSetCurrentPracticeAreaTaxonomies, items, paramValue, userProfile])
}
