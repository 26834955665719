import React from 'react'

export const AssistantWorkflowYouComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div>
      <p className="text-secondary">You</p>
      {children}
    </div>
  )
}

export const AssistantWorkflowHarveyComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div>
      <p className="text-secondary">Harvey</p>
      {children}
    </div>
  )
}

export const AssistantWorkflowThreadText: React.FC<{ text: string }> = ({
  text,
}) => {
  return <p className="text-primary">{text}</p>
}

export const AssistantWorkflowThreadBlock: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <div className="flex flex-col">{children}</div>
}

// TODO: Make a full input component builder
export const AssistantWorkflowInputComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <div className="rounded-md bg-secondary p-4">{children}</div>
}
