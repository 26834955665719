import React, { useState, useRef } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { ReviewWorkflowVisibilityKind } from 'openapi/models/ReviewWorkflowVisibilityKind'

import {
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogContent,
  Dialog,
} from 'components/ui/dialog'
import { MultiSelect } from 'components/ui/multi-select'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { useWorkspaces } from 'components/vault/utils/use-workspaces'
import { PublishWorkflow } from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultPublishWorkflow: React.FC = () => {
  const [selectedVisibility, setSelectedVisibility] = useState<
    ReviewWorkflowVisibilityKind | ''
  >('')
  const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState<string[]>([])
  const [isSubmittingPublish, setIsSubmittingPublish] = useState(false)
  const [
    selectedWorkflow,
    workflowModalState,
    setWorkflowModalState,
    setWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.selectedWorkflow,
      state.workflowModalState,
      state.setWorkflowModalState,
      state.setWorkflow,
    ])
  )

  const { data: availableWorkspaces, isFetching: workspacesLoading } =
    useWorkspaces()

  const handlePublish = async () => {
    if (!selectedVisibility) {
      displayWarningMessage('Please select a visibility option.')
      return
    }
    let workspaceIds: number[] = []
    if (selectedVisibility === ReviewWorkflowVisibilityKind.WORKSPACE) {
      if (selectedWorkspaceIds.length === 0) {
        displayWarningMessage('Please select at least one workspace.')
        return
      }
    }
    workspaceIds = selectedWorkspaceIds.map(Number)
    setIsSubmittingPublish(true)
    try {
      const response = await PublishWorkflow({
        workflowId: selectedWorkflow!.id,
        visibility: selectedVisibility,
        workspaceIds:
          selectedVisibility === ReviewWorkflowVisibilityKind.WORKSPACE
            ? workspaceIds
            : [],
        hiddenWorkspaceIds:
          selectedVisibility === ReviewWorkflowVisibilityKind.PUBLIC
            ? workspaceIds
            : [],
      })
      displaySuccessMessage('Workflow published successfully')
      setWorkflow(response.workflow)
      setWorkflowModalState(WorkflowModalState.Selector)
    } catch (error) {
      displayErrorMessage('Failed to publish workflow')
    } finally {
      setIsSubmittingPublish(false)
    }
  }

  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Publish}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.None)
        }
      }}
    >
      <DialogContent className="bg-secondary sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Publish Workflow</DialogTitle>
          <DialogDescription>
            Select visibility and{' '}
            {selectedVisibility === ReviewWorkflowVisibilityKind.WORKSPACE
              ? 'select workspaces'
              : 'publish the workflow'}
            .
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <label htmlFor="visibility" className="block text-sm font-medium">
              Visibility
            </label>
            <Select
              value={selectedVisibility}
              onValueChange={(value) => {
                setSelectedVisibility(value as ReviewWorkflowVisibilityKind)
                setSelectedWorkspaceIds([])
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select visibility" />
              </SelectTrigger>
              <SelectContent>
                {[
                  ReviewWorkflowVisibilityKind.WORKSPACE,
                  ReviewWorkflowVisibilityKind.PUBLIC,
                ].map((visibility) => (
                  <SelectItem key={visibility} value={visibility}>
                    {visibility}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {!!selectedVisibility && (
            <div ref={containerRef}>
              <label htmlFor="workspaces" className="block text-sm font-medium">
                {selectedVisibility === ReviewWorkflowVisibilityKind.WORKSPACE
                  ? 'Select workspaces to publish to'
                  : '(Optional) Select workspaces to hide workflow from'}
              </label>
              <MultiSelect
                placeholder="Select workspaces"
                sortedEntries={(availableWorkspaces ?? []).map((workspace) => ({
                  text: workspace.name,
                  value: workspace.id.toString(),
                }))}
                selectedValues={selectedWorkspaceIds}
                setSelectedValues={(values) => setSelectedWorkspaceIds(values)}
                className="mt-1 w-full"
                disabled={workspacesLoading}
                containerRef={containerRef}
              />
            </div>
          )}

          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => {
                setWorkflowModalState(WorkflowModalState.Selector)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handlePublish}
              disabled={
                isSubmittingPublish ||
                !selectedVisibility ||
                (selectedVisibility ===
                  ReviewWorkflowVisibilityKind.WORKSPACE &&
                  selectedWorkspaceIds.length === 0)
              }
              isLoading={isSubmittingPublish}
            >
              Publish
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultPublishWorkflow
