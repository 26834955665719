import React, { useCallback, useEffect, useState } from 'react'
import { useMount } from 'react-use'

import { Select } from 'antd'
import emailAddresses, { ParsedMailbox } from 'email-addresses'
import _ from 'lodash'
import pluralize from 'pluralize'

import { fetchAllActiveWorkspaceUsersInternal } from 'models/users'
import { addVaultAddOnUsers } from 'models/vault'
import { FetchWorkspace, Workspace } from 'models/workspace'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import MultiEmailTextarea, {
  getCleanEmails,
} from 'components/settings/user-management/multi-email-textarea'
import { useVaultManagementStore } from 'components/settings/workspace/workspace-details/vault-management/vault-management-store'
import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

import VaultUserAddDialog from './vault-users-add-dialog'

const MAX_NUM_USERS = 200

interface VaultUsersAddProps {
  workspace: Workspace
  currentUsers: Set<string>
  type: 'add-on'
}

const VaultUsersAdd = ({
  type,
  workspace,
  currentUsers,
}: VaultUsersAddProps) => {
  const [userEmailTextArea, setUserEmailTextArea] = useState<string>('')
  const [userLimitExceeded, setUserLimitExceeded] = useState<boolean>(false)
  const [textAreaLines, setTextAreaLines] = useState<number>(0)
  const [addedUsers, setAddedUsers] = useState<number>(0)
  const [failedToAddUsers, setFailedToAddUsers] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [availableUsers, setAvailableUsers] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [mismatchDomainUsers, setMismatchDomainUsers] = useState<string[]>([])
  const [duplicateEmails, setDuplicateEmails] = useState<string[]>([])
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])
  const [usersToAdd, setUsersToAdd] = useState<string[]>([])
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false)
  const [domains, setDomains] = useState<string[]>([])
  const setVaultAddOnUsers = useVaultManagementStore(
    (state) => state.setVaultAddOnUsers
  )

  useMount(async () => {
    if (workspace.parentId) {
      const parent = await FetchWorkspace(workspace.parentId).catch(() =>
        displayErrorMessage('Failed to fetch parent workspace')
      )
      setDomains([...(parent?.domains ?? []), ...workspace.domains])
      return
    }
    setDomains(workspace.domains)
  })

  useEffect(() => {
    const fetchWorkspaceUsers = async () => {
      const users = await fetchAllActiveWorkspaceUsersInternal(workspace.id)
      const userEmails = users
        .map((user) => user.email)
        .filter((email) => !currentUsers.has(email))
      setAvailableUsers(userEmails)
    }
    void fetchWorkspaceUsers()
  }, [currentUsers, workspace.id])

  const numUsersToAdd = textAreaLines + selectedUsers.length

  const onClear = () => {
    setUserEmailTextArea('')
    setTextAreaLines(0)
    setAddedUsers(0)
    setFailedToAddUsers([])
    setLoading(false)
    setUserLimitExceeded(false)
  }

  const onAddUsers = useCallback(() => {
    let textAreaEmails = getCleanEmails(userEmailTextArea).map((email) =>
      email.toLowerCase()
    )

    if (textAreaEmails.length > MAX_NUM_USERS) {
      displayErrorMessage(
        `Exceeded maximum number of users (${MAX_NUM_USERS}) allowed`
      )
      setLoading(false)
      return
    }
    const [validEmails, invalidEmails] = _.partition(
      textAreaEmails,
      (email) => {
        const parsed = emailAddresses.parseOneAddress(email)
        return parsed && /^[^@]+@[^@]+\.[^@]+$/.test(email) // Regex to ensure domain part has a dot
      }
    )
    setInvalidEmails(invalidEmails)

    // also remove any emails in currentUsers from validEmails
    textAreaEmails = validEmails.filter((email) => !currentUsers.has(email))
    const dupeTextAreaEmails = _.intersection(
      validEmails,
      Array.from(currentUsers)
    )

    // dedupe emails
    const emails = _.uniq([...textAreaEmails, ...selectedUsers])
    const dupes = _.intersection(textAreaEmails, selectedUsers)

    let mismatchDomainUsers: string[] = []
    if (!_.isEmpty(domains)) {
      mismatchDomainUsers = emails.filter((email) => {
        const parsed = emailAddresses.parseOneAddress(email)
        const domain =
          parsed && 'domain' in parsed ? (parsed as ParsedMailbox).domain : ''
        return !domains.includes(domain)
      })
    }

    // remove mismatched domain users from emails
    // final cleanup
    const userEmails = emails
      .filter((email) => !mismatchDomainUsers.includes(email))
      .map((email) => email.toLowerCase().trim())

    setMismatchDomainUsers(mismatchDomainUsers)
    setDuplicateEmails([...dupes, ...dupeTextAreaEmails])
    setUsersToAdd(userEmails)
    setShowConfirmationDialog(true)
  }, [userEmailTextArea, currentUsers, selectedUsers, domains])

  const onUserAddSubmit = async () => {
    setLoading(true)
    try {
      const resp = await addVaultAddOnUsers(workspace.id, usersToAdd)
      setAddedUsers(resp.managedCount)
      setFailedToAddUsers(
        resp.failedUserEmailWithReasons?.map((u) => u.userEmail) ?? []
      )
      setVaultAddOnUsers(resp.users.filter((u) => u.hasSeat))
      displaySuccessMessage('Users added successfully', 5)
    } catch (error) {
      const errMsg =
        error instanceof Error ? error.message : 'Failed to add vault user'
      displayErrorMessage(errMsg, 10)
    }
    setLoading(false)
  }

  return (
    <>
      <div className="mt-4 space-y-4">
        <Select
          mode="multiple"
          placeholder="Select users"
          virtual={false}
          style={{ width: '100%' }}
          options={_.sortBy(
            availableUsers.map((user) => {
              return { value: user, label: user }
            }),
            'label'
          )}
          loading={_.isEmpty(availableUsers)}
          optionFilterProp="label"
          onChange={(value) => setSelectedUsers(value)}
          value={selectedUsers}
        />
        <MultiEmailTextarea
          value={userEmailTextArea}
          onChange={(emails: string) => setUserEmailTextArea(emails)}
          maxNumUsers={MAX_NUM_USERS}
          emailsValidCallback={(valid: boolean) => setUserLimitExceeded(!valid)}
          placeholder={`Enter new user emails to grant vault ${type} seats, one email per line`}
          setNumEmails={(numEmails: number) => setTextAreaLines(numEmails)}
        />
        {addedUsers === 0 ? (
          <div className="mt-4 flex w-full justify-end">
            {loading ? (
              <Spinner />
            ) : (
              <Button
                disabled={userLimitExceeded || numUsersToAdd === 0}
                onClick={onAddUsers}
                tooltip={
                  userLimitExceeded
                    ? `Exceeded maximum number of users (${MAX_NUM_USERS}) allowed`
                    : undefined
                }
              >
                Grant{' '}
                {numUsersToAdd === 0
                  ? 'users'
                  : `${numUsersToAdd} ${pluralize(
                      'users',
                      numUsersToAdd
                    )}`}{' '}
                {type}{' '}
                {numUsersToAdd === 0
                  ? 'seats'
                  : pluralize('seats', numUsersToAdd)}
              </Button>
            )}
          </div>
        ) : (
          <div className="flex justify-between">
            <div className="space-y-2">
              <p>
                {`${addedUsers} ${pluralize('users', addedUsers)}`}{' '}
                {addedUsers > 1 ? 'have' : 'has'} been granted vault {type}{' '}
                {pluralize('seats', numUsersToAdd)}
              </p>
              {failedToAddUsers.length > 0 && (
                <p>
                  {failedToAddUsers.length}{' '}
                  {pluralize('user', failedToAddUsers.length)} failed to be
                  added:
                  <br />
                  {failedToAddUsers.map((user, index) => (
                    <React.Fragment key={index}>
                      {user}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              )}
            </div>
            <Button variant="secondary" onClick={onClear}>
              Clear
            </Button>
          </div>
        )}
      </div>
      <VaultUserAddDialog
        showConfirmationDialog={showConfirmationDialog}
        setShowConfirmationDialog={setShowConfirmationDialog}
        usersToAdd={usersToAdd}
        mismatchDomainUsers={mismatchDomainUsers}
        invalidEmails={invalidEmails}
        duplicateEmails={duplicateEmails}
        handleAddUserSubmit={onUserAddSubmit}
      />
    </>
  )
}

export default VaultUsersAdd
