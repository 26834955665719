import { QueryClient } from '@tanstack/react-query'
import { ColDef, GridApi } from 'ag-grid-community'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultFile } from 'openapi/models/VaultFile'
import { HistoryItem } from 'types/history'

import { LEFT_PINNED_COLUMNS } from 'components/vault/query-detail/vault-query-detail'
import {
  QueryQuestion,
  ReviewColumn,
  ReviewEvent,
  ReviewSource,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import { mapReviewEventToEventV1Metadata } from 'components/vault/utils/vault-helpers'
import {
  useVaultStore,
  VaultCurrentStreamingState,
  VaultReviewSocketState,
} from 'components/vault/utils/vault-store'

import {
  GridTransactionAction,
  computeIsQueryLoading,
  addFilesToGrid,
  mapReviewColumnToColumnDef,
  invalidateQuery,
  checkIfHasReachedQuestionsLimit,
} from './data-grid-helpers'

export type CellViewerData = {
  fileId: string
  file: VaultFile
  colId: string
}

export type ReviewHistoryItem = HistoryItem &
  ReviewEvent &
  VaultReviewSocketState &
  VaultCurrentStreamingState

export interface AddColumnCellState {
  isHoveringAddColumn: boolean
  currentPendingColumnId: string | null
  hasReachedQuestionsLimit: boolean
}

export interface VaultQueryDetailState {
  workflow: ReviewWorkflow | null
  useV1QueryDetail: boolean
  queryId: string
  isRunButtonLoading: boolean
  isQueryLoading: boolean
  isFetchingQuery: boolean
  historyItem: HistoryItem | ReviewHistoryItem | null
  fetchingFileIdsSources: string[]
  fileIdToSources: Record<string, ReviewSource[]>
}

interface VaultDataGridState {
  gridApi: GridApi | null
  isGridDirty: boolean
  hasOnGridReadyExecuted: boolean
  initialHistoryItem: HistoryItem | ReviewHistoryItem | null
  initialPendingQueryFileIds: string[] | null
  initialPendingQueryQuestions: QueryQuestion[] | null
  pendingQueryFileIds: string[] | null
  pendingQueryQuestions: QueryQuestion[] | null
  cellViewerData: CellViewerData | null
}

interface AddColumnCellAction {
  setIsHoveringAddColumn: (isHoveringAddColumn: boolean) => void
  setCurrentPendingColumnId: (columnId: string | null) => void
}

export interface VaultQueryDetailAction {
  setUseV1QueryDetail: (useV1QueryDetail: boolean) => void
  setQueryId: (queryId: string, hasToFetchQuery?: boolean) => void
  setIsRunButtonLoading: (isRunButtonLoading: boolean) => void
  setHistoryItem: (historyItem: HistoryItem | ReviewHistoryItem | null) => void
  addFileIdToFetchingFileIdsSources: (fileId: string) => void
  addSourcesToFileId: (fileId: string, sources: ReviewSource[]) => void
}
export interface VaultDataGridAction {
  setWorkflow: (workflow: ReviewWorkflow | null) => void
  setGridApi: (gridApi: GridApi | null, queryClient?: QueryClient) => void
  setIsGridDirty: (isGridDirty: boolean) => void
  setHasOnGridReadyExecuted: (hasOnGridReadyExecuted: boolean) => void
  setPendingQueryFileIds: (fileIds: string[] | null) => void
  addToPendingQueryFileIds: (fileIds: string[]) => void
  removeFileIdsFromPendingQueryFileIds: (fileIds: string[]) => void
  setPendingQueryQuestions: (questions: QueryQuestion[] | null) => void
  addToPendingQueryQuestions: (
    question: QueryQuestion,
    shouldSetPendingColumnId?: boolean
  ) => void
  removeFromPendingQueryQuestions: (columnId: string) => void
  updatePendingQueryQuestion: (
    columnId: string,
    key: string,
    value: string
  ) => void
  updateColumnInHistoryItem: (column: ReviewColumn) => void
  setCellViewerData: (cellViewerData: CellViewerData | null) => void
}

const useVaultQueryDetailStore = create(
  immer(
    devtools(
      immer<
        VaultQueryDetailState &
          VaultQueryDetailAction &
          VaultDataGridState &
          VaultDataGridAction &
          AddColumnCellState &
          AddColumnCellAction
      >((set) => ({
        // Add Column State
        isHoveringAddColumn: false,
        currentPendingColumnId: null,
        hasReachedQuestionsLimit: false,
        // Vault Query Detail State
        useV1QueryDetail: false,
        workflow: null,
        queryId: 'new',
        isRunButtonLoading: false,
        isQueryLoading: false,
        isFetchingQuery: true,
        historyItem: null,
        gridApi: null,
        isGridDirty: false,
        hasOnGridReadyExecuted: false,
        initialHistoryItem: null,
        initialPendingQueryFileIds: null,
        initialPendingQueryQuestions: null,
        pendingQueryFileIds: null,
        pendingQueryQuestions: null,
        cellViewerData: null,
        fetchingFileIdsSources: [],
        fileIdToSources: {},
        // Add Column Action
        setIsHoveringAddColumn: (isHoveringAddColumn: boolean) => {
          set((state) => {
            state.isHoveringAddColumn = isHoveringAddColumn
          })
        },
        setCurrentPendingColumnId: (columnId: string | null) => {
          set((state) => {
            state.currentPendingColumnId = columnId
          })
        },
        // Vault Query Detail Action
        setUseV1QueryDetail: (useV1QueryDetail: boolean) => {
          set((state) => {
            state.useV1QueryDetail = useV1QueryDetail
          })
        },
        setQueryId: (queryId: string, hasToFetchQuery?: boolean) => {
          set((state) => {
            state.queryId = queryId
            state.isQueryLoading = hasToFetchQuery ?? queryId !== 'new'
            // if we are setting the queryId then we are not fetching the query
            // this is required to prevent vault-query-detail from flashing the null table
            state.isFetchingQuery = hasToFetchQuery ?? queryId === 'new'
          })
        },
        setIsRunButtonLoading: (isRunButtonLoading: boolean) => {
          set((state) => {
            state.isRunButtonLoading = isRunButtonLoading
          })
        },
        setGridApi: (gridApi: GridApi | null, queryClient?: QueryClient) => {
          set((state) => {
            state.gridApi = gridApi
            if (state.isGridDirty && queryClient) {
              invalidateQuery(queryClient, state.queryId)
            }
            state.isGridDirty = false
          })
        },
        setIsGridDirty: (isGridDirty: boolean) => {
          set((state) => {
            state.isGridDirty = isGridDirty
          })
        },
        setHasOnGridReadyExecuted: (hasOnGridReadyExecuted: boolean) => {
          set((state) => {
            state.hasOnGridReadyExecuted = hasOnGridReadyExecuted
          })
        },
        setHistoryItem: (
          historyItem: HistoryItem | ReviewHistoryItem | null
        ) => {
          set((state) => {
            const newQueryId = historyItem?.id ?? 'new'
            const newIsQueryLoading = computeIsQueryLoading(historyItem)

            const previouslyErroredFileIds = state.historyItem
              ? Object.keys(
                  (state.historyItem as ReviewHistoryItem).errors ?? {}
                )
              : []
            state.historyItem = historyItem
            state.queryId = newQueryId
            state.isQueryLoading = newIsQueryLoading

            // if we are setting the historyItem to null then we should reset our state (when component unMounts)
            state.isFetchingQuery = newQueryId === 'new' ? true : !historyItem
            state.hasOnGridReadyExecuted = !historyItem
              ? false
              : state.hasOnGridReadyExecuted
            state.initialHistoryItem = !historyItem
              ? historyItem
              : !state.initialHistoryItem
              ? historyItem
              : state.initialHistoryItem
            state.fetchingFileIdsSources = !historyItem
              ? []
              : state.fetchingFileIdsSources
            state.fileIdToSources = !historyItem ? {} : state.fileIdToSources

            const reviewEvent = historyItem as ReviewHistoryItem
            state.hasReachedQuestionsLimit = !historyItem
              ? false
              : checkIfHasReachedQuestionsLimit(reviewEvent)
            // when streaming we want to use the historyItem to update row data
            // only after the hasOnGridReadyExecuted is true, we know that the grid is ready
            if (state.gridApi && historyItem && state.hasOnGridReadyExecuted) {
              const setDisplayedRows =
                useVaultDataGridFilterStore.getState().setDisplayedRows
              const folderIdToVaultFolder =
                useVaultStore.getState().folderIdToVaultFolder
              const fileIdToVaultFile =
                useVaultStore.getState().fileIdToVaultFile

              // update columnDefs with columnId
              const currentColumnDefs = state.gridApi.getColumnDefs()
              const updatedColumnDefs = (currentColumnDefs
                ?.map((colDef) => {
                  const isIdInColDef = 'colId' in colDef
                  if (!isIdInColDef) return colDef
                  const colId = colDef.colId
                  const historyItemColumn = reviewEvent.columns.find(
                    (column) => column.displayId.toString() === colId
                  )
                  if (historyItemColumn) {
                    if (historyItemColumn.isHidden) {
                      return null
                    }
                    return {
                      ...colDef,
                      suppressMovable: newIsQueryLoading,
                      resizable: !newIsQueryLoading,
                      eventId: reviewEvent.id,
                      backingReviewColumn: historyItemColumn,
                    }
                  }
                  return colDef
                })
                .filter(Boolean) ?? []) as ColDef<any, any>[]
              const newColumns = reviewEvent.columns
                .filter((column) => !column.isHidden)
                .filter(
                  (column) =>
                    !updatedColumnDefs?.some(
                      (colDef) =>
                        'colId' in colDef &&
                        colDef.colId === column.displayId.toString()
                    )
                )
                .map((column) => {
                  return {
                    suppressMovable: newIsQueryLoading,
                    resizable: !newIsQueryLoading,
                    ...mapReviewColumnToColumnDef(column, reviewEvent.eventId),
                  }
                })
              const allColumnDefs = [...updatedColumnDefs, ...newColumns]
              allColumnDefs.sort((a, b) => {
                // Prioritize pinned columns
                const aField = a.field ?? ''
                const bField = b.field ?? ''
                const isAPinned = LEFT_PINNED_COLUMNS.includes(aField)
                const isBPinned = LEFT_PINNED_COLUMNS.includes(bField)

                if (isAPinned && !isBPinned) return -1
                if (!isAPinned && isBPinned) return 1

                const aOrder =
                  'backingReviewColumn' in a
                    ? a.backingReviewColumn.order
                    : // XXX: Add a large number to make sure pending columns are always at the end
                      allColumnDefs.indexOf(a) + 10_000
                const bOrder =
                  'backingReviewColumn' in b
                    ? b.backingReviewColumn.order
                    : // XXX: Add a large number to make sure pending columns are always at the end
                      allColumnDefs.indexOf(b) + 10_000
                return aOrder - bOrder
              })
              state.gridApi.setGridOption('columnDefs', allColumnDefs)

              const historyFilesToAdd =
                (reviewEvent?.rows
                  .filter((row) => !row.isHidden)
                  .map((row) => fileIdToVaultFile[row.fileId])
                  .filter(Boolean) as VaultFile[]) ?? []

              addFilesToGrid({
                gridApi: state.gridApi as GridApi<any>,
                setDisplayedRows: setDisplayedRows,
                files: historyFilesToAdd,
                folderIdToVaultFolder: folderIdToVaultFolder,
                isLoading: newIsQueryLoading,
                gridAction: GridTransactionAction.UPDATE,
                isDryRun: reviewEvent.dryRun,
                fileIdToAnswers: reviewEvent.answers,
                fileIdToErrors: reviewEvent.errors,
                previouslyErroredFileIds: previouslyErroredFileIds,
                questions: reviewEvent.questions,
                reviewRows: reviewEvent.rows,
                suppressedFileIdsSet: new Set(reviewEvent.suppressedFileIds),
              })
            }
          })
        },
        setWorkflow: (workflow: ReviewWorkflow | null) => {
          set((state) => {
            state.workflow = workflow
          })
        },
        setPendingQueryFileIds: (fileIds: string[] | null) => {
          set((state) => {
            state.initialPendingQueryFileIds = fileIds
            state.pendingQueryFileIds = fileIds
          })
        },
        removeFileIdsFromPendingQueryFileIds: (fileIds: string[]) => {
          set((state) => {
            if (state.pendingQueryFileIds) {
              const filteredPendingQueryFileIds =
                state.pendingQueryFileIds.filter(
                  (fileId) => !fileIds.includes(fileId)
                )
              state.pendingQueryFileIds =
                filteredPendingQueryFileIds.length > 0
                  ? filteredPendingQueryFileIds
                  : null
            } else {
              state.pendingQueryFileIds = null
            }
          })
        },
        addToPendingQueryFileIds: (fileIds: string[]) => {
          set((state) => {
            state.pendingQueryFileIds = [
              ...(state.pendingQueryFileIds ?? []),
              ...fileIds,
            ]
          })
        },
        setPendingQueryQuestions: (questions: QueryQuestion[] | null) => {
          set((state) => {
            state.initialPendingQueryQuestions = questions
            state.pendingQueryQuestions = questions
          })
        },
        addToPendingQueryQuestions: (
          question: QueryQuestion,
          shouldSetPendingColumnId: boolean = true
        ) => {
          set((state) => {
            if (shouldSetPendingColumnId) {
              state.currentPendingColumnId = question.id
            }
            state.pendingQueryQuestions = [
              ...(state.pendingQueryQuestions ?? []),
              question,
            ]
          })
        },
        removeFromPendingQueryQuestions: (columnId: string) => {
          set((state) => {
            if (state.pendingQueryQuestions) {
              // Filter out the deleted question
              const filteredQuestions = state.pendingQueryQuestions.filter(
                (question) => question.id !== columnId
              )
              state.pendingQueryQuestions =
                filteredQuestions.length > 0 ? filteredQuestions : null
            } else {
              state.pendingQueryQuestions = null
            }
          })
        },
        updatePendingQueryQuestion: (
          columnId: string,
          key: string,
          value: string
        ) => {
          set((state) => {
            state.pendingQueryQuestions = state.pendingQueryQuestions
              ? state.pendingQueryQuestions?.map((question) => {
                  if (question.id === columnId) {
                    return {
                      ...question,
                      [key]: value,
                    }
                  }
                  return question
                })
              : null
          })
        },
        updateColumnInHistoryItem: (column: ReviewColumn) => {
          set((state) => {
            if (!state.historyItem) return
            const reviewEvent = JSON.parse(
              JSON.stringify(state.historyItem)
            ) as ReviewHistoryItem
            reviewEvent.columns = reviewEvent.columns.map((existingColumn) => {
              if (existingColumn.id === column.id) {
                return column
              }
              return existingColumn
            })
            const metadata = mapReviewEventToEventV1Metadata(reviewEvent)
            const newHistoryItem = {
              ...reviewEvent,
              metadata: metadata,
              ...metadata,
            } as ReviewHistoryItem
            state.setHistoryItem(newHistoryItem)
          })
        },
        setCellViewerData: (cellViewerData: CellViewerData | null) => {
          set((state) => {
            state.cellViewerData = cellViewerData
          })
        },
        addFileIdToFetchingFileIdsSources: (fileId: string) => {
          set((state) => {
            state.fetchingFileIdsSources = [
              ...state.fetchingFileIdsSources,
              fileId,
            ]
          })
        },
        addSourcesToFileId: (fileId: string, sources: ReviewSource[]) => {
          set((state) => {
            state.fetchingFileIdsSources = state.fetchingFileIdsSources.filter(
              (id) => id !== fileId
            )
            state.fileIdToSources = {
              ...state.fileIdToSources,
              [fileId]: sources,
            }
          })
        },
      }))
    )
  )
)

export default useVaultQueryDetailStore
