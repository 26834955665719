import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { isNull } from 'lodash'
import { Trash } from 'lucide-react'
import pluralize from 'pluralize'

import {
  levelToDisplayString,
  timeFrameToDisplayString,
  unitLevelToDisplayString,
  vaultFeatureToDisplayString,
} from 'models/query-cap-rule'
import { removeVaultAddOnUsers, VaultUser } from 'models/vault'
import { Workspace } from 'models/workspace'
import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'

import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { useVaultManagementStore } from 'components/settings/workspace/workspace-details/vault-management/vault-management-store'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import Icon from 'components/ui/icon/icon'

import VaultUserManageDialog from './vault-user-manage-dialog'

interface VaultAddOnUsersTableProps {
  vaultUsers: VaultUser[]
  isLoading: boolean
  workspace: Workspace
  onAddOnUserUpdate: (vaultUser: VaultUser) => void
}

const VaultUsersTable = ({
  vaultUsers,
  isLoading,
  workspace,
  onAddOnUserUpdate,
}: VaultAddOnUsersTableProps) => {
  const userInfo = useAuthUser()

  const [manageDialogOpen, setManageDialogOpen] = useState<boolean>(false)
  const [manageUser, setManageUser] = useState<VaultUser | undefined>()

  const setVaultAddOnUsers = useVaultManagementStore(
    (state) => state.setVaultAddOnUsers
  )

  const onRemoveUser = async (userEmail: string) => {
    try {
      const resp = await removeVaultAddOnUsers(workspace.id, [userEmail])
      setVaultAddOnUsers(resp.users.filter((u) => u.hasSeat))
      displaySuccessMessage('Vault add-on user removed successfully', 5)
    } catch (error) {
      const errMsg =
        error instanceof Error
          ? error.message
          : 'Failed to remove vault add-on user'
      displayErrorMessage(errMsg, 10)
    }
  }

  const onManageClick = (user: VaultUser) => {
    setManageUser(user)
    setManageDialogOpen(true)
  }

  const isUnlimited =
    workspace.hasVaultAddOn &&
    workspace.vaultAddOnSettings
      .vault_review_files_count_unlimited_per_user_per_month === 1
  const isSharedWithinWorkspace =
    workspace.hasVaultAddOn &&
    isNull(
      workspace.vaultAddOnSettings
        .vault_review_files_count_limit_per_user_per_month
    ) &&
    !isNull(
      workspace.vaultAddOnSettings
        .vault_review_files_count_limit_per_workspace_per_month
    )

  const columns: ColumnDef<VaultUser>[] = [
    {
      accessorKey: 'userEmail',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Email" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 w-1/2 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'feature',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Feature" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 w-1/2 text-sm">
          {vaultFeatureToDisplayString[String(getValue()) as WorkspaceFeature]}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'level',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Level" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 w-1/2 text-sm">
          {levelToDisplayString[String(getValue()) as QueryCapRuleLevel]}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'reviewLimit',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Review Limit" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 w-1/2 text-sm">
          {isUnlimited ? 'Unlimited' : String(getValue())}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'reviewUsage',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Review Usage" />
      },
      cell: ({ row }) => (
        <div
          className={cn(
            'ml-3 w-1/2 text-sm',
            !isUnlimited && row.original.reviewUsage > row.original.reviewLimit
              ? 'text-destructive'
              : ''
          )}
        >
          {row.original.reviewUsage}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'unitLevel',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Unit Level" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 w-1/2  text-sm">
          {
            unitLevelToDisplayString[
              String(getValue()) as QueryCapRuleUnitLevel
            ]
          }
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'timeFrame',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Time Frame" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 w-1/2  text-sm">
          {
            timeFrameToDisplayString[
              String(getValue()) as QueryCapRuleTimeFrame
            ]
          }
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'hasSeatSince',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Date added" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 w-1/2 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        return (
          <div className="float-right flex justify-end space-x-2">
            {!isUnlimited && !isSharedWithinWorkspace && (
              <Button
                size="sm"
                variant="secondary"
                onClick={() => onManageClick(row.original)}
              >
                Manage
              </Button>
            )}
            <Button
              className="flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={`Remove ${row.getValue('userEmail')}`}
              tooltipSide="left"
              onClick={() => onRemoveUser(row.getValue('userEmail'))}
            >
              <Icon icon={Trash} className="stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: vaultUsers,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        rolePk: false,
        actions: userInfo.IsInternalAdminWriter,
      },
    },

    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'users',
    table.getFilteredRowModel().rows.length
  )}`

  return (
    <>
      <div className="-mt-10">
        <DataTableHeader
          searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
        />
        <div className="mt-4">
          <DataTable
            table={table}
            className={cn({ 'h-[420px]': !isLoading })}
            useVirtual={vaultUsers.length > 100}
            isLoading={isLoading}
            emptyStateText="No users found"
          />
        </div>
        <DataTableFooter table={table} isLoading={isLoading}>
          <p>{rowCountCopy}</p>
        </DataTableFooter>
      </div>
      <VaultUserManageDialog
        workspace={workspace}
        open={manageDialogOpen}
        onOpenChange={setManageDialogOpen}
        user={manageUser}
        onAddOnUserUpdate={onAddOnUserUpdate}
      />
    </>
  )
}

export default VaultUsersTable
