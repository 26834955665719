import React from 'react'

import { cn } from 'utils/utils'

import { getProgressPath } from './progress-play'

interface ProgressPauseProps extends React.SVGProps<SVGSVGElement> {
  className?: string
  progressPercentage?: number
}

export const ProgressPause = ({
  className,
  progressPercentage = 0,
  ...props
}: ProgressPauseProps): JSX.Element => {
  const progressPath = getProgressPath(progressPercentage)

  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="9" stroke="#E5E3E1" strokeWidth="2" />
      <path
        d={progressPath}
        stroke="#0F0D0B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="9" y="9" width="2" height="6" rx="1" fill="#0F0D0B" />
      <rect x="13" y="9" width="2" height="6" rx="1" fill="#0F0D0B" />
    </svg>
  )
}
