import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import { LanguageCode } from 'openapi/models/LanguageCode'

import Modal from './components/modal'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import ProfileLanguageForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
} from 'components/common/profile/profile-preferred-language-form'

import { JobTitleStepType } from './job-title-step'
import { PracticeAreaStepType } from './practice-area-step'
import { ProfileSetupFlowType } from './profile-setup'

export type PrimaryLanguageStepType = Step<
  PracticeAreaStepType['stepOutput'] | JobTitleStepType['stepOutput'],
  {
    preferredLanguage: LanguageCode
  }
>

const primaryLanguageSchema = z.object({
  preferredLanguage: z.nativeEnum(LanguageCode),
})

export type PrimaryLanguageSchema = z.infer<typeof primaryLanguageSchema>

const PrimaryLanguageStep: React.FC = () => {
  const { goBack, navigateToStep } = useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<PrimaryLanguageStepType>()
  const form = useForm<PrimaryLanguageSchema>({
    resolver: zodResolver(primaryLanguageSchema),
  })
  const { trackEvent } = useAnalytics()

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent(
      'profile_setup_primary_language_step_viewed',
      preparedEventProperties
    )
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    navigateToStep('profile-complete-step', {
      ...stepInput,
      preferredLanguage: data.preferredLanguage,
    })
  })

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      <ProfileLanguageForm control={form.control} />
    </Modal>
  )
}

export default PrimaryLanguageStep
