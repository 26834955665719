/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the query cap rule unit level, whether the cap is per cell, per file, or per query.
 * @export
 */
export const QueryCapRuleUnitLevel = {
    CELL: 'CELL',
    FILE: 'FILE',
    QUERY: 'QUERY'
} as const;
export type QueryCapRuleUnitLevel = typeof QueryCapRuleUnitLevel[keyof typeof QueryCapRuleUnitLevel];


export function instanceOfQueryCapRuleUnitLevel(value: any): boolean {
    for (const key in QueryCapRuleUnitLevel) {
        if (Object.prototype.hasOwnProperty.call(QueryCapRuleUnitLevel, key)) {
            if (QueryCapRuleUnitLevel[key as keyof typeof QueryCapRuleUnitLevel] === value) {
                return true;
            }
        }
    }
    return false;
}

export function QueryCapRuleUnitLevelFromJSON(json: any): QueryCapRuleUnitLevel {
    return QueryCapRuleUnitLevelFromJSONTyped(json, false);
}

export function QueryCapRuleUnitLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryCapRuleUnitLevel {
    return json as QueryCapRuleUnitLevel;
}

export function QueryCapRuleUnitLevelToJSON(value?: QueryCapRuleUnitLevel | null): any {
    return value as any;
}

export function QueryCapRuleUnitLevelToJSONTyped(value: any, ignoreDiscriminator: boolean): QueryCapRuleUnitLevel {
    return value as QueryCapRuleUnitLevel;
}

