/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractsSmartFilterQuery
 */
export interface ContractsSmartFilterQuery {
    /**
     * 
     * @type {string}
     * @memberof ContractsSmartFilterQuery
     */
    expectedValue: string;
    /**
     * 
     * @type {string}
     * @memberof ContractsSmartFilterQuery
     */
    nameOfKey: string;
    /**
     * 
     * @type {string}
     * @memberof ContractsSmartFilterQuery
     */
    question: string;
}

/**
 * Check if a given object implements the ContractsSmartFilterQuery interface.
 */
export function instanceOfContractsSmartFilterQuery(value: object): value is ContractsSmartFilterQuery {
    if (!('expectedValue' in value) || value['expectedValue'] === undefined) return false;
    if (!('nameOfKey' in value) || value['nameOfKey'] === undefined) return false;
    if (!('question' in value) || value['question'] === undefined) return false;
    return true;
}

export function ContractsSmartFilterQueryFromJSON(json: any): ContractsSmartFilterQuery {
    return ContractsSmartFilterQueryFromJSONTyped(json, false);
}

export function ContractsSmartFilterQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsSmartFilterQuery {
    if (json == null) {
        return json;
    }
    return {
        
        'expectedValue': json['expected_value'],
        'nameOfKey': json['name_of_key'],
        'question': json['question'],
    };
}

export function ContractsSmartFilterQueryToJSON(json: any): ContractsSmartFilterQuery {
    return ContractsSmartFilterQueryToJSONTyped(json, false);
}

export function ContractsSmartFilterQueryToJSONTyped(value?: ContractsSmartFilterQuery | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'expected_value': value['expectedValue'],
        'name_of_key': value['nameOfKey'],
        'question': value['question'],
    };
}

