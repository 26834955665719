import React, { useMemo } from 'react'

import { toLower } from 'lodash'
import { RefreshCw } from 'lucide-react'
import { Dict } from 'mixpanel-browser'
import { useShallow } from 'zustand/react/shallow'

import Services from 'services'

import { TodayOption, readableFormat } from 'utils/date-utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

import { LibraryConstants } from './library-constants'
import {
  usePromptItemsStore,
  useExampleItemsStore,
} from './library-items-store'
import { LibraryItemKind } from './library-types'

// Generic prop types, adjust according to your needs
interface LibraryStatusProps {
  isLoading: boolean
  lastUpdatedTime: Date | null
  fetchUpdate: () => void
  libraryItemKind: LibraryItemKind
  recordMetric: string
  trackEvent: (eventName: string, properties?: Dict | undefined) => void
}

const LibraryStatus: React.FC<LibraryStatusProps> = ({
  isLoading,
  lastUpdatedTime,
  libraryItemKind,
  fetchUpdate,
  recordMetric,
  trackEvent,
}) => {
  const onClickRefreshButton = () => {
    Services.HoneyComb.Record({
      metric: recordMetric,
      last_updated_time: lastUpdatedTime,
    })
    trackEvent('Library Table Refreshed', {
      kind: libraryItemKind,
    })
    fetchUpdate()
  }

  const statusMessage = useMemo(() => {
    if (lastUpdatedTime === null && !isLoading) {
      return `Unable to retrieve ${toLower(libraryItemKind)}s`
    } else if (isLoading) {
      return `Retrieving ${toLower(libraryItemKind)}s…`
    } else if (lastUpdatedTime) {
      return `Last updated on ${readableFormat(
        lastUpdatedTime,
        TodayOption.showTime
      )}`
    }
  }, [isLoading, lastUpdatedTime, libraryItemKind])

  return (
    <div className="flex h-8 items-center">
      {isLoading ? (
        <Spinner size="xs" className="mx-2 shrink-0" />
      ) : (
        <div className="flex shrink-0 items-center">
          <Button
            aria-label="Refresh library"
            size="xsIcon"
            variant="ghost"
            className="mx-1 text-muted"
            onClick={onClickRefreshButton}
          >
            <RefreshCw className="h-4 w-4 shrink-0" />
          </Button>
        </div>
      )}
      <p className="text-muted">{statusMessage}</p>
    </div>
  )
}

type StatusProps = {
  fetchUpdate: () => void
}

export const PromptsStatus = ({ fetchUpdate }: StatusProps) => {
  const { isLoading, lastUpdatedTime } = usePromptItemsStore(
    useShallow((s) => ({
      isLoading: s.isLoading,
      lastUpdatedTime: s.lastUpdatedTime,
    }))
  )
  const { trackEvent } = useAnalytics()
  return (
    <LibraryStatus
      isLoading={isLoading}
      lastUpdatedTime={lastUpdatedTime}
      fetchUpdate={fetchUpdate}
      libraryItemKind={LibraryItemKind.PROMPT}
      recordMetric={LibraryConstants.PROMPTS_METRIC}
      trackEvent={trackEvent}
    />
  )
}

export const ExampleStatus = ({ fetchUpdate }: StatusProps) => {
  const { isLoading, lastUpdatedTime } = useExampleItemsStore(
    useShallow((s) => ({
      isLoading: s.isLoading,
      lastUpdatedTime: s.lastUpdatedTime,
    }))
  )
  const { trackEvent } = useAnalytics()
  return (
    <LibraryStatus
      isLoading={isLoading}
      lastUpdatedTime={lastUpdatedTime}
      fetchUpdate={fetchUpdate}
      libraryItemKind={LibraryItemKind.EXAMPLE}
      recordMetric={LibraryConstants.EXAMPLES_METRIC}
      trackEvent={trackEvent}
    />
  )
}
