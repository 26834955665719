import { useCallback, useState } from 'react'
import { useMount } from 'react-use'

import {
  FetchWorkspaceOffboardJobs,
  WorkspaceOffboardJobs,
} from 'models/workspace'

const useWorkspaceOffboardJobs = (workspace_id: number) => {
  const [workspaceOffboardJobs, setWorkspaceOffboardJobs] =
    useState<WorkspaceOffboardJobs | null>(null)
  const [isLoadingWorkspaceOffboardJobs, setIsLoadingWorkspaceOffboardJobs] =
    useState(true)

  const fetchWorkspaceOffboardJobs = useCallback(async () => {
    setIsLoadingWorkspaceOffboardJobs(true)
    try {
      const res = await FetchWorkspaceOffboardJobs(workspace_id)
      setWorkspaceOffboardJobs(res)
    } catch (e) {
      console.error('Failed to fetch workspace offboard jobs', e)
    } finally {
      setIsLoadingWorkspaceOffboardJobs(false)
    }
  }, [workspace_id])

  useMount(() => {
    void fetchWorkspaceOffboardJobs()
  })

  const refetchWorkspaceOffboardJobs = useCallback(() => {
    void fetchWorkspaceOffboardJobs()
  }, [fetchWorkspaceOffboardJobs])

  return {
    workspaceOffboardJobs,
    isLoadingWorkspaceOffboardJobs,
    refetchWorkspaceOffboardJobs,
  }
}

export default useWorkspaceOffboardJobs
