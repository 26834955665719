import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLocation } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { startCase, isEmpty } from 'lodash'
import { InfoIcon, Plus } from 'lucide-react'
import pluralize from 'pluralize'
import queryString from 'query-string'
import { toast } from 'sonner'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { bytesToPreciseReadable } from 'utils/file-utils'
import { SafeRecord } from 'utils/safe-types'
import { TaskType } from 'utils/task'
import { displayErrorMessage, displayInfoMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import {
  ClientMatter,
  useClientMattersStore,
} from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import SetExampleProject from 'components/vault/components/set-example-project'
import VaultExportDialog from 'components/vault/dialogs/vault-export-dialog'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultProjectHistoryStats } from 'components/vault/utils/use-vault-project-history-stats'
import {
  REMOVE_PARAMS,
  newProjectPathRaw,
  projectsPath,
  projectsPathRaw,
  filesPathRaw,
  queriesPathRaw,
  DOT_SEPARATOR,
  filesPath,
} from 'components/vault/utils/vault'
import { CancelVaultHistoryItem } from 'components/vault/utils/vault-fetcher'
import { QUERY_TYPES, useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

import FileBreadcrumb from './file-breadcrumb'
import { FileControls } from './file-controls'
import QueryDetailBreadcrumb from './query-detail-breadcrumb'
import VaultAssistantButton from './vault-assistant-button'
import VaultHomeHeader from './vault-home-header'
import VaultProjectTabs from './vault-project-tabs'
import VaultShareButton from './vault-share-button'

const loadingText = 'Loading…'
const untitledQueryText = 'Untitled'
interface BreadcrumbPath {
  name: string
  path: string
}

const VaultAppHeader = () => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const { trackEvent } = useAnalytics()

  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const allFoldersMetadata = useVaultStore(
    useShallow((s) => s.allFoldersMetadata)
  )
  const folderIdToVaultFileIds = useVaultStore(
    useShallow((s) => s.folderIdToVaultFileIds)
  )
  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const queryId = useVaultStore((s) => s.queryId)
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const queryIdToReviewState = useVaultStore((s) => s.queryIdToReviewState)
  const exampleProjectIds = useVaultStore((s) => s.exampleProjectIds)

  const clientMatters = useClientMattersStore((s) => s.clientMatters)
  const query = queryIdToState[queryId]?.query
  const isLoading = queryIdToState[queryId]?.isLoading ?? false
  const questions = queryIdToReviewState[queryId]?.questions || []
  const creatorUserEmail =
    queryIdToState[queryId]?.creatorUserEmail ?? undefined
  const taskType = queryIdToState[queryId]?.taskType
  const processedFileIds = useMemo(
    () =>
      queryIdToReviewState[queryId]?.processedFileIds.filter(
        (id) => !!fileIdToVaultFile[id]
      ),
    [queryIdToReviewState, fileIdToVaultFile, queryId]
  )

  const fileIds = useMemo(
    () =>
      queryIdToState[queryId]?.fileIds.filter(
        (id) => !!fileIdToVaultFile[id]
      ) || [],
    [queryIdToState, fileIdToVaultFile, queryId]
  )
  const numFiles = useMemo(
    () =>
      fileIds.length == 0 && queryIdToState[queryId]?.numFiles
        ? queryIdToState[queryId!]!.numFiles
        : fileIds.length,
    [fileIds, queryId, queryIdToState]
  )
  const startedAt = queryIdToState[queryId]?.startedAt
  const completedAt = queryIdToState[queryId]?.completedAt
  const pausedAt = queryIdToState[queryId]?.pausedAt
  const failedAt = queryIdToState[queryId]?.failedAt
  const eta = queryIdToReviewState[queryId]?.eta

  const isExampleProject = useMemo(
    () => currentProject && exampleProjectIds.has(currentProject.id),
    [currentProject, exampleProjectIds]
  )

  const setIsTextAreaFocused = useVaultStore(
    (state) => state.setIsTextAreaFocused
  )
  const setQueryType = useVaultStore((state) => state.setQueryType)
  const setPendingQuery = useVaultStore((state) => state.setPendingQuery)
  const setPendingQueryFileIds = useVaultStore(
    (state) => state.setPendingQueryFileIds
  )
  const markInProgressTaskAsFromHistory = useVaultStore(
    (s) => s.markInProgressTaskAsFromHistory
  )

  const [isSetExampleOpen, setIsSetExampleOpen] = useState<boolean>(false)
  const [lastCancelRequestedAt, setLastCancelRequestedAt] =
    useState<Date | null>(null)
  const isCancelling =
    !!lastCancelRequestedAt && !!startedAt && lastCancelRequestedAt > startedAt

  const shouldRenderVaultHomeHeader = useMemo(() => {
    return (
      location.pathname === BaseAppPath.Vault ||
      location.pathname === `${BaseAppPath.Vault}/${projectsPathRaw}` ||
      location.pathname === `${BaseAppPath.Vault}${projectsPath}`
    )
  }, [location.pathname])

  const shouldRenderNewProjectBreadcrumb = useMemo(() => {
    const paths = location.pathname.split('/').filter(Boolean)
    return paths.length === 2 && paths[1] === newProjectPathRaw
  }, [location.pathname])

  const shouldRenderQueriesBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 4 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderQueryDetailBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries/:queryId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderFileBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/files/:fileId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === filesPathRaw
    )
  }, [location.pathname])

  const isShowingProjectPage = useMemo(() => {
    // When path is /vault/projects/:projectId
    const paths = location.pathname.split('/').filter(Boolean)

    return paths.length === 3 && paths[1] === projectsPathRaw
  }, [location.pathname])

  // we cannot get the fileId from useParams because vault-app-header is not a child of the layout
  // its in the same route as the layout
  const currentFileId = useMemo(() => {
    if (!shouldRenderFileBreadcrumb) return undefined
    const paths = location.pathname.split('/').filter(Boolean)
    return paths[paths.length - 1]
  }, [location, shouldRenderFileBreadcrumb])
  const currentFile = useMemo(() => {
    if (!currentFileId) return undefined
    return fileIdToVaultFile[currentFileId]
  }, [fileIdToVaultFile, currentFileId])

  const {
    doesCurrentUserHaveEditPermission,
    isOwner,
    canCurrentUserCreateShares,
  } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const shouldHideShareButtonForProjectOwner =
    isOwner && !canCurrentUserCreateShares

  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const breadcrumbPaths: BreadcrumbPath[] = useMemo(() => {
    // When path is either
    // 1. /vault/projects
    // 2. /vault/projects/:projectId
    // 3. /vault/projects/:projectId/queries
    // We should show the project name in the breadcrumbs
    const vaultDisplayNameWithPath = { name: 'Vault', path: BaseAppPath.Vault }
    const paths = location.pathname.split('/').filter(Boolean)

    if (shouldRenderNewProjectBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        { name: 'New project', path: location.pathname },
      ]
    }

    if (paths.length <= 2 || paths[1] !== projectsPathRaw) {
      // Since we are at vault app header, we should default to the vault projects path
      // if the path is not valid or too short
      return [vaultDisplayNameWithPath]
    }

    const projectId = paths[2]
    const projectDetailPath = `${BaseAppPath.Vault}${projectsPath}${projectId}`
    const projectDetailDisplayNameWithPath = {
      name: currentProject?.name || loadingText,
      path: projectDetailPath,
    }

    if (paths.length === 3) {
      return [vaultDisplayNameWithPath, projectDetailDisplayNameWithPath]
    }

    if (shouldRenderQueriesBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(queriesPathRaw), path: location.pathname },
      ]
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        {
          name: startCase(queriesPathRaw),
          path: `${projectDetailPath}/${queriesPathRaw}`,
        },
        { name: 'Query', path: location.pathname },
      ]
    }

    if (shouldRenderFileBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(filesPathRaw), path: projectDetailPath },
        { name: 'File', path: location.pathname },
      ]
    }
    return []
  }, [
    location.pathname,
    currentProject?.name,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderFileBreadcrumb,
  ])

  const pathForIndexFunc = useCallback(
    (index: number, pathname: string, search: string) => {
      const queryParams = queryString.parse(search)
      for (const param of REMOVE_PARAMS) {
        delete queryParams[param]
      }
      if (Object.keys(queryParams).length > 0) {
        return `${breadcrumbPaths[index].path}?${queryString.stringify(
          queryParams
        )}`
      }
      return breadcrumbPaths[index].path
    },
    [breadcrumbPaths]
  )

  const showNewQueryButton =
    taskType === TaskType.VAULT &&
    queryId &&
    !isLoading &&
    canCurrentUserEditProject
  const showCancelRequestNNButton =
    taskType === TaskType.VAULT_REVIEW &&
    questions.length > 0 &&
    isLoading &&
    (!processedFileIds || processedFileIds.length < fileIds.length)
  // show assistant button if user is currently viewing a project homepage
  const showAssistantButton =
    !isLoading &&
    userInfo.IsVaultKnowledgeSourceUser &&
    !isExampleProject &&
    currentProject?.id &&
    isShowingProjectPage
  const showAssistantButtonWithSharing = userInfo.IsVaultViewSharesUser
    ? doesCurrentUserHaveEditPermission && showAssistantButton
    : showAssistantButton
  // show project sharing button if user is currently viewing a project homepage
  const showShareButton =
    userInfo.IsVaultViewSharesUser &&
    currentProject?.id &&
    !isExampleProject &&
    !isLoading &&
    isShowingProjectPage &&
    !shouldHideShareButtonForProjectOwner
  const showFileControls = !!currentFile
  const fileIdsInCurrentFolder = useMemo(() => {
    if (!currentFile?.vaultFolderId) return []
    return (folderIdToVaultFileIds[currentFile.vaultFolderId] ?? [])
      .map((id) => fileIdToVaultFile[id])
      .filter((file) => !!file)
      .sort((a, b) => a!.name.localeCompare(b!.name))
      .map((file) => file!.id)
  }, [currentFile, folderIdToVaultFileIds, fileIdToVaultFile])
  const previousFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (currentIndex === -1 || currentIndex === 0) return undefined
    return fileIdsInCurrentFolder[currentIndex - 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const nextFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (
      currentIndex === -1 ||
      currentIndex === fileIdsInCurrentFolder.length - 1
    )
      return undefined
    return fileIdsInCurrentFolder[currentIndex + 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const currentFileIndex = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    return fileIdsInCurrentFolder.indexOf(currentFile.id)
  }, [currentFile, fileIdsInCurrentFolder])

  const shouldShowClientMatterBadge =
    userInfo.IsVaultProjectClientMatterUser &&
    isShowingProjectPage &&
    currentProject &&
    currentProject.clientMatterId

  const onNewQuery = () => {
    if (!showNewQueryButton) return
    trackEvent('Vault Review Query New Query Button Clicked', {
      query_id: queryId,
    })
    setIsTextAreaFocused(true)
    setQueryType(QUERY_TYPES.N1)
    setPendingQuery(query ?? '')
    setPendingQueryFileIds(fileIds.length > 0 ? fileIds : null)
  }

  const onCancelNNQuery = async () => {
    if (!showCancelRequestNNButton) return
    trackEvent('Vault Review Query Cancel Button Clicked', {
      query_id: queryId,
    })
    setLastCancelRequestedAt(new Date())
    // If we are using job queue for vault review, we could cancel the query
    // via job queue implementation
    try {
      const response = await CancelVaultHistoryItem(queryId)
      if (response.eventCancelled) {
        markInProgressTaskAsFromHistory(TaskType.VAULT_REVIEW)
        await queryClient.invalidateQueries({
          queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, queryId],
        })
      } else {
        displayInfoMessage('Cancelling, it will take a few seconds…', 5)
      }
    } catch (e) {
      console.error(e)
      displayErrorMessage('Failed to cancel query, please try again later')
    }
  }

  const navigateToFile = (currentProjectId: string, fileId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}${filesPath}${fileId}`,
      {},
      REMOVE_PARAMS
    )
  }
  useHotkeys(
    'up',
    () => {
      if (previousFileId) {
        navigateToFile(currentProject!.id, previousFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!previousFileId,
    }
  )
  useHotkeys(
    'down',
    () => {
      if (nextFileId) {
        navigateToFile(currentProject!.id, nextFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!nextFileId,
    }
  )
  const navigateToProject = (currentProjectId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}`,
      {},
      REMOVE_PARAMS
    )
  }
  // when the user presses escape key we want to hide the document previewer
  useHotkeys(
    'esc',
    () => {
      navigateToProject(currentProject!.id)
    },
    {
      enabled: showFileControls && !!currentProject,
    },
    [navigateToProject, currentProject, currentFile]
  )

  const dryRunStartToastIdRef = useRef<
    SafeRecord<string, string | number | null>
  >({})
  const dryRunSuccessToastIdRef = useRef<
    SafeRecord<string, string | number | null>
  >({})
  useEffect(() => {
    if (!queryIdToState[queryId]?.dryRun) {
      for (const toastId of Object.values({
        ...dryRunStartToastIdRef.current,
        ...dryRunSuccessToastIdRef.current,
      })) {
        if (toastId) {
          // Dismiss the previous toast if it exists
          toast.dismiss(toastId)
        }
      }
      dryRunStartToastIdRef.current = {}
      dryRunSuccessToastIdRef.current = {}
    }
  }, [queryId, queryIdToState])

  useEffect(() => {
    if (
      queryIdToState[queryId]?.dryRun &&
      queryIdToState[queryId]?.isLoading &&
      fileIds.length > 1
    ) {
      if (
        dryRunStartToastIdRef.current[queryId] ||
        dryRunSuccessToastIdRef.current[queryId]
      ) {
        // If the toast is already showing, don't show it again
        return
      }
      dryRunStartToastIdRef.current[queryId] = displayInfoMessage(
        'The review query will run the first file, you can add more columns or continue review on the remaining files once it finishes',
        10
      )
    }
  }, [fileIds.length, queryId, queryIdToState])

  const isNewQuery = queryId === 'new'
  const appHeaderTitle = isNewQuery
    ? untitledQueryText
    : queryIdToState[queryId]?.title || query || loadingText
  const overridePath = breadcrumbPaths.map((path) => path.name).join('/')

  useEffect(() => {
    if (isEmpty(currentProject)) {
      document.title = 'Vault'
    } else if (currentFile) {
      document.title = currentFile.name
    } else if (!isEmpty(currentProject) && !queryId) {
      document.title = currentProject.name
    } else if (!queryId) {
      document.title = untitledQueryText
    } else {
      document.title = appHeaderTitle
    }

    return () => {
      document.title = 'Harvey'
    }
  }, [appHeaderTitle, currentProject, queryId, overridePath, currentFile])

  const projectFilesCountLimitDisplayText = useMemo(() => {
    return `Each project can contain up to ${userInfo.workspace
      .getVaultFilesCountLimit(userInfo.vaultFeature)
      .toLocaleString()} files`
  }, [userInfo.vaultFeature, userInfo.workspace])

  const sizeDisplayText = useMemo(() => {
    const projectData = currentProject
      ? allFoldersMetadata[currentProject.id]
      : null
    if (!projectData) return 'Loading…'
    const numProjectFiles = projectData.totalFiles || 0
    const projectSize = bytesToPreciseReadable(
      projectData.folderSize || 0,
      2,
      true
    )
    if (numProjectFiles === 0) {
      return projectFilesCountLimitDisplayText
    }
    return `${pluralizeFiles(numProjectFiles)} (${projectSize})`
  }, [currentProject, allFoldersMetadata, projectFilesCountLimitDisplayText])

  const { historyStats } = useVaultProjectHistoryStats(currentProject?.id ?? '')

  const queriesDisplayText = useMemo(() => {
    if (!historyStats || historyStats.totalCount === 0) return null
    return `${historyStats.totalCount} ${pluralize(
      'query',
      historyStats.totalCount
    )}`
  }, [historyStats])

  const subtitleForProjectDetail = useMemo(() => {
    return [sizeDisplayText, queriesDisplayText]
      .filter(Boolean)
      .join(DOT_SEPARATOR)
  }, [sizeDisplayText, queriesDisplayText])

  const TitleComponent = useMemo(() => {
    if (shouldRenderVaultHomeHeader) {
      return <VaultHomeHeader />
    }

    if (shouldRenderFileBreadcrumb) {
      return <FileBreadcrumb currentFile={currentFile} />
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return (
        <QueryDetailBreadcrumb
          numFiles={numFiles}
          // Only show the query limit for vault review tasks
          processedFileIds={processedFileIds}
          isLoading={isLoading}
          completedAt={completedAt}
          pausedAt={pausedAt}
          failedAt={failedAt}
          eta={eta ? parseIsoString(eta) : null}
          // Only show the processing started time if it's a review task, because
          // for other types of tasks, it's usually quick and not worth showing.
          startedAt={taskType === TaskType.VAULT_REVIEW ? startedAt : undefined}
          projectId={currentProject?.id}
          title={appHeaderTitle}
          creatorUserEmail={creatorUserEmail}
        />
      )
    }

    if (breadcrumbPaths.length === 0) return null
    const lastBreadcrumbName = breadcrumbPaths[breadcrumbPaths.length - 1]?.name
    const subtitle = () => {
      if (shouldRenderQueriesBreadcrumb) return 'Browse your previous queries'
      if (shouldRenderNewProjectBreadcrumb)
        return projectFilesCountLimitDisplayText
      return subtitleForProjectDetail
    }

    return (
      <>
        <div className="flex space-x-2">
          <p className="line-clamp-2 text-xl font-medium">
            {lastBreadcrumbName}
          </p>
          {isExampleProject && (
            <Badge variant="secondary" className="border-0 text-muted" isPill>
              Example
              <Tooltip delayDuration={200}>
                <TooltipTrigger className="ml-1">
                  <Icon icon={InfoIcon} size="small" />
                </TooltipTrigger>
                <TooltipContent
                  className="max-w-80 text-start"
                  align="start"
                  side="right"
                >
                  This is an example project to give you a preview of how Vault
                  works. Queries cannot be run on this project. It will not
                  count towards any limits and cannot be deleted
                </TooltipContent>
              </Tooltip>
            </Badge>
          )}
          <SetExampleProject
            isSetExampleOpen={isSetExampleOpen}
            setIsSetExampleOpen={setIsSetExampleOpen}
          />
        </div>
        <p className="truncate text-xs text-muted">{subtitle()}</p>
      </>
    )
  }, [
    currentFile,
    currentProject,
    numFiles,
    taskType,
    appHeaderTitle,
    isLoading,
    processedFileIds,
    startedAt,
    completedAt,
    pausedAt,
    failedAt,
    eta,
    creatorUserEmail,
    shouldRenderFileBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderVaultHomeHeader,
    breadcrumbPaths,
    projectFilesCountLimitDisplayText,
    isExampleProject,
    subtitleForProjectDetail,
    isSetExampleOpen,
    setIsSetExampleOpen,
  ])

  return (
    <AppHeader
      breadcrumbs={
        breadcrumbPaths.length === 0 ? null : (
          <RouterBreadcrumbs
            overridesDocumentTitle
            overridePath={overridePath}
            keepCaseStartingFromIndex={1}
            checkHistoryIdInPath={false}
            pathForIndexFunc={pathForIndexFunc}
          />
        )
      }
      titleComponent={TitleComponent}
      actions={
        <div className="ml-2 flex shrink-0 grow items-center justify-end gap-2">
          {shouldShowClientMatterBadge && (
            <Badge
              variant="secondary"
              className="text-muted transition hover:bg-button-secondary"
            >
              CM#{' '}
              {
                clientMatters.find(
                  (cm: ClientMatter) =>
                    cm.id === currentProjectMetadata.clientMatterId ||
                    cm.id === currentProject.clientMatterId
                )?.name
              }
            </Badge>
          )}
          {showNewQueryButton && (
            <Button variant="outline" onClick={onNewQuery}>
              <Icon icon={Plus} className="mr-1" />
              New query
            </Button>
          )}
          {showCancelRequestNNButton && (
            <Button
              variant="outline"
              onClick={onCancelNNQuery}
              disabled={isCancelling}
              data-testid="cancel-query-button"
            >
              {isCancelling ? 'Cancelling…' : 'Cancel'}
            </Button>
          )}
          {shouldRenderQueryDetailBreadcrumb && <VaultExportDialog />}
          {showAssistantButtonWithSharing && <VaultAssistantButton />}
          {showShareButton && <VaultShareButton />}
          {showFileControls && currentProject && (
            <FileControls
              currentFileIndex={currentFileIndex}
              fileIds={fileIdsInCurrentFolder}
              previousFileId={previousFileId}
              nextFileId={nextFileId}
              onNavigateToFile={(fileId: string) => {
                navigateToFile(currentProject.id, fileId)
              }}
              onExit={() => {
                navigateToProject(currentProject.id)
              }}
            />
          )}
        </div>
      }
    >
      {userInfo.IsVaultViewSharesUser && shouldRenderVaultHomeHeader && (
        <div className="px-6">
          <VaultProjectTabs />
        </div>
      )}
    </AppHeader>
  )
}

export default VaultAppHeader
