import * as React from 'react'

import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'

import { cn } from 'utils/utils'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from 'components/ui/accordion'
import { Button } from 'components/ui/button'

interface ProfileDropdownProps {
  children: React.ReactNode
  isResetable?: boolean
  isSavable?: boolean
  isSaving?: boolean
  onSave: () => void
  onReset: () => void
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  children,
  onSave,
  onReset,
  isResetable = false,
  isSavable = false,
  isSaving = false,
}) => {
  const handleResetClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onReset()
  }

  const handleSaveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onSave()
  }

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="edit-answers" className="border-none">
        <AccordionPrimitive.Header className="flex">
          <AccordionPrimitive.Trigger
            className={cn(
              'flex flex-1 items-center justify-start space-x-2 rounded-md border px-4 py-2 font-sans text-sm  [&[data-state=open]>svg]:rotate-180 [&[data-state=open]]:rounded-b-none'
            )}
          >
            <ChevronDown className="size-4 shrink-0 transition-transform duration-200" />
            <p className="font-sans text-sm leading-4">Edit answers</p>
            <div className="flex flex-1 items-center justify-end space-x-2 transition-opacity duration-200">
              {isResetable && (
                <Button variant="ghost" onClick={handleResetClick}>
                  Reset
                </Button>
              )}
              <Button
                variant="outline"
                isLoading={isSaving}
                onClick={handleSaveClick}
                disabled={!isSavable}
              >
                Save changes
              </Button>
            </div>
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionContent className="-mt-px rounded-b-md border p-4">
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default ProfileDropdown
