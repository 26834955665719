/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountingPracticeAreaOptions = {
    AUDITING_AND_ASSURANCE: 'AUDITING_AND_ASSURANCE',
    FINANCIAL: 'FINANCIAL',
    FORENSIC: 'FORENSIC',
    MANAGEMENT: 'MANAGEMENT',
    TAX: 'TAX'
} as const;
export type AccountingPracticeAreaOptions = typeof AccountingPracticeAreaOptions[keyof typeof AccountingPracticeAreaOptions];


export function instanceOfAccountingPracticeAreaOptions(value: any): boolean {
    for (const key in AccountingPracticeAreaOptions) {
        if (Object.prototype.hasOwnProperty.call(AccountingPracticeAreaOptions, key)) {
            if (AccountingPracticeAreaOptions[key as keyof typeof AccountingPracticeAreaOptions] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AccountingPracticeAreaOptionsFromJSON(json: any): AccountingPracticeAreaOptions {
    return AccountingPracticeAreaOptionsFromJSONTyped(json, false);
}

export function AccountingPracticeAreaOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingPracticeAreaOptions {
    return json as AccountingPracticeAreaOptions;
}

export function AccountingPracticeAreaOptionsToJSON(value?: AccountingPracticeAreaOptions | null): any {
    return value as any;
}

export function AccountingPracticeAreaOptionsToJSONTyped(value: any, ignoreDiscriminator: boolean): AccountingPracticeAreaOptions {
    return value as AccountingPracticeAreaOptions;
}

