import React, { useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'
import { HistoryItem } from 'types/history'

import { TaskType } from 'utils/task'
import { cn } from 'utils/utils'

import VaultQueryResultTable from './components/data-grid/vault-query-result-table'
import VaultAskQueryResponse from './components/vault-ask-query-response'
import VaultQueryBox from './components/vault-query-box/vault-query-box'
import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { ScrollArea } from 'components/ui/scroll-area'

import { useFetchEventFeedbackSentiments } from './hooks/use-feedback'
import { useVaultHistoryItemQuery } from './hooks/use-vault-history-item'
import VaultPushSheet from './sheets/vault-push-sheet'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
  QueryQuestionsResponseData,
  fileIdSearchParamKey,
  projectsPath,
  sourceIdSearchParamKey,
} from './utils/vault'
import { updateQueryStateForHistoryItem } from './utils/vault-helpers'
import { useVaultStore } from './utils/vault-store'

const VaultQueryDetail = ({
  sendCancelRequestN1,
  generateQuestions,
  generateNNResponse,
  generateN1Response,
}: {
  sendCancelRequestN1: () => void
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
}) => {
  const { projectId, queryId } = useParams()
  const userInfo = useAuthUser()

  const setIsSidebarOpenAndToggle = useGeneralStore(
    (state) => state.setIsSidebarOpenAndToggle
  )
  const revertSidebarOpen = useGeneralStore((state) => state.revertSidebarOpen)

  const gridApi = useVaultStore((state) => state.gridApi)
  const activeDocument = useVaultStore((state) => state.activeDocument)
  const queryIdToState = useVaultStore(
    useShallow((state) => state.queryIdToState)
  )
  const queryIdToReviewState = useVaultStore(
    useShallow((state) => state.queryIdToReviewState)
  )
  const setError = useVaultStore((state) => state.setError)
  const setQueryId = useVaultStore((state) => state.setQueryId)
  const setIsNewVaultReviewQuery = useVaultStore(
    (state) => state.setIsNewVaultReviewQuery
  )
  const setTask = useVaultStore((state) => state.setTask)
  const setReviewTask = useVaultStore((state) => state.setReviewTask)
  const clearNewQueryState = useVaultStore((state) => state.clearNewQueryState)
  const setPendingColumnIds = useVaultStore(
    (state) => state.setPendingColumnIds
  )
  const setPendingQueryFileIds = useVaultStore(
    (state) => state.setPendingQueryFileIds
  )
  const markInProgressTaskAsFromHistory = useVaultStore(
    (state) => state.markInProgressTaskAsFromHistory
  )

  const clearDocumentAnnotation = usePDFViewerStore(
    (s) => s.clearDocumentAnnotation
  )

  const isSidebarOpen = useGeneralStore((state) => state.isSidebarOpen)
  const isNewQuery = queryId === 'new'

  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const sourceId = searchParams.get(sourceIdSearchParamKey)

  // Only want to clear document-specific annotation cache when switching to a different document
  useEffect(() => {
    clearDocumentAnnotation()
  }, [activeDocument, clearDocumentAnnotation])

  const taskType = queryIdToState[queryId!]?.taskType
  const queryReviewState = queryId ? queryIdToReviewState[queryId] : null
  const hasCachedAnswers = !isEmpty(queryReviewState?.answers)
  const hasCachedColumnHeaders = !isEmpty(queryReviewState?.columnHeaders)
  // Only fetch the history item without sources if we didn't have answers yet and we are not looking at a file or source
  const shouldFetchHistoryItemWithoutSources =
    !hasCachedAnswers && !fileId && !sourceId

  const [queryIdToFetch, setQueryIdToFetch] = useState<string | null>(null)

  // If we don't have a history item or answers, we need to fetch the history item (without sources to speed up the process)
  const {
    historyItem: historyItemWithoutSources,
    error: errorWithoutSources,
    isFetching: isFetchingWithoutSources,
  } = useVaultHistoryItemQuery({
    id: queryIdToFetch,
    vaultFolderId: projectId!,
    isV2: userInfo.IsVaultV2User,
    isDualWriteUser: userInfo.IsVaultDualWriteUser,
    isEnabled: !!queryIdToFetch && shouldFetchHistoryItemWithoutSources,
    throwOnError: true,
    skipSources: true,
  })

  // Only fetch the history item with sources once the history item without sources has been fetched or we don't need to fetch that
  const shouldFetchHistoryItemWithSources =
    !!historyItemWithoutSources || !shouldFetchHistoryItemWithoutSources
  // Then we fetch the sources for the history item (once we have a history item or answers), and poll for updates on the history item
  const {
    historyItem: historyItemWithSources,
    error: errorWithSources,
    isFetching: isFetchingWithSources,
  } = useVaultHistoryItemQuery({
    id: queryIdToFetch,
    vaultFolderId: projectId!,
    isV2: userInfo.IsVaultV2User,
    isDualWriteUser: userInfo.IsVaultDualWriteUser,
    isEnabled: !!queryIdToFetch && shouldFetchHistoryItemWithSources,
    throwOnError: true,
    refetchInterval: (query) => {
      const queryIdState = query.state.data as HistoryItem
      if (userInfo.IsVaultV2User) {
        if (isNewQuery) {
          return false
        }
        const queryState = queryIdToState[queryId!]
        if (
          queryState?.isFromHistory &&
          queryState.taskType === TaskType.VAULT_REVIEW &&
          queryState.isLoading
        ) {
          // Poll every 10 seconds if the user is on the new vault v2 endpoint
          return 10_000
        }
      }
      // Poll for history item updates every 10 seconds if it is in progress
      if (
        query.state.status === 'success' &&
        query.state.data &&
        queryIdState.status === 'IN_PROGRESS'
      ) {
        return 10_000
      }
      return false
    },
    skipSources: false,
  })

  if (errorWithoutSources || errorWithSources) {
    setError({
      message: `The requested history item ${queryId} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`,
      cta: {
        redirectUri: `${BaseAppPath.Vault}${projectsPath}${projectId}`,
        message: 'Back to Vault project',
      },
    })
  }

  useEffect(() => {
    if (historyItemWithSources) {
      updateQueryStateForHistoryItem(
        historyItemWithSources as HistoryItem,
        setTask,
        setReviewTask
      )
    } else if (historyItemWithoutSources) {
      updateQueryStateForHistoryItem(
        historyItemWithoutSources as HistoryItem,
        setTask,
        setReviewTask
      )
    }
  }, [
    historyItemWithoutSources,
    historyItemWithSources,
    setTask,
    setReviewTask,
  ])

  // Fetch the feedback sentiments for the query
  useFetchEventFeedbackSentiments(
    queryIdToFetch ? Number(queryIdToFetch) : null,
    userInfo
  )

  useMount(() => {
    // on mount we want to set the sidebar to false
    setIsSidebarOpenAndToggle(false)
  })

  useEffect(() => {
    if (isNewQuery) {
      setQueryId('new')
      setIsNewVaultReviewQuery(true)
      return
    }
    setIsNewVaultReviewQuery(false)
    setQueryId(queryId!)

    if (userInfo.IsVaultV2User) {
      // Always fetch query from the backend if the user is on the new vault v2 endpoint
      markInProgressTaskAsFromHistory(TaskType.VAULT_REVIEW)
      setQueryIdToFetch(queryId ?? null)
    } else {
      // We will always have queryId here, it can come from the socket or the deeplink url.
      // If it comes from the socket (hasLocalState is true), then we don't need to fetch from
      // the backend. Otherwise (if hasLocalState is false), we have to fetch from the backend.
      const hasLocalState =
        queryIdToState[queryId!] && !queryIdToState[queryId!]?.isFromHistory
      const queryIdToFetch = hasLocalState ? null : queryId ?? null
      setQueryIdToFetch(queryIdToFetch)
    }
  }, [
    setIsNewVaultReviewQuery,
    setQueryId,
    markInProgressTaskAsFromHistory,
    isNewQuery,
    queryId,
    queryIdToState,
    userInfo.IsVaultV2User,
  ])

  useUnmount(() => {
    // general store
    revertSidebarOpen()
    setIsNewVaultReviewQuery(false)

    // vault store
    setQueryId('')
    clearNewQueryState()
    setPendingColumnIds(null)
    setPendingQueryFileIds(null)
  })

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)
  const defaultResizablePanelSizes = [50, 50]
  const defaultResizablePanelMinSize = 20
  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  return (
    <AppMain className="flex w-full">
      <FullscreenLoading
        zIndex="z-50"
        isLoading={
          (taskType === TaskType.VAULT_REVIEW && !gridApi) ||
          ((isFetchingWithoutSources || isFetchingWithSources) &&
            !historyItemWithoutSources &&
            !historyItemWithSources &&
            !hasCachedAnswers &&
            !hasCachedColumnHeaders)
        }
      />
      {(taskType === TaskType.VAULT_REVIEW || isNewQuery) && (
        <>
          <div className="relative h-full w-full">
            <VaultQueryBox
              generateQuestions={generateQuestions}
              generateNNResponse={generateNNResponse}
              generateN1Response={generateN1Response}
              shouldHideWhenUnfocused
              disableQueryTypeSelect
              className={cn(
                'container fixed left-0 w-full translate-x-[35px] xl:max-w-none',
                {
                  'w-[calc(100vw-600px)]': activeDocument,
                  'pl-[158px]': isSidebarOpen,
                }
              )}
            />
            <VaultQueryResultTable generateNNResponse={generateNNResponse} />
          </div>
          <VaultPushSheet />
        </>
      )}
      {taskType === TaskType.VAULT && (
        <ResizablePanelGroup
          direction="horizontal"
          ref={resizablePanelGroupRef}
          className="flex w-full"
        >
          <ResizablePanel
            defaultSize={defaultResizablePanelSizes[0]}
            minSize={defaultResizablePanelMinSize}
          >
            <ScrollArea className="h-full w-full">
              <div className="container relative mx-auto py-4">
                <VaultQueryBox
                  generateQuestions={generateQuestions}
                  generateNNResponse={generateNNResponse}
                  generateN1Response={generateN1Response}
                  shouldHideWhenUnfocused
                  disableQueryTypeSelect
                  className={cn(
                    'container fixed left-0 w-full translate-x-[35px] xl:max-w-none',
                    {
                      'w-[calc(100vw-600px)]': activeDocument,
                      'pl-[158px]': isSidebarOpen,
                    }
                  )}
                />
                <div className="space-y-4">
                  <VaultAskQueryResponse
                    sendCancelRequestN1={sendCancelRequestN1}
                  />
                </div>
              </div>
            </ScrollArea>
          </ResizablePanel>
          {sourceId && (
            <>
              <ResizableHandle
                withHandle
                onDoubleClick={resetLayout}
                hidden={!activeDocument}
                className="z-20 w-0"
              />
              <ResizablePanel
                defaultSize={defaultResizablePanelSizes[1]}
                minSize={defaultResizablePanelMinSize}
                hidden={!activeDocument}
              >
                <div className="relative h-full w-full">
                  <VaultPushSheet />
                </div>
              </ResizablePanel>
            </>
          )}
        </ResizablePanelGroup>
      )}
    </AppMain>
  )
}

export default VaultQueryDetail
