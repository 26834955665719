/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PracticeArea = {
    ANTITRUST_AND_COMPETITION: 'ANTITRUST_AND_COMPETITION',
    AUDITING_AND_ASSURANCE: 'AUDITING_AND_ASSURANCE',
    BANKING_AND_FINANCE: 'BANKING_AND_FINANCE',
    BANKING_AND_FINANCIAL_SERVICES: 'BANKING_AND_FINANCIAL_SERVICES',
    BANKRUPTCY_AND_RESTRUCTURING: 'BANKRUPTCY_AND_RESTRUCTURING',
    CAPITAL_MARKETS: 'CAPITAL_MARKETS',
    CIVIL_LITIGATION: 'CIVIL_LITIGATION',
    COMMERCIAL: 'COMMERCIAL',
    COMMERCIAL_LITIGATION: 'COMMERCIAL_LITIGATION',
    CONTROVERSY_AND_DISPUTE_RESOLUTION: 'CONTROVERSY_AND_DISPUTE_RESOLUTION',
    CORPORATE: 'CORPORATE',
    CORPORATE_GOVERNANCE: 'CORPORATE_GOVERNANCE',
    CRIMINAL_LAW: 'CRIMINAL_LAW',
    EMERGING_COMPANIES_VENTURE_CAPITAL: 'EMERGING_COMPANIES/VENTURE_CAPITAL',
    EXECUTIVE_COMPENSATION_AND_EMPLOYEE_BENEFITS: 'EXECUTIVE_COMPENSATION_AND_EMPLOYEE_BENEFITS',
    FAMILY_LAW: 'FAMILY_LAW',
    FINANCIAL: 'FINANCIAL',
    FINANCIAL_PLANNING_AND_ANALYSIS: 'FINANCIAL_PLANNING_AND_ANALYSIS',
    FORENSIC: 'FORENSIC',
    GOVERNMENT_INVESTIGATIONS_AND_WHITE_COLLAR: 'GOVERNMENT_INVESTIGATIONS_AND_WHITE_COLLAR',
    INDIRECT: 'INDIRECT',
    INTELLECTUAL_PROPERTY: 'INTELLECTUAL_PROPERTY',
    INTERNATIONAL: 'INTERNATIONAL',
    INVESTMENT_FUNDS_AND_MANAGEMENT: 'INVESTMENT_FUNDS_AND_MANAGEMENT',
    INVESTMENT_MANAGEMENT: 'INVESTMENT_MANAGEMENT',
    LABOR_AND_EMPLOYMENT: 'LABOR_AND_EMPLOYMENT',
    LITIGATION__GENERAL: 'LITIGATION_(GENERAL)',
    MANAGEMENT: 'MANAGEMENT',
    MERGERS_AND_ACQUISITION: 'MERGERS_AND_ACQUISITION',
    PLANNING_AND_ADVISORY: 'PLANNING_AND_ADVISORY',
    PRIVACY: 'PRIVACY',
    PRIVATE_EQUITY: 'PRIVATE_EQUITY',
    REAL_ESTATE: 'REAL_ESTATE',
    REAL_ESTATE_AND_REITS: 'REAL_ESTATE_AND_REITS',
    RISK_MANAGEMENT: 'RISK_MANAGEMENT',
    TAX: 'TAX',
    TRANSFER_PRICING: 'TRANSFER_PRICING',
    VENTURE_CAPITAL: 'VENTURE_CAPITAL'
} as const;
export type PracticeArea = typeof PracticeArea[keyof typeof PracticeArea];


export function instanceOfPracticeArea(value: any): boolean {
    for (const key in PracticeArea) {
        if (Object.prototype.hasOwnProperty.call(PracticeArea, key)) {
            if (PracticeArea[key as keyof typeof PracticeArea] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PracticeAreaFromJSON(json: any): PracticeArea {
    return PracticeAreaFromJSONTyped(json, false);
}

export function PracticeAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeArea {
    return json as PracticeArea;
}

export function PracticeAreaToJSON(value?: PracticeArea | null): any {
    return value as any;
}

export function PracticeAreaToJSONTyped(value: any, ignoreDiscriminator: boolean): PracticeArea {
    return value as PracticeArea;
}

