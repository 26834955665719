/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConsultingPracticeAreaOptions = {
    AUDITING_AND_ASSURANCE: 'AUDITING_AND_ASSURANCE',
    BANKING_AND_FINANCIAL_SERVICES: 'BANKING_AND_FINANCIAL_SERVICES',
    CONTROVERSY_AND_DISPUTE_RESOLUTION: 'CONTROVERSY_AND_DISPUTE_RESOLUTION',
    CORPORATE: 'CORPORATE',
    INDIRECT: 'INDIRECT',
    INTERNATIONAL: 'INTERNATIONAL',
    INVESTMENT_MANAGEMENT: 'INVESTMENT_MANAGEMENT',
    MERGERS_AND_ACQUISITION: 'MERGERS_AND_ACQUISITION',
    PLANNING_AND_ADVISORY: 'PLANNING_AND_ADVISORY',
    REAL_ESTATE: 'REAL_ESTATE',
    RISK_MANAGEMENT: 'RISK_MANAGEMENT',
    TRANSFER_PRICING: 'TRANSFER_PRICING'
} as const;
export type ConsultingPracticeAreaOptions = typeof ConsultingPracticeAreaOptions[keyof typeof ConsultingPracticeAreaOptions];


export function instanceOfConsultingPracticeAreaOptions(value: any): boolean {
    for (const key in ConsultingPracticeAreaOptions) {
        if (Object.prototype.hasOwnProperty.call(ConsultingPracticeAreaOptions, key)) {
            if (ConsultingPracticeAreaOptions[key as keyof typeof ConsultingPracticeAreaOptions] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ConsultingPracticeAreaOptionsFromJSON(json: any): ConsultingPracticeAreaOptions {
    return ConsultingPracticeAreaOptionsFromJSONTyped(json, false);
}

export function ConsultingPracticeAreaOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultingPracticeAreaOptions {
    return json as ConsultingPracticeAreaOptions;
}

export function ConsultingPracticeAreaOptionsToJSON(value?: ConsultingPracticeAreaOptions | null): any {
    return value as any;
}

export function ConsultingPracticeAreaOptionsToJSONTyped(value: any, ignoreDiscriminator: boolean): ConsultingPracticeAreaOptions {
    return value as ConsultingPracticeAreaOptions;
}

