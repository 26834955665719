import React from 'react'

import _ from 'lodash'
import { File, Folder } from 'lucide-react'
import pluralize from 'pluralize'

import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import Services from 'services'

import { displayErrorMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import Banner from 'components/ui/banner'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Icon } from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'
import { VaultItemType } from 'components/vault/utils/vault'
import {
  BulkDeleteVaultFiles,
  DeleteVaultFolder,
} from 'components/vault/utils/vault-fetcher'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

const VaultDeleteDialog: React.FC = () => {
  const deleteRecords = useVaultStore((s) => s.deleteRecords)
  const isDeleteDialogOpen = useVaultStore((s) => s.isDeleteDialogOpen)
  const activeDocument = useVaultStore((s) => s.activeDocument)
  const currentProject = useVaultStore((s) => s.currentProject)
  const setDeleteRecords = useVaultStore((s) => s.setDeleteRecords)
  const setIsDeleteDialogOpen = useVaultStore((s) => s.setIsDeleteDialogOpen)
  const deleteVaultFolders = useVaultStore((s) => s.deleteVaultFolders)
  const deleteVaultFiles = useVaultStore((s) => s.deleteVaultFiles)
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const setRequiresProjectDataRefetch = useVaultStore(
    (s) => s.setRequiresProjectDataRefetch
  )
  const setSelectedRows = useVaultFileExplorerStore((s) => s.setSelectedRows)
  const setShowProcessingProgress = useVaultStore(
    (s) => s.setShowProcessingProgress
  )
  const { trackEvent } = useAnalytics()

  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  const firstRecordType =
    deleteRecords.length >= 1 ? deleteRecords[0].type : 'item'
  const recordsType: VaultItemType | 'item' = deleteRecords.every(
    (r) => r.type === firstRecordType
  )
    ? firstRecordType
    : 'item'
  const recordsDisplayString = `${deleteRecords.length.toLocaleString()} ${pluralize(
    recordsType,
    deleteRecords.length
  )}`

  const dialogTitle = `Delete ${pluralize(recordsType, deleteRecords.length)}`

  const description = React.useMemo(() => {
    switch (recordsType) {
      case VaultItemType.project:
      case VaultItemType.folder:
        return `This will permanently delete ${recordsDisplayString} and all associated sub-folders and files.`
      case VaultItemType.file:
      case 'item':
        return `This will permanently delete ${recordsDisplayString} and any associated data.`
    }
  }, [recordsType, recordsDisplayString])

  const onDeleteSubmit = React.useCallback(async () => {
    const key = `${recordsType}_ids`
    Services.HoneyComb.Record({
      metric: `ui.vault_delete_${recordsType}_dialog_submitted`,
      [key]: deleteRecords.map((r) => r.id),
    })
    trackEvent(`Vault Delete ${_.upperFirst(recordsType)} Dialog Submitted`, {
      [key]: deleteRecords.map((r) => r.id),
    })

    const foldersToDelete = deleteRecords
      .filter(
        (r) =>
          r.type === VaultItemType.folder || r.type === VaultItemType.project
      )
      .map((r) => r.data) as VaultFolder[]
    const filesToDelete = deleteRecords
      .filter((r) => r.type === VaultItemType.file)
      .map((r) => r.data) as VaultFile[]

    setIsDeleting(true)

    if (foldersToDelete.length > 0) {
      const deleteFolderResponses = await Promise.all(
        foldersToDelete.map((folder) => DeleteVaultFolder(folder.id))
      )
      deleteFolderResponses.forEach((response) => {
        deleteVaultFiles(response.fileIds, currentProject?.id)
        deleteVaultFolders(response.folderIds, currentProject?.id)
        if (
          activeDocument &&
          'id' in activeDocument &&
          response.fileIds.includes((activeDocument as VaultFile).id)
        ) {
          // This is to close the push sheet if the folder is deleted
          setActiveDocument(null)
        }
      })
    }

    if (filesToDelete.length > 0) {
      const isDeletingFailedFiles = filesToDelete.every(
        (file) => file.failureReason
      )

      // Let's delete local files first, we don't need to make network calls
      const localFilesToDelete = filesToDelete.filter((file) => !file.path)
      deleteVaultFiles(
        localFilesToDelete.map((file) => file.id),
        currentProject?.id
      )

      // Now let's delete the remote files
      const remoteFilesToDelete = filesToDelete.filter((file) => file.path)
      const { deletedFileIds, failedFilesWithErrors } =
        await BulkDeleteVaultFiles(remoteFilesToDelete.map((file) => file.id))
      if (failedFilesWithErrors && failedFilesWithErrors.length > 0) {
        displayErrorMessage(
          `${pluralizeFiles(
            failedFilesWithErrors.length
          )} failed to delete, please try again`
        )
      }
      deleteVaultFiles(deletedFileIds, currentProject?.id)

      if (
        activeDocument &&
        'id' in activeDocument &&
        !!filesToDelete.find((file) => file.id === activeDocument.id)
      ) {
        // This is to close the push sheet if the file is deleted
        setActiveDocument(null)
      }

      if (
        currentProject?.id &&
        isDeletingFailedFiles &&
        deletedFileIds.length === filesToDelete.length
      ) {
        // Hide processing progress if all failed files are deleted
        setShowProcessingProgress(currentProject.id, false)
      }
    }

    setRequiresProjectDataRefetch(true)
    setIsDeleting(false)
    setIsDeleteDialogOpen(false)
    setSelectedRows([])
  }, [
    recordsType,
    deleteRecords,
    trackEvent,
    setRequiresProjectDataRefetch,
    setIsDeleteDialogOpen,
    setSelectedRows,
    deleteVaultFiles,
    deleteVaultFolders,
    activeDocument,
    setActiveDocument,
    currentProject?.id,
    setShowProcessingProgress,
  ])

  return (
    <Dialog open={isDeleteDialogOpen}>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogDescription>
            <div className="flex items-center">
              <span className="mr-1 shrink-0">
                Confirm deletion of {recordsDisplayString}
              </span>
              {deleteRecords.length === 1 && (
                <Badge variant="secondary" className="shrink space-x-1 px-2">
                  <Icon
                    icon={deleteRecords[0].type === 'file' ? File : Folder}
                  />
                  <span className="truncate">{deleteRecords[0].data.name}</span>
                </Badge>
              )}
            </div>
          </DialogDescription>
        </DialogHeader>
        <Banner
          title="Are you sure?"
          description={
            <p>{description + `\nOnce deleted, they cannot be recovered.`}</p>
          }
        />
        <div className="mt-6 flex flex-row justify-end space-x-2">
          <Button
            variant="ghost"
            disabled={isDeleting}
            onClick={() => {
              const key = `${recordsType}_ids`
              Services.HoneyComb.Record({
                metric: `ui.vault_delete_${recordsType}_dialog_cancelled`,
                [key]: deleteRecords.map((r) => r.id),
              })
              trackEvent(
                `Vault Delete ${_.upperFirst(recordsType)} Dialog Cancelled`,
                {
                  [key]: deleteRecords.map((r) => r.id),
                }
              )
              setDeleteRecords([])
              setIsDeleteDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="destructive"
            disabled={isDeleting}
            onClick={onDeleteSubmit}
          >
            {isDeleting ? (
              <div className="flex items-center">
                <Spinner size="xxs" className="top-3 mr-2" />
                <p>Deleting…</p>
              </div>
            ) : (
              <p>Permanently delete</p>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultDeleteDialog
