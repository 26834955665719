import { useCallback, useRef } from 'react'
import { useMount } from 'react-use'

import { useAuth0 } from '@auth0/auth0-react'
import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'

import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { uploadVaultFiles } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const useVaultUploadFiles = () => {
  const { getAccessTokenSilently } = useAuth0()
  // cache the uploaded files to avoid duplicate uploads
  // this is a problem locally when we have Strict.Mode enabled
  const uploadFolderCache = useRef<Record<string, boolean>>({})
  const filesToUpload = useVaultCreateProjectStore(
    useShallow((s) => s.filesToUpload)
  )
  const filesGroupedByVaultFolderId = useVaultCreateProjectStore(
    useShallow((s) => s.filesGroupedByVaultFolderId)
  )
  const vaultFolderIdToPrefix = useVaultCreateProjectStore(
    useShallow((s) => s.vaultFolderIdToPrefix)
  )
  const clearFilesToUpload = useVaultCreateProjectStore(
    useShallow((s) => s.clearFilesToUpload)
  )
  const [currentProject, setAreUploadButtonsDisabled, upsertVaultFiles] =
    useVaultStore(
      useShallow((s) => [
        s.currentProject,
        s.setAreUploadButtonsDisabled,
        s.upsertVaultFiles,
      ])
    )

  const addFolderIdToFilesUploading = useGeneralStore(
    useShallow((s) => s.addFolderIdToFilesUploading)
  )
  const removeFolderIdFromFilesUploading = useGeneralStore(
    useShallow((s) => s.removeFolderIdFromFilesUploading)
  )

  const upload = useCallback(async () => {
    if (filesToUpload.length === 0) {
      setAreUploadButtonsDisabled(false)
      return
    }
    const accessToken = await getAccessTokenSilently()
    let isFirstRequest = true
    const uploadedAt = new Date(Date.now()).toISOString()
    const vaultFolderIds = Object.keys(filesGroupedByVaultFolderId)
    for (const folderId of vaultFolderIds) {
      if (!uploadFolderCache.current[folderId]) {
        uploadFolderCache.current[folderId] = true
        addFolderIdToFilesUploading(folderId)
        const { files, failedFiles } = await uploadVaultFiles({
          accessToken,
          files: filesGroupedByVaultFolderId[folderId],
          folderId: folderId,
          projectId: currentProject?.id ?? '',
          prefix: vaultFolderIdToPrefix[folderId],
          shouldSetUploadTimestamp: isFirstRequest,
          uploadedAt,
        })
        isFirstRequest = false
        removeFolderIdFromFilesUploading(folderId)
        upsertVaultFiles([...files, ...failedFiles], currentProject?.id)
      }
    }
    uploadFolderCache.current = {}
    clearFilesToUpload()
    setAreUploadButtonsDisabled(false)
  }, [
    filesToUpload,
    filesGroupedByVaultFolderId,
    vaultFolderIdToPrefix,
    upsertVaultFiles,
    addFolderIdToFilesUploading,
    removeFolderIdFromFilesUploading,
    clearFilesToUpload,
    setAreUploadButtonsDisabled,
    getAccessTokenSilently,
    currentProject,
  ])

  useMount(() => {
    void upload()
  })

  return null
}

export default useVaultUploadFiles
