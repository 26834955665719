import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Collapse, Divider, Typography } from 'antd'
import _ from 'lodash'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { site } from 'utils/server-data'
import { getSiteColor, getSiteName } from 'utils/site'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import useWorkspaceDetail from 'components/settings/hooks/use-workspace-detail'
import useWorkspaceOffboardJobs from 'components/settings/hooks/use-workspace-offboard-jobs'
import useWorkspacePermUserCounts from 'components/settings/hooks/use-workspace-perm-user-counts'
import useWorkspaceUsers from 'components/settings/hooks/use-workspace-users'
import useWorkspaces from 'components/settings/hooks/use-workspaces'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Badge } from 'components/ui/badge'
import { Button as TailwindButton } from 'components/ui/button'

import WorkspaceDetailsImplementation from './workspace-details-implementation'
import WorkspaceDetailsUsers from './workspace-details-users'

const WorkspaceDetails: React.FC = () => {
  const navigate = useNavigateWithQueryParams()
  const { id: workspaceId } = useParams()
  const { workspaces } = useWorkspaces()
  const { workspace, isLoadingWorkspace } = useWorkspaceDetail({ workspaceId })
  const { workspaceUsers: users, fetchWorkspaceUsers } = useWorkspaceUsers({
    workspaceId,
  })
  const {
    permUserCounts: permUserCountByWorkspace,
    fetchWorkspacePermUserCounts,
  } = useWorkspacePermUserCounts({
    workspaceId,
  })
  const userInfo = useAuthUser()

  const { workspaceOffboardJobs } = useWorkspaceOffboardJobs(
    Number(workspaceId)
  )

  const handleEdit = (): void => {
    navigate(`/settings/internal_admin/workspaces/${workspace.id}/edit`)
  }

  const fetchData = useCallback(async () => {
    if (_.isNil(workspace)) {
      return
    }
    await fetchWorkspaceUsers()
    await fetchWorkspacePermUserCounts()
  }, [workspace, fetchWorkspaceUsers, fetchWorkspacePermUserCounts])

  if (
    _.isNil(userInfo) ||
    !userInfo.IsInternalAdminReader ||
    isLoadingWorkspace ||
    _.isEmpty(workspace)
  )
    return null

  const channelPartner = workspace.settings.channelPartner ?? ''
  const badgeColor = workspace.site === site ? '' : getSiteColor(workspace.site)

  const displayOffboardBadge =
    !_.isNil(workspaceOffboardJobs?.jobs) &&
    workspaceOffboardJobs.jobs.length > 0 &&
    workspaceOffboardJobs.jobs.some((job) => job.jobStatus !== 'CANCELLED')

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <RouterBreadcrumbs
          overridePath={`workspaces/${workspace.clientName}`}
          pathForIndexFunc={(index) => {
            if (index === 0) return '/settings/internal_admin/workspaces'
            return ''
          }}
        />
        <div className="flex items-center justify-start space-x-4">
          <div className="flex items-center space-x-3">
            <Typography.Title level={3}>
              {workspace.clientName}
            </Typography.Title>
            {/* NOTE: we can use a zustand store to update this badge automatically after user actions */}
            {displayOffboardBadge &&
              (workspaceOffboardJobs.jobs.every(
                (job) => job.jobStatus === 'COMPLETED'
              ) ? (
                <Badge variant="destructive" className="mb-2 h-6">
                  Offboarded
                </Badge>
              ) : (
                <Badge variant="destructive" className="mb-2 h-6">
                  Offboarding
                </Badge>
              ))}
            <Badge
              variant="secondary"
              className={cn('mb-2 h-6', {
                'text-primary-inverse': !_.isEmpty(badgeColor),
              })}
              style={{ backgroundColor: badgeColor }}
            >
              {!_.isEmpty(badgeColor)
                ? `Migrated to ${getSiteName(workspace.site)}`
                : getSiteName(workspace.site)}
              {}
            </Badge>
          </div>
          {_.isEmpty(channelPartner) ? null : (
            <Badge variant="secondary" className="h-6">
              From {_.startCase(channelPartner.toLowerCase())}
            </Badge>
          )}
        </div>
        <br />
        <Collapse
          items={[
            {
              key: '1',
              label: (
                <Typography.Title level={5}>
                  Raw workspace info
                </Typography.Title>
              ),
              children: (
                <Typography>
                  <pre>{JSON.stringify(workspace, null, 2)}</pre>
                </Typography>
              ),
              extra: userInfo.IsInternalAdminWriter && (
                <TailwindButton
                  variant="outline"
                  onClick={handleEdit}
                  data-testid="edit-workspace-submit"
                >
                  Edit
                </TailwindButton>
              ),
            },
          ]}
        />

        <div className="mt-10">
          <Divider>Workspace users</Divider>
          <WorkspaceDetailsUsers
            workspace={workspace}
            workspaces={workspaces}
            permUserCountByWorkspace={permUserCountByWorkspace}
            users={users}
            fetchData={fetchData}
          />
        </div>

        <div className="my-10">
          <Divider>Workspace implementation</Divider>
          <WorkspaceDetailsImplementation
            workspace={workspace}
            fetchData={fetchData}
          />
        </div>
      </SettingsLayout>
    </>
  )
}

export default WorkspaceDetails
