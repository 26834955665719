/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowFileUploadInputBlockBlockParams
 */
export interface WorkflowFileUploadInputBlockBlockParams {
    /**
     * 
     * @type {string}
     * @memberof WorkflowFileUploadInputBlockBlockParams
     */
    headerText: string;
}

/**
 * Check if a given object implements the WorkflowFileUploadInputBlockBlockParams interface.
 */
export function instanceOfWorkflowFileUploadInputBlockBlockParams(value: object): value is WorkflowFileUploadInputBlockBlockParams {
    if (!('headerText' in value) || value['headerText'] === undefined) return false;
    return true;
}

export function WorkflowFileUploadInputBlockBlockParamsFromJSON(json: any): WorkflowFileUploadInputBlockBlockParams {
    return WorkflowFileUploadInputBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowFileUploadInputBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowFileUploadInputBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'headerText': json['header_text'],
    };
}

export function WorkflowFileUploadInputBlockBlockParamsToJSON(json: any): WorkflowFileUploadInputBlockBlockParams {
    return WorkflowFileUploadInputBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowFileUploadInputBlockBlockParamsToJSONTyped(value?: WorkflowFileUploadInputBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'header_text': value['headerText'],
    };
}

