import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { ArrowRight, MoreHorizontal } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { Event } from 'models/event'
import { HistoryTypeEnum } from 'models/helpers/history-helper'
import { getHistoryPollingQueryData } from 'models/queries/use-history-polling-query'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskType } from 'utils/task'
import { displayInfoMessage } from 'utils/toast'
import { cn, parseIsoString } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import HistoryDeleteDialog from 'components/history/history-delete-dialog'
import { useHistoryMetadataStore } from 'components/history/history-metadata-store'
import { HISTORY_BATCH_SIZE } from 'components/history/history-table'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import Skeleton from 'components/ui/skeleton'
import { Spinner } from 'components/ui/spinner'
import { Table, TableBody, TableHeader, TableRow } from 'components/ui/table'
import { TextLink } from 'components/ui/text-link'
import useRecentQueries from 'components/vault/hooks/use-recent-queries'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  MAX_QUESTION_CHAR_LENGTH,
  MIN_QUESTION_CHAR_LENGTH,
  NUM_ALL_QUERIES_TO_FETCH,
  REMOVE_PARAMS,
  projectsPath,
  queriesPath,
} from 'components/vault/utils/vault'
import {
  convertVaultStateToEvent,
  getOriginalQuery,
  getQueryUpdatedAt,
  hasReviewErrors,
} from 'components/vault/utils/vault-helpers'
import { updateQueryStateForEvent } from 'components/vault/utils/vault-helpers'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { QUERY_TYPES, useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

import {
  VaultQueryRow,
  VaultQueryRowProps,
  VaultQueryRowStatus,
} from './vault-query-row'
import VaultSectionBreadcrumb from './vault-section-breadcrumb'
import VaultTableHead from './vault-table'

const MAX_RECENT_QUERIES = 5

const VaultRecentQueries = ({ showAll = false }: { showAll?: boolean }) => {
  const { projectId } = useParams()
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()
  const maxQueries = showAll ? NUM_ALL_QUERIES_TO_FETCH : MAX_RECENT_QUERIES

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const currentProjectFileIds = useMemo(() => {
    return new Set(
      currentProjectMetadata.descendantFiles?.map(
        (vaultFile) => vaultFile.id
      ) ?? []
    )
  }, [currentProjectMetadata])
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const queryIdToReviewState = useVaultStore((s) => s.queryIdToReviewState)
  const showRecentQueries = useVaultStore((s) => s.showRecentQueries)
  const showQueries = showAll || showRecentQueries
  const exampleProjectIds = useVaultStore(
    useShallow((s) => s.exampleProjectIds)
  )
  const sharedProjectIds = useVaultStore(useShallow((s) => s.sharedProjectIds))
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((s) => s.permissionsByProjectId)
  )
  const isExampleProject = useMemo(
    () => projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )
  const isSharedProject = isProjectShared(
    sharedProjectIds,
    permissionsByProjectId,
    projectId
  )
  const setShowRecentQueries = useVaultStore((s) => s.setShowRecentQueries)
  const setPendingQuery = useVaultStore((s) => s.setPendingQuery)
  const setIsTextAreaFocused = useVaultStore((s) => s.setIsTextAreaFocused)
  const setQueryType = useVaultStore((s) => s.setQueryType)
  const setQuestions = useVaultStore(useShallow((s) => s.setQuestions))
  const setSelectedQuestions = useVaultStore(
    useShallow((s) => s.setSelectedQuestions)
  )
  const setMaxQuestionCharacterLength = useVaultStore(
    useShallow((s) => s.setMaxQuestionCharacterLength)
  )
  const setMinQuestionCharacterLength = useVaultStore(
    (s) => s.setMinQuestionCharacterLength
  )
  const setIsQuestionsOpen = useVaultStore((s) => s.setIsQuestionsOpen)
  const setTask = useVaultStore((s) => s.setTask)
  const setReviewTask = useVaultStore((s) => s.setReviewTask)

  const reviewQuestionsPerQueryLimit = useVaultUsageStore(
    (state) => state.reviewQuestionsPerQueryLimit
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: projectId!,
  })

  const getEventDeleted = useHistoryMetadataStore((s) => s.getEventDeleted)

  const queryStates = useMemo(
    () =>
      Object.values(queryIdToState)
        .filter(Boolean)
        .filter(
          (state) =>
            state &&
            state.taskType &&
            state.queryId &&
            state.queryId !== '' &&
            state.vaultFolderId === projectId
        )
        .map((state) => {
          const updatedAt = getQueryUpdatedAt(state)
          if (!state || !updatedAt) {
            throw new Error('Query updatedAt is missing')
          }

          const status = state.isLoading
            ? VaultQueryRowStatus.Processing
            : state.failedAt ||
              hasReviewErrors(
                currentProjectFileIds,
                queryIdToReviewState[state.queryId]
              )
            ? VaultQueryRowStatus.Failed
            : state.pausedAt
            ? VaultQueryRowStatus.Paused
            : VaultQueryRowStatus.Completed

          const eta = state.isLoading
            ? queryIdToReviewState[state.queryId]?.eta
            : null

          const originalQuery = getOriginalQuery(
            state.queryId,
            state.query,
            queryIdToReviewState
          )
          return {
            ...state,
            originalQuery: originalQuery,
            updatedAt: updatedAt,
            status: status,
            eta: eta ? parseIsoString(eta) : null,
          }
        })
        .sort((a, b) => {
          return b.updatedAt.getTime() - a.updatedAt.getTime()
        }),
    [queryIdToState, queryIdToReviewState, projectId, currentProjectFileIds]
  )

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [eventToDelete, setEventToDelete] = useState<Event | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [hasLoadedCachedUserHistory, setHasLoadedCachedUserHistory] =
    useState(false)

  const hasInProgressHistoryEvents = queryStates.some(
    (state) => state.isFromHistory && state.isLoading
  )

  const { historyData, isLoadingHistory } = useRecentQueries({
    projectId: projectId!,
    maxQueries: maxQueries,
    hasInProgressHistoryEvents: hasInProgressHistoryEvents,
  })

  const getTitle = useCallback(
    (queryId: string, query?: string) => {
      const queryState = queryIdToState[queryId]
      if (queryState && !_.isEmpty(queryState.title)) {
        return queryState.title
      }
      return query ?? ''
    },
    [queryIdToState]
  )

  const rowData: VaultQueryRowProps[] = useMemo(() => {
    if (!historyData && !hasLoadedCachedUserHistory) {
      // If we don't have history data, we don't want to show any rows yet
      return []
    }

    return queryStates
      .map((state) => ({
        ...state,
        queryId: state.queryId!,
        projectId: state.vaultFolderId ?? projectId!,
        title: getTitle(state.queryId!, state.query),
        originalQuery: state.originalQuery,
        taskType: state.taskType!,
        userId: state.creatorUserEmail ?? undefined,
        onCopyQueryHandler: async () => {
          trackEvent('Vault Query Copied', {
            query_type: state.taskType,
            query_id: state.queryId,
          })
          await navigator.clipboard.writeText(state.originalQuery)
          // User can open query box if
          // 1. Not showing all queries
          // 2. Not in example project
          // 3. Is shared on the project with edit access or above
          // 4. Is vault user for vault query or vault review user for vault review query
          const canOpenQueryBox =
            !showAll &&
            !isExampleProject &&
            doesCurrentUserHaveEditPermission &&
            ((state.taskType === TaskType.VAULT && userInfo.IsVaultUser) ||
              (state.taskType === TaskType.VAULT_REVIEW &&
                userInfo.IsVaultReviewUser))
          if (canOpenQueryBox) {
            // When showing recent queries, we can copy the query to the query box
            setIsTextAreaFocused(true)
            setQueryType(
              state.taskType === TaskType.VAULT_REVIEW
                ? QUERY_TYPES.NN
                : QUERY_TYPES.N1
            )
            setPendingQuery(state.originalQuery)
            if (state.taskType === TaskType.VAULT_REVIEW) {
              setIsQuestionsOpen(true)
              const questions =
                JSON.parse(
                  JSON.stringify(
                    queryIdToReviewState[state.queryId]?.questions ?? []
                  )
                ) ?? []
              setQuestions(questions)
              setSelectedQuestions(
                questions.slice(0, reviewQuestionsPerQueryLimit),
                questions
              )
              setMaxQuestionCharacterLength(
                queryIdToReviewState[state.queryId]
                  ?.maxQuestionCharacterLength ?? MAX_QUESTION_CHAR_LENGTH
              )
              setMinQuestionCharacterLength(
                queryIdToReviewState[state.queryId]
                  ?.minQuestionCharacterLength ?? MIN_QUESTION_CHAR_LENGTH
              )
            } else {
              setIsQuestionsOpen(false)
            }
            // There are many moving pieces and we want a longer duration for toast
            displayInfoMessage(
              'Query copied to clipboard and you can ask Harvey again',
              5
            )
          } else {
            displayInfoMessage('Query copied to clipboard')
          }
        },
        isDeleteEnabled: !state.isLoading,
        onDeleteHandler: () => {
          const event =
            historyData?.events
              .map((event) => ({
                ...event,
                // Trim query and response for each event
                query: _.trim(event.query),
                response: _.trim(event.response),
              }))
              .find((event) => event.id.toString() === state.queryId) ??
            convertVaultStateToEvent(state)
          setEventToDelete(event)
          setModalOpen(true)
        },
      }))
      .filter((state) => !getEventDeleted(convertVaultStateToEvent(state)))
  }, [
    historyData,
    hasLoadedCachedUserHistory,
    queryStates,
    projectId,
    getTitle,
    trackEvent,
    showAll,
    isExampleProject,
    doesCurrentUserHaveEditPermission,
    userInfo.IsVaultUser,
    userInfo.IsVaultReviewUser,
    setIsTextAreaFocused,
    setQueryType,
    setPendingQuery,
    setIsQuestionsOpen,
    queryIdToReviewState,
    setQuestions,
    setSelectedQuestions,
    reviewQuestionsPerQueryLimit,
    setMaxQuestionCharacterLength,
    setMinQuestionCharacterLength,
    getEventDeleted,
  ])

  rowData.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
  rowData.splice(maxQueries)

  const historyTotalCountIncludingLocal =
    (historyData?.total ?? 0) +
    queryStates.filter((state) => !state.isFromHistory).length
  const shouldShowViewAllButton =
    !showAll &&
    showQueries &&
    Math.max(queryStates.length, historyTotalCountIncludingLocal) > maxQueries
  const shouldShowLoadingSkeleton = isLoadingHistory && rowData.length === 0

  useMount(() => {
    // Reuse the history polling query cache to update the vault store with the latest history events
    getHistoryPollingQueryData(queryClient, {
      currentPage: 1,
      pageSize: HISTORY_BATCH_SIZE,
      workspaceSlug: userInfo.workspace.slug,
      historyType: HistoryTypeEnum.USER,
    })
      // If the query already exists in the vault store, we don't want to update the state
      .filter((event: Event) => !queryIdToState[event.id.toString()])
      .forEach((event: Event) => {
        setHasLoadedCachedUserHistory(true)
        updateQueryStateForEvent({ event, setTask, setReviewTask })
      })
  })

  return (
    <div>
      {!showAll && (
        <VaultSectionBreadcrumb
          title={
            <div className="flex items-center gap-2">
              <p className="font-semibold">
                {isExampleProject ? 'Example queries' : 'Recent queries'}
              </p>
              {isLoadingHistory && <Spinner size="xs" />}
            </div>
          }
          trailingActions={[
            <DropdownMenu key="more" onOpenChange={setIsDropdownOpen}>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="smIcon"
                  className={cn({
                    'bg-button-secondary text-primary': isDropdownOpen,
                  })}
                >
                  <Icon icon={MoreHorizontal} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  disabled={rowData.length === 0}
                  onClick={(event) => {
                    event.stopPropagation()
                    if (showRecentQueries) {
                      trackEvent('Vault Recent Queries Hidden')
                    }
                    setShowRecentQueries(!showRecentQueries)
                  }}
                >
                  {showRecentQueries
                    ? 'Hide recent queries'
                    : 'Show recent queries'}
                </DropdownMenuItem>
                <DropdownMenuItem
                  disabled={rowData.length === 0}
                  onClick={(event) => {
                    event.stopPropagation()
                    trackEvent('Vault Recent Queries View All Button Clicked')
                    navigate(
                      `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}`,
                      {},
                      REMOVE_PARAMS
                    )
                  }}
                >
                  View all queries
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>,
          ]}
        />
      )}
      {(isLoadingHistory || (showQueries && rowData.length > 0)) && (
        <Table>
          {showAll && (
            <TableHeader>
              <TableRow>
                <VaultTableHead>Name</VaultTableHead>
                {isSharedProject && <VaultTableHead>Created by</VaultTableHead>}
                <VaultTableHead>Type</VaultTableHead>
                <VaultTableHead className="text-right">Updated</VaultTableHead>
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {rowData.map((row) => (
              <VaultQueryRow
                key={row.queryId}
                isFromHistory={row.isFromHistory}
                projectId={row.projectId}
                queryId={row.queryId}
                title={row.title}
                originalQuery={row.originalQuery}
                taskType={row.taskType}
                status={row.status}
                updatedAt={row.updatedAt}
                eta={row.eta}
                userId={row.userId}
                showUserIconAndTooltip={!showAll}
                onCopyQueryHandler={row.onCopyQueryHandler}
                isDeleteEnabled={row.isDeleteEnabled}
                onDeleteHandler={row.onDeleteHandler}
              />
            ))}
            {shouldShowLoadingSkeleton && (
              <tr>
                <td colSpan={3} className="p-0 pt-2">
                  <Skeleton rowHeight="h-4" rows={3} rowSpacing="gap-y-3" />
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      )}
      {shouldShowViewAllButton && (
        <div className="flex h-10 items-center">
          <TextLink
            label="View all"
            href={`${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}`}
            removeParams={REMOVE_PARAMS}
            className="font-semibold"
            trailingIcon={<ArrowRight />}
          />
        </div>
      )}
      {!isLoadingHistory && rowData.length === 0 && (
        <div className="flex h-20 items-center justify-center">
          <p className="text-muted">
            {isSharedProject
              ? 'No queries have been created in this project yet'
              : 'You haven’t created any queries yet'}
          </p>
        </div>
      )}
      {!showQueries && rowData.length > 0 && (
        <div className="flex h-20 items-center justify-center text-muted">
          <p className="text-center">
            Your recent queries are hidden. To show queries, click the more (
            <Icon icon={MoreHorizontal} className="mx-1 inline" />) button
          </p>
        </div>
      )}
      {eventToDelete && (
        <HistoryDeleteDialog
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          event={eventToDelete}
          isVaultDelete
          isWorkspaceDelete={false}
          description="This permanently deletes the query and all of its response. Once deleted, they cannot be recovered."
        />
      )}
    </div>
  )
}

export default VaultRecentQueries
