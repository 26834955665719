/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FinancePracticeAreaOptions = {
    BANKING_AND_FINANCIAL_SERVICES: 'BANKING_AND_FINANCIAL_SERVICES',
    CORPORATE: 'CORPORATE',
    FINANCIAL_PLANNING_AND_ANALYSIS: 'FINANCIAL_PLANNING_AND_ANALYSIS',
    INVESTMENT_MANAGEMENT: 'INVESTMENT_MANAGEMENT',
    PRIVATE_EQUITY: 'PRIVATE_EQUITY',
    RISK_MANAGEMENT: 'RISK_MANAGEMENT',
    VENTURE_CAPITAL: 'VENTURE_CAPITAL'
} as const;
export type FinancePracticeAreaOptions = typeof FinancePracticeAreaOptions[keyof typeof FinancePracticeAreaOptions];


export function instanceOfFinancePracticeAreaOptions(value: any): boolean {
    for (const key in FinancePracticeAreaOptions) {
        if (Object.prototype.hasOwnProperty.call(FinancePracticeAreaOptions, key)) {
            if (FinancePracticeAreaOptions[key as keyof typeof FinancePracticeAreaOptions] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FinancePracticeAreaOptionsFromJSON(json: any): FinancePracticeAreaOptions {
    return FinancePracticeAreaOptionsFromJSONTyped(json, false);
}

export function FinancePracticeAreaOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancePracticeAreaOptions {
    return json as FinancePracticeAreaOptions;
}

export function FinancePracticeAreaOptionsToJSON(value?: FinancePracticeAreaOptions | null): any {
    return value as any;
}

export function FinancePracticeAreaOptionsToJSONTyped(value: any, ignoreDiscriminator: boolean): FinancePracticeAreaOptions {
    return value as FinancePracticeAreaOptions;
}

