import { useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { BaseAppPath } from 'components/base-app-path'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  projectsPath,
  queriesPath,
  QueryQuestion,
  REMOVE_PARAMS,
  ReviewCellStatus,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  getQuestionsLimit,
  columnToQueryQuestion,
  runReviewQuery,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

export const useRunReview = () => {
  const userInfo = useAuthUser()
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()

  const [reviewQuestionsPerQueryLimit, reviewFilesPerQueryLimit] =
    useVaultUsageStore(
      useShallow((s) => [
        s.reviewQuestionsPerQueryLimit,
        s.reviewFilesPerQueryLimit,
      ])
    )

  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )

  const [
    currentProject,
    currentProjectMetadata,
    folderIdToVaultFolder,
    exampleProjectIds,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProject,
      state.currentProjectMetadata,
      state.folderIdToVaultFolder,
      state.exampleProjectIds,
    ])
  )

  const [selectedRows, clearSelectedRows] = useVaultDataGridFilterStore(
    useShallow((state) => [state.selectedRows, state.clearSelectedRows])
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const isExampleProject = useMemo(
    () => currentProject && exampleProjectIds.has(currentProject.id),
    [currentProject, exampleProjectIds]
  )

  const [
    workflow,
    gridApi,
    queryId,
    isRunButtonLoading,
    historyItem,
    pendingQueryFileIds,
    pendingQueryQuestions,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
    setWorkflow,
    setQueryId,
    setIsRunButtonLoading,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.workflow,
      state.gridApi,
      state.queryId,
      state.isRunButtonLoading,
      state.historyItem,
      state.pendingQueryFileIds,
      state.pendingQueryQuestions,
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
      state.setWorkflow,
      state.setQueryId,
      state.setIsRunButtonLoading,
    ])
  )

  const isNewQuery = queryId === 'new'
  const hasSelectedFiles = selectedRows.length > 0
  const reviewEvent = historyItem as ReviewHistoryItem | undefined
  const filesLimit = reviewEvent?.filesLimit ?? reviewFilesPerQueryLimit
  const queryQuestionsLimit =
    reviewEvent?.questionsLimit ?? reviewQuestionsPerQueryLimit
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission
  const hasNewFilesToRun =
    (pendingQueryFileIds && pendingQueryFileIds.length > 0) ?? false
  const hasNewQuestionsToRun =
    (pendingQueryQuestions && pendingQueryQuestions.length > 0) ?? false
  const progressPercentage = useMemo(() => {
    if (!reviewEvent) return 100
    const visibleColumnIds = new Set(
      reviewEvent.columns
        .filter((column) => !column.isHidden)
        .map((column) => column.id)
    )
    const visibleRowIds = new Set(
      reviewEvent.rows.filter((row) => !row.isHidden).map((row) => row.id)
    )
    if (visibleColumnIds.size === 0 || visibleRowIds.size === 0) return 100

    const numProcessedCells = reviewEvent.cells.filter(
      (cell) =>
        cell.status !== ReviewCellStatus.EMPTY &&
        visibleRowIds.has(cell.reviewRowId) &&
        visibleColumnIds.has(cell.reviewColumnId)
    ).length
    const totalCells = visibleColumnIds.size * visibleRowIds.size

    return (numProcessedCells / totalCells) * 100
  }, [reviewEvent])
  const hasEmptyCells = useMemo(() => {
    return progressPercentage < 100
  }, [progressPercentage])
  const allQueryQuestions = [
    ...(reviewEvent?.columns
      .filter((column) => !column.isHidden)
      .map(columnToQueryQuestion) ?? []),
    ...(pendingQueryQuestions ?? []),
  ]
  const questionsLimit = getQuestionsLimit(
    queryQuestionsLimit,
    reviewQuestionsPerQueryLimit
  )

  const clientMatterId: string | undefined =
    userInfo.IsVaultProjectClientMatterUser
      ? currentProjectMetadata.clientMatterId
      : userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : undefined

  const shouldCheckIsQueryLoadingForRunButton = false

  const hasNoGridApi = !gridApi
  const hasNewFilesToRunCheck =
    hasNewFilesToRun && allQueryQuestions.length === 0
  const hasNoDataToRun =
    !hasNewQuestionsToRun &&
    !hasNewFilesToRun &&
    !hasSelectedFiles &&
    !hasEmptyCells
  // If we have selected files, we need to check that the selected files are exactly the same as the pending files.
  const selectedFilesAreNotPendingFiles =
    hasNewQuestionsToRun &&
    hasNewFilesToRun &&
    hasSelectedFiles &&
    !pendingQueryFileIds?.every((id) => selectedRows.includes(id))

  // If we have pending questions, we need to make sure there are no selected files.
  const hasPendingQuestionsAndSelectedFiles =
    hasNewQuestionsToRun && hasSelectedFiles

  const hasIncompletePendingQuestions =
    hasNewQuestionsToRun &&
    pendingQueryQuestions?.some((pendingQuestion) => !pendingQuestion.text)

  const isRunDisabled =
    hasNoGridApi ||
    isRunButtonLoading ||
    !canCurrentUserEditProject ||
    hasNewFilesToRunCheck ||
    hasNoDataToRun ||
    selectedFilesAreNotPendingFiles ||
    hasPendingQuestionsAndSelectedFiles ||
    hasIncompletePendingQuestions

  const handleRun = async ({
    query,
    pendingFileIds,
    pendingQuestions,
    isRetry,
  }: {
    query?: string
    pendingFileIds?: string[]
    pendingQuestions?: QueryQuestion[]
    isRetry?: boolean
  }) => {
    const allQueryFileIds: string[] = []
    gridApi?.forEachNode((node) => {
      if (node.group) return
      allQueryFileIds.push(node.data.id)
    })
    const allQueryFileIdsSet = new Set(allQueryFileIds)
    for (const fileId of pendingFileIds ?? []) {
      if (!allQueryFileIdsSet.has(fileId)) {
        allQueryFileIds.push(fileId)
      }
    }
    const allQueryQuestions = [
      ...(reviewEvent?.columns
        .filter((column) => !column.isHidden)
        .map(columnToQueryQuestion) ?? []),
      ...(pendingQuestions ?? pendingQueryQuestions ?? []).filter(
        // We don't want to run columns that are already in the review event
        (question) => !question.backingReviewColumn
      ),
    ]
    const newQueryId = await runReviewQuery({
      currentProject,
      currentProjectMetadata,
      folderIdToVaultFolder,
      gridApi,
      hasSelectedFiles,
      hasNewFilesToRun:
        (pendingFileIds && pendingFileIds.length > 0) ?? hasNewFilesToRun,
      hasNewQuestionsToRun:
        (pendingQuestions && pendingQuestions.length > 0) ??
        hasNewQuestionsToRun,
      hasEmptyCells,
      selectedRows,
      pendingQueryFileIds: pendingFileIds ?? pendingQueryFileIds,
      pendingQueryQuestions: pendingQuestions ?? pendingQueryQuestions,
      workflow,
      queryId,
      query,
      isNewQuery,
      isRetry: isRetry ?? false,
      allQueryFileIds,
      allQueryQuestions,
      questionsLimit,
      filesLimit,
      clientMatterId,
      setIsRunButtonLoading,
      recordQuerySubmitted,
      setQueryId,
      setPendingQueryFileIds,
      setPendingQueryQuestions,
      setWorkflow,
      clearSelectedRows,
      queryClient,
      hasToFetchQuery: false,
    })
    if (currentProject && newQueryId && queryId !== newQueryId) {
      const newPath = `${BaseAppPath.Vault}${projectsPath}${currentProject.id}${queriesPath}${newQueryId}`
      navigate(newPath, { replace: true }, REMOVE_PARAMS)
    }
  }

  return {
    isRunDisabled,
    shouldCheckIsQueryLoadingForRunButton,
    progressPercentage,
    hasEmptyCells,
    handleRun,
  }
}
