/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UploadedFile } from './UploadedFile';
import {
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
    UploadedFileToJSONTyped,
} from './UploadedFile';
import type { TranscriptsDocumentQaFile } from './TranscriptsDocumentQaFile';
import {
    TranscriptsDocumentQaFileFromJSON,
    TranscriptsDocumentQaFileFromJSONTyped,
    TranscriptsDocumentQaFileToJSON,
    TranscriptsDocumentQaFileToJSONTyped,
} from './TranscriptsDocumentQaFile';
import type { TranscriptsDocumentTopic } from './TranscriptsDocumentTopic';
import {
    TranscriptsDocumentTopicFromJSON,
    TranscriptsDocumentTopicFromJSONTyped,
    TranscriptsDocumentTopicToJSON,
    TranscriptsDocumentTopicToJSONTyped,
} from './TranscriptsDocumentTopic';
import type { TranscriptsDocumentMetadata } from './TranscriptsDocumentMetadata';
import {
    TranscriptsDocumentMetadataFromJSON,
    TranscriptsDocumentMetadataFromJSONTyped,
    TranscriptsDocumentMetadataToJSON,
    TranscriptsDocumentMetadataToJSONTyped,
} from './TranscriptsDocumentMetadata';

/**
 * 
 * @export
 * @interface TranscriptsDocument
 */
export interface TranscriptsDocument {
    /**
     * 
     * @type {UploadedFile}
     * @memberof TranscriptsDocument
     */
    file: UploadedFile;
    /**
     * 
     * @type {Array<TranscriptsDocumentTopic>}
     * @memberof TranscriptsDocument
     */
    topics: Array<TranscriptsDocumentTopic>;
    /**
     * 
     * @type {TranscriptsDocumentQaFile}
     * @memberof TranscriptsDocument
     */
    qaFile: TranscriptsDocumentQaFile;
    /**
     * 
     * @type {boolean}
     * @memberof TranscriptsDocument
     */
    isLoading: boolean;
    /**
     * 
     * @type {TranscriptsDocumentMetadata}
     * @memberof TranscriptsDocument
     */
    metadata?: TranscriptsDocumentMetadata;
}

/**
 * Check if a given object implements the TranscriptsDocument interface.
 */
export function instanceOfTranscriptsDocument(value: object): value is TranscriptsDocument {
    if (!('file' in value) || value['file'] === undefined) return false;
    if (!('topics' in value) || value['topics'] === undefined) return false;
    if (!('qaFile' in value) || value['qaFile'] === undefined) return false;
    if (!('isLoading' in value) || value['isLoading'] === undefined) return false;
    return true;
}

export function TranscriptsDocumentFromJSON(json: any): TranscriptsDocument {
    return TranscriptsDocumentFromJSONTyped(json, false);
}

export function TranscriptsDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsDocument {
    if (json == null) {
        return json;
    }
    return {
        
        'file': UploadedFileFromJSON(json['file']),
        'topics': ((json['topics'] as Array<any>).map(TranscriptsDocumentTopicFromJSON)),
        'qaFile': TranscriptsDocumentQaFileFromJSON(json['qa_file']),
        'isLoading': json['is_loading'],
        'metadata': json['metadata'] == null ? undefined : TranscriptsDocumentMetadataFromJSON(json['metadata']),
    };
}

export function TranscriptsDocumentToJSON(json: any): TranscriptsDocument {
    return TranscriptsDocumentToJSONTyped(json, false);
}

export function TranscriptsDocumentToJSONTyped(value?: TranscriptsDocument | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'file': UploadedFileToJSON(value['file']),
        'topics': ((value['topics'] as Array<any>).map(TranscriptsDocumentTopicToJSON)),
        'qa_file': TranscriptsDocumentQaFileToJSON(value['qaFile']),
        'is_loading': value['isLoading'],
        'metadata': TranscriptsDocumentMetadataToJSON(value['metadata']),
    };
}

