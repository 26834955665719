import * as Sentry from '@sentry/browser'
import { createWorkerFactory, terminate } from '@shopify/web-worker'
import _ from 'lodash'

import { Event } from 'models/event'
import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'
import { ReviewWorkflowKind } from 'openapi/models/ReviewWorkflowKind'
import { ReviewWorkflowVisibilityKind } from 'openapi/models/ReviewWorkflowVisibilityKind'
import { VaultExampleProjectsApiResponseData } from 'openapi/models/VaultExampleProjectsApiResponseData'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFileApiResponseData } from 'openapi/models/VaultFileApiResponseData'
import { VaultFilesApiResponseData } from 'openapi/models/VaultFilesApiResponseData'
import { VaultFilesDeleteApiResponseData } from 'openapi/models/VaultFilesDeleteApiResponseData'
import { VaultFilesRetryApiResponseData } from 'openapi/models/VaultFilesRetryApiResponseData'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderAccessPermission } from 'openapi/models/VaultFolderAccessPermission'
import { VaultFolderApiResponseData } from 'openapi/models/VaultFolderApiResponseData'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { VaultFolderShareStatusApiResponseData } from 'openapi/models/VaultFolderShareStatusApiResponseData'
import { VaultFoldersApiResponseData } from 'openapi/models/VaultFoldersApiResponseData'
import { VaultProjectsMetadataApiResponseData } from 'openapi/models/VaultProjectsMetadataApiResponseData'
import { VaultSetupApiResponseData } from 'openapi/models/VaultSetupApiResponseData'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

import { backendRestUrl } from 'utils/server-data'
import { TaskType } from 'utils/task'

import { TrackEventFunction } from 'components/common/analytics/analytics-context'
import {
  FileToUpload,
  VaultDeleteFolder,
  JobQueueEtaApiResponseData,
  QueryQuestionsResponseData,
  GenerateNNRequestType,
  ReviewEvent,
  QueryQuestion,
  ReviewColumn,
  ColumnDataType,
  ReviewRow,
  ReviewRowResponse,
  ReviewWorkflowTag,
} from 'components/vault/utils/vault'

const BATCH_FILE_SIZE_BYTES = 10_000_000 // 10MB
const BATCH_FILE_AMOUNT = 100

const uploadWorker = createWorkerFactory(
  () => import('services/workers/upload/upload-worker')
)

const FetchVaultSetup = async (): Promise<VaultSetupApiResponseData> => {
  const requestPath = 'vault/setup'
  const response =
    await Services.Backend.Get<VaultSetupApiResponseData>(requestPath)
  return response
}

const FetchVaultProjects = async ({
  includeSharedFolders,
  includeSharedWithWorkspaceFolders,
}: {
  includeSharedFolders?: boolean
  includeSharedWithWorkspaceFolders?: boolean
}): Promise<VaultFoldersApiResponseData> => {
  const searchParams = new URLSearchParams()
  if (includeSharedFolders) {
    searchParams.append('include_shared_folders', 'true')
    // only add include_shared_with_workspace_folders param if also including shared folders
    if (includeSharedWithWorkspaceFolders) {
      searchParams.append('include_shared_with_workspace_folders', 'true')
    }
  }
  const requestPath =
    searchParams.size > 0
      ? `vault/user?${searchParams.toString()}`
      : 'vault/user'
  const response = await Services.Backend.Get<VaultFoldersApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const FetchVaultExampleProjects =
  async (): Promise<VaultExampleProjectsApiResponseData> => {
    const requestPath = 'vault/example-projects'
    const response =
      await Services.Backend.Get<VaultExampleProjectsApiResponseData>(
        requestPath
      )
    return response
  }

const SetVaultExampleProject = async (projectId: string): Promise<void> => {
  const requestPath = `vault/set-example-project/${projectId}`
  await Services.Backend.Post<void>(requestPath, {}, { throwOnError: true })
}

const UnsetVaultExampleProject = async (projectId: string): Promise<void> => {
  const requestPath = `vault/unset-example-project/${projectId}`
  await Services.Backend.Post<void>(requestPath, {}, { throwOnError: true })
}

const FetchVaultProjectsMetadata = async (
  folderId: string
): Promise<VaultFolderMetadata[]> => {
  const requestPath = `vault/folders/${folderId}/project-metadata`
  const response =
    await Services.Backend.Get<VaultProjectsMetadataApiResponseData>(
      requestPath,
      {
        throwOnError: true,
      }
    )
  return response.metadata
}

export type VaultProjectMetadata = {
  totalSize: number
  filesCount: number
  completedFilesCount: number
  failedFilesCount: number
  projectCreatorEmail: string
}

const FetchVaultProjectMetadata = async (
  projectId: string
): Promise<VaultProjectMetadata> => {
  const requestPath = `vault/projects/${projectId}/metadata`
  const response = await Services.Backend.Get<VaultProjectMetadata>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const FetchVaultFolder = async (
  folderId: string,
  skipUrlSigning = false
): Promise<VaultFolderApiResponseData> => {
  const requestPath = skipUrlSigning
    ? `vault/folder/${folderId}?skip_url_signing=true`
    : `vault/folder/${folderId}`
  const response = await Services.Backend.Get<VaultFolderApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const SetVaultFolderLastOpened = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}/open`
  await Services.Backend.Post<void>(requestPath, {})
}

const CreateVaultFolder = async (
  folderName: string,
  parentId: string | null = null,
  clientMatterId: string | null = null
): Promise<VaultFolderApiResponseData> => {
  const requestPath = 'vault/folder'
  const response = await Services.Backend.Post<VaultFolderApiResponseData>(
    requestPath,
    {
      name: folderName,
      parentId: parentId,
      clientMatterId: clientMatterId,
    },
    // We want to throw an error if the folder is not created.
    { throwOnError: true }
  )
  return response
}

// we need the prefix here to update the file name when uploading zip folders
// for example, when uploading a file in a zip, the file name could be like: safes/discount/yc_safe.pdf
// in this example we need to remove the prefix 'safes/discount/' and rename the file to be just yc_safe.pdf
export const UploadVaultFiles = async ({
  accessToken,
  files,
  vaultFolderId,
  prefix,
  shouldSetUploadTimestamp,
  uploadedAt,
}: {
  accessToken: string
  files: FileToUpload[]
  vaultFolderId: string
  prefix: string
  shouldSetUploadTimestamp: boolean
  uploadedAt: string
}): Promise<VaultFilesApiResponseData> => {
  if (files.length === 0) {
    throw new Error('Cannot upload files, files is not set')
  }
  const results: VaultFilesApiResponseData[] = []
  let isFirstRequest = true
  let currFileIndex = 0
  let lastUploadedFileIndex = 0
  let currFormDataSize = 0
  let currNumFiles = 0
  while (currFileIndex < files.length) {
    const currFile = files[currFileIndex]
    currFile.name = currFile.name.replace(prefix, '')
    if (
      currFormDataSize < BATCH_FILE_SIZE_BYTES &&
      currNumFiles < BATCH_FILE_AMOUNT
    ) {
      currFormDataSize += currFile.file.size
      currNumFiles += 1
      currFileIndex += 1
    } else {
      const slicedFiles = files.slice(lastUploadedFileIndex, currFileIndex)
      const worker = uploadWorker()
      const res = await worker.uploadVaultFile({
        endpoint: `${backendRestUrl}/vault/folder/${vaultFolderId}/files`,
        accessToken,
        files: slicedFiles,
        shouldSetUploadTimestamp: shouldSetUploadTimestamp && isFirstRequest,
        uploadedAt,
      })
      terminate(worker)
      results.push(res)
      isFirstRequest = false
      lastUploadedFileIndex = currFileIndex
      currFormDataSize = 0
      currNumFiles = 0
    }
  }

  // upload the last files
  if (lastUploadedFileIndex < files.length) {
    const worker = uploadWorker()
    const res = await worker.uploadVaultFile({
      endpoint: `${backendRestUrl}/vault/folder/${vaultFolderId}/files`,
      accessToken,
      files: files.slice(lastUploadedFileIndex, files.length),
      shouldSetUploadTimestamp: shouldSetUploadTimestamp && isFirstRequest,
      uploadedAt,
    })
    terminate(worker)
    results.push(res)
  }

  const filesApiResponseData: VaultFilesApiResponseData = {
    files: [],
    failedFilesWithErrors: [],
  }
  results.forEach((result) => {
    filesApiResponseData.files = [
      ...filesApiResponseData.files,
      ...result.files,
    ]
    if (result.failedFilesWithErrors) {
      filesApiResponseData.failedFilesWithErrors = [
        ...(filesApiResponseData.failedFilesWithErrors || []),
        ...result.failedFilesWithErrors,
      ]
    }
  })
  return filesApiResponseData
}

const FetchVaultFile = async (fileId: string) => {
  const requestPath = `vault/file/${fileId}`
  const response =
    await Services.Backend.Get<VaultFileApiResponseData>(requestPath)
  return response
}

const FetchVaultFiles = async (
  fileIds: string[]
): Promise<VaultFilesApiResponseData> => {
  const requestPath = `vault/files`
  const response = await Services.Backend.Post<VaultFilesApiResponseData>(
    requestPath,
    { file_ids: fileIds }
  )
  return response
}

const FetchJobQueueEta = async (
  projectId: string,
  lookbackWindowForJobs = 24,
  numRecentJobsToComputeAverageEta = 100
) => {
  try {
    const params = new URLSearchParams({
      project_id: projectId,
      lookback_window_for_jobs: lookbackWindowForJobs.toString(),
      num_recent_jobs_to_compute_average_eta:
        numRecentJobsToComputeAverageEta.toString(),
    })

    const response = await Services.Backend.Get<JobQueueEtaApiResponseData>(
      `vault/job_queue/eta?${params.toString()}`,
      { throwOnError: true, maxRetryCount: 0 }
    )
    return response
  } catch (e) {
    // We don't want to show an error message to the user if the job queue ETA request fails.
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return { etaInSeconds: null }
  }
}

const PatchFolder = async (
  folderId: string,
  data: Record<string, string>
): Promise<VaultFolderApiResponseData> => {
  const requestPath = `vault/folder/${folderId}`
  const response = await Services.Backend.Patch<
    VaultFolderApiResponseData | RequestError
  >(requestPath, data, { throwOnError: true })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const BulkPatchFiles = async (data: Record<string, string>[]) => {
  const requestPath = `vault/files`
  const response = await Services.Backend.Patch<VaultFilesApiResponseData>(
    requestPath,
    { new_file_data: data }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const PatchFile = async (fileId: string, data: Record<string, string>) => {
  const requestPath = `vault/file/${fileId}`
  const response = await Services.Backend.Patch<
    VaultFileApiResponseData | RequestError
  >(requestPath, data, { throwOnError: true })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const BulkDeleteVaultFiles = async (fileIds: string[]) => {
  const requestPath = `vault/files`
  const response =
    await Services.Backend.Delete<VaultFilesDeleteApiResponseData>(
      requestPath,
      {
        fileIds,
      }
    )
  return response
}

const DeleteVaultFolder = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}`
  const response = await Services.Backend.Delete<VaultDeleteFolder>(requestPath)
  const fileIds = response.deletedFilesMetadata
    ? response.deletedFilesMetadata.map((file) => file.id)
    : []
  const folderIds = response.deletedFoldersMetadata
    ? response.deletedFoldersMetadata.map((folder) => folder.id)
    : []

  return { fileIds, folderIds }
}

const FetchFolderQueryQuestions = async (
  folderId: string,
  query: string
): Promise<QueryQuestionsResponseData> => {
  const requestPath = `vault/folder/${folderId}/query_questions`
  const response = await Services.Backend.Post<QueryQuestionsResponseData>(
    requestPath,
    { query },
    { throwOnError: true }
  )
  return response
}

const RetryFiles = async (fileIds: string[]) => {
  const requestPath = 'vault/files/retry'
  const response = await Services.Backend.Post<VaultFilesRetryApiResponseData>(
    requestPath,
    { fileIds },
    { throwOnError: true }
  )
  return response
}

type ClearQueryErrorsApiResponseData = {
  clearedFileIds: string[]
}

const ClearQueryErrors = async (
  queryId: string,
  fileIds: string[]
): Promise<ClearQueryErrorsApiResponseData> => {
  const requestPath = `vault/query/${queryId}/clear_errors`
  const response = await Services.Backend.Post<ClearQueryErrorsApiResponseData>(
    requestPath,
    { file_ids: fileIds },
    { throwOnError: true }
  )
  return response
}

const fetchAndRecordHistoryEndpointPerformance = async <T>({
  v1Path,
  v2Path,
  isV1,
  shouldQueryV2,
  trackEvent,
}: {
  v1Path: string
  v2Path: string
  isV1: boolean
  shouldQueryV2: boolean
  trackEvent?: TrackEventFunction
}): Promise<T> => {
  const executeV1Promise = async () => {
    const startTime = performance.now()
    const result = await Services.Backend.Get<{
      events: Event[]
      total: number
    }>(v1Path, { throwOnError: isV1 })
    if (!result || !result.events) {
      return result
    }
    const endTime = performance.now()
    trackEvent?.('Vault History V1 Queried', {
      duration: endTime - startTime,
      num_events: result.events.length,
    })
    return result
  }

  // return early if we don't need to query V2
  if (!shouldQueryV2) {
    return (await executeV1Promise()) as T
  }

  const executeV2Promise = async () => {
    const startTime = performance.now()
    const result = await Services.Backend.Get<{
      events: ReviewEvent[]
      eventsCount: number
    }>(v2Path, { throwOnError: !isV1 })
    if (!result || !result.events) {
      return result
    }
    const endTime = performance.now()
    trackEvent?.('Vault History V2 Queried', {
      duration: endTime - startTime,
      num_events: result.events.length,
    })
    return result
  }

  if (isV1) {
    // start v1 before v2
    const promiseToBeReturned = executeV1Promise()
    void executeV2Promise()
    // return v1
    return (await promiseToBeReturned) as T
  } else {
    // start v2 before v1
    const promiseToBeReturned = executeV2Promise()
    void executeV1Promise()
    // return v2
    return (await promiseToBeReturned) as T
  }
}

// Args needed to fetch history
interface FetchVaultHistoryArgs {
  currentPage: number
  pageSize: number
  shouldQueryV2: boolean
  vaultFolderId?: string
  threadOnly?: boolean
  trackEvent?: TrackEventFunction
}

const FetchVaultHistoryByPage = async ({
  currentPage,
  pageSize,
  vaultFolderId,
  threadOnly,
  shouldQueryV2,
  trackEvent,
}: FetchVaultHistoryArgs) => {
  try {
    const params = new URLSearchParams()
    if (!_.isNil(pageSize)) {
      params.append('page_size', pageSize.toString())
    }
    if (!_.isNil(currentPage)) {
      params.append('page_number', currentPage.toString())
    }
    if (!_.isNil(vaultFolderId)) {
      params.append('vault_folder_id', vaultFolderId)
    }
    if (!_.isNil(threadOnly)) {
      params.append('thread_only', threadOnly.toString())
    }
    const result = await fetchAndRecordHistoryEndpointPerformance<{
      events: Event[]
      total: number
    }>({
      v1Path: `vault/history?${params.toString()}`,
      v2Path: `vault/v2/history?${params.toString()}`,
      isV1: true,
      shouldQueryV2: shouldQueryV2 && !threadOnly,
      trackEvent,
    })
    return result
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    throw e
  }
}

const FetchVaultHistoryByPageV2 = async ({
  currentPage,
  pageSize,
  vaultFolderId,
  trackEvent,
  shouldQueryV2,
}: FetchVaultHistoryArgs) => {
  try {
    const params = `page_number=${currentPage}&page_size=${pageSize}&vault_folder_id=${vaultFolderId}`
    const response = await fetchAndRecordHistoryEndpointPerformance<{
      events: ReviewEvent[]
      eventsCount: number
    }>({
      v1Path: `vault/history?${params}`,
      v2Path: `vault/v2/history?${params}`,
      isV1: false,
      shouldQueryV2,
      trackEvent,
    })
    return response
  } catch (e) {
    return { events: [], eventsCount: 0 }
  }
}

type CancelVaultHistoryItemResponseData = {
  eventCancelled: boolean
}

const CancelVaultHistoryItem = async (
  queryId: string
): Promise<CancelVaultHistoryItemResponseData> => {
  const requestPath = `vault/history/${queryId}/cancel`
  return await Services.Backend.Post<CancelVaultHistoryItemResponseData>(
    requestPath,
    { throwOnError: true }
  )
}

const ReorderVaultReviewQueryColumns = async (
  queryId: string,
  columnOrder: string[]
) => {
  const requestPath = `vault/history/${queryId}/reorder_columns`
  return await Services.Backend.Post(
    requestPath,
    { columnOrder },
    { throwOnError: true }
  )
}

type VaultReviewQueryUsageApiResponseData = {
  limit: number
  usage: number
  unitLevel: QueryCapRuleUnitLevel
  timeFrame: QueryCapRuleTimeFrame
  level: QueryCapRuleLevel
}

const FetchVaultReviewQueryUsage = async () => {
  const requestPath = `vault/review_query_usage`
  try {
    const response =
      await Services.Backend.Get<VaultReviewQueryUsageApiResponseData>(
        requestPath,
        { throwOnError: true }
      )
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return {
      limit: null,
      usage: 0,
      unitLevel: QueryCapRuleUnitLevel.CELL,
      timeFrame: QueryCapRuleTimeFrame.CALENDAR_MONTH,
      level: QueryCapRuleLevel.PER_USER,
    }
  }
}

interface RerunVaultReviewQueriesParams {
  requestType: GenerateNNRequestType
  queryIds: string[]
  fileIds: string[]
  pendingQueryUsageByQueryId: Record<string, number>
  pendingFileUsageByQueryId: Record<string, number>
  projectId: string
}

const RerunVaultReviewQueries = async ({
  requestType,
  queryIds,
  fileIds,
  pendingQueryUsageByQueryId,
  pendingFileUsageByQueryId,
  projectId,
}: RerunVaultReviewQueriesParams) => {
  const requestPath = `vault/rerun_review_queries`
  const body = {
    requestType,
    queryIds,
    fileIds,
    pendingQueryUsageByQueryId,
    pendingFileUsageByQueryId,
    projectId,
  }

  try {
    const response = await Services.Backend.Post(requestPath, body, {
      throwOnError: true,
    })
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
  }
}

export type VaultFolderHistoryStatsApiResponseData = {
  totalCount: number
  inProgressCount: number
}

const FetchVaultFolderHistoryStats = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}/history_stats`
  try {
    const response =
      await Services.Backend.Get<VaultFolderHistoryStatsApiResponseData>(
        requestPath,
        { throwOnError: true }
      )
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return { totalCount: 0, inProgressCount: 0 }
  }
}

interface SemanticSearchApiResponseData {
  fileIds: string[]
}

const SemanticSearch = async (
  projectId: string,
  query: string,
  signal?: AbortSignal
): Promise<SemanticSearchApiResponseData> => {
  const requestPath = `vault/semantic_search`
  const response = await Services.Backend.Post<SemanticSearchApiResponseData>(
    requestPath,
    { projectId, query },
    { throwOnError: true, signal }
  )
  return response
}

/**
 * Sharing routes
 */

const FetchVaultFolderShareStatus = async (
  projectId: string
): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Get<VaultFolderShareStatusApiResponseData>(
      requestPath,
      { throwOnError: true }
    )
  return response
}

export type WorkspaceAndUserSharingInfo = {
  shareWithWorkspaces?: Array<{
    workspaceId: number
    permissionLevel: VaultFolderAccessPermission
  }>
  shareWithUsers?: Array<{
    userId: string
    permissionLevel: VaultFolderAccessPermission
  }>
}

export type ShareVaultFolderParams = {
  projectId: string
} & WorkspaceAndUserSharingInfo

const ShareVaultFolder = async ({
  projectId,
  shareWithWorkspaces,
  shareWithUsers,
}: ShareVaultFolderParams): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Post<VaultFolderShareStatusApiResponseData>(
      requestPath,
      {
        shareWithWorkspaces,
        shareWithUsers,
      },
      { throwOnError: true }
    )

  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export type UpdatedWorkspaceAndUserSharingInfo = {
  updateShareWithWorkspaces?: Array<{
    workspaceId: number
    permissionLevel: VaultFolderAccessPermission
  }>
  removeShareWithWorkspaces?: number[]
  updateShareWithUsers?: Array<{
    userId: string
    permissionLevel: VaultFolderAccessPermission
  }>

  removeShareWithUsers?: string[]
}

export type UpdateVaultFolderShareParams = {
  projectId: string
} & UpdatedWorkspaceAndUserSharingInfo

const UpdateVaultFolderShare = async ({
  projectId,
  updateShareWithWorkspaces,
  removeShareWithWorkspaces,
  updateShareWithUsers,
  removeShareWithUsers,
}: UpdateVaultFolderShareParams): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Patch<VaultFolderShareStatusApiResponseData>(
      requestPath,
      {
        updateShareWithWorkspaces,
        removeShareWithWorkspaces,
        updateShareWithUsers,
        removeShareWithUsers,
      },
      { throwOnError: true }
    )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const UnshareVaultFolder = async (projectId: string) => {
  const requestPath = `vault/folder/${projectId}/share`
  const response = await Services.Backend.Delete(requestPath, {
    throwOnError: true,
  })
  return response
}

const UpdateVaultFileTags = async (
  fileId: string,
  oldTagIds: string[],
  newTagIds: string[]
) => {
  const requestPath = `vault/file/${fileId}/tags`
  const response = await Services.Backend.Patch(
    requestPath,
    { oldTagIds, newTagIds },
    { throwOnError: true }
  )
  return response
}

export type VaultFolderUpdateSubscriptionResponseData = {
  hasContentUpdates: boolean
  hasEventsUpdates: boolean
  hasSharingUpdates: boolean
  updatedFolders?: VaultFolder[]
  updatedFiles?: VaultFile[]
  updatedProjectMetadata?: VaultFolderMetadata[]
  updatedSharing?: VaultFolderShareStatusApiResponseData
}

const SubscribeToVaultFolderUpdates = async (
  folderId: string,
  lastUpdatedAt: Date,
  skipContentUpdates: boolean
): Promise<VaultFolderUpdateSubscriptionResponseData> => {
  const searchParams = new URLSearchParams()
  searchParams.append('last_updated_at', lastUpdatedAt.toISOString())
  if (skipContentUpdates) {
    searchParams.append('skip_content_updates', 'true')
  }
  const requestPath = `vault/folder/${folderId}/subscribe_updates?${searchParams.toString()}`

  return await Services.Backend.Get<VaultFolderUpdateSubscriptionResponseData>(
    requestPath,
    {
      throwOnError: true,
    }
  )
}

export type SharingUpdateSubscriptionResponseData = {
  hasSharingUpdates: boolean
  updatedSharedProjects?: VaultFolder[]
}

const SubscribeToVaultSharingUpdates = async (
  lastUpdatedAt: Date
): Promise<SharingUpdateSubscriptionResponseData> => {
  const searchParams = new URLSearchParams()
  searchParams.append('last_updated_at', lastUpdatedAt.toISOString())
  const requestPath = `vault/shares/subscribe_updates?${searchParams.toString()}`

  return await Services.Backend.Get<SharingUpdateSubscriptionResponseData>(
    requestPath,
    {
      throwOnError: true,
    }
  )
}

export type ReviewWorkflow = {
  id: string
  name: string
  description: string
  kind: ReviewWorkflowKind
  strictDocumentType: boolean
  visibility: ReviewWorkflowVisibilityKind
  createdAt: string
  updatedAt: string
  columns: ReviewColumn[]
  tags: ReviewWorkflowTag[]
  additionalVisibilitiesInfo: {
    id: string
    isHiddenForWorkspace: boolean
    visibility: ReviewWorkflowVisibilityKind
    workspaceId: number
  }[]
}

export type VaultWorkflowApiResponseData = {
  workflows: ReviewWorkflow[]
}

const FetchVaultWorkflows = async () => {
  const requestPath = 'vault/workflows'
  const response = await Services.Backend.Get<VaultWorkflowApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

type CreateVaultReviewQueryParams = {
  eventId: number | null
  query: string
  clientMatterId: string | undefined
  taskType: TaskType
  vaultFolderId: string
  fileIds: string[]
  questions: QueryQuestion[]
  questionsLimit: number
  filesLimit: number | null
  requestType: GenerateNNRequestType
  pendingQueryUsage: number
  pendingFileUsage: number
  dryRun: boolean
  workflowId: string | null
  selectedWorkflowColumnIds: string[] | null
}

type CreateVaultReviewQueryResponseData = {
  reviewQueryJobEventId: string
}

const CreateVaultReviewQuery = async ({
  eventId,
  query,
  clientMatterId,
  taskType,
  vaultFolderId,
  fileIds,
  questions,
  questionsLimit,
  filesLimit,
  requestType,
  pendingQueryUsage,
  pendingFileUsage,
  dryRun,
  workflowId,
  selectedWorkflowColumnIds,
}: CreateVaultReviewQueryParams) => {
  const requestPath = 'vault/review_query'
  const response =
    await Services.Backend.Post<CreateVaultReviewQueryResponseData>(
      requestPath,
      {
        eventId,
        data: query,
        clientMatterId,
        taskType,
        vaultFolderId,
        fileIds,
        questions,
        questionsLimit,
        filesLimit,
        requestType,
        pendingQueryUsage,
        pendingFileUsage,
        dryRun,
        workflowId,
        selectedWorkflowColumnIds,
      },
      { throwOnError: true }
    )
  return response
}

interface DraftWorkflowParams {
  name: string
  description: string
  kind: string
  columns?: Array<{ full_text: string; data_type: string; header: string }>
  csvFile?: File
}

export type CreateDraftWorkflowApiResponseData = {
  workflow: ReviewWorkflow
}

const CreateDraftWorkflow = async ({
  name,
  description,
  kind,
  columns,
  csvFile,
}: DraftWorkflowParams) => {
  const requestPath = `vault/workflows/draft`
  const formData = new FormData()
  formData.append('name', name)
  formData.append('description', description)
  formData.append('kind', kind)
  if (columns) {
    formData.append('columns', JSON.stringify(columns))
  }
  if (csvFile) {
    formData.append('csv_file', csvFile)
  }

  try {
    return await Services.Backend.Post<CreateDraftWorkflowApiResponseData>(
      requestPath,
      formData,
      {
        throwOnError: true,
      }
    )
  } catch (error) {
    console.error('Error creating draft workflow:', error)
    throw error
  }
}

export type VaultWorkflowPublishApiResponseData = {
  workflow: ReviewWorkflow
}

type PublishWorkflowParams = {
  workflowId: string
  visibility: ReviewWorkflowVisibilityKind
  workspaceIds: number[]
  hiddenWorkspaceIds: number[]
}

const PublishWorkflow = async ({
  workflowId,
  visibility,
  workspaceIds,
  hiddenWorkspaceIds,
}: PublishWorkflowParams) => {
  try {
    return await Services.Backend.Post<VaultWorkflowPublishApiResponseData>(
      `vault/workflows/draft/${workflowId}/publish`,
      {
        visibility: visibility,
        workspace_ids: workspaceIds,
        hidden_workspace_ids: hiddenWorkspaceIds,
      },
      { throwOnError: true }
    )
  } catch (error) {
    console.error('Error publishing workflow:', error)
    throw error
  }
}

export type VaultWorkflowUnpublishApiResponseData = {
  workflow: ReviewWorkflow
}

const UnpublishWorkflow = async (workflowId: string) => {
  try {
    return await Services.Backend.Post<VaultWorkflowUnpublishApiResponseData>(
      `vault/workflows/${workflowId}/unpublish`,
      {},
      { throwOnError: true }
    )
  } catch (error) {
    console.error('Error unpublishing workflow:', error)
    throw error
  }
}

const DeleteDraftVaultWorkflow = async (workflowId: string): Promise<void> => {
  const requestPath = `vault/workflows/draft/${workflowId}`
  try {
    await Services.Backend.Delete<void>(requestPath, { throwOnError: true })
  } catch (error) {
    console.error(`Error deleting draft workflow with ID ${workflowId}:`, error)
    throw error
  }
}

export type UpdateReviewColumnParams = {
  eventId: number
  columnId: string
  header: string
  fullText: string
  dataType: ColumnDataType
  options: string[]
}

const UpdateReviewColumn = async ({
  eventId,
  columnId,
  header,
  fullText,
  dataType,
  options,
}: UpdateReviewColumnParams) => {
  const requestPath = `vault/events/${eventId}/columns/${columnId}`
  const response = await Services.Backend.Patch<ReviewColumn>(
    requestPath,
    { header, fullText, dataType, options },
    { throwOnError: true }
  )
  return response
}

const DeleteReviewColumn = async (eventId: number, columnId: string) => {
  const requestPath = `vault/events/${eventId}/columns/${columnId}`
  const response = await Services.Backend.Delete<ReviewColumn>(
    requestPath,
    {},
    {
      throwOnError: true,
      maxRetryCount: 0,
    }
  )
  return response
}

const DeleteReviewRow = async (eventId: number, rowId: string) => {
  const requestPath = `vault/events/${eventId}/rows/${rowId}`
  const response = await Services.Backend.Delete<ReviewRow>(
    requestPath,
    {},
    {
      throwOnError: true,
      maxRetryCount: 0,
    }
  )
  return response
}

const FetchReviewRow = async (eventId: string, rowId: string) => {
  const requestPath = `vault/events/${eventId}/rows/${rowId}`
  const response = await Services.Backend.Get<ReviewRowResponse>(requestPath, {
    throwOnError: true,
  })
  return response
}

export type ReviewRowsResponse = {
  rows: ReviewRowResponse[]
  total: number
}

const FetchReviewRows = async (
  fileId: string,
  pageSize: number,
  pageNumber: number
) => {
  const requestPath = `vault/file/${fileId}/review_rows?page_size=${pageSize}&page_number=${pageNumber}`
  const response = await Services.Backend.Get<ReviewRowsResponse>(requestPath, {
    throwOnError: true,
  })
  return response
}

const FetchSuggestedReviewColumn = async (
  question: string,
  signal?: AbortSignal
): Promise<SuggestedReviewColumn> => {
  const requestPath = `vault/columns/suggest`
  return await Services.Backend.Post<SuggestedReviewColumn>(
    requestPath,
    { question },
    {
      throwOnError: true,
      maxRetryCount: 1,
      signal,
    }
  )
}

// TEMP: eventually use OpenAPI type for this
export type SuggestedReviewColumn = {
  fullText: string
  header: string
  dataType: ColumnDataType
  options?: string[]
  displayId: string
}

const BuildReviewColumns = async (question: string) => {
  const requestPath = `vault/columns/build`
  const response = await Services.Backend.Post<SuggestedReviewColumn[]>(
    requestPath,
    { question },
    { throwOnError: true }
  )
  return response
}

export {
  FetchVaultSetup,
  FetchVaultProjects,
  FetchVaultExampleProjects,
  SetVaultExampleProject,
  UnsetVaultExampleProject,
  FetchVaultFolder,
  SetVaultFolderLastOpened,
  FetchVaultProjectsMetadata,
  FetchVaultProjectMetadata,
  CreateVaultFolder,
  FetchVaultFile,
  FetchVaultFiles,
  FetchJobQueueEta,
  PatchFolder,
  BulkPatchFiles,
  PatchFile,
  BulkDeleteVaultFiles,
  DeleteVaultFolder,
  FetchFolderQueryQuestions,
  RetryFiles,
  ClearQueryErrors,
  FetchVaultHistoryByPage,
  FetchVaultHistoryByPageV2,
  CancelVaultHistoryItem,
  ReorderVaultReviewQueryColumns,
  FetchVaultReviewQueryUsage,
  RerunVaultReviewQueries,
  FetchVaultFolderHistoryStats,
  SemanticSearch,
  FetchVaultFolderShareStatus,
  ShareVaultFolder,
  UpdateVaultFolderShare,
  UnshareVaultFolder,
  UpdateVaultFileTags,
  SubscribeToVaultFolderUpdates,
  SubscribeToVaultSharingUpdates,
  FetchVaultWorkflows,
  CreateVaultReviewQuery,
  CreateDraftWorkflow,
  PublishWorkflow,
  UnpublishWorkflow,
  DeleteDraftVaultWorkflow,
  UpdateReviewColumn,
  DeleteReviewColumn,
  DeleteReviewRow,
  FetchSuggestedReviewColumn,
  BuildReviewColumns,
  FetchReviewRow,
  FetchReviewRows,
}
