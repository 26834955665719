import React, { useCallback, useState } from 'react'

import * as Sentry from '@sentry/browser'
import CryptoJS from 'crypto-js'
import saveAs from 'file-saver'
import _, { isNumber } from 'lodash'
import {
  FileIcon,
  LucideIcon,
  Maximize,
  Minimize,
  TrashIcon,
  DownloadIcon,
  ZoomIn,
  ZoomOut,
} from 'lucide-react'
import PSPDFKit, { Instance } from 'pspdfkit'

import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'
import { useGeneralStore } from 'stores/general-store'
import { Maybe } from 'types'
import { Theme } from 'types/ui-constants'

import { sanitizePdfFileName } from 'utils/file-utils'
import { getPdfKitConfig } from 'utils/pspdfkit'
import { displayWarningMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'

interface Props {
  canClear?: boolean
  onClear?: () => void
  ClearComponent?: LucideIcon
  clearTooltipText?: string
  document: UploadedFile | File | null
  pspdfInstanceRef: React.MutableRefObject<Maybe<Instance>>
  canMaximize?: boolean
  isMaximized?: boolean
  setIsMaximized?: (value: boolean) => void
  applyAnnotations?: (instance: Instance) => Promise<void>
  closeIcon?: LucideIcon | null
  footerElement?: React.ReactNode
  initialPage?: number
  hideToolbar?: boolean
  className?: string
  shouldAutoFocus?: boolean
}

const PdfViewer: React.FC<Props> = ({
  canClear,
  onClear,
  document,
  pspdfInstanceRef,
  canMaximize,
  isMaximized,
  setIsMaximized,
  applyAnnotations,
  closeIcon = TrashIcon,
  footerElement,
  initialPage,
  hideToolbar,
  className,
  shouldAutoFocus = true,
}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(1)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const applyAnnotationsRef = React.useRef(applyAnnotations)
  const { trackEvent } = useAnalytics()

  // Update the ref if applyAnnotations changes, this does not trigger re-render
  React.useEffect(() => {
    applyAnnotationsRef.current = applyAnnotations
  }, [applyAnnotations])

  const theme = useGeneralStore((s) => s.theme)
  const applyTheme = useCallback(() => {
    if (!containerRef.current) return
    const iframe = containerRef.current.querySelector('iframe')
    const root = iframe?.contentWindow?.document.querySelector('.PSPDFKit-Root')
    if (root) root.classList.add(theme)
  }, [theme])

  const hashDocumentName = (name: string | undefined): string => {
    if (!name) return 'unknown'
    const hash = CryptoJS.SHA256(name)
    return hash.toString(CryptoJS.enc.Hex).slice(0, 8)
  }

  const clearState = () => {
    trackEvent('PDF Viewer Closed', {
      document_name_hash: hashDocumentName(document?.name),
    })
    onClear?.()
  }

  const getConfig = async (
    document: File | UploadedFile,
    container: HTMLDivElement,
    theme: Theme
  ) => {
    const baseConfig = getPdfKitConfig(undefined, theme)
    const config: any = {
      ...baseConfig,
      container,
      document:
        document instanceof File ? await document.arrayBuffer() : document.url,
    }
    if (document instanceof File || !document.docAsPdfUrl) {
      return config
    }

    if (document.docAsPdfUrl.startsWith('https://')) {
      return {
        ...config,
        document: document.docAsPdfUrl,
      }
    }

    // base64 deserialization is only used for old docx files
    const byteCharacters = atob(document.docAsPdfUrl)
    const buffer = Uint8Array.from(byteCharacters, (c) =>
      c.charCodeAt(0)
    ).buffer

    return {
      ...config,
      document: buffer,
    }
  }

  React.useEffect(() => {
    const loadPDF = async (): Promise<void> => {
      if (_.isNil(document)) return
      const container = containerRef.current
      if (_.isNil(container)) return

      const config = await getConfig(document, container, theme)
      PSPDFKit.unload(container)

      const instance = await PSPDFKit.load(config).catch((error) => {
        Sentry.captureException(error)
        Services.HoneyComb.RecordError(error)
      })

      if (!instance) return

      applyTheme()
      pspdfInstanceRef.current = instance
      trackEvent('PDF Viewer Opened', {
        document_name_hash: hashDocumentName(document.name),
      })

      if (shouldAutoFocus) {
        instance.contentWindow.focus()
      }

      // Use the current value of the ref here
      if (applyAnnotationsRef.current) {
        await applyAnnotationsRef.current(instance)
      }
    }

    void loadPDF()

    const container = containerRef.current
    return () => {
      if (!_.isNil(container)) {
        PSPDFKit.unload(container)
      }
    }
  }, [
    shouldAutoFocus,
    document,
    initialPage,
    pspdfInstanceRef,
    trackEvent,
    applyTheme,
    theme,
  ])

  const handleDownload = async () => {
    if (!document) {
      displayWarningMessage('No document to download')
      return
    }
    trackEvent('PDF Downloaded', {
      document_name_hash: hashDocumentName(document.name),
    })

    const sanitizedFileName = sanitizePdfFileName(document.name)

    if (document instanceof File) {
      const buffer = await document.arrayBuffer()
      const blob = new Blob([buffer])
      saveAs(blob, sanitizedFileName)
    } else {
      if (!document.url) {
        console.error('Document does not have URL to download')
        displayWarningMessage('No document to download')
        return
      }

      saveAs(document.url, sanitizedFileName)
    }
  }

  const handleZoomIn = () => {
    if (pspdfInstanceRef.current) {
      pspdfInstanceRef.current.setViewState((viewState) => {
        const currentZoom = isNumber(viewState.zoom) ? viewState.zoom : 1
        const newZoom = currentZoom + 0.1
        setZoomLevel(newZoom)
        const hash = hashDocumentName(document?.name)
        trackEvent('PDF Zoom In', {
          newZoom,
          document_name_hash: hash,
        })
        return viewState.set('zoom', newZoom)
      })
    }
  }

  const handleZoomOut = () => {
    if (pspdfInstanceRef.current) {
      pspdfInstanceRef.current.setViewState((viewState) => {
        const currentZoom = isNumber(viewState.zoom) ? viewState.zoom : 1
        const newZoom = currentZoom - 0.1
        setZoomLevel(newZoom)
        const hash = hashDocumentName(document?.name)
        trackEvent('PDF Zoom Out', {
          newZoom,
          document_name_hash: hash,
        })
        return viewState.set('zoom', newZoom)
      })
    }
  }

  const MaximizeComponent = isMaximized ? Minimize : Maximize

  return (
    <div
      className={cn(
        'relative flex h-full w-full flex-col overflow-clip rounded-lg border',
        className
      )}
    >
      {!hideToolbar && (
        <div className="flex h-12 w-full shrink-0 items-center justify-between border-b bg-secondary px-4">
          <div className="flex min-w-0 items-center">
            <FileIcon className="mr-1 size-4 shrink-0" />
            <p className="line-clamp-1">{document?.name}</p>
          </div>
          <div className="flex shrink-0 items-center">
            <Button
              id="download"
              onClick={handleDownload}
              data-testid="download"
              variant="ghost"
              size="smIcon"
              aria-label="Download PDF"
            >
              <DownloadIcon className="size-4" />
            </Button>
            <Button
              id="zoom-out"
              role="status"
              aria-live="polite"
              onClick={handleZoomOut}
              data-testid="zoom-out"
              variant="ghost"
              size="smIcon"
              aria-label="Zoom out"
            >
              <ZoomOut className="size-4" />
            </Button>
            <Button
              role="status"
              aria-live="polite"
              id="zoom-in"
              onClick={handleZoomIn}
              data-testid="zoom-in"
              variant="ghost"
              size="smIcon"
              aria-label="Zoom in"
            >
              <ZoomIn className="size-4" />
            </Button>
            {closeIcon && (
              <Button
                data-testid="clear-doc"
                size="smIcon"
                variant="ghost"
                onClick={clearState}
                className="shrink-0"
                disabled={!canClear}
                aria-label="Close PDF"
              >
                {React.createElement(closeIcon, { className: 'size-4' })}
              </Button>
            )}
            {document && canMaximize && (
              <div className="h-fit">
                <Button
                  id="clear-doc"
                  onClick={() => setIsMaximized?.(!isMaximized)}
                  data-testid="Maximize-pdf"
                  variant="ghost"
                  size="smIcon"
                  aria-label="Maximize PDF"
                >
                  <MaximizeComponent className="size-4" />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {footerElement && (
        <div className="absolute bottom-0 w-full p-2">{footerElement}</div>
      )}
      <div
        ref={containerRef}
        className="size-full overflow-hidden"
        data-testid="pdf-viewer"
      />
      {/* Hidden live region for screen readers */}
      <div role="status" aria-live="polite" className="sr-only">
        Zoom level: {zoomLevel.toFixed(1)}
      </div>
    </div>
  )
}

export default PdfViewer
