import React, { useEffect, useState } from 'react'

import pluralize from 'pluralize'

import { deleteWorkspaceRole, getRoleUsers, WorkspaceRole } from 'models/roles'
import { DefaultRole } from 'openapi/models/DefaultRole'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

interface WorkspaceDeleteRoleDialogProps {
  workspaceSlug: string
  isOpen: boolean
  onClose: () => void
  onDelete: (params: { rolePk: string; reassignToRolePk?: string }) => void
  role: WorkspaceRole
  availableRoles: WorkspaceRole[]
}

const WorkspaceDeleteRoleDialog = ({
  workspaceSlug,
  isOpen,
  onClose,
  onDelete,
  role,
  availableRoles,
}: WorkspaceDeleteRoleDialogProps) => {
  const [selectedRolePk, setSelectedRolePk] = useState<string | undefined>(
    undefined
  )
  const [numRoleUsers, setNumRoleUsers] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchRoleUsers = async () => {
      setIsLoading(true)
      const roleUsers = await getRoleUsers(role.rolePk)
      setNumRoleUsers(roleUsers.length)
      setIsLoading(false)
    }
    void fetchRoleUsers()
  }, [role.rolePk])

  const filteredRoles = availableRoles.filter((r) => {
    if (r.deletedAt) {
      return false
    }
    if (r.rolePk === role.rolePk) {
      return false
    }
    // Special default roles that users should not be re-assigned to here
    const baseRoleId = r.roleId.slice(workspaceSlug.length + 1)
    if (
      baseRoleId === DefaultRole.API ||
      baseRoleId === DefaultRole.VAULT_ADD_ON
    ) {
      return false
    }
    return true
  })

  const handleDelete = async () => {
    try {
      await deleteWorkspaceRole(role.rolePk, selectedRolePk)
      displaySuccessMessage(
        `Successfully deleted role and reassigned ${numRoleUsers} ${pluralize(
          'user',
          numRoleUsers
        )} to ${
          filteredRoles.find((r) => r.rolePk === selectedRolePk)?.name ||
          'a different role'
        }.`
      )
      onDelete({
        rolePk: role.rolePk,
        reassignToRolePk: selectedRolePk,
      })
      onClose()
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to delete role')
      }
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent showCloseIcon>
        <DialogHeader>
          <DialogTitle>Delete &ldquo;{role.name}&rdquo;?</DialogTitle>
          <DialogDescription>
            {numRoleUsers > 0
              ? `This role will be permanently deleted, and the ${numRoleUsers} ${pluralize(
                  'user',
                  numRoleUsers
                )} in this role will not have access to the permissions in this role anymore.`
              : 'This role will be permanently deleted.'}
          </DialogDescription>
        </DialogHeader>

        {numRoleUsers > 0 && (
          <div className="space-y-2">
            <p className="font-medium">
              Choose another role to reassign these users to:
            </p>
            <Select
              value={selectedRolePk}
              onValueChange={(value) => setSelectedRolePk(value)}
            >
              <SelectTrigger className="w-60">
                <SelectValue placeholder="Select role" />
              </SelectTrigger>
              <SelectContent>
                {filteredRoles.map((role) => (
                  <SelectItem key={role.rolePk} value={role.rolePk}>
                    {role.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={handleDelete}
            disabled={isLoading || (numRoleUsers > 0 && !selectedRolePk)}
          >
            {numRoleUsers > 0 ? 'Delete and reassign' : 'Delete'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceDeleteRoleDialog
