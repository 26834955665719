import * as React from 'react'

import * as Checkbox from '@radix-ui/react-checkbox'

import { cn } from 'utils/utils'

import Tag from 'components/ui/tag'

interface SelectableTagProps {
  text: string
  checked: boolean
  setChecked: (checked: boolean) => void
  disabled?: boolean
}

const SelectableTag: React.FC<SelectableTagProps> = ({
  text,
  checked,
  setChecked,
  disabled,
}) => {
  return (
    <Checkbox.Root
      checked={checked}
      onCheckedChange={setChecked}
      disabled={disabled}
      className="rounded-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
    >
      <Tag
        text={text}
        className={cn(
          'relative rounded-lg border border-primary bg-transparent px-2 py-1 transition before:absolute before:-inset-[1px] before:rounded-lg before:border-2 before:border-transparent before:transition-all before:content-[""] hover:bg-secondary',
          {
            'border-selected bg-secondary before:border-selected': checked,
            'border-background-disabled bg-background-disabled opacity-50':
              disabled,
          }
        )}
      />
    </Checkbox.Root>
  )
}

export default SelectableTag
