import React from 'react'

import { cn } from 'utils/utils'

import SharePointIcon from 'components/ui/icons/sharepoint-icon'

interface SharepointOneDriveLogoProps {
  wrap?: boolean
}

const SharepointOneDriveLogo: React.FC<SharepointOneDriveLogoProps> = ({
  wrap = false,
}) => {
  return (
    <div className="flex items-center space-x-2 pl-px">
      <div className="flex items-center space-x-2">
        <SharePointIcon className="size-4" />
        <div
          className={cn('flex', {
            'flex-col': wrap,
            'flex-row items-center space-x-1': !wrap,
          })}
        >
          <p className="text-sm text-primary">SharePoint</p>
          <span>
            <p className={cn('text-secondary', { 'text-xs': wrap })}>
              (with OneDrive)
            </p>
          </span>
        </div>
      </div>
    </div>
  )
}

export default SharepointOneDriveLogo
