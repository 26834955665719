import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'
import { homePageTabSearchParamKey } from 'components/vault/utils/vault'
import { getVaultProjects } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

export enum VaultProjectTabTypes {
  YOUR_PROJECTS = 'your-projects',
  SHARED_WITH_YOU = 'shared-with-you',
}

const VaultProjectTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()

  const allFolderIdToVaultFolder = useVaultStore(
    useShallow((state) => state.allFolderIdToVaultFolder)
  )
  const rootVaultFolderIds = useVaultStore(
    useShallow((state) => state.rootVaultFolderIds)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const sharedProjectIds = useVaultStore(
    useShallow((state) => state.sharedProjectIds)
  )
  const defaultTab = VaultProjectTabTypes.YOUR_PROJECTS

  const tabParam = searchParams.get(homePageTabSearchParamKey)
  const selectedTab = tabParam ?? defaultTab
  const setSelectedTab = (tab: VaultProjectTabTypes) => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set(homePageTabSearchParamKey, tab)
        return newParams
      },
      { replace: true }
    )
    trackEvent('Vault Projects Tab Selected', {
      selected_tab: tab,
    })
  }

  const handleTabChange = (value: string) => {
    setSelectedTab(value as VaultProjectTabTypes)
  }

  const sharedProjectsCount = useMemo(() => {
    const allVaultProjects = getVaultProjects({
      allFolderIdToVaultFolder,
      rootVaultFolderIds,
      userId: userInfo.dbId,
      sharedProjectIds,
    })
    const sharedProjects = allVaultProjects.filter(
      (vaultProject) =>
        sharedProjectIds.has(vaultProject.id) &&
        !exampleProjectIds.has(vaultProject.id)
    )
    return sharedProjects.length
  }, [
    userInfo.dbId,
    exampleProjectIds,
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    sharedProjectIds,
  ])

  return (
    <Tabs value={selectedTab} onValueChange={handleTabChange}>
      <TabsList variant="minimal" className="w-full border-b-0">
        <TabsTrigger
          id="vault-your-projects-tab"
          value={VaultProjectTabTypes.YOUR_PROJECTS}
          variant="minimal"
          className="border-b-0 font-medium"
        >
          <p className="px-3 py-1.5">Your projects</p>
        </TabsTrigger>
        <TabsTrigger
          id="vault-shared-projects-tab"
          value={VaultProjectTabTypes.SHARED_WITH_YOU}
          variant="minimal"
          className="font-medium"
        >
          <div className="flex items-center gap-1 px-3 py-1.5">
            <p>Shared with you</p>
            {sharedProjectsCount > 0 && (
              <Badge variant="secondary" className="px-1.5">
                {sharedProjectsCount}
              </Badge>
            )}
          </div>
        </TabsTrigger>
      </TabsList>
    </Tabs>
  )
}

export default VaultProjectTabs
