import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import _ from 'lodash'

import { UserInfo } from 'models/user-info'
import Services from 'services'

import {
  TrackEventFunction,
  useAnalytics,
} from 'components/common/analytics/analytics-context'
import {
  Announcement,
  PersistanceBehavior,
  AnnouncementType,
} from 'components/common/announcements/announcement.types'
import Markdown from 'components/common/markdown/markdown'
import AnnouncementCard from 'components/ui/announcement-card/announcement-card'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogClose,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'

import { getActiveAnnouncementsForUser } from './lib/get-announcements-for-user'
import { useIsDismissed } from './lib/is-dismissed'

/*
 * Handlers to record metrics
 */

const recordAnnouncementCardClickMetric = (
  announcementKey: string,
  trackEvent: TrackEventFunction
) => {
  Services.HoneyComb.Record({
    metric: 'ui.announcement_card_click',
    announcement_key: announcementKey,
  })
  trackEvent('Announcement Card Clicked', {
    announcement_key: announcementKey,
  })
}

const recordAnnouncementActionClickMetric = (
  announcementKey: string,
  actionButtonLabel: string,
  trackEvent: TrackEventFunction
) => {
  Services.HoneyComb.Record({
    metric: 'ui.announcement_action_click',
    announcement_key: announcementKey,
  })
  trackEvent('Announcement Action Clicked', {
    announcement_key: announcementKey,
    action_button_label: actionButtonLabel,
  })
}

const recordAnnouncementCardDismissMetric = (
  announcementKey: string,
  trackEvent: TrackEventFunction
) => {
  trackEvent('Announcement Dismissed', {
    announcement_key: announcementKey,
  })
}

const recordAnnouncementCloseMetric = (
  announcementKey: string,
  trackEvent: TrackEventFunction
) => {
  trackEvent('Announcement Modal Closed', {
    announcement_key: announcementKey,
  })
}

const recordAnnouncementShownMetric = (
  announcementKey: string,
  trackEvent: TrackEventFunction
) => {
  trackEvent('Announcement Modal Shown', {
    announcement_key: announcementKey,
  })
}

interface ModalAnnouncementProps {
  handleAction: () => void
  cardTitle: string
  cardDescription: string
  announcementKey: string
  modalContent: string
  modalHeight: string
  modalWidth: string
  actionButtonLabel: string
  hideClose?: boolean
  hideActionButton?: boolean
  dismiss?: () => void
  defaultShow?: boolean
  show?: () => void
  trackEvent: TrackEventFunction
  preloadImages?: string[]
}

const ModalAnnouncement: React.FC<ModalAnnouncementProps> = ({
  handleAction,
  cardTitle,
  cardDescription,
  announcementKey,
  modalContent,
  modalHeight,
  modalWidth,
  actionButtonLabel,
  hideClose,
  hideActionButton,
  dismiss,
  defaultShow,
  show,
  trackEvent,
  preloadImages,
}) => {
  const [content, setContent] = useState(modalContent)
  useEffect(() => {
    if (preloadImages?.length) {
      let replacedContent = modalContent
      preloadImages.forEach((path) => {
        const img = new Image()
        // Append current timestamp to bust any caching
        const cacheBustPath = `${path}?ts=${Date.now()}`
        img.src = cacheBustPath
        replacedContent = modalContent.replace(path, cacheBustPath)
      })
      setContent(replacedContent)
    }
  }, [modalContent, preloadImages])

  const onDismiss = (e: React.MouseEvent) => {
    e.stopPropagation()
    recordAnnouncementCardDismissMetric(announcementKey, trackEvent)
    dismiss?.()
  }

  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (defaultShow) setIsOpen(defaultShow)
  }, [defaultShow])

  const handleOpened = () => {
    recordAnnouncementShownMetric(announcementKey, trackEvent)
    show?.()
  }

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen)
    if (!isOpen) {
      recordAnnouncementCloseMetric(announcementKey, trackEvent)
    }
  }

  const handleCardClick = () => {
    setIsOpen(true)
    recordAnnouncementCardClickMetric(announcementKey, trackEvent)
  }

  return (
    <>
      <AnnouncementCard
        title={cardTitle}
        description={cardDescription}
        announcementKey={announcementKey}
        actionButtonLabel={actionButtonLabel}
        dismiss={onDismiss}
        onClick={handleCardClick}
      />
      <Dialog open={isOpen} onOpenChange={handleOpenChange}>
        <DialogContent
          showCloseIcon={false}
          className={modalWidth}
          onOpenAutoFocus={handleOpened}
        >
          <ScrollArea className={modalHeight}>
            <Markdown content={content} />
          </ScrollArea>
          <DialogFooter>
            {!hideClose && (
              <DialogClose asChild>
                <Button variant="outline" aria-label="Close announcement">
                  Close
                </Button>
              </DialogClose>
            )}
            {!hideActionButton && (
              <DialogClose asChild>
                <Button onClick={handleAction}>{actionButtonLabel}</Button>
              </DialogClose>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

interface NoModalAnnouncementProps {
  handleAction: () => void
  cardTitle: string
  cardDescription: string
  announcementKey: string
  modalContent?: string
  actionButtonLabel?: string
  dismiss?: () => void
  trackEvent: TrackEventFunction
}

const NoModalAnnouncement: React.FC<NoModalAnnouncementProps> = ({
  handleAction,
  cardTitle,
  cardDescription,
  announcementKey,
  actionButtonLabel,
  dismiss,
  trackEvent,
}) => {
  const onClick = () => {
    recordAnnouncementCardClickMetric(announcementKey, trackEvent)
    handleAction()
  }

  const onDismiss = (e: React.MouseEvent) => {
    e.stopPropagation()
    recordAnnouncementCardDismissMetric(announcementKey, trackEvent)
    dismiss?.()
  }

  return (
    <AnnouncementCard
      title={cardTitle}
      description={cardDescription}
      onClick={onClick}
      announcementKey={announcementKey}
      actionButtonLabel={actionButtonLabel}
      dismiss={onDismiss}
    />
  )
}

/*
 * This component is responsible for rendering a single announcement.
 */
const OneAnnouncement: React.FC<{
  announcement: Announcement
  userInfo: UserInfo
  trackEvent: TrackEventFunction
}> = ({ announcement, userInfo, trackEvent }) => {
  const { isDismissed, dismiss, wasShown, show } = useIsDismissed(announcement)
  const [shouldAutoOpen, setShouldAutoOpen] = useState(false)

  const viewRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (isDismissed) return
    // Have the card be visible for at least a second before being counted as "shown"
    viewRef.current = setTimeout(() => {
      trackEvent('Announcement Card Shown', {
        announcement_key: announcement.announcementKey,
      })
      setShouldAutoOpen(!!announcement.autoOpen)
    }, 1000)

    return () => {
      if (viewRef.current) {
        clearTimeout(viewRef.current)
        viewRef.current = null
      }
    }
  }, [announcement, isDismissed, trackEvent])

  const navigate = useNavigate()
  const handleAction = () => {
    recordAnnouncementActionClickMetric(
      announcement.announcementKey,
      announcement.actionButtonLabel || 'Show me',
      trackEvent
    )
    if (
      announcement.criteria.persistanceBehavior ===
      PersistanceBehavior.HIDE_AFTER_VIEWED
    ) {
      dismiss()
    }
    if (announcement.announcementAction) {
      announcement.announcementAction(navigate, userInfo, trackEvent)
    }
  }

  const modalContent =
    typeof announcement.modalContent === 'function'
      ? announcement.modalContent(userInfo)
      : announcement.modalContent
  const hasModalContent = modalContent && modalContent.length > 0

  const isModal =
    announcement.announcementType === AnnouncementType.MODAL && hasModalContent

  if (isDismissed) {
    return null
  }

  return isModal ? (
    <ModalAnnouncement
      handleAction={handleAction}
      cardDescription={announcement.cardDescription}
      cardTitle={announcement.cardTitle}
      announcementKey={announcement.announcementKey}
      modalContent={modalContent || ''}
      modalHeight={announcement.modalHeight || ''}
      modalWidth={announcement.modalWidth || ''}
      dismiss={announcement.allowDismiss ? dismiss : undefined}
      defaultShow={shouldAutoOpen && !wasShown}
      show={show}
      actionButtonLabel={announcement.actionButtonLabel || 'Show me'}
      trackEvent={trackEvent}
      hideClose={announcement.hideClose}
      hideActionButton={announcement.hideActionButton}
      preloadImages={announcement.preloadImages}
    />
  ) : (
    <NoModalAnnouncement
      handleAction={handleAction}
      cardDescription={announcement.cardDescription}
      cardTitle={announcement.cardTitle}
      announcementKey={announcement.announcementKey}
      actionButtonLabel={announcement.actionButtonLabel || 'Show me'}
      dismiss={announcement.allowDismiss ? dismiss : undefined}
      trackEvent={trackEvent}
    />
  )
}

// Ultimate top level component that finds active announcements for a user and then renders a single one
const HarveyAnnouncements: React.FC<{ userInfo: UserInfo }> = ({
  userInfo,
}) => {
  const { trackEvent } = useAnalytics()
  const activeAnnouncement = useMemo(
    () => getActiveAnnouncementsForUser(userInfo)[0],
    [userInfo]
  )

  if (_.isNil(activeAnnouncement)) {
    return null
  } else {
    return (
      <OneAnnouncement
        announcement={activeAnnouncement}
        userInfo={userInfo}
        trackEvent={trackEvent}
      />
    )
  }
}

// exports
export default HarveyAnnouncements
