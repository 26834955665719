/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowSelectInputBlockOutput
 */
export interface WorkflowSelectInputBlockOutput {
    /**
     * 
     * @type {string}
     * @memberof WorkflowSelectInputBlockOutput
     */
    selected: string;
}

/**
 * Check if a given object implements the WorkflowSelectInputBlockOutput interface.
 */
export function instanceOfWorkflowSelectInputBlockOutput(value: object): value is WorkflowSelectInputBlockOutput {
    if (!('selected' in value) || value['selected'] === undefined) return false;
    return true;
}

export function WorkflowSelectInputBlockOutputFromJSON(json: any): WorkflowSelectInputBlockOutput {
    return WorkflowSelectInputBlockOutputFromJSONTyped(json, false);
}

export function WorkflowSelectInputBlockOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowSelectInputBlockOutput {
    if (json == null) {
        return json;
    }
    return {
        
        'selected': json['selected'],
    };
}

export function WorkflowSelectInputBlockOutputToJSON(json: any): WorkflowSelectInputBlockOutput {
    return WorkflowSelectInputBlockOutputToJSONTyped(json, false);
}

export function WorkflowSelectInputBlockOutputToJSONTyped(value?: WorkflowSelectInputBlockOutput | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'selected': value['selected'],
    };
}

