/**
 * OrderedSet maintains both insertion order and uniqueness of elements.
 * It provides O(1) lookup and preserves the order in which elements were first added.
 */
export class OrderedSet<T> implements Iterable<T> {
  private items = new Map<T, number>()
  private order: T[] = []

  constructor(values?: Iterable<T>) {
    if (values) {
      // @ts-expect-error not going to change the tsconfig for this
      for (const value of values) {
        this.add(value)
      }
    }
  }

  /**
   * Add an item to the set if it doesn't already exist
   */
  add(value: T): this {
    if (!this.has(value)) {
      this.items.set(value, this.order.length)
      this.order.push(value)
    }
    return this
  }

  /**
   * Remove an item from the set
   */
  delete(value: T): boolean {
    const index = this.items.get(value)
    if (index === undefined) return false

    // Remove from map and array
    this.items.delete(value)
    this.order.splice(index, 1)

    // Update indices for all elements after the removed one
    for (let i = index; i < this.order.length; i++) {
      this.items.set(this.order[i], i)
    }

    return true
  }

  /**
   * Check if an item exists in the set
   */
  has(value: T): boolean {
    return this.items.has(value)
  }

  /**
   * Get the number of items in the set
   */
  get size(): number {
    return this.order.length
  }

  /**
   * Clear all items from the set
   */
  clear(): void {
    this.items.clear()
    this.order = []
  }

  /**
   * Get an item by its insertion order index
   */
  at(index: number): T | undefined {
    return this.order[index]
  }

  /**
   * Get the index of an item
   */
  indexOf(value: T): number {
    return this.items.get(value) ?? -1
  }

  /**
   * Convert the set to an array preserving insertion order
   */
  toArray(): T[] {
    return [...this.order]
  }

  /**
   * Implement Iterator protocol
   */
  [Symbol.iterator](): Iterator<T> {
    return this.order[Symbol.iterator]()
  }

  /**
   * Create a new OrderedSet from an iterable
   */
  static from<T>(values: Iterable<T>): OrderedSet<T> {
    return new OrderedSet(values)
  }
}
