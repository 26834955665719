import * as React from 'react'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'

import { Title } from 'openapi/models/Title'

import { getTitleText } from 'utils/user-profile-helpers'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

interface ProfileTitleFormProps {
  control: Control<any>
  size?: 'md' | 'lg'
  onChange?: (value: Title) => void
}

const OPTIONS = Object.values(Title).map((title) => ({
  value: title,
  label: getTitleText(title),
}))

export const FORM_HEADING = 'What is your job title?'
export const FORM_DESCRIPTION =
  'Select the title that most closely matches your current position. You will still have access to all content, regardless of your selection.'

const ProfileTitleForm: React.FC<ProfileTitleFormProps> = ({
  control,
  onChange,
}) => {
  const handleChange = (
    field: ControllerRenderProps<any, 'title'>,
    value: string
  ) => {
    onChange?.(value as Title)
    field.onChange(value)
  }

  return (
    <Controller
      name="title"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Select
          value={field.value}
          onValueChange={(value) => handleChange(field, value)}
        >
          <SelectTrigger className="w-full max-w-96">
            <SelectValue placeholder="Select job title" />
          </SelectTrigger>
          <SelectContent>
            {OPTIONS.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    />
  )
}

export default ProfileTitleForm
