/* tslint:disable */
/* eslint-disable */
/**
 * Harvey - Side-by-Side (SxS) Evaluations
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EvaluationQuestionResponseType = {
    PREFERENCE: 'PREFERENCE',
    RATING: 'RATING',
    FREE_FORM: 'FREE_FORM',
    MULTIPLE_SELECT: 'MULTIPLE_SELECT',
    FLAG: 'FLAG'
} as const;
export type EvaluationQuestionResponseType = typeof EvaluationQuestionResponseType[keyof typeof EvaluationQuestionResponseType];


export function instanceOfEvaluationQuestionResponseType(value: any): boolean {
    for (const key in EvaluationQuestionResponseType) {
        if (Object.prototype.hasOwnProperty.call(EvaluationQuestionResponseType, key)) {
            if (EvaluationQuestionResponseType[key as keyof typeof EvaluationQuestionResponseType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EvaluationQuestionResponseTypeFromJSON(json: any): EvaluationQuestionResponseType {
    return EvaluationQuestionResponseTypeFromJSONTyped(json, false);
}

export function EvaluationQuestionResponseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationQuestionResponseType {
    return json as EvaluationQuestionResponseType;
}

export function EvaluationQuestionResponseTypeToJSON(value?: EvaluationQuestionResponseType | null): any {
    return value as any;
}

export function EvaluationQuestionResponseTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): EvaluationQuestionResponseType {
    return value as EvaluationQuestionResponseType;
}

