import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { useAssistantChatStreamHandler } from 'components/assistant/hooks/use-assistant-chat'
import { useAssistantDraftStreamHandler } from 'components/assistant/hooks/use-assistant-draft'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { PROMPT_PARAM } from 'components/assistant/utils/assistant-helpers'
import {
  FileSource,
  VaultKnowledgeSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { DEFAULT_FILES_WARNING_THRESHOLD } from 'components/vault/components/file-explorer/vault-file-explorer'
import { getReadyToQueryFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import VaultWorkflowAddFiles from 'components/vault/workflows/vault-workflow-add-files'

import tableIllustration from './assets/table-illustration.svg'
import threadIllustration from './assets/thread-illustration.svg'
import VaultAssistantThreadInput from './vault-assistant-thread-input'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

const VaultCreateBlank = () => {
  const { projectId } = useParams()
  const navigate = useNavigateWithQueryParams()
  const useChat = useAssistantChatStreamHandler()
  const useDraft = useAssistantDraftStreamHandler()

  const [workflowModalState, selectedWorkflow, setWorkflowModalState] =
    useVaultWorkflowStore(
      useShallow((state) => [
        state.workflowModalState,
        state.selectedWorkflow,
        state.setWorkflowModalState,
      ])
    )

  const [setKnowledgeSource, setCreatedAt, setQuery, reset] = useAssistantStore(
    useShallow((s) => [
      s.setKnowledgeSource,
      s.setCreatedAt,
      s.setQuery,
      s.reset,
    ])
  )
  const [pendingQuery, currentProjectMetadata, folderIdToVaultFolder] =
    useVaultStore(
      useShallow((s) => [
        s.pendingQuery,
        s.currentProjectMetadata,
        s.folderIdToVaultFolder,
      ])
    )

  const [assistantMode, setAssistantMode] = useState<AssistantMode>(
    AssistantMode.ASSIST
  )

  const [preselectFileIds, setPreselectFileIds] = useState<Set<string>>(
    new Set()
  )

  const handleOpenAddFiles = () => {
    const readyToQueryFileIds = getReadyToQueryFileIds(
      currentProjectMetadata,
      null,
      folderIdToVaultFolder
    )
    const fileIds = new Set(readyToQueryFileIds)
    if (fileIds.size <= DEFAULT_FILES_WARNING_THRESHOLD) {
      // If the number of files is less than the threshold, preselect them.
      // Otherwise, we'll show a warning in the add files dialog if user tries
      // to select all these files.
      setPreselectFileIds(fileIds)
    }
  }

  const authCallback = useCallback(
    (eventId: string) => {
      if (eventId) {
        navigate(`/assistant/${assistantMode}/${eventId}`, {}, [PROMPT_PARAM])
      }
      setQuery('')
    },
    [navigate, setQuery, assistantMode]
  )

  const handleAssistantThreadSubmit = (
    selectedFileIds: string[],
    mode: AssistantMode
  ) => {
    reset()
    const knowledgeSourceInput = {
      type: FileSource.VAULT,
      folderId: currentProjectMetadata.id,
      // If user has pending files to be queried, we need to pass them to Assistant, otherwise we leave it empty
      fileIds: selectedFileIds,
      query: pendingQuery,
    } as VaultKnowledgeSource

    setKnowledgeSource(knowledgeSourceInput)
    setQuery(pendingQuery)

    setCreatedAt(new Date())

    if (mode === AssistantMode.ASSIST) {
      useChat.initChat(
        pendingQuery,
        null,
        authCallback,
        {},
        knowledgeSourceInput
      )
    } else {
      useDraft.initDraft(pendingQuery, null, authCallback, knowledgeSourceInput)
    }
  }

  if (!projectId) {
    return null
  }

  return (
    <Dialog
      open={
        (workflowModalState === WorkflowModalState.CreateBlank ||
          workflowModalState === WorkflowModalState.AddFilesReviewTable ||
          workflowModalState === WorkflowModalState.AssistantThreadInput ||
          workflowModalState === WorkflowModalState.AddFilesAssistantThread) &&
        !selectedWorkflow
      }
      onOpenChange={(open) =>
        setWorkflowModalState(
          open ? WorkflowModalState.CreateBlank : WorkflowModalState.None
        )
      }
    >
      <DialogContent
        className="sm:max-w-[800px]"
        innerClassName="flex flex-col"
        hasContainer={false}
      >
        {workflowModalState === WorkflowModalState.CreateBlank && (
          <VaultQueryTypeSelector handleOpenAddFiles={handleOpenAddFiles} />
        )}
        {(workflowModalState === WorkflowModalState.AddFilesReviewTable ||
          workflowModalState ===
            WorkflowModalState.AddFilesAssistantThread) && (
          <VaultWorkflowAddFiles
            projectId={projectId}
            onBackButtonClicked={() => {
              if (
                workflowModalState === WorkflowModalState.AddFilesReviewTable
              ) {
                setWorkflowModalState(WorkflowModalState.CreateBlank)
              } else {
                setWorkflowModalState(WorkflowModalState.AssistantThreadInput)
              }
            }}
            buttonLabel={
              workflowModalState === WorkflowModalState.AddFilesReviewTable
                ? 'Create table'
                : 'Ask Harvey'
            }
            handleSubmitAssistant={
              workflowModalState === WorkflowModalState.AddFilesAssistantThread
                ? handleAssistantThreadSubmit
                : undefined
            }
            assistantMode={
              workflowModalState === WorkflowModalState.AddFilesAssistantThread
                ? assistantMode
                : undefined
            }
            preselectFileIds={preselectFileIds}
          />
        )}
        {workflowModalState === WorkflowModalState.AssistantThreadInput && (
          <VaultAssistantThreadInput
            assistantMode={assistantMode}
            setAssistantMode={setAssistantMode}
            onBackButtonClicked={() => {
              setWorkflowModalState(WorkflowModalState.CreateBlank)
            }}
            handleOpenAddFiles={handleOpenAddFiles}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const VaultQueryTypeSelector = ({
  handleOpenAddFiles,
}: {
  handleOpenAddFiles: () => void
}) => {
  const { trackEvent } = useAnalytics()

  const [setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.setWorkflowModalState])
  )
  return (
    <>
      <DialogHeader className="gap-4">
        <DialogTitle className="w-full text-center">
          Choose query type
        </DialogTitle>
        <div className="-mx-6 border-b" />
      </DialogHeader>
      <div className="flex w-full gap-4">
        <Card className="w-full">
          <div className="flex items-center justify-center rounded-lg bg-secondary py-12">
            <img
              src={tableIllustration}
              alt="Table illustration"
              className="pointer-events-none select-none"
            />
          </div>
          <div className="flex flex-col gap-4 p-4">
            <div className="flex flex-col gap-1">
              <p className="text-base">Review table</p>
              <p className="text-sm text-muted">
                Analyze every file individually, asking the same questions to
                each file
              </p>
            </div>
            <Button
              className="w-fit"
              onClick={() => {
                trackEvent('Vault New Review Clicked')
                trackEvent('Vault File Selector Viewed', {
                  source: 'Vault New Review',
                })
                handleOpenAddFiles()
                setWorkflowModalState(WorkflowModalState.AddFilesReviewTable)
              }}
            >
              Create review table
            </Button>
          </div>
        </Card>
        <Card className="w-full">
          <div className="flex items-center justify-center rounded-lg bg-secondary py-12">
            <img
              src={threadIllustration}
              alt="Thread illustration"
              className="pointer-events-none select-none"
            />
          </div>
          <div className="flex flex-col gap-4 p-4">
            <div className="flex flex-col gap-1">
              <p className="text-base">Assistant thread</p>
              <p className="text-sm text-muted">
                Get a single answer on collective information across all files
              </p>
            </div>
            <Button
              className="w-fit"
              onClick={() => {
                trackEvent('Vault New Thread Clicked')
                setWorkflowModalState(WorkflowModalState.AssistantThreadInput)
              }}
            >
              Create thread
            </Button>
          </div>
        </Card>
      </div>
    </>
  )
}

export default VaultCreateBlank
