import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { UserInfo } from 'models/user-info'

import { backendRestUrl, environment, site } from './server-data'

const PROXY = `${backendRestUrl}/dp`

export const initDatadog = (service: string = 'frontend') => {
  // Docs: https://docs.datadoghq.com/logs/log_collection/javascript/
  const logClientToken = process.env.REACT_APP_DATADOG_LOG_CLIENT_TOKEN
  if (logClientToken) {
    try {
      datadogLogs.init({
        // The Word Add-In execution environment blocks third-party cookies from being set,
        // so we need to use local storage as a fallback.
        allowFallbackToLocalStorage: true,
        clientToken: logClientToken,
        env: environment,
        forwardConsoleLogs: 'all',
        forwardErrorsToLogs: true,
        proxy: PROXY,
        service,
        sessionSampleRate: 100,
        version: process.env.REACT_APP_REVISION,
      })

      datadogLogs.setGlobalContextProperty('site', site)
    } catch (e: unknown) {
      console.warn('Error initializing datadog logs', e)
    }
  }

  // Docs: https://docs.datadoghq.com/real_user_monitoring/
  const rumClientToken = process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN
  const rumApplicationId = process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID
  if (rumClientToken && rumApplicationId) {
    try {
      datadogRum.init({
        allowFallbackToLocalStorage: true,
        allowedTracingUrls: backendRestUrl ? [backendRestUrl] : undefined,
        applicationId: rumApplicationId,
        clientToken: rumClientToken,
        // Most restrictive: https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#mask-mode
        defaultPrivacyLevel: 'mask',
        env: environment,
        proxy: PROXY,
        service,
        sessionReplaySampleRate: 0,
        sessionSampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: false,
        version: process.env.REACT_APP_REVISION,
      })

      datadogRum.setGlobalContextProperty('site', site)
    } catch (e: unknown) {
      console.warn('Error initializing datadog rum', e)
    }
  }
}

export const setDatadogState = (userInfo: UserInfo | null) => {
  const user = {
    id: userInfo?.pseudonymizedId,
    workspace_id: userInfo?.workspace.id,
    workspace_slug: userInfo?.workspace.slug,
    permissions: userInfo?.permissions,
  }

  datadogLogs.setUser(user)
  datadogRum.setUser(user)
}
