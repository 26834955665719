import * as React from 'react'

import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button, ButtonProps, buttonVariants } from 'components/ui/button'

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex list-none flex-row items-center gap-1', className)}
    {...props}
  />
))
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('text-sm', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
  href: string
} & Pick<ButtonProps, 'size'> &
  React.ComponentProps<'a'>

const PaginationLink = ({
  className,
  isActive,
  size = 'icon',
  href,
  ...props
}: PaginationLinkProps) => (
  // eslint-disable-next-line
  <a
    href={href}
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      'cursor-pointer text-sm',
      className
    )}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

type PaginationButtonProps = {
  isActive?: boolean
  className?: string
} & ButtonProps
const PaginationButton = ({
  className,
  isActive,
  size = 'default',
  ...props
}: PaginationButtonProps) => (
  <Button
    variant="unstyled"
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      'cursor-pointer text-sm',
      className
    )}
    {...props}
  />
)
PaginationButton.displayName = 'PaginationButton'

const PaginationPrevious = ({ className, ...props }: PaginationButtonProps) => (
  <PaginationButton
    aria-label="Go to previous page"
    className={cn('gap-1 pl-2.5', className)}
    {...props}
  >
    <ChevronLeft className="size-4" />
    <span className="text-sm">Previous</span>
  </PaginationButton>
)

PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({ className, ...props }: PaginationButtonProps) => (
  <PaginationButton
    aria-label="Go to next page"
    className={cn('gap-1 pr-2.5', className)}
    {...props}
  >
    <span className="text-sm">Next</span>
    <ChevronRight className="size-4" />
  </PaginationButton>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <MoreHorizontal className="size-4" />
    <span className="sr-only">More pages</span>
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

interface PaginationPageInputProps {
  className?: string
  page: number
  setPage: (page: number) => void
  onSubmit?: (page: number) => void
}

const PaginationPageInput = ({
  className,
  page,
  setPage,
  onSubmit,
}: PaginationPageInputProps) => (
  <input
    className={cn('w-10 rounded-sm border text-center text-sm', className)}
    value={page}
    onChange={(e) => setPage(Number(e.target.value))}
    onKeyDown={(e) => {
      if (e.key === 'Enter' && onSubmit) {
        onSubmit(page)
      }
    }}
  />
)

PaginationPageInput.displayName = 'PaginationPageInput'

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
  PaginationPageInput,
}
