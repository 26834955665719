import React, { useCallback, useMemo, memo } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import {
  RowHeightParams,
  GridReadyEvent,
  RowGroupOpenedEvent,
  SortChangedEvent,
  FilterChangedEvent,
  ColumnMovedEvent,
  ColumnResizedEvent,
  BodyScrollEvent,
  CellClassParams,
} from 'ag-grid-community'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'

import { displayErrorMessage } from 'utils/toast'

import { DataGrid } from 'components/ui/data-grid/data-grid-v2'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  createGridColumnDefs,
  addFilesToGrid,
  getDisplayedRows,
  GridTransactionAction,
  updateAddColumnDef,
  ADD_COLUMN_FIELD,
  ROOT_NODE_ID,
  QuestionColumnDef,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { ReorderVaultReviewQueryColumns } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

import AddColumnCell from './cells/add-column-cell'
import RowNumberCell from './cells/row-number-cell'
import DocumentCell from './cells/vault-document-cell'
import VaultGroupRowRenderer from './cells/vault-group-row-renderer'
import VaultHeaderCell from './cells/vault-header-cell'
import NoRowsOverlay from './no-rows-overlay'

interface VaultDataGridProps {
  projectId: string
  isExampleProject: boolean
  doesCurrentUserHaveEditPermission: boolean
  onGridReady: (e: GridReadyEvent) => void
  onGridDestroyed: () => void
  onRowGroupOpened: (e: RowGroupOpenedEvent) => void
  onSortChanged: (e: SortChangedEvent) => void
  onFilterChanged: (e: FilterChangedEvent) => void
  onColumnMoved: (e: ColumnMovedEvent) => void
  onColumnResized: (e: ColumnResizedEvent) => void
  onBodyScroll: (e: BodyScrollEvent) => void
}

const VaultDataGrid = memo(
  ({
    projectId,
    isExampleProject,
    doesCurrentUserHaveEditPermission,
    onGridReady,
    onGridDestroyed,
    onRowGroupOpened,
    onSortChanged,
    onFilterChanged,
    onColumnMoved,
    onColumnResized,
    onBodyScroll,
  }: VaultDataGridProps) => {
    const getCellClass = (params: CellClassParams) => {
      const { node } = params
      // if the node is the last child of group then we want to remove the border-bottom
      // except when it's the last child of the entire grid
      if (
        !node.group &&
        node.firstChild &&
        node.parent?.group &&
        node.parent.id !== ROOT_NODE_ID
      ) {
        return 'border-b border-t-color'
      }
      return 'border-b no-top-border'
    }

    const questionColumnType = {
      useValueFormatterForExport: false,
      cellRenderer: DocumentCell,
      pinned: false,
      lockPinned: true,
      lockPosition: false,
      // do not allow moving question columns for example projects or when the query is still loading
      suppressMovable: isExampleProject || !doesCurrentUserHaveEditPermission,
    }

    return (
      <DataGrid
        gridOptions={{
          // allowing reactive components
          reactiveCustomComponents: true,
          // allowing multiple row selection
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          // setting up the grid options to allow for row grouping
          // we set groupDefaultExpanded to -1 so that all of the groups will be expanded by default
          animateRows: false,
          groupDefaultExpanded: -1,
          groupDisplayType: 'groupRows',
          groupRowRenderer: VaultGroupRowRenderer,
          getRowHeight: (e: RowHeightParams) => (e.node.group ? 48 : null),
          // we need this to prevent the grid from scrolling to the top when new data is loaded
          // new data is loaded when we stream responses and update rowData
          // or when we toggle short/long responses
          // https://stackoverflow.com/questions/55723337/ag-grid-how-to-scroll-to-last-known-position
          suppressScrollOnNewData: true,
          suppressColumnMoveAnimation: true,
          suppressCellFocus: true,
          suppressHeaderFocus: true,
          suppressDragLeaveHidesColumns: true,
          suppressNoRowsOverlay: true,
          // default header height
          headerHeight: 32,
          rowData: [],
          columnDefs: [],
        }}
        defaultColDef={{
          headerComponent: VaultHeaderCell,
          headerClass: 'border-b',
          cellClass: getCellClass,
          suppressMovable: true,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            maxNumConditions: Number.MAX_SAFE_INTEGER,
          },
          lockPosition: true,
          resizable: false,
          cellRendererParams: {
            projectId,
          },
          headerComponentParams: {
            isExampleProject,
            doesCurrentUserHaveEditPermission,
          },
        }}
        columnTypes={{
          hidden: {
            hide: true,
            lockVisible: true,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
          },
          number: {
            sortable: false,
            useValueFormatterForExport: false,
            cellRenderer: RowNumberCell,
          },
          document: {
            useValueFormatterForExport: false,
            cellRenderer: DocumentCell,
          },
          text: questionColumnType,
          date: questionColumnType,
          addColumn: {
            useValueFormatterForExport: false,
            resizable: false,
            lockPosition: 'right',
            cellRenderer: AddColumnCell,
          },
        }}
        onGridReady={onGridReady}
        onGridDestroyed={onGridDestroyed}
        noRowsOverlayComponent={NoRowsOverlay}
        onRowGroupOpened={onRowGroupOpened}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        onColumnMoved={onColumnMoved}
        onColumnResized={onColumnResized}
        onBodyScroll={onBodyScroll}
      />
    )
  }
)

const VaultDataGridWrapper = () => {
  const queryClient = useQueryClient()
  const [setDisplayedRows, bulkRemoveSelectedRows] =
    useVaultDataGridFilterStore(
      useShallow((state) => [
        state.setDisplayedRows,
        state.bulkRemoveSelectedRows,
      ])
    )

  const [
    currentProject,
    currentProjectMetadata,
    exampleProjectIds,
    fileIdToVaultFile,
    folderIdToVaultFolder,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProject,
      state.currentProjectMetadata,
      state.exampleProjectIds,
      state.fileIdToVaultFile,
      state.folderIdToVaultFolder,
    ])
  )

  const [
    queryId,
    isQueryLoading,
    initialHistoryItem,
    initialPendingQueryFileIds,
    initialPendingQueryQuestions,
    setGridApi,
    setIsGridDirty,
    setHasOnGridReadyExecuted,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.queryId,
      state.isQueryLoading,
      state.initialHistoryItem,
      state.initialPendingQueryFileIds,
      state.initialPendingQueryQuestions,
      state.setGridApi,
      state.setIsGridDirty,
      state.setHasOnGridReadyExecuted,
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
    ])
  )

  const isNewQuery = queryId === 'new'
  const projectId = currentProject?.id ?? ''
  const isExampleProject = useMemo(
    () => (currentProject ? exampleProjectIds.has(currentProject.id) : false),
    [currentProject, exampleProjectIds]
  )
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: projectId,
  })

  const onGridDestroyed = useCallback(() => {
    setPendingQueryQuestions(null)
    setPendingQueryFileIds(null)
    setGridApi(null, queryClient)
  }, [
    queryClient,
    setGridApi,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
  ])

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      const api = e.api
      setGridApi(api)
      const projectFolders = [
        currentProjectMetadata,
        ...(currentProjectMetadata.descendantFolders ?? []),
      ]
      const shouldGroupRows = projectFolders.length > 1

      const reviewEvent = initialHistoryItem as ReviewHistoryItem
      const reviewEventColumns = reviewEvent?.columns ?? []
      const sortedReviewEventColumns = reviewEventColumns.toSorted(
        (a, b) => a.order - b.order
      )
      // 1. create the column definitions
      createGridColumnDefs({
        gridApi: api,
        shouldGroupRows,
        isQueryLoading,
        eventId: Number(reviewEvent?.eventId),
        historyColumns: sortedReviewEventColumns,
        pendingQueryQuestions: initialPendingQueryQuestions ?? [],
      })

      // 2. create the necessary row data
      const historyFilesToAdd =
        (reviewEvent?.rows
          .filter((row) => !row.isHidden)
          .map((row) => fileIdToVaultFile[row.fileId])
          .filter(Boolean) as VaultFile[]) ?? []

      const processedFileIds = reviewEvent?.processedFileIds ?? []
      const pendingFilesToAdd =
        initialPendingQueryFileIds?.map(
          (fileId: string) => fileIdToVaultFile[fileId]
        ) ?? []

      if (historyFilesToAdd.length > 0) {
        addFilesToGrid({
          gridApi: api,
          setDisplayedRows: setDisplayedRows,
          files: [...historyFilesToAdd, ...pendingFilesToAdd].filter(
            Boolean
          ) as VaultFile[],
          folderIdToVaultFolder,
          isLoading: isQueryLoading,
          gridAction: GridTransactionAction.ADD,
          isDryRun: reviewEvent.dryRun ?? false,
          fileIdToAnswers: reviewEvent.answers,
          fileIdToErrors: reviewEvent.errors,
          suppressedFileIdsSet: new Set(reviewEvent.suppressedFileIds),
          processedFileIdsSet: new Set(processedFileIds),
          questions: reviewEvent.questions,
          reviewRows: reviewEvent.rows,
        })
      }

      if (pendingFilesToAdd.length > 0) {
        addFilesToGrid({
          gridApi: api,
          setDisplayedRows: setDisplayedRows,
          files: pendingFilesToAdd.filter(Boolean) as VaultFile[],
          folderIdToVaultFolder,
          isLoading: isQueryLoading,
          gridAction: GridTransactionAction.ADD,
        })
      }

      // 3. We want to remove the suppressNoRowsOverlay so it will display if there are no rows
      api.setGridOption('suppressNoRowsOverlay', false)
      if (historyFilesToAdd.length === 0 && pendingFilesToAdd.length === 0) {
        api.showNoRowsOverlay()
      }
      setHasOnGridReadyExecuted(true)
    },
    [
      setGridApi,
      setHasOnGridReadyExecuted,
      setDisplayedRows,
      isQueryLoading,
      currentProjectMetadata,
      initialPendingQueryFileIds,
      initialPendingQueryQuestions,
      initialHistoryItem,
      fileIdToVaultFile,
      folderIdToVaultFolder,
    ]
  )

  const onRowGroupOpened = useCallback((e: RowGroupOpenedEvent) => {
    e.api.refreshCells()
  }, [])

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      setDisplayedRows(getDisplayedRows(e.api))
      e.api.refreshCells({
        force: true,
      })
    },
    [setDisplayedRows]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const numVisibleRows = e.api.getDisplayedRowCount()
      if (numVisibleRows === 0) {
        e.api.showNoRowsOverlay()
      } else {
        e.api.hideOverlay()
      }
      e.api.refreshCells({
        force: true,
      })
      setDisplayedRows(getDisplayedRows(e.api))

      // after the filter is applied we need to update the selected rows
      // we do this by getting the selected rows and then updating the selected rows
      // this is necessary because the selected rows are not updated automatically
      // when the filter is applied
      const selectedNodes = e.api.getSelectedNodes()
      const nodesToUnselect: string[] = []
      selectedNodes.forEach((node) => {
        if (!node.displayed && node.id) {
          nodesToUnselect.push(node.id)
          node.setSelected(false)
        }
      })
      bulkRemoveSelectedRows(nodesToUnselect)
    },
    [setDisplayedRows, bulkRemoveSelectedRows]
  )

  const onColumnMoved = useCallback(
    async (e: ColumnMovedEvent) => {
      const toIndex = e.toIndex

      const column = e.column
      const movedColumnId = column?.getColId()

      const shouldMoveBeApplied =
        e.finished &&
        e.source === 'uiColumnMoved' &&
        !isNewQuery &&
        !!column &&
        movedColumnId &&
        toIndex

      if (shouldMoveBeApplied) {
        // mark the grid as dirty so that the query is invalidated when the user leaves the page
        setIsGridDirty(true)

        // extract and save the previous column order
        const colDef = column.getColDef() as QuestionColumnDef
        // We are going to add 2 to the fromIndex because we want to account for the row & name columns
        const fromIndex = colDef.backingReviewColumn?.order ?? 0

        const gridApi = e.api
        const allGridColumns = gridApi.getAllGridColumns()
        const updatedQuestionIdOrder = allGridColumns
          .map((col) => col.getColDef())
          // only question columns are moveable
          .filter((col) => !col.suppressMovable)
          .map((col) => col.field)
          .filter((field): field is string => field !== undefined)

        // In the BE, we do not store the additional columns (row, name, folderPath, etc)
        // As a result, we need to offset the toIndex we get from the grid by the number of additional columns we have
        // The number of additional columns is the difference between the total number of columns and the number of question columns
        // We subtract 1 because the last column is the add column/gutter column
        const offset = allGridColumns.length - updatedQuestionIdOrder.length - 1

        try {
          await ReorderVaultReviewQueryColumns(queryId, updatedQuestionIdOrder)
          // if the api update was successful, we need to update the column order for the moved column
          // so the next time the user moves the column it will be in the correct position
          const columnDefs = gridApi.getColumnDefs() ?? []
          columnDefs.forEach((colDef, idx) => {
            const isGroup = 'group' in colDef && colDef.group
            const isMovable =
              'suppressMovable' in colDef && colDef.suppressMovable
            if (isGroup || isMovable) return
            const questionColDef = colDef as QuestionColumnDef
            questionColDef.backingReviewColumn!.order = idx - offset + 1
          })
          gridApi.setGridOption('columnDefs', columnDefs)
        } catch (error) {
          displayErrorMessage('Error moving column, please try again.')

          // get all of the column ids
          const columnDefs = gridApi.getColumnDefs() ?? []

          // given the new columnIds order, we need to move the column back to its original position
          // we need to subtract 1 from the offset because the fromIndex is 1-indexed, and splice is 0-indexed
          const newFromIndex = fromIndex + offset - 1
          const [colDefAtToIndex] = columnDefs.splice(toIndex, 1)
          columnDefs.splice(newFromIndex, 0, colDefAtToIndex)
          gridApi.setGridOption('columnDefs', columnDefs)
        }
      }
    },
    [isNewQuery, queryId, setIsGridDirty]
  )

  const onColumnResized = useCallback((e: ColumnResizedEvent) => {
    const isFinishedResizing = e.finished
    const resizedColumnId = e.column?.getColId()
    if (!isFinishedResizing || resizedColumnId === ADD_COLUMN_FIELD) return
    // once the column has finished resizing, we need to update the add column def in case we have to pin/unpin it
    const api = e.api
    const columnDefs = api.getColumnDefs()
    const haveToPinAddColumn = updateAddColumnDef(columnDefs!)
    api.setGridOption('columnDefs', columnDefs)
    api.ensureColumnVisible(haveToPinAddColumn ? ADD_COLUMN_FIELD : 'end')
  }, [])

  const onBodyScroll = useCallback((e: BodyScrollEvent) => {
    if (e.direction === 'vertical') return

    // if we are at the leftmost edge of the grid, then the e.left will be 0
    if (e.left === 0) {
      const leftPinnedHeader = document.querySelector('.ag-pinned-left-header')
      const leftShadowContainer = document.querySelector(
        '.ag-pinned-left-cols-container'
      )
      leftPinnedHeader?.classList.remove('left-shadow')
      leftShadowContainer?.classList.remove('left-shadow')
    }
    // if we are not at the leftmost edge then e.left will be greater than 0
    // we only want to apply the left shadow if e.left is defined
    else if (e.left > 0) {
      const leftPinnedHeader = document.querySelector('.ag-pinned-left-header')
      const leftShadowContainer = document.querySelector(
        '.ag-pinned-left-cols-container'
      )
      leftPinnedHeader?.classList.add('left-shadow')
      leftShadowContainer?.classList.add('left-shadow')
    }

    // if we are at the rightmost edge of the grid, remove the right shadow
    const gridBody = document.querySelector(
      '.ag-body-horizontal-scroll-viewport'
    )
    if (!gridBody) return
    const maxScrollLeft = gridBody.scrollWidth - gridBody.clientWidth
    if (e.left === maxScrollLeft) {
      const addColumnCells = document.querySelectorAll(
        '.ag-cell[col-id="addColumn"]'
      )
      addColumnCells.forEach((cell) => {
        cell.firstElementChild?.classList.remove('border-l')
      })
      const addColumnHeaderCell = document.querySelector(
        '.ag-header-cell[col-id="addColumn"]'
      )
      const addColumnHeaderCellCompWrapper = addColumnHeaderCell?.querySelector(
        '.ag-header-cell-comp-wrapper'
      )
      addColumnHeaderCellCompWrapper?.firstElementChild?.classList.remove(
        'border-l'
      )
    } else {
      const addColumnCells = document.querySelectorAll(
        '.ag-cell[col-id="addColumn"]'
      )
      addColumnCells.forEach((cell) => {
        cell.firstElementChild?.classList.add('border-l')
      })
      const addColumnHeaderCell = document.querySelector(
        '.ag-header-cell[col-id="addColumn"]'
      )
      const addColumnHeaderCellCompWrapper = addColumnHeaderCell?.querySelector(
        '.ag-header-cell-comp-wrapper'
      )
      addColumnHeaderCellCompWrapper?.firstElementChild?.classList.add(
        'border-l'
      )
    }
  }, [])

  return (
    <VaultDataGrid
      projectId={projectId}
      isExampleProject={isExampleProject}
      doesCurrentUserHaveEditPermission={doesCurrentUserHaveEditPermission}
      onGridReady={onGridReady}
      onGridDestroyed={onGridDestroyed}
      onRowGroupOpened={onRowGroupOpened}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      onColumnMoved={onColumnMoved}
      onColumnResized={onColumnResized}
      onBodyScroll={onBodyScroll}
    />
  )
}

VaultDataGrid.displayName = 'VaultDataGrid'
VaultDataGridWrapper.displayName = 'VaultDataGridWrapper'
export default VaultDataGridWrapper
