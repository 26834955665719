import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'

import { FetchVaultProjectMetadata } from './vault-fetcher'

export const useVaultProjectMetadata = (projectId: string) => {
  const { data, isFetching } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.VaultProjectMetadata, projectId],
    queryFn: () => FetchVaultProjectMetadata(projectId),
    enabled: !!projectId,
    // Disable refetching on window focus to avoid unnecessary network requests
    refetchOnWindowFocus: false,
  })
  return { projectMetadata: data, isLoadingMetadata: isFetching && !data }
}
