/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContractsCustomTerm } from './ContractsCustomTerm';
import {
    ContractsCustomTermFromJSON,
    ContractsCustomTermFromJSONTyped,
    ContractsCustomTermToJSON,
    ContractsCustomTermToJSONTyped,
} from './ContractsCustomTerm';
import type { ContractsSmartFilterUserInput } from './ContractsSmartFilterUserInput';
import {
    ContractsSmartFilterUserInputFromJSON,
    ContractsSmartFilterUserInputFromJSONTyped,
    ContractsSmartFilterUserInputToJSON,
    ContractsSmartFilterUserInputToJSONTyped,
} from './ContractsSmartFilterUserInput';
import type { ContractsDocument } from './ContractsDocument';
import {
    ContractsDocumentFromJSON,
    ContractsDocumentFromJSONTyped,
    ContractsDocumentToJSON,
    ContractsDocumentToJSONTyped,
} from './ContractsDocument';

/**
 * 
 * @export
 * @interface ContractsCreateSmartFilterRequest
 */
export interface ContractsCreateSmartFilterRequest {
    /**
     * 
     * @type {ContractsSmartFilterUserInput}
     * @memberof ContractsCreateSmartFilterRequest
     */
    userInput: ContractsSmartFilterUserInput;
    /**
     * 
     * @type {Array<ContractsDocument>}
     * @memberof ContractsCreateSmartFilterRequest
     */
    documents: Array<ContractsDocument>;
    /**
     * 
     * @type {ContractsCustomTerm}
     * @memberof ContractsCreateSmartFilterRequest
     */
    customTerm?: ContractsCustomTerm;
}

/**
 * Check if a given object implements the ContractsCreateSmartFilterRequest interface.
 */
export function instanceOfContractsCreateSmartFilterRequest(value: object): value is ContractsCreateSmartFilterRequest {
    if (!('userInput' in value) || value['userInput'] === undefined) return false;
    if (!('documents' in value) || value['documents'] === undefined) return false;
    return true;
}

export function ContractsCreateSmartFilterRequestFromJSON(json: any): ContractsCreateSmartFilterRequest {
    return ContractsCreateSmartFilterRequestFromJSONTyped(json, false);
}

export function ContractsCreateSmartFilterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsCreateSmartFilterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userInput': ContractsSmartFilterUserInputFromJSON(json['user_input']),
        'documents': ((json['documents'] as Array<any>).map(ContractsDocumentFromJSON)),
        'customTerm': json['custom_term'] == null ? undefined : ContractsCustomTermFromJSON(json['custom_term']),
    };
}

export function ContractsCreateSmartFilterRequestToJSON(json: any): ContractsCreateSmartFilterRequest {
    return ContractsCreateSmartFilterRequestToJSONTyped(json, false);
}

export function ContractsCreateSmartFilterRequestToJSONTyped(value?: ContractsCreateSmartFilterRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'user_input': ContractsSmartFilterUserInputToJSON(value['userInput']),
        'documents': ((value['documents'] as Array<any>).map(ContractsDocumentToJSON)),
        'custom_term': ContractsCustomTermToJSON(value['customTerm']),
    };
}

