import React from 'react'

import { ArrowRight } from 'lucide-react'

import { EventKind } from 'openapi/models/EventKind'
import { PracticeAreaTaxonomy } from 'openapi/models/PracticeAreaTaxonomy'

import { toTitleCase } from 'utils/string'

import { AssistantExampleCardList } from 'components/assistant/components/assistant-example-card-list'
import { LibraryPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { LibraryFilterKey } from 'components/library/library-filter-store'
import { useLibraryMetadataStore } from 'components/library/library-metadata-store'
import LibraryPreview from 'components/library/library-preview'
import { LibraryItem, LibraryItemKind } from 'components/library/library-types'
import { sortByStarred } from 'components/library/library.helpers'
import { useLibraryData } from 'components/library/use-library-data'
import Skeleton from 'components/ui/skeleton'
import { TextLink } from 'components/ui/text-link'

interface Props {
  isLoading: boolean
}

const Scope = {
  TEAM: 'WORKSPACE',
  HARVEY: 'HARVEY',
}

const PracticeAreas = {
  GENERAL: 'GENERAL',
  TRANSACTIONAL: PracticeAreaTaxonomy.TRANSACTIONAL,
  LITIGATION: PracticeAreaTaxonomy.LITIGATION,
} as const

type PracticeAreas = (typeof PracticeAreas)[keyof typeof PracticeAreas]

type CategorizedHarveyEvents = Record<Lowercase<PracticeAreas>, LibraryItem[]>

const AssistantExamples = ({ isLoading }: Props) => {
  const userInfo = useAuthUser()
  const { items } = useLibraryData(LibraryItemKind.EXAMPLE)
  const itemsList = Object.values(items)
  const workspaceName =
    userInfo.workspace.friendlyName ?? userInfo.workspace.clientName

  // ----- Pre - Discovery Tab Functionality ----->
  const MAX_EXAMPLES = 10

  const getFavoriteStatus = useLibraryMetadataStore((s) => s.getFavoriteStatus)

  const sortedItems = Object.values(items).sort((a, b) => {
    return sortByStarred(a, b, getFavoriteStatus)
  })
  //---

  const isAssistantEvent = (item: LibraryItem) => {
    return (
      item.eventKind === EventKind.ASSISTANT_CHAT ||
      item.eventKind === EventKind.ASSISTANT_DRAFT
    )
  }

  const categorizeHarveyEvent = (
    item: LibraryItem,
    categorizedEvents: CategorizedHarveyEvents
  ) => {
    item.practiceAreas.forEach((areaKey) => {
      const area = areaKey.toLowerCase() as keyof CategorizedHarveyEvents
      if (area in categorizedEvents) {
        categorizedEvents[area].push(item)
      }
    })
  }

  const processEvents = (
    items: LibraryItem[]
  ): {
    customEvents: LibraryItem[]
    categorizedHarveyEvents: CategorizedHarveyEvents
  } => {
    const customEvents: LibraryItem[] = []

    const categorizedHarveyEvents: CategorizedHarveyEvents = {
      transactional: [],
      litigation: [],
      general: [],
    }

    items.forEach((item) => {
      if (!isAssistantEvent(item) || item.name === '') return

      if (item.visibilityScope === Scope.TEAM) {
        customEvents.push(item)
      } else if (item.visibilityScope === Scope.HARVEY) {
        categorizeHarveyEvent(item, categorizedHarveyEvents)
      }
    })

    return {
      customEvents,
      categorizedHarveyEvents,
    }
  }

  const buildSeeAllUrl = (scope?: string, practiceArea?: PracticeAreas) => {
    const params = new URLSearchParams()

    if (practiceArea && scope === Scope.HARVEY) {
      params.append(LibraryFilterKey.PRACTICE_AREA, toTitleCase(practiceArea))
      params.append(LibraryFilterKey.VISIBILITY_SCOPE, scope.toLowerCase())
    }
    return `${LibraryPath.Examples}?${params.toString()}`
  }

  if (isLoading) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!itemsList.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Example queries will appear here
      </p>
    )
  }

  const { customEvents, categorizedHarveyEvents } = processEvents(itemsList)
  const { transactional, litigation, general } = categorizedHarveyEvents

  return userInfo.isDiscoverTabUser ? (
    <div className="mt-6 flex flex-col gap-6">
      <AssistantExampleCardList
        userInfo={userInfo}
        items={customEvents}
        title={`From ${workspaceName}`}
        href={buildSeeAllUrl()}
      />
      <AssistantExampleCardList
        userInfo={userInfo}
        items={general}
        title="For General Work"
        href={buildSeeAllUrl(Scope.HARVEY, PracticeAreas.GENERAL)}
      />

      <AssistantExampleCardList
        userInfo={userInfo}
        items={transactional}
        title="For Transactional Work"
        href={buildSeeAllUrl(Scope.HARVEY, PracticeAreas.TRANSACTIONAL)}
      />
      <AssistantExampleCardList
        userInfo={userInfo}
        items={litigation}
        title="For Litigation Work"
        href={buildSeeAllUrl(Scope.HARVEY, PracticeAreas.LITIGATION)}
      />
    </div>
  ) : (
    <>
      <LibraryPreview
        items={sortedItems.slice(0, MAX_EXAMPLES)}
        isLoading={isLoading}
        showHeaders
      />
      <TextLink
        className="mt-2 inline-block font-semibold"
        label="View all examples"
        href={LibraryPath.Examples}
        trailingIcon={<ArrowRight />}
      />
    </>
  )
}
export default AssistantExamples
