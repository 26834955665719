import React, { useState } from 'react'

import { Card } from 'antd'
import _ from 'lodash'

import { Workspace, WorkspaceOffboardJob } from 'models/workspace'

import { displayErrorMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import useWorkspaceOffboardJobs from 'components/settings/hooks/use-workspace-offboard-jobs'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Separator } from 'components/ui/separator'

import WorkspaceOffboardCancelModal from './workspace-offboard-cancel-modal'
import WorkspaceOffboardModal from './workspace-offboard-modal'
import WorkspaceOffboardRescheduleModal from './workspace-offboard-reschedule-modal'

interface WorkspaceOffboardProps {
  workspace: Workspace
}

export const MIN_GRACE_PERIOD = 0
export const MIN_GRACE_PERIOD_DATA_CLEANUP = 7
export const MAX_GRACE_PERIOD = 60
export const MAX_GRACE_PERIOD_DATA_CLEANUP = 30

const WorkspaceOffboard = ({ workspace }: WorkspaceOffboardProps) => {
  const userInfo = useAuthUser()
  const [gracePeriod, setGracePeriod] = useState(30)
  const {
    workspaceOffboardJobs,
    isLoadingWorkspaceOffboardJobs,
    refetchWorkspaceOffboardJobs,
  } = useWorkspaceOffboardJobs(workspace.id)

  const validGracePeriod =
    gracePeriod >= MIN_GRACE_PERIOD && gracePeriod <= MAX_GRACE_PERIOD

  const allowToTriggerOffboard =
    userInfo.IsInternalAdminWriter && !isLoadingWorkspaceOffboardJobs
  const hasJobs =
    !_.isNil(workspaceOffboardJobs?.jobs) &&
    workspaceOffboardJobs.jobs.length > 0
  const offboardJobsActiveOrCompleted =
    hasJobs &&
    workspaceOffboardJobs.jobs.some((job) => job.jobStatus !== 'CANCELLED')
  const offboardFullyCompleted =
    hasJobs &&
    workspaceOffboardJobs.jobs.every((job) => job.jobStatus === 'COMPLETED')

  const triggerButtonDisabled = !allowToTriggerOffboard || !validGracePeriod
  const [selectedJob, setSelectedJob] = useState<WorkspaceOffboardJob>()
  const [offboardModalOpen, setOffboardModalOpen] = useState(false)
  const [offboardCancelModalOpen, setOffboardCancelModalOpen] = useState(false)
  const [offboardRescheduleModalOpen, setOffboardRescheduleModalOpen] =
    useState(false)

  const handleGracePeriodChange = (value: string) => {
    const parsedValue = parseInt(value, 10)
    if (value === '') {
      setGracePeriod(0)
    } else if (isNaN(parsedValue)) {
      displayErrorMessage('Grace period must be a number')
      setGracePeriod(30)
    } else if (
      parsedValue < MIN_GRACE_PERIOD ||
      parsedValue > MAX_GRACE_PERIOD
    ) {
      displayErrorMessage(
        `Grace period must be between ${MIN_GRACE_PERIOD} and ${MAX_GRACE_PERIOD}`
      )
      setGracePeriod(30)
    } else {
      setGracePeriod(parsedValue)
    }
  }

  const offboardStatus = () => {
    if (!hasJobs) {
      return null
    }

    return (
      <>
        <WorkspaceOffboardCancelModal
          open={offboardCancelModalOpen}
          workspaceId={workspace.id}
          onOpenChange={(open) => {
            setOffboardCancelModalOpen(open)
          }}
          onCancelSuccess={refetchWorkspaceOffboardJobs}
        />
        <WorkspaceOffboardRescheduleModal
          open={offboardRescheduleModalOpen}
          workspaceId={workspace.id}
          onOpenChange={(open) => {
            setOffboardRescheduleModalOpen(open)
          }}
          onRescheduleSuccess={refetchWorkspaceOffboardJobs}
          selectedJob={selectedJob}
        />

        <div className="flex-col space-y-3">
          {workspaceOffboardJobs.jobs
            .filter((job) => job.jobStatus !== 'CANCELLED')
            .map((job) => (
              <>
                <div>
                  <Label>
                    <b>Job Type</b>: {job.jobType}
                  </Label>
                </div>
                <div>
                  <Label>
                    <b>Offboard status</b>: {job.jobStatus}
                  </Label>
                </div>
                <div>
                  <Label>
                    <b>Initiated at</b>: {job.jobCreatedAt}
                  </Label>
                </div>
                <div>
                  <Label>
                    <b>Scheduled at</b>: {job.jobAttemptNext}
                  </Label>
                </div>
                <div className="mt-4 flex items-center space-x-3">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setSelectedJob(job)
                      setOffboardRescheduleModalOpen(true)
                    }}
                    disabled={
                      !allowToTriggerOffboard || job.jobStatus !== 'QUEUED'
                    }
                  >
                    Reschedule
                  </Button>
                </div>
                <Separator />
              </>
            ))}
          <Button
            variant="destructive"
            onClick={() => setOffboardCancelModalOpen(true)}
            disabled={!allowToTriggerOffboard || offboardFullyCompleted}
          >
            Cancel all offboarding jobs
          </Button>
        </div>
      </>
    )
  }

  const onOffboardClick = () => {
    if (gracePeriod < MIN_GRACE_PERIOD || gracePeriod > MAX_GRACE_PERIOD) {
      displayErrorMessage(
        `Grace period must be between ${MIN_GRACE_PERIOD} and ${MAX_GRACE_PERIOD}`
      )
      return
    }
    setOffboardModalOpen(true)
  }

  return (
    <>
      <WorkspaceOffboardModal
        open={offboardModalOpen}
        workspaceId={workspace.id}
        gracePeriod={gracePeriod}
        onOpenChange={(open) => {
          setOffboardModalOpen(open)
        }}
        onOffboardSuccess={refetchWorkspaceOffboardJobs}
      />
      <Card>
        {isLoadingWorkspaceOffboardJobs && 'Loading…'}
        {!isLoadingWorkspaceOffboardJobs &&
          (offboardJobsActiveOrCompleted ? (
            offboardStatus()
          ) : (
            <div>
              <div>
                <p>
                  Queue a job to start offboarding process for this workspace.
                  Schedule a job for {MIN_GRACE_PERIOD} to {MAX_GRACE_PERIOD}{' '}
                  grace period days from today. Setting this job will block user
                  and API access at the end of the grace period for all users of{' '}
                  {workspace.clientName}. Afterwards, another job will be
                  scheduled to delete workspace users, permissions and data.
                </p>
              </div>
              <div className="mb-4 mt-4 flex items-center space-x-3 p-2 pl-1">
                <p className="text-stone-500">Grace period days:</p>
                <Input
                  className="h-8 w-48"
                  value={gracePeriod}
                  onChange={(e) => handleGracePeriodChange(e.target.value)}
                />
                <Button
                  onClick={onOffboardClick}
                  disabled={triggerButtonDisabled}
                >
                  Schedule Offboard
                </Button>
              </div>
            </div>
          ))}
      </Card>
    </>
  )
}

export default WorkspaceOffboard
