import { useShallow } from 'zustand/react/shallow'

import useHarveySocket from 'utils/use-harvey-socket'

import { useSocketCompletedCallback } from 'components/assistant/hooks/use-assistant-socket-completed-callback'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'

export type AssistantWorkflowStreamHandler = {
  sendWorkflowChat: (params: {
    eventId: string | null
    workflowId: string
    workflowStepId: string
    data: any
  }) => void
  closeSocket: () => void
}

export const useAssistantWorkflowStreamHandler =
  (): AssistantWorkflowStreamHandler => {
    const [workflowSetter] = useAssistantWorkflowStore(
      useShallow((s) => [s.workflowSetter])
    )
    const { socketCompletedCallback } = useSocketCompletedCallback()

    const socket = useHarveySocket({
      path: 'assistant/workflow',
      setter: workflowSetter,
      endCallback: socketCompletedCallback,
      closeOnUnmount: false,
    })

    const sendWorkflowChat = async (params: {
      eventId: string | null
      workflowId: string
      workflowStepId: string
      data: any
    }) => {
      const { eventId, workflowId, workflowStepId, data } = params
      const req: any = {
        query: '',
        additionalRequestParams: {
          workflow_step_id: workflowStepId,
          workflow_step_data: data,
        },
        additionalAuthParams: {
          workflow_id: workflowId,
        },
      }

      if (eventId) {
        req['additionalAuthParams']['event_id'] = eventId
      }

      socket.initSocketAndSendQuery(req)
    }

    const closeSocket = () => {
      socket.sendCancelRequest()
    }

    return { sendWorkflowChat, closeSocket }
  }
