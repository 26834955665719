/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SourceBadge } from './SourceBadge';
import {
    SourceBadgeFromJSON,
    SourceBadgeFromJSONTyped,
    SourceBadgeToJSON,
    SourceBadgeToJSONTyped,
} from './SourceBadge';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
    SourceToJSONTyped,
} from './Source';

/**
 * 
 * @export
 * @interface PdfKitSource
 */
export interface PdfKitSource extends Source {
    /**
     * 
     * @type {Array<object>}
     * @memberof PdfKitSource
     */
    annotations: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof PdfKitSource
     */
    page: number;
    /**
     * 
     * @type {string}
     * @memberof PdfKitSource
     */
    documentUrl?: string;
}

/**
 * Check if a given object implements the PdfKitSource interface.
 */
export function instanceOfPdfKitSource(value: object): value is PdfKitSource {
    if (!('annotations' in value) || value['annotations'] === undefined) return false;
    if (!('page' in value) || value['page'] === undefined) return false;
    return true;
}

export function PdfKitSourceFromJSON(json: any): PdfKitSource {
    return PdfKitSourceFromJSONTyped(json, false);
}

export function PdfKitSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfKitSource {
    if (json == null) {
        return json;
    }
    return {
        ...SourceFromJSONTyped(json, true),
        'annotations': json['annotations'],
        'page': json['page'],
        'documentUrl': json['document_url'] == null ? undefined : json['document_url'],
    };
}

export function PdfKitSourceToJSON(json: any): PdfKitSource {
    return PdfKitSourceToJSONTyped(json, false);
}

export function PdfKitSourceToJSONTyped(value?: PdfKitSource | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        ...SourceToJSONTyped(value, true),
        'annotations': value['annotations'],
        'page': value['page'],
        'document_url': value['documentUrl'],
    };
}

