/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SensitiveUserPermission = {
    ADMINORGANIZATION: 'admin:organization',
    ADMINTERRITORY: 'admin:territory',
    READALL_WORKSPACES: 'read:all_workspaces',
    WRITEALL_WORKSPACES: 'write:all_workspaces',
    MANAGEUSERS: 'manage:users',
    READALL_SENSITIVE: 'read:all_sensitive',
    READSOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    CLIENT_ADMINREMOVE_USERS: 'client_admin:remove_users',
    CLIENT_ADMINADD_USERS: 'client_admin:add_users',
    READWORKSPACE_HISTORY: 'read:workspace_history',
    READWORKSPACE_VAULT: 'read:workspace_vault',
    DELETEWORKSPACE_HISTORY: 'delete:workspace_history',
    DELETEWORKSPACE_HISTORY_BULK: 'delete:workspace_history_bulk',
    CREATEHARVEY_LIBRARY_ITEMS: 'create:harvey_library_items',
    READENG_INTERNAL: 'read:eng_internal',
    WRITEENG_INTERNAL: 'write:eng_internal'
} as const;
export type SensitiveUserPermission = typeof SensitiveUserPermission[keyof typeof SensitiveUserPermission];


export function instanceOfSensitiveUserPermission(value: any): boolean {
    for (const key in SensitiveUserPermission) {
        if (Object.prototype.hasOwnProperty.call(SensitiveUserPermission, key)) {
            if (SensitiveUserPermission[key as keyof typeof SensitiveUserPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SensitiveUserPermissionFromJSON(json: any): SensitiveUserPermission {
    return SensitiveUserPermissionFromJSONTyped(json, false);
}

export function SensitiveUserPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensitiveUserPermission {
    return json as SensitiveUserPermission;
}

export function SensitiveUserPermissionToJSON(value?: SensitiveUserPermission | null): any {
    return value as any;
}

export function SensitiveUserPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): SensitiveUserPermission {
    return value as SensitiveUserPermission;
}

