import React, { useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { ArrowLeft } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage } from 'utils/toast'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { BaseAppPath } from 'components/base-app-path'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DialogHeader, DialogTitle } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  projectsPath,
  queriesPath,
  QueryQuestion,
  REMOVE_PARAMS,
} from 'components/vault/utils/vault'
import {
  getSelectedFiles,
  columnToQueryQuestion,
  runReviewQuery,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

/**
 * Dialog contents for adding files to an existing or new blank workflow
 */
const VaultWorkflowAddFiles = ({
  projectId,
  onBackButtonClicked,
  buttonLabel,
  preselectFileIds,
  handleSubmitAssistant,
  assistantMode,
}: {
  projectId: string
  onBackButtonClicked: () => void
  buttonLabel: string
  preselectFileIds: Set<string>
  handleSubmitAssistant?: (
    selectedFileIds: string[],
    mode: AssistantMode
  ) => void
  assistantMode?: AssistantMode
}) => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const { trackEvent } = useAnalytics()

  const [currentProject, currentProjectMetadata, folderIdToVaultFolder] =
    useVaultStore(
      useShallow((state) => [
        state.currentProject,
        state.currentProjectMetadata,
        state.folderIdToVaultFolder,
      ])
    )

  const [
    showDocClassificationMismatchWarning,
    selectedWorkflow,
    selectedFilesForWorkflow,
    setWorkflowModalState,
    setSelectedFilesForWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.showDocClassificationMismatchWarning,
      state.selectedWorkflow,
      state.selectedFilesForWorkflow,
      state.setWorkflowModalState,
      state.setSelectedFilesForWorkflow,
    ])
  )

  const [
    gridApi,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
    setWorkflow,
    setQueryId,
    setIsRunButtonLoading,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.gridApi,
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
      state.setWorkflow,
      state.setQueryId,
      state.setIsRunButtonLoading,
    ])
  )
  const [reviewQuestionsPerQueryLimit, reviewFilesPerQueryLimit] =
    useVaultUsageStore(
      useShallow((s) => [
        s.reviewQuestionsPerQueryLimit,
        s.reviewFilesPerQueryLimit,
      ])
    )
  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const selectedFiles = getSelectedFiles(selectedFilesForWorkflow)
    const selectedFileIds = selectedFiles.map((file) => file.id)
    if (selectedFileIds.length === 0) {
      displayErrorMessage('No files selected for query')
      setIsSubmitting(false)
      return
    }

    if (handleSubmitAssistant && assistantMode) {
      handleSubmitAssistant(selectedFileIds, assistantMode)
      setWorkflowModalState(WorkflowModalState.None)
      setIsSubmitting(false)
      return
    }

    if (selectedWorkflow) {
      trackEvent('Vault Workflow Confirmed', {
        workflow_id: selectedWorkflow.id,
        workflow_name: selectedWorkflow.name,
        ...(showDocClassificationMismatchWarning && {
          mismatched_documents: true,
        }),
      })

      setWorkflow(selectedWorkflow)
      const pendingQueryFileIds = selectedFileIds
      const pendingQueryQuestions = selectedWorkflow.columns
        .toSorted((a, b) => a.displayId - b.displayId)
        .map(columnToQueryQuestion) as QueryQuestion[]
      const clientMatterId: string | undefined =
        userInfo.IsVaultProjectClientMatterUser
          ? currentProjectMetadata.clientMatterId
          : userInfo.isClientMattersReadUser
          ? selectedClientMatter?.id
          : undefined

      const queryId = await runReviewQuery({
        currentProject,
        currentProjectMetadata,
        folderIdToVaultFolder,
        gridApi,
        hasSelectedFiles: false,
        hasNewFilesToRun: true,
        hasNewQuestionsToRun: true,
        hasEmptyCells: false,
        selectedRows: [],
        pendingQueryFileIds,
        pendingQueryQuestions,
        workflow: selectedWorkflow,
        queryId: '',
        query: selectedWorkflow.name,
        isNewQuery: true,
        isRetry: false,
        allQueryFileIds: pendingQueryFileIds,
        allQueryQuestions: pendingQueryQuestions,
        questionsLimit: reviewQuestionsPerQueryLimit,
        filesLimit: reviewFilesPerQueryLimit,
        clientMatterId,
        setIsRunButtonLoading,
        recordQuerySubmitted,
        setQueryId,
        setPendingQueryFileIds,
        setPendingQueryQuestions,
        setWorkflow,
        clearSelectedRows: () => {},
        queryClient,
        hasToFetchQuery: true,
      })
      setIsSubmitting(false)
      if (queryId) {
        const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${queryId}`
        navigate(newPath, {}, REMOVE_PARAMS)
        setWorkflowModalState(WorkflowModalState.None)
      }
    } else {
      trackEvent('Vault New Query Submitted')
      setPendingQueryFileIds(selectedFileIds)
      setIsSubmitting(false)
      navigate(
        `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}new`
      )
      setWorkflowModalState(WorkflowModalState.None)
    }
  }

  const numSelectedFiles = useMemo(() => {
    const selectedFiles = getSelectedFiles(selectedFilesForWorkflow)
    return selectedFiles.length
  }, [selectedFilesForWorkflow])

  return (
    <>
      <DialogHeader className="gap-4 px-5 pt-4">
        <Button
          variant="ghost"
          onClick={onBackButtonClicked}
          className="absolute left-2 top-2"
        >
          <Icon icon={ArrowLeft} />
        </Button>
        <DialogTitle className="w-full text-center">Choose files</DialogTitle>
        <div className="-mx-5 border-b" />
      </DialogHeader>
      <VaultFileExplorer
        isAddingFilesToQuery
        selectedRows={selectedFilesForWorkflow}
        showDocumentClassificationMismatchWarning={
          showDocClassificationMismatchWarning
        }
        setSelectedRows={setSelectedFilesForWorkflow}
        preselectFileIds={preselectFileIds}
        projectId={projectId}
        className="flex min-h-0 grow flex-col rounded-none"
      />
      <div className="flex items-center justify-between gap-2 px-5 pb-4">
        <p className="text-xs text-muted">{numSelectedFiles} files selected</p>
        <AskHarveyButton
          buttonTitle={buttonLabel}
          disabled={selectedFilesForWorkflow.length === 0 || isSubmitting}
          tooltip={
            selectedFilesForWorkflow.length === 0
              ? 'You must select at least one file'
              : undefined
          }
          isLoading={isSubmitting}
          handleSubmit={handleSubmit}
          className="w-fit"
        />
      </div>
    </>
  )
}

export default VaultWorkflowAddFiles
