import React, { useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { SafeRecord } from 'utils/safe-types'

import { RenderWorkflowComponent } from './assistant-block-renderer'
import { useAssistantWorkflowStore } from './stores/assistant-workflow'
import {
  getInputParamsForBlock,
  stepBlockIdToWorkflowBlockDefinition,
} from './utils'

export const useAssistantWorkflowComponents = (
  handleCurrentStepCompletion: (stepId: string, result: any) => void
) => {
  const [workflowDefinition, currentEvent] = useAssistantWorkflowStore(
    useShallow((state) => [state.workflowDefinition, state.currentEvent])
  )

  const completedData = useMemo(() => {
    // go through all the steps in currentEvent and create a dictionary of outputLabel to outputData
    // it should be in the format of {outputLabel: [outputData, outputData, ...]}
    const completedDataDict: SafeRecord<string, any[]> = {}
    currentEvent?.steps.forEach((step) => {
      if (step.outputData && step.outputLabel) {
        if (!completedDataDict[step.outputLabel]) {
          completedDataDict[step.outputLabel] = []
        }
        completedDataDict[step.outputLabel]!.push(step.outputData)
      }
    })
    return completedDataDict
  }, [currentEvent])

  const componentsToRender = useMemo(() => {
    const ThreadComponents: React.ReactNode[] = []
    let InputComponent: React.ReactNode | null = null

    // Completed steps
    // for (const step of currentEvent?.steps || []) {
    let stepIdx = 0
    for (; stepIdx < (currentEvent?.steps?.length || 0); stepIdx++) {
      const step = currentEvent!.steps[stepIdx]

      const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
        workflowDefinition,
        step.workflowBlockId
      )

      const blockParams = getInputParamsForBlock(blockDefinition, completedData)

      ThreadComponents.push(
        <RenderWorkflowComponent
          key={step.id}
          stepIdx={stepIdx}
          blockType={blockDefinition.blockId as any}
          blockParams={blockParams as any}
          outputData={step.outputData as any}
          renderType="thread"
          onCompleted={(result: unknown) =>
            handleCurrentStepCompletion(step.id, result)
          }
        />
      )
    }

    // Current step
    // TODO: If this is input, we don't want to render it until the previous step is completed
    // But if it's a render step, we should render it immediately?
    const currentDefinition = workflowDefinition?.steps[stepIdx]
    if (currentDefinition) {
      const blockParams: any = getInputParamsForBlock(
        currentDefinition,
        completedData
      )

      ThreadComponents.push(
        <RenderWorkflowComponent
          key={currentDefinition.id}
          stepIdx={stepIdx}
          blockType={currentDefinition.blockId as any}
          blockParams={blockParams}
          outputData={null}
          renderType="thread"
          onCompleted={(result: any) =>
            handleCurrentStepCompletion(currentDefinition.id, result)
          }
        />
      )

      InputComponent = (
        <RenderWorkflowComponent
          key={currentDefinition.id}
          stepIdx={stepIdx}
          blockType={currentDefinition.blockId as any}
          blockParams={blockParams}
          outputData={null}
          renderType="input"
          onCompleted={(result: any) =>
            handleCurrentStepCompletion(currentDefinition.id, result)
          }
        />
      )
    }

    return { InputComponent, ThreadComponents }
  }, [
    currentEvent,
    workflowDefinition,
    completedData,
    handleCurrentStepCompletion,
  ])
  return componentsToRender
}
