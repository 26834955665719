/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the access permission for a Vault folder and its queries and files.
 * @export
 */
export const VaultFolderAccessPermission = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    FULL_ACCESS: 'FULL_ACCESS'
} as const;
export type VaultFolderAccessPermission = typeof VaultFolderAccessPermission[keyof typeof VaultFolderAccessPermission];


export function instanceOfVaultFolderAccessPermission(value: any): boolean {
    for (const key in VaultFolderAccessPermission) {
        if (Object.prototype.hasOwnProperty.call(VaultFolderAccessPermission, key)) {
            if (VaultFolderAccessPermission[key as keyof typeof VaultFolderAccessPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function VaultFolderAccessPermissionFromJSON(json: any): VaultFolderAccessPermission {
    return VaultFolderAccessPermissionFromJSONTyped(json, false);
}

export function VaultFolderAccessPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): VaultFolderAccessPermission {
    return json as VaultFolderAccessPermission;
}

export function VaultFolderAccessPermissionToJSON(value?: VaultFolderAccessPermission | null): any {
    return value as any;
}

export function VaultFolderAccessPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): VaultFolderAccessPermission {
    return value as VaultFolderAccessPermission;
}

