import { WorkflowEvent } from 'openapi/models/WorkflowEvent'
import Services from 'services'

import { WorkflowDefinition, WorkflowListItem } from './types'

export const loadWorkflow = async (workflowId: string, eventId?: string) => {
  const route = `assistant/workflows/${workflowId}${
    eventId ? `/${eventId}` : ''
  }`
  return Services.Backend.Get<{
    workflowDefinition: WorkflowDefinition
    workflowEvent: WorkflowEvent | null
  }>(route)
}

export const loadWorkflows = async () => {
  const route = `assistant/workflows`
  return Services.Backend.Get<WorkflowListItem[]>(route)
}
