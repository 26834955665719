import React from 'react'

import Banner from 'components/ui/banner'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

interface Props {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  onUpdateSubmit: () => void
  isUpdating: boolean
}

const WorkspaceDataPrivacyUpdateModal: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  onUpdateSubmit,
  isUpdating,
}) => {
  return (
    <Dialog
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open)
      }}
    >
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">Updating workspace data privacy</p>
          <Banner
            title="Are you sure?"
            description={
              <p>
                This will permanently delete or move queries and any associated
                documents. If deleted, they cannot be recovered.
              </p>
            }
            className="mt-4"
          />
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isUpdating}
              onClick={onUpdateSubmit}
              className="*:text-sm"
            >
              {isUpdating ? (
                <div className="flex items-center">
                  <Spinner size="xxs" className="top-3 mr-2" />
                  Updating…
                </div>
              ) : (
                <span>Update</span>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceDataPrivacyUpdateModal
