/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractsSmartFilterUserInput
 */
export interface ContractsSmartFilterUserInput {
    /**
     * 
     * @type {string}
     * @memberof ContractsSmartFilterUserInput
     */
    contractType: string;
    /**
     * 
     * @type {string}
     * @memberof ContractsSmartFilterUserInput
     */
    term: string;
    /**
     * Only the users text
     * @type {string}
     * @memberof ContractsSmartFilterUserInput
     */
    userQuery: string;
}

/**
 * Check if a given object implements the ContractsSmartFilterUserInput interface.
 */
export function instanceOfContractsSmartFilterUserInput(value: object): value is ContractsSmartFilterUserInput {
    if (!('contractType' in value) || value['contractType'] === undefined) return false;
    if (!('term' in value) || value['term'] === undefined) return false;
    if (!('userQuery' in value) || value['userQuery'] === undefined) return false;
    return true;
}

export function ContractsSmartFilterUserInputFromJSON(json: any): ContractsSmartFilterUserInput {
    return ContractsSmartFilterUserInputFromJSONTyped(json, false);
}

export function ContractsSmartFilterUserInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsSmartFilterUserInput {
    if (json == null) {
        return json;
    }
    return {
        
        'contractType': json['contract_type'],
        'term': json['term'],
        'userQuery': json['user_query'],
    };
}

export function ContractsSmartFilterUserInputToJSON(json: any): ContractsSmartFilterUserInput {
    return ContractsSmartFilterUserInputToJSONTyped(json, false);
}

export function ContractsSmartFilterUserInputToJSONTyped(value?: ContractsSmartFilterUserInput | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'contract_type': value['contractType'],
        'term': value['term'],
        'user_query': value['userQuery'],
    };
}

