import React, { useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import _ from 'lodash'
import { AlertCircle, InfoIcon } from 'lucide-react'
import Papa from 'papaparse'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { Maybe } from 'types'
import { FileType } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { createAcceptObject, mbToBytes } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { isBooleanLike } from 'utils/utils'

import {
  CLIENT_MATTER_SETTINGS_ADD_METRIC,
  CLIENT_MATTER_SETTINGS_BULK_ADD_METRIC,
  ClientMatterAdd,
  MAX_CLIENT_MATTER_DESC_LENGTH,
  MAX_CLIENT_MATTER_NAME_LENGTH,
} from 'components/client-matters/client-matter-utils'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { Dropzone } from 'components/common/dropzone/dropzone'
import { useSettingsState } from 'components/settings/settings-store'
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import CsvDownload from 'components/ui/csv-download'
import { Dialog, DialogContent } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Switch } from 'components/ui/switch'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

const MAX_FILES = 1
const MAX_FILE_SIZE_MB = 1
const ACCEPTED_FILE_TYPES = [FileType.CSV, FileType.TEXT]
const EXAMPLE_CM_CSV_DATA = [
  ['12345', 'Acme'],
  ['56789', 'Harvey'],
]
const EXAMPLE_DISALLOWED_CM_CSV_DATA = [
  ['12345', 'true', 'Acme'],
  ['56789', 'false', 'Harvey'],
]

interface ClientMattersAddEditDialogProps {
  clientMatterModalOpen: boolean
  setClientMatterModalOpen: (open: boolean) => void
  onCmAdd: (
    clientMatters: ClientMatterAdd[],
    successCallback: () => void,
    metricName: string
  ) => void
  onCmEdit: (
    editedCmName: string,
    editedCmDesc: Maybe<string>,
    editedCmAllowed: boolean
  ) => void
  editCmName: string
  editCmDesc: Maybe<string>
  cmAllowed: boolean
}

const ClientMattersAddEditDialog: React.FC<ClientMattersAddEditDialogProps> = ({
  clientMatterModalOpen,
  setClientMatterModalOpen,
  onCmAdd,
  onCmEdit,
  editCmName = '',
  editCmDesc = '',
  cmAllowed = true,
}) => {
  const settingsUser = useSettingsState((s) => s.settingsUser)
  const [dropzoneLoading, setDropzoneLoading] = useState(false)
  const [bulkClientMattersLength, setBulkClientMattersLength] =
    useState<number>(0)
  const [bulkClientMattersToAdd, setBulkClientMattersToAdd] = useState<
    ClientMatterAdd[]
  >([])
  const [duplicateClientMatters, setDuplicateClientMatters] =
    useState<number>(0)
  const [singleClientMatter, setSingleClientMatter] =
    useState<string>(editCmName)
  const [singleClientMatterDesc, setSingleClientMatterDesc] = useState<string>(
    editCmDesc ?? ''
  ) // empty desc is stored as null in backend
  const [singleClientMatterError, setSingleClientMatterError] =
    useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const { clientMatters: clientMattersData } = useClientMattersStore(
    useShallow((s) => ({
      clientMatters: s.clientMatters,
    }))
  )
  const [cmAllowedToggle, setCmAllowedToggle] = useState<boolean>(cmAllowed)
  const [bulkAddDuplicateExists, setBulkAddDuplicateExists] =
    useState<boolean>(false)

  const isEditing = !_.isEmpty(editCmName) || !_.isEmpty(editCmDesc)

  const hasDisallowedCmPerm = settingsUser?.isDisallowClientMattersUser ?? false

  useEffect(() => {
    setSingleClientMatter(editCmName.trim())
    setSingleClientMatterDesc((editCmDesc ?? '').trim())
    setCmAllowedToggle(cmAllowed)
  }, [editCmName, editCmDesc, cmAllowed])

  const onFileDrop = async (files: File[]) => {
    if (files.length !== 1) {
      displayErrorMessage('Please upload a single CSV or Text file')
      return
    }
    setDropzoneLoading(true)
    const fileReader = new FileReader()
    fileReader.readAsText(files[0], 'UTF-8')
    fileReader.onload = function (evt) {
      const file = evt?.target?.result as string
      if (file) {
        Papa.parse(file, {
          complete: function (results) {
            let hasNullAllowed = false

            const clientMatters = []
            const newCmSet = new Set<string>()

            for (const value of results.data) {
              let cmAllowed: boolean | null = true
              let clientMatter: string
              let description: string
              let allowed: string

              if (!hasDisallowedCmPerm) {
                ;[clientMatter, description] = value as [string, string]
              } else {
                ;[clientMatter, allowed, description] = value as [
                  string,
                  string,
                  string,
                ]

                // parse allowed column if present, default to true if not present
                if (!_.isEmpty(allowed)) {
                  const parsedAllowed = isBooleanLike(allowed)
                  if (_.isNil(parsedAllowed)) {
                    hasNullAllowed = true
                    cmAllowed = true
                  } else {
                    cmAllowed = parsedAllowed
                  }
                }
              }

              if (!_.isEmpty(clientMatter.trim())) {
                const trimmedCm = clientMatter.trim()
                if (newCmSet.has(trimmedCm)) {
                  setBulkAddDuplicateExists(true)
                } else {
                  newCmSet.add(trimmedCm)
                  clientMatters.push({
                    cmName: trimmedCm,
                    cmDesc: description ? description.trim() : null,
                    cmAllowed,
                  })
                }
              }
            }

            // Retrieve existing client matter names for comparison
            const existingCmNames = (clientMattersData ?? []).map(
              (cm) => cm.name
            )

            // Partition client matters into duplicates and uniques
            const [duplicateClientMatters, uniqueClientMatters] = _.partition(
              clientMatters,
              (cm) => existingCmNames.includes(cm.cmName)
            )

            // Check if any unique client matter has cmAllowed set to null
            if (hasNullAllowed && hasDisallowedCmPerm) {
              displayErrorMessage(
                'Some rows have invalid values for allowed column. Please use true/t/1 or false/f/0 for the second column.'
              )
              setDropzoneLoading(false)
              return
            }

            // Update the state accordingly
            setDuplicateClientMatters(duplicateClientMatters.length)
            setBulkClientMattersToAdd(uniqueClientMatters)
            setBulkClientMattersLength(clientMatters.length)
          },
          header: false, // assuming no headers
          skipEmptyLines: true,
        })
      }
    }
    setUploadedFile(files[0])
    setDropzoneLoading(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: createAcceptObject(ACCEPTED_FILE_TYPES),
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) =>
      onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: 0,
        maxFiles: MAX_FILES,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE_MB),
        handleAcceptedFiles: onFileDrop,
      }),
  })

  useEffect(() => {
    if (isEditing && singleClientMatter === editCmName) {
      setSingleClientMatterError(false)
    } else {
      const clientMatterExists = (
        _.isEmpty(clientMattersData) ? [] : clientMattersData
      )
        .map((cm) => cm.name)
        .some((cm) => cm === singleClientMatter)
      setSingleClientMatterError(clientMatterExists)
    }
  }, [singleClientMatter, clientMattersData, editCmName, isEditing])

  const cleanBulkState = () => {
    setBulkClientMattersLength(0)
    setUploadedFile(null)
    setDuplicateClientMatters(0)
    setBulkAddDuplicateExists(false)
  }

  const onSingleSubmit = () => {
    const cleanCmName = singleClientMatter.trim()
    const cleanCmDesc = singleClientMatterDesc.trim()
    if (isEditing) {
      onCmEdit(cleanCmName, cleanCmDesc, cmAllowedToggle)
    } else {
      onCmAdd(
        [
          {
            cmName: cleanCmName,
            cmDesc: cleanCmDesc,
            cmAllowed: cmAllowedToggle,
          },
        ],
        () => {
          setSingleClientMatter('')
          setSingleClientMatterDesc('')
        },
        CLIENT_MATTER_SETTINGS_ADD_METRIC
      )
    }
  }

  const getAddEditButtonDisabled = useCallback((): boolean => {
    const cleanSingleCm = singleClientMatter.trim()
    const cleanSingleDesc = singleClientMatterDesc.trim()

    const noChanges =
      cleanSingleCm === editCmName &&
      cleanSingleDesc === editCmDesc &&
      cmAllowedToggle === cmAllowed

    const ret =
      cleanSingleCm.length === 0 || singleClientMatterError || noChanges

    return ret
  }, [
    singleClientMatter,
    singleClientMatterDesc,
    singleClientMatterError,
    editCmName,
    editCmDesc,
    cmAllowedToggle,
    cmAllowed,
  ])

  const confirmButtonText = isEditing ? 'Edit' : 'Add'

  const singleClientMatterAddContent = (
    <div className="mt-4">
      <div className="mt-4 flex flex-col">
        <Label htmlFor="single-client-matter-id">Client matter #</Label>
        <Input
          id="single-client-matter-id"
          type="text"
          className="mt-2 w-full rounded-md border px-4 py-2"
          value={singleClientMatter}
          onChange={(e) => setSingleClientMatter(e.target.value)}
          maxLength={MAX_CLIENT_MATTER_NAME_LENGTH}
          placeholder="12345-56789"
          description={
            singleClientMatterError
              ? {
                  label: `Client matter ${singleClientMatter} already exists`,
                  variant: 'error',
                  leadingIcon: (
                    <AlertCircle
                      size="12"
                      className="mr-1 mt-px stroke-destructive"
                    />
                  ),
                }
              : undefined
          }
        />
      </div>
      <div className="mt-3">
        <Label htmlFor="single-client-matter-description">Description</Label>
        <Input
          id="single-client-matter-description"
          type="text"
          className="mt-1 w-full rounded-md border px-4 py-2"
          value={singleClientMatterDesc}
          onChange={(e) => setSingleClientMatterDesc(e.target.value)}
          maxLength={MAX_CLIENT_MATTER_DESC_LENGTH}
          placeholder="Acme Merger"
        />
      </div>
      {hasDisallowedCmPerm && (
        <div className="mt-4 flex flex-row items-center justify-between space-x-2">
          <div className="flex items-center space-x-1">
            <Label>Allowed</Label>
            <Tooltip delayDuration={200}>
              <TooltipTrigger className="pb-0.5">
                <Icon
                  icon={InfoIcon}
                  size="small"
                  className="cursor-pointer "
                />
              </TooltipTrigger>
              <TooltipContent className="max-w-sm text-start">
                Only allowed client matters can be used to tag queries.
              </TooltipContent>
            </Tooltip>
          </div>
          <Switch
            checked={cmAllowedToggle}
            onCheckedChange={() => setCmAllowedToggle(!cmAllowedToggle)}
          />
        </div>
      )}
      <div className="mt-6 flex flex-row justify-end space-x-2">
        <Button variant="ghost" onClick={() => setClientMatterModalOpen(false)}>
          Cancel
        </Button>
        <Button onClick={onSingleSubmit} disabled={getAddEditButtonDisabled()}>
          {confirmButtonText} client matter
        </Button>
      </div>
    </div>
  )

  const onOpenChange = (open: boolean) => {
    setClientMatterModalOpen(open)
    cleanBulkState()
  }

  return (
    <Dialog open={clientMatterModalOpen} onOpenChange={onOpenChange}>
      <DialogContent aria-label="Add client matters" showCloseIcon={false}>
        {settingsUser?.isClientMattersManagementUser && !isEditing ? (
          <div className="flex flex-col space-y-4">
            <Tabs defaultValue="single">
              <div className="flex flex-row items-center justify-between">
                <h2 className="text-xl">Add client matters</h2>
                <TabsList>
                  <TabsTrigger value="single">Single</TabsTrigger>
                  <TabsTrigger value="bulk">Bulk</TabsTrigger>
                </TabsList>
              </div>
              <TabsContent value="single">
                {singleClientMatterAddContent}
              </TabsContent>
              <TabsContent value="bulk">
                <div className="mt-4 space-y-4">
                  <p>
                    Upload a CSV or Text file containing a list of client
                    matters and their descriptions. Entries should be one per
                    line with no header columns, formatted as below:
                    <br />
                    <span className="font-semibold">
                      client matter #
                      {hasDisallowedCmPerm ? ', allowed (true/false)' : ''},
                      description (optional)
                    </span>
                  </p>
                  <div className="space-y-2">
                    {!uploadedFile ? (
                      <Dropzone
                        isLoading={dropzoneLoading}
                        dropzone={{ getRootProps, getInputProps }}
                        description="Max 1MB file or 10k client matters"
                      />
                    ) : (
                      <div className="flex flex-col space-y-3">
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-col space-y-1">
                            <p>File name</p>
                            <p className="text-muted">{uploadedFile.name}</p>
                          </div>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              cleanBulkState()
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                        <div className="flex flex-row justify-between">
                          <div className="space-y-1">
                            <p>Client matters parsed</p>
                            <p className="text-muted">
                              {bulkClientMattersLength}
                            </p>
                          </div>
                        </div>
                        {duplicateClientMatters > 0 && (
                          <div className="flex flex-row justify-between">
                            <div className="space-y-1">
                              <p>Duplicate existing client matters</p>
                              <p className="text-muted">
                                {duplicateClientMatters}
                              </p>
                            </div>
                          </div>
                        )}
                        {bulkAddDuplicateExists && (
                          <div className="flex flex-row justify-between">
                            <Alert variant="destructive">
                              <AlertTitle>
                                Duplicate client matters in CSV
                              </AlertTitle>
                              <AlertDescription>
                                Some rows in the CSV file contain duplicate
                                client matters. Remove duplicates and try again.
                              </AlertDescription>
                            </Alert>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-between">
                    <CsvDownload
                      data={
                        hasDisallowedCmPerm
                          ? EXAMPLE_DISALLOWED_CM_CSV_DATA
                          : EXAMPLE_CM_CSV_DATA
                      }
                      buttonText="Example CSV"
                      filename="client-matters-example.csv"
                      hasIcon
                      className="w-fit"
                    />
                    <div className="justify-end space-x-2">
                      <Button
                        variant="ghost"
                        onClick={() => setClientMatterModalOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          onCmAdd(
                            bulkClientMattersToAdd,
                            () => {
                              cleanBulkState()
                            },
                            CLIENT_MATTER_SETTINGS_BULK_ADD_METRIC
                          )
                        }
                        disabled={
                          bulkClientMattersToAdd.length === 0 ||
                          bulkAddDuplicateExists
                        }
                      >
                        {bulkClientMattersLength > 0
                          ? `Add ${bulkClientMattersToAdd.length} ${pluralize(
                              'client matters',
                              bulkClientMattersToAdd.length
                            )}`
                          : `Add client matters`}
                      </Button>
                    </div>
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </div>
        ) : (
          <div>
            <p className="text-xl">{confirmButtonText} client matter</p>
            {singleClientMatterAddContent}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ClientMattersAddEditDialog
