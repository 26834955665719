import React, { useCallback, useEffect, useRef, useState } from 'react'

import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import 'driver.js/dist/driver.css'
import ky from 'ky'

import { FetchUserInfo, UserInfo } from 'models/user-info'
import Services from 'services'
import useTabFocusTracking from 'services/honey-comb/use-tab-focus-tracking'
import { loginCacheWrapper, logoutCacheWrapper } from 'stores/offline-db'
import ReactQueryWrapper from 'stores/react-query-wrapper'

import { setDatadogState } from 'utils/datadog'
import { backendRestUrl } from 'utils/server-data'

import AppRouter from 'components/app-router'
import ErrorPage from 'components/common/error/error'
import NotAuthorizedScreen from 'components/common/error/not-authorized-screen'
import LoadingScreen from 'components/common/loading-screen'

import './App.css'

function App(): JSX.Element {
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0()
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [userInfoFetched, setUserInfoFetched] = useState(false)
  const userInfoFetchInitialized = useRef(false)

  Services.Backend.AttachGetToken(async () => await getAccessTokenSilently())
  Services.Backend.AttachLogout(() =>
    logout({ returnTo: window.location.origin })
  )

  Sentry.setUser({
    username: userInfo?.nonDbId,
  })

  // track user activity when tab is focused
  useTabFocusTracking()

  setDatadogState(userInfo)

  const ensureAuth = useCallback(async (): Promise<void> => {
    if (user && !userInfoFetchInitialized.current) {
      // auth_case1: user is authN, try authZ
      const info = await FetchUserInfo(user)
      userInfoFetchInitialized.current = true
      setUserInfo(info)
      setUserInfoFetched(true)
      Services.HoneyComb.AttachUser(info)
    } else if (!isAuthenticated && !isLoading) {
      // auth_case2: user is not authN, and we are not loading. redirect to login

      const loginWithRedirectWrapper = async (
        options?: RedirectLoginOptions
      ): Promise<void> => {
        // HACK: Using react-router's useLocation() anywhere in this component causes
        // unknown side-effects that break our use-navigation hook. Its unclear why.
        // To fix this, and given that we don't actually need any reactivity here,
        // we just check the javascript property to determine if we are logging in via
        // a login slug.
        const location = window.location.pathname

        if (location.startsWith('/login/')) {
          const login_slug = location.substring('/login/'.length)
          return new Promise(
            (resolve: (options?: RedirectLoginOptions) => void) => {
              ky.post(backendRestUrl + '/login_slug', {
                json: { login_slug },
              })
                .text()
                .then((hint) =>
                  resolve({
                    login_hint: hint,
                    ...options,
                  })
                )
                .catch((error) => {
                  console.error(
                    'Failed fetching login slugs, falling back to regular login parameters',
                    error
                  )
                  resolve(options)
                })
            }
          ).then(loginWithRedirect)
        } else {
          return loginWithRedirect(options)
        }
      }

      await loginCacheWrapper(loginWithRedirectWrapper)
    }
    return
    // No Op cases here
    // auth_case3: if authN or authZ loading, we render loading screen below
    // auth_case4: user is authN but no authZ, we render unauthorized help screen below
    // auth_case5: user is authN and authZ, we render app in this case below
  }, [user, isAuthenticated, isLoading, loginWithRedirect]) // check the auth anytime user, authentication, or loading status changes

  useEffect(() => {
    ensureAuth().catch((e) => {
      Sentry.captureException(e)
      Services.HoneyComb.RecordError(e)
    })
  }, [ensureAuth])

  const logoutHandler = async () => {
    await logoutCacheWrapper(logout)
  }

  const isUserLoading = isLoading || !userInfoFetched // user is loading if either auth0 is loading or userInfoFetch was not yet attempted
  if (isUserLoading) {
    // UI_case1: user authN and authZ are still loading
    return <LoadingScreen />
  } else if (userInfo && userInfo.IsAuthorized) {
    return (
      // The component below is the root for authN users.
      // The userBuster invalidates the cache if the stored cache has a different value for the userBuster.
      // TODO(Adam): should we change what we use for the userBuster? For now we are using the id/email
      <ReactQueryWrapper userBuster={userInfo.id}>
        <AppRouter userInfo={userInfo} logoutHandler={logoutHandler} />
        {/* {isInternalUserInDev && <ReactQueryDevtools initialIsOpen={false} />} */}
      </ReactQueryWrapper>
    )
  } else if (userInfo && !userInfo.IsAuthorized) {
    // UI_case3: user is authN but authZ is empty
    return (
      <NotAuthorizedScreen logoutHandler={logoutHandler} userInfo={userInfo} />
    )
  } else {
    // UI_case4: some other error, catch all
    return <ErrorPage />
  }
}

export default App
