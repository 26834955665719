/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Internal Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetOnboardingRequest
 */
export interface ResetOnboardingRequest {
    /**
     * The email of the user to reset onboarding for
     * @type {string}
     * @memberof ResetOnboardingRequest
     */
    userEmail: string;
}

/**
 * Check if a given object implements the ResetOnboardingRequest interface.
 */
export function instanceOfResetOnboardingRequest(value: object): value is ResetOnboardingRequest {
    if (!('userEmail' in value) || value['userEmail'] === undefined) return false;
    return true;
}

export function ResetOnboardingRequestFromJSON(json: any): ResetOnboardingRequest {
    return ResetOnboardingRequestFromJSONTyped(json, false);
}

export function ResetOnboardingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetOnboardingRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userEmail': json['user_email'],
    };
}

  export function ResetOnboardingRequestToJSON(json: any): ResetOnboardingRequest {
      return ResetOnboardingRequestToJSONTyped(json, false);
  }

  export function ResetOnboardingRequestToJSONTyped(value?: ResetOnboardingRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'user_email': value['userEmail'],
    };
}

