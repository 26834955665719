import React from 'react'

import { InfoIcon } from 'lucide-react'
import pluralize from 'pluralize'

import {
  FileType,
  FileTypeReadableName,
  removeSubsetDuplicates,
} from 'types/file'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

type CustomFileType = {
  fileType: FileType
  maxSize: string
}

interface Props {
  children?: React.ReactNode
  className?: string
  customFileTypes?: CustomFileType[]
  fileTypes: FileType[]
  maxFiles?: number
  maxSize: string
  totalSize?: string
  descriptionClassName?: string
}

const DropzoneDescription = ({
  children,
  className,
  customFileTypes,
  fileTypes,
  maxFiles,
  maxSize,
  totalSize,
  descriptionClassName,
}: Props) => {
  return (
    <span className="flex flex-col items-center gap-1">
      <span className={cn('max-w-60', className)}>
        Supported file types:{' '}
        {removeSubsetDuplicates(
          fileTypes.map((fileType) => FileTypeReadableName[fileType])
        )
          .sort((a, b) => a.localeCompare(b))
          .join(', ')}
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              className="ml-1 shrink-0 align-top transition hover:bg-button-secondary-hover"
              size="xxsIcon"
              variant="ghost"
              aria-label="Upload limits"
            >
              <Icon icon={InfoIcon} size="small" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="text-left" side="right">
            Maximum size per file: {maxSize}
            {customFileTypes &&
              ` (${customFileTypes
                .map(
                  (fileType) =>
                    `${fileType.maxSize} for ${
                      FileTypeReadableName[fileType.fileType]
                    }`
                )
                .join(', ')})`}
            {(maxFiles || totalSize) && (
              <span className="block">
                Upload limit:{' '}
                {maxFiles && `${maxFiles} ${pluralize('file', maxFiles)}`}
                {totalSize && (
                  <>
                    {maxFiles && ', '}
                    {totalSize} total
                  </>
                )}
              </span>
            )}
          </TooltipContent>
        </Tooltip>
      </span>
      <span className={cn('max-w-80', descriptionClassName)}>{children}</span>
    </span>
  )
}

export default DropzoneDescription
