import React from 'react'

import { cn } from 'utils/utils'

import RouterBreadcrumbs from './router-breadcrumbs'
import StatusBar from './status-bar'

type Props = {
  title?: string
  titleComponent?: React.ReactNode
  subtitle?: string
  subtitleComponent?: React.ReactNode
  children?: React.ReactNode
  breadcrumbs?: React.ReactNode
  actions?: React.ReactNode
  isInternalAdmin?: boolean
}

export const AppHeader = ({
  breadcrumbs,
  title,
  titleComponent,
  subtitle,
  subtitleComponent,
  actions,
  isInternalAdmin,
  children,
}: Props) => {
  return (
    <>
      {!isInternalAdmin && <StatusBar />}
      <div
        className={cn('z-40 w-full shrink-0 border-b bg-primary py-4', {
          'mt-10': isInternalAdmin,
          'pb-0': children,
        })}
      >
        <div className="flex w-full items-center justify-between px-6">
          {breadcrumbs ? breadcrumbs : <RouterBreadcrumbs />}
        </div>
        <div className="flex w-full items-end justify-between px-6">
          <div>
            {titleComponent ? (
              titleComponent
            ) : (
              <h1 className="line-clamp-2 whitespace-pre-wrap text-xl font-medium">
                {title}
              </h1>
            )}
            {subtitleComponent ? (
              subtitleComponent
            ) : subtitle ? (
              <p className="truncate text-xs text-muted">{subtitle}</p>
            ) : null}
          </div>
          <div className="shrink-0">{actions}</div>
        </div>
        {children && <div className="mt-4">{children}</div>}
      </div>
    </>
  )
}
