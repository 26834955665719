import React from 'react'

import { cn } from 'utils/utils'

interface ProgressPlayProps extends React.SVGProps<SVGSVGElement> {
  className?: string
  progressPercentage?: number
}

const PROGRESS_PATHS = {
  P95: 'M12 3C12 3 12.97 2.8702 14.7812 3.45662C16.586 4.04305 18.164 5.18844 19.2812 6.72605C20.3983 8.26366 21 10.1155 21 12.016C21 13.9166 20.3983 15.7684 19.2812 17.3061C18.1641 18.8437 16.5889 19.9882 14.7814 20.5755C12.9738 21.1629 11.0267 21.163 9.21912 20.5757C7.41154 19.9884 5.83626 18.844 4.71906 17.3065C3.60186 15.7689 3.00008 13.92 3 12.0165C2.99993 10.12 3.59776 8.2633 4.71906 6.72605C5.84036 5.18879 7.41 4.04 9.21912 3.45662',
  P85: 'M12 3C12 3 12.97 2.8702 14.7812 3.45662C16.586 4.04305 18.164 5.18844 19.2812 6.72605C20.3983 8.26366 21 10.1155 21 12.016C21 13.9166 20.3983 15.7684 19.2812 17.3061C18.1641 18.8437 16.5889 19.9882 14.7814 20.5755C12.9738 21.1629 11.0267 21.163 9.21912 20.5757C7.41154 19.9884 5.83626 18.844 4.71906 17.3065C3.60186 15.7689 3.00008 13.92 3 12.0165C2.99993 10.12 3.59776 8.2633 4.71906 6.72605',
  P75: 'M3 12.0165C3.00008 13.92 3.60186 15.7689 4.71906 17.3065C5.83626 18.844 7.41154 19.9884 9.21912 20.5757C11.0267 21.163 12.9738 21.1629 14.7814 20.5755C16.5889 19.9882 18.1641 18.8437 19.2812 17.3061C20.3983 15.7684 21 13.9166 21 12.016C21 10.1155 20.3983 8.26366 19.2812 6.72605C18.164 5.18844 16.586 4.04305 14.7812 3.45662C12.97 2.8702 12 3 12 3',
  P65: 'M4.71906 17.3065C5.83626 18.844 7.41154 19.9884 9.21912 20.5757C11.0267 21.163 12.9738 21.1629 14.7814 20.5755C16.5889 19.9882 18.1641 18.8437 19.2812 17.3061C20.3983 15.7684 21 13.9166 21 12.016C21 10.1155 20.3983 8.26366 19.2812 6.72605C18.164 5.18844 16.586 4.04305 14.7812 3.45662C12.97 2.8702 12 3 12 3',
  P55: 'M9.21912 20.5757C11.0267 21.163 12.9738 21.1629 14.7814 20.5755C16.5889 19.9882 18.1641 18.8437 19.2812 17.3061C20.3983 15.7684 21 13.9166 21 12.016C21 10.1155 20.3983 8.26366 19.2812 6.72605C18.164 5.18844 16.586 4.04305 14.7812 3.45662C12.97 2.8702 12 3 12 3',
  P45: 'M14.7814 20.5755C16.5889 19.9882 18.1641 18.8437 19.2812 17.3061C20.3983 15.7684 21 13.9166 21 12.016C21 10.1155 20.3983 8.26366 19.2812 6.72605C18.164 5.18844 16.586 4.04305 14.7812 3.45662C12.97 2.8702 12 3 12 3',
  P35: 'M19.2812 17.3061C20.3983 15.7684 21 13.9166 21 12.016C21 10.1155 20.3983 8.26366 19.2812 6.72605C18.164 5.18844 16.586 4.04305 14.7812 3.45662C12.97 2.8702 12 3 12 3',
  P25: 'M21 12.016C21 10.1155 20.3983 8.26366 19.2812 6.72605C18.164 5.18844 16.586 4.04305 14.7812 3.45662C12.97 2.8702 12 3 12 3',
  P15: 'M19.2812 6.72605C18.1641 5.18844 16.5861 4.04305 14.7813 3.45662C12.9701 2.8702 12.0001 3 12.0001 3',
  P5: 'M14.7814 3.45662C12.9701 2.8702 12.0001 3 12.0001 3',
}

export const getProgressPath = (percentage: number): string => {
  if (percentage >= 95) return PROGRESS_PATHS.P95
  if (percentage >= 85) return PROGRESS_PATHS.P85
  if (percentage >= 75) return PROGRESS_PATHS.P75
  if (percentage >= 65) return PROGRESS_PATHS.P65
  if (percentage >= 55) return PROGRESS_PATHS.P55
  if (percentage >= 45) return PROGRESS_PATHS.P45
  if (percentage >= 35) return PROGRESS_PATHS.P35
  if (percentage >= 25) return PROGRESS_PATHS.P25
  if (percentage >= 15) return PROGRESS_PATHS.P15
  return PROGRESS_PATHS.P5
}

export const ProgressPlay = ({
  className,
  progressPercentage = 0,
  ...props
}: ProgressPlayProps): JSX.Element => {
  const progressPath = getProgressPath(progressPercentage)

  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="9" stroke="#E5E3E1" strokeWidth="2" />
      <path
        d={progressPath}
        stroke="#ADAAA4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8.14258L16 11.9997L10 15.8569V8.14258Z"
        fill="#0F0D0B"
        stroke="black"
        strokeWidth="1.44643"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
