import * as React from 'react'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { cn } from 'utils/utils'

const RadioCardGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('flex flex-wrap gap-2', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioCardGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioCardGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    Icon: React.ElementType
    label: string
  }
>(({ Icon, label, className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'group relative flex-1 rounded-sm border bg-transparent text-primary ring-offset-primary transition before:absolute before:-inset-[1px] before:rounded-sm before:border-2 before:border-transparent before:transition-all before:content-[""] hover:bg-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-selected data-[state=checked]:bg-secondary data-[state=checked]:before:border-selected',
        className
      )}
      {...props}
    >
      <div className="flex flex-col items-start justify-center space-y-1 p-4 text-primary">
        <Icon className="size-4" />
        <span className="font-sans text-xs font-medium">{label}</span>
      </div>
    </RadioGroupPrimitive.Item>
  )
})
RadioCardGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioCardGroup, RadioCardGroupItem }
