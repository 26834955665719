import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { throttle } from 'lodash'
import { ArrowDown } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { UploadedFile } from 'openapi/models/UploadedFile'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { useNavigation, NavigationMessage } from 'hooks/use-navigation'
import { Source } from 'utils/task'
import { cn, isElementInView } from 'utils/utils'

import AssistantSources from 'components/assistant/components//assistant-sources'
import { AssistantErrorPage } from 'components/assistant/components/assistant-error-page'
import AssistantFollowUps from 'components/assistant/components/assistant-follow-ups'
import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import AssistantReplyInput from 'components/assistant/components/assistant-reply-input'
import AssistantThread, {
  ThreadMessage,
} from 'components/assistant/components/assistant-thread'
import {
  AssistantThreadSidebar,
  AssistantThreadSidebarPlaceholderRight,
} from 'components/assistant/components/assistant-thread-layout'
import AssistantToolbar from 'components/assistant/components/assistant-toolbar'
import { AssistantChatStreamHandler } from 'components/assistant/hooks/use-assistant-chat'
import { useRestoreAssistantHistoryItem } from 'components/assistant/hooks/use-assistant-load-history-item'
import { useIsCuatrecasas } from 'components/assistant/hooks/use-is-cuatrecasas'
import { useIsPwcTax } from 'components/assistant/hooks/use-is-pwc-tax'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  AssistantChatMessage,
  AssistantMessage,
} from 'components/assistant/types'
import {
  EXAMPLE_MESSAGE_ID,
  getIsFinalizingStream,
  getMessageQuery,
} from 'components/assistant/utils/assistant-helpers'
import {
  isResearchKnowledgeSource,
  isVaultKnowledgeSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import FeedbackTax from 'components/common/feedback/feedback-tax'
import Markdown from 'components/common/markdown/markdown'
import {
  CUATRECASAS_HELP,
  PWC_DISCLAIMER_TEXT,
} from 'components/research/constants'
import { getAllChildrenDeep } from 'components/research/research-helpers'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { getVaultFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

type Props = {
  useChat: AssistantChatStreamHandler
}

const AssistantChat = ({ useChat }: Props) => {
  const { eventId, messageId } = useParams()
  const navigate = useNavigateWithQueryParams()

  const { error } = useRestoreAssistantHistoryItem(
    eventId,
    messageId,
    AssistantMode.ASSIST
  )

  const [editingMessage, setEditingMessage] =
    useState<AssistantChatMessage | null>(null)

  const [
    streamingMessage,
    messages,
    documents,
    documentsUploading,
    getCurrentThreadMessages,
    currentMessageId,
    setCurrentMessageId,
    pendingMessage,
    setPendingMessage,
    restoreState,
    userCaption,
    knowledgeSource,
  ] = useAssistantStore(
    useShallow((s) => [
      s.streamingMessage,
      s.messages,
      s.documents,
      s.documentsUploading,
      s.getCurrentThreadMessages,
      s.currentMessageId,
      s.setCurrentMessageId,
      s.pendingMessage,
      s.setPendingMessage,
      s.restoreState,
      s.userCaption,
      s.knowledgeSource,
    ])
  )

  const [folderIdToVaultFileIds, fileIdToVaultFile, parentIdToVaultFolderIds] =
    useVaultStore(
      useShallow((s) => [
        s.folderIdToVaultFileIds,
        s.fileIdToVaultFile,
        s.parentIdToVaultFolderIds,
      ])
    )

  const sourceDocuments = useMemo(() => {
    if (isVaultKnowledgeSource(knowledgeSource) && knowledgeSource.folderId) {
      const vaultSourceFileIds = new Set(knowledgeSource.fileIds)
      const vaultFolderFileIds = getVaultFileIds({
        folderId: knowledgeSource.folderId,
        parentIdToVaultFolderIds,
        folderIdToVaultFileIds,
      })
      return vaultFolderFileIds
        .map((fileId) => fileIdToVaultFile[fileId] as UploadedFile)
        .filter((file) => vaultSourceFileIds.has(file.id))
    }
    return documents
  }, [
    documents,
    folderIdToVaultFileIds,
    fileIdToVaultFile,
    parentIdToVaultFolderIds,
    knowledgeSource,
  ])

  const currentThreadMessages = useMemo(getCurrentThreadMessages, [
    messages,
    currentMessageId,
    getCurrentThreadMessages,
  ])

  useEffect(() => {
    setEditingMessage(null)
  }, [currentThreadMessages])

  const isExample = eventId === EXAMPLE_MESSAGE_ID

  const allSources = useMemo(() => {
    let sources: Source[] = []
    currentThreadMessages.forEach((message) => {
      if (message.sources.length > 0) {
        sources = sources.concat(message.sources)
      }
    })
    return sources
  }, [currentThreadMessages])

  const navMessages: NavigationMessage[] = useMemo(() => {
    const messages = streamingMessage
      ? [...currentThreadMessages, streamingMessage]
      : currentThreadMessages
    return messages.map((message) => ({
      id: message.messageId,
      title: getMessageQuery(message),
    }))
  }, [currentThreadMessages, streamingMessage])

  const hasSources =
    allSources.length > 0 ||
    documents.length > 0 ||
    documentsUploading.length > 0 ||
    !!knowledgeSource

  const replyRef = useRef<HTMLTextAreaElement | null>(null)
  const [showReplyBubble, setShowReplyBubble] = useState(false)
  const handleScrollToReply = () => {
    if (!scrollRef.current) return

    // Focus input after we've scrolled to the bottom
    const handleScrollEnd = () => {
      if (!scrollRef.current) return
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
      if (scrollTop + clientHeight >= scrollHeight) {
        replyRef.current?.focus()
        scrollRef.current.removeEventListener('scroll', handleScrollEnd)
      }
    }
    scrollRef.current.addEventListener('scroll', handleScrollEnd)

    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  // 48 = `py-12` on ThreadMessage (32 = `pt-8` on first ThreadMessage)
  const getPadding = (i: number) => ({
    top: i === 0 ? 32 : 48,
    bottom: 48,
  })
  const { navigationEl, messageRefs, scrollRef, unthrottledScroll } =
    useNavigation(navMessages, getPadding)

  const handleScroll = () => {
    unthrottledScroll()

    if (!scrollRef.current || !replyRef.current) return
    const scrollEl = scrollRef.current as HTMLDivElement
    const replyEl = replyRef.current as HTMLTextAreaElement

    const scrollRect = scrollEl.getBoundingClientRect()
    const replyRect = replyEl.getBoundingClientRect()
    const shouldShowBubble = !!(replyEl.value || replyEl.matches(':focus'))
    setShowReplyBubble(
      shouldShowBubble &&
        !isElementInView(replyRect, scrollRect, { absolute: true })
    )
  }

  const onScroll = throttle(handleScroll, 250)

  const autoScroll = useRef<boolean>(false)
  const autoScrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const handleWheel = () => {
    if (autoScrollTimeout.current) {
      clearTimeout(autoScrollTimeout.current)
      autoScrollTimeout.current = null
    }
    autoScroll.current = false
  }

  const isStreaming = !!streamingMessage

  const streamedPrevMessageId = useRef<string | null>('')
  const lastStreamedHeaderText = useRef<string>('')
  useEffect(() => {
    if (!streamingMessage) return
    if (
      scrollRef.current &&
      streamingMessage.prevMessageId !== streamedPrevMessageId.current
    ) {
      autoScroll.current = false
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      })
      autoScrollTimeout.current = setTimeout(() => {
        autoScroll.current = true
      }, 250)
    }
    streamedPrevMessageId.current = streamingMessage.prevMessageId
    if (streamingMessage.headerText)
      lastStreamedHeaderText.current = streamingMessage.headerText

    if (scrollRef.current && autoScroll.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
      })
    }
  }, [scrollRef, streamingMessage])

  const lastMessage = currentThreadMessages[currentThreadMessages.length - 1]
  useEffect(() => {
    if (!scrollRef.current || !autoScroll.current || !lastMessage) return
    if (lastMessage.prevMessageId === streamedPrevMessageId.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [scrollRef, lastMessage])

  const firstMessage = currentThreadMessages.length
    ? currentThreadMessages[0]
    : streamingMessage
  const threadTitle =
    userCaption ||
    firstMessage?.caption ||
    (firstMessage?.isLoading ? '' : getMessageQuery(firstMessage))

  const handleRegenerateMessage = async (message: AssistantMessage) => {
    const prevMessage = message.prevMessageId
      ? currentThreadMessages.find((m) => m.messageId === message.prevMessageId)
      : null

    setCurrentMessageId(prevMessage?.messageId || null)
    await useChat.createChatReply(
      (message as AssistantChatMessage).query,
      prevMessage?.messageId
    )
  }

  const handleEditMessageQuery = async (query: string | undefined) => {
    if (!editingMessage || !query) return

    const prevMessage = editingMessage.prevMessageId
      ? currentThreadMessages.find(
          (m) => m.messageId === editingMessage.prevMessageId
        )
      : null

    setCurrentMessageId(prevMessage?.messageId || null)
    await useChat.createChatReply(query, prevMessage?.messageId)
  }

  const handleCancel = () => {
    useChat.sendCancelRequest()
    if (!messages.length) {
      restoreState()
      navigate('/assistant', { state: { skipReset: true } })
    }
  }

  const handleCancelPending = () => {
    setPendingMessage(null)
  }

  const [searchParams] = useSearchParams()
  const isCopilotPlugin = searchParams.get('isCopilotPlugin') === 'true'

  const isPwcTax = useIsPwcTax()
  const isCuatrecasas = useIsCuatrecasas()
  const { taxonomy, isFetched: isTaxonomyFetched } = useResearchTaxonomyQuery(
    ResearchArea.TAX,
    isPwcTax
  )
  const allFiltersFlattened = useMemo(() => {
    const researchFilters = taxonomy
    return researchFilters.concat(
      researchFilters.flatMap((researchFilter) =>
        getAllChildrenDeep(researchFilter)
      )
    )
  }, [taxonomy])
  const selectedFilters = useMemo(() => {
    if (!isResearchKnowledgeSource(knowledgeSource)) return []
    const restrictsSet = new Set(knowledgeSource.filterIds)
    return allFiltersFlattened.filter((filter) => restrictsSet.has(filter.id))
  }, [allFiltersFlattened, knowledgeSource])

  // Prevent re-render of existing thread messages when unrelated state changes
  // e.g. streaming message or table of contents (navigationEl on the left) highlighted bar
  const memoizedThreadMessages = useMemo(
    () =>
      currentThreadMessages.map((message, i) => (
        <ThreadMessage
          key={message.messageId}
          className={cn('-scroll-mt-px', {
            'min-h-full':
              !isStreaming &&
              (message.prevMessageId === streamedPrevMessageId.current ||
                currentThreadMessages.length === 1),
          })}
          message={message}
          sidebar={
            hasSources && (
              <AssistantThreadSidebar>
                <AssistantSources
                  allDocuments={sourceDocuments}
                  sources={message.sources}
                  message={message}
                />
              </AssistantThreadSidebar>
            )
          }
          footer={
            isExample ? (
              <AssistantFollowUps
                questions={message.relatedQuestions}
                onSelectQuestion={() => null}
              />
            ) : (
              !isStreaming &&
              i === currentThreadMessages.length - 1 && (
                <AssistantReplyInput
                  prevMessageId={currentMessageId || undefined}
                  relatedQuestions={message.relatedQuestions}
                  useChat={useChat}
                  inputRef={replyRef}
                />
              )
            )
          }
          ref={(el) => (messageRefs.current[i] = el)}
          edit={{
            handleEditMessageQuery,
            setEditingMessage,
            editingMessage,
            threadNumber: i + 1,
            messageNumber:
              messages.findIndex((m) => m.messageId === message.messageId) + 1,
          }}
          lastLoadingMessage={
            i === 0 || message.prevMessageId === streamedPrevMessageId.current
              ? lastStreamedHeaderText.current
              : undefined
          }
        >
          {(getHrvyInfoMetadata) => (
            <>
              <Markdown
                className="mb-6"
                content={message.response}
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                sources={message.sources}
                width="100%"
              />
              {eventId && isPwcTax && isTaxonomyFetched && i === 0 && (
                <div className="my-6">
                  <FeedbackTax
                    queryId={eventId}
                    selectedFilters={selectedFilters}
                    allFilterNodes={allFiltersFlattened}
                  />
                </div>
              )}
              {isCopilotPlugin && (
                <div className="mb-1 text-right text-xs text-muted">
                  AI-generated content may be incorrect
                </div>
              )}

              <AssistantToolbar
                documents={sourceDocuments}
                message={message}
                onRegenerateMessage={handleRegenerateMessage}
                threadTitle={userCaption || currentThreadMessages[0]?.caption}
                threadNumber={i + 1}
                messageNumber={
                  messages.findIndex((m) => m.messageId === message.messageId) +
                  1
                }
              />
            </>
          )}
        </ThreadMessage>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentThreadMessages,
      currentMessageId,
      sourceDocuments,
      editingMessage,
      isStreaming,
      isTaxonomyFetched,
      useChat,
    ]
  )

  if (error) {
    return <AssistantErrorPage error={error} />
  }

  const streamingSidebar =
    streamingMessage &&
    hasSources &&
    (getIsFinalizingStream(streamingMessage) ? (
      <AssistantThreadSidebar>
        <AssistantSources
          isStreaming
          allDocuments={sourceDocuments}
          sources={streamingMessage.sources}
        />
      </AssistantThreadSidebar>
    ) : (
      <AssistantThreadSidebarPlaceholderRight />
    ))

  return (
    <AssistantThread
      messages={currentThreadMessages}
      sources={allSources}
      title={threadTitle}
    >
      {navigationEl}
      <div
        className="virtualized-scrollbar h-full grow"
        onScroll={onScroll}
        onWheel={handleWheel}
        ref={scrollRef}
      >
        {memoizedThreadMessages}
        {streamingMessage && (
          <ThreadMessage
            className="min-h-[calc(100%+1px)] -scroll-mt-px"
            message={streamingMessage}
            onCancel={handleCancel}
            sidebar={streamingSidebar}
            footer={
              !!streamingMessage.relatedQuestions?.length &&
              !pendingMessage && (
                <AssistantReplyInput
                  isStreaming
                  prevMessageId={currentMessageId || undefined}
                  relatedQuestions={streamingMessage.relatedQuestions}
                  useChat={useChat}
                  isFinalizingStream
                />
              )
            }
            ref={(el) => (messageRefs.current[messages.length] = el)}
          >
            {(getHrvyInfoMetadata) => (
              <>
                {isPwcTax && (
                  <div className="-mx-4 mb-6 rounded-md bg-secondary px-4 py-6">
                    <div className="mb-2 text-sm font-medium">Disclaimer</div>
                    <p>{PWC_DISCLAIMER_TEXT}</p>
                  </div>
                )}
                {isCuatrecasas && (
                  <div className="-mx-4 mb-6 rounded-md bg-secondary px-4 py-6">
                    <div className="mb-2 text-sm font-medium">Disclaimer</div>
                    <Markdown content={CUATRECASAS_HELP} />
                  </div>
                )}
                <Markdown
                  content={streamingMessage.response}
                  getHrvyInfoMetadata={getHrvyInfoMetadata}
                  isLoading
                  width="100%"
                />
              </>
            )}
          </ThreadMessage>
        )}
        {/* Empty container message before we copy to streamingMessage */}
        {pendingMessage && (
          <ThreadMessage
            className="min-h-full"
            message={pendingMessage}
            onCancel={handleCancelPending}
            sidebar={
              sourceDocuments.length > 0 && (
                <AssistantThreadSidebar>
                  <AssistantSources
                    allDocuments={sourceDocuments}
                    isStreaming
                    sources={pendingMessage.sources}
                  />
                </AssistantThreadSidebar>
              )
            }
          >
            {(getHrvyInfoMetadata) => (
              <Markdown
                content=""
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                width="100%"
              />
            )}
          </ThreadMessage>
        )}
      </div>
      <div className="z-50 flex justify-center">
        <Button
          className={cn(
            'fixed bottom-8 translate-y-20 rounded-full pl-3 pr-4 text-xs transition-transform ease-in',
            {
              'translate-y-0': showReplyBubble,
            }
          )}
          onClick={handleScrollToReply}
        >
          <Icon icon={ArrowDown} className="mr-2" /> Back to follow-up question
        </Button>
      </div>
    </AssistantThread>
  )
}

export default AssistantChat
