import React from 'react'

import {
  BlocksIcon,
  FileSearchIcon,
  ListEndIcon,
  SquarePenIcon,
  ChevronRight,
} from 'lucide-react'

import { UserInfo } from 'models/user-info'

import { getRouteForEvent } from 'utils/routing'

import { LibraryItem } from 'components/library/library-types'
import { getNavigateOptionsState } from 'components/library/library.helpers'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { TextLink } from 'components/ui/text-link'

const categoryToIconMap = {
  ['Summarize']: ListEndIcon,
  ['Ideate']: BlocksIcon,
  ['Analyze']: FileSearchIcon,
  ['Default']: SquarePenIcon,
}

export const AssistantExampleCardList = ({
  title,
  items,
  userInfo,
  href,
}: {
  title?: string
  items: LibraryItem[]
  userInfo: UserInfo
  href: string
}) => {
  if (!items.length) return null

  const itemsSlice = items.slice(0, 4)

  const getIcon = (category: keyof typeof categoryToIconMap) => {
    return category in categoryToIconMap
      ? categoryToIconMap[category]
      : categoryToIconMap['Default']
  }

  return (
    <div>
      <div className="flex justify-between">
        {title && <h3 className="mb-3 font-medium">{title}</h3>}
        <TextLink
          noUnderline
          className="inline-block"
          label="See all"
          href={href}
          trailingIcon={<ChevronRight />}
        />
      </div>

      <ul className="grid grid-cols-4 gap-4">
        {itemsSlice.map((item) => (
          <li className="h-full list-none" key={item.id}>
            <Button
              variant="unstyled"
              size="stretch"
              className="flex items-start justify-start gap-2 rounded-sm border p-3 text-left transition hover:bg-secondary-hover"
              to={getRouteForEvent(
                {
                  id: item.eventId,
                  kind: item.eventKind,
                  libraryItemKind: item.kind,
                },
                userInfo,
                userInfo.IsAssistantV2User
                  ? getNavigateOptionsState(item)
                  : undefined
              )}
            >
              <Icon
                icon={getIcon(
                  item.categories[0] as keyof typeof categoryToIconMap
                )}
              />
              <p className="-mt-px line-clamp-2 h-10">{item.name}</p>
            </Button>
          </li>
        ))}
      </ul>
    </div>
  )
}
