import React, { useEffect, useState } from 'react'

import pluralize from 'pluralize'

import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'

import { Spinner } from 'components/ui/spinner'

interface Props {
  documents: TranscriptsDocument[]
}

const LoadingTexts = [
  { text: 'Sorting transcripts', endMinute: 1 },
  { text: 'Identifying witnesses', endMinute: 2 },
  { text: 'Extracting questions and answers', endMinute: 4 },
  { text: 'Reviewing questions and answers', endMinute: 6 },
  { text: 'Generating topics', endMinute: 7 },
  { text: 'Analyzing topics', endMinute: 10 },
  { text: 'Generating summaries', endMinute: 12 },
  { text: 'Finalizing', endMinute: 15 },
]

const TranscriptsIsLoading: React.FC<Props> = ({ documents }) => {
  const loadingDocuments = documents.filter((d) => d.isLoading)
  const [loadingText, setLoadingText] = useState('Processing')

  const isLoadingMessage = `${loadingText}: ${
    loadingDocuments.length
  } ${pluralize('Document', loadingDocuments.length)} Remaining`

  const documentsLoading = loadingDocuments.length > 0

  useEffect(() => {
    if (!loadingDocuments.length) {
      // When there are no documents loading, reset the state
      sessionStorage.removeItem('startTime')
      return
    }

    const updateTextBasedOnTime = () => {
      const now = new Date().getTime()
      const startTime = parseInt(
        sessionStorage.getItem('startTime') || now.toString(),
        10
      )
      if (!sessionStorage.getItem('startTime')) {
        sessionStorage.setItem('startTime', now.toString())
      }

      const elapsedMinutes = (now - startTime) / 60000 // Convert milliseconds to minutes

      const currentText =
        LoadingTexts.find((text) => elapsedMinutes < text.endMinute)?.text ||
        'Processing…'
      if (currentText !== loadingText) setLoadingText(currentText)
    }

    // Initial update
    updateTextBasedOnTime()

    // Set interval for periodic updates
    const interval = setInterval(updateTextBasedOnTime, 1000 * 10) // Check every 10 seconds

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsLoading])

  if (loadingDocuments.length === 0) return null

  return (
    <div className="flex h-8 items-center justify-end pb-1 pr-2">
      <Spinner size="xxs" className="mr-2" />
      <p className="text-xs text-muted">{isLoadingMessage}</p>
    </div>
  )
}

export default TranscriptsIsLoading
