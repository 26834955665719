import React, { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useState from 'react-usestateref'

import { Form, Select, Modal, message } from 'antd'
import { addSeconds } from 'date-fns'
import emailAddresses from 'email-addresses'
import _ from 'lodash'

import {
  AddPermsToUser,
  DeleteUserPerm,
  FetchAllPerms,
  grantSensitiveDataPerm,
  Perm,
  PermSource,
} from 'models/perms'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { getUserQueryCapRules } from 'models/query-cap-rule'
import {
  Permission,
  UserInfo,
  isInternalOnlyPerm,
  isInternalUser,
  isRestrictedPerm,
  isSensitiveUserPerm,
  type RawUserInfo,
  isSensitiveCustomerDataPerm,
  SENSITIVE_DATA_PERM_GRANTER_EMAILS,
} from 'models/user-info'
import { ResetOnboarding, ResetUserProductTour } from 'models/users'
import {
  FetchWorkspaces,
  type Workspace,
  MoveToWorkspace,
} from 'models/workspace'
import Services from 'services'
import { Maybe } from 'types'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { Env } from 'utils/env'
import { environment } from 'utils/server-data'
import {
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import WorkspaceDropdown from 'components/dashboard/workspace-dropdown'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import QueryCapRulesTable from 'components/settings/workspace/workspace-details/vault-management/query-cap/query-cap-rules-table'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import Banner from 'components/ui/banner'
import { Button, Button as TailwindButton } from 'components/ui/button'
import { CardContent, CardHeader, Card } from 'components/ui/card'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Select as TailwindSelect,
} from 'components/ui/select'
import { Separator } from 'components/ui/separator'
import Skeleton from 'components/ui/skeleton'
import { Textarea } from 'components/ui/text-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import UserPermissionsTable from './user-permissions-table'
import { SENSITIVE_PERM_GRANT_CATEGORY } from './utils'

enum SensitiveDataPermExpiryTime {
  TEN_MINUTES = 'Ten minutes',
  ONE_HOUR = 'One hour',
  EIGHT_HOURS = 'Eight hours',
}

const SensitiveDataPermExpiryTimeOptionToSeconds = {
  [SensitiveDataPermExpiryTime.TEN_MINUTES]: 10 * 60,
  [SensitiveDataPermExpiryTime.ONE_HOUR]: 60 * 60,
  [SensitiveDataPermExpiryTime.EIGHT_HOURS]: 8 * 60 * 60,
}

const UserInspector = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigateWithQueryParams()
  const userEmail = location.state?.email
  const destinationHash = location.state?.hash

  useEffect(() => {
    if (_.isNil(userEmail) || userEmail.length === 0) {
      navigate('/settings/users/')
    }
  }, [userEmail, navigate])

  useEffect(() => {
    if (!_.isNil(destinationHash)) {
      let attempts = 0
      const maxAttempts = 50 // Maximum number of attempts till data fetched
      const interval = 200 // Interval in milliseconds between each attempt

      const attemptScrollToElement = () => {
        const element = document.getElementById(destinationHash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        } else if (attempts < maxAttempts) {
          setTimeout(attemptScrollToElement, interval)
          attempts++
        }
      }

      attemptScrollToElement()
    }
  }, [destinationHash])

  const [permEmail, setPermEmail] = useState<Maybe<string>>(userEmail)
  const [inputEmail, setInputEmail] = useState<Maybe<string>>(userEmail)
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [userInfo, setUserInfo] = useState<RawUserInfo>()
  const [userInfoError, setUserInfoError] = useState<boolean>(false)
  const [userWorkspaces, setUserWorkspaces] = useState<Workspace[]>([])
  const [permWorkspace, setPermWorkspace] = useState<Maybe<Workspace>>()
  const [enrichedPerms, setEnrichedPerms] = useState<Perm[]>([])
  const [moveWithPermsChecked, setMoveWithPermsChecked] =
    useState<boolean>(false)
  const [moveWithPermsAcknowledge, setMoveWithPermsAcknowledge] =
    useState<boolean>(false)

  const getInternalAdminUserInfo = async (
    email: string
  ): Promise<RawUserInfo> => {
    const searchParams = new URLSearchParams()
    searchParams.append('email', email)
    const userInfo = await Services.Backend.Get<RawUserInfo>(
      `internal_admin/user_info?${searchParams.toString()}`
    )
    return userInfo
  }

  const [selectedWorkspace, setSelectedWorkspace] = useState<Maybe<Workspace>>(
    userInfo?.workspace as Workspace
  )

  const fetchAllData = useCallback(
    async (email: string) => {
      const userInfo = await getInternalAdminUserInfo(email)
      if (_.isEmpty(userInfo)) {
        setUserInfoError(true)

        // only set userInfo if it's non-empty, otherwise the subsequent _.isNil() checks
        // won't detect when userInfo is empty
        setUserInfo(undefined)
        setUserWorkspaces([])
        setPermWorkspace(undefined)
        setEnrichedPerms([])
        setSelectedWorkspace(undefined)
        return
      }
      setUserInfo(userInfo)
      setUserInfoError(false)
      setPermWorkspace(userInfo?.workspace as Workspace)
      setSelectedWorkspace(userInfo?.workspace as Workspace)

      const workspaces = await FetchWorkspaces()
      setWorkspaces(workspaces)

      const administrableWorkspaces = workspaces.filter(
        (w) => userInfo.administrableWorkspaces?.includes(w.id) ?? false
      )

      // go through all permWithSource and get unique list of workspaces
      const permWorkspaces = _.uniq(
        userInfo.permsWithSource?.map((p) => p.workspaceId)
      ).map((workspaceId) => workspaces.find((w) => w.id === workspaceId))
      const allWorkspaces = [...administrableWorkspaces, ...permWorkspaces]
      setUserWorkspaces(_.compact(_.uniqBy(allWorkspaces, 'id')))
    },
    [setUserInfo, setWorkspaces, setUserInfoError]
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await fetchAllData(userEmail)
      setLoading(false)
    }
    fetchData()
  }, [userEmail, fetchAllData])

  const { data: queryCapRules } = useWrappedQuery({
    queryKey: [
      HarvQueryKeyPrefix.QueryCapRulesQuery,
      userInfo?.dbId,
      selectedWorkspace?.id,
    ],
    queryFn: () => getUserQueryCapRules(userInfo?.dbId, selectedWorkspace?.id),
    enabled: !!userInfo?.dbId && !!selectedWorkspace?.id,
  })

  const authUser = useAuthUser()

  const [workspaceForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [allPerms, setAllPerms] = useState<Perm[]>([])
  const [permOptions, setPermOptions] = useState<Perm[]>([])

  const [selectedPerms, setSelectedPerms] = useState<Permission[]>([])
  const [workspaceUpdateModalOpen, setWorkspaceUpdateModalOpen] =
    useState(false)

  const [sensitiveDataPermOptions, setSensitiveDataPermOptions] = useState<
    Perm[]
  >([])
  const [selectedSensitiveDataPerm, setSelectedSensitiveDataPerm] =
    useState<Permission>()
  const [sensitivePermExpiry, setSensitivePermExpiry] =
    useState<SensitiveDataPermExpiryTime>(
      SensitiveDataPermExpiryTime.TEN_MINUTES
    )
  const [sensitiveDataPermGrantCategory, setSensitiveDataPermGrantCategory] =
    useState<string>(SENSITIVE_PERM_GRANT_CATEGORY[0])

  const getEnrichedPerms = useCallback(
    (workspaceId: number) => {
      if (_.isEmpty(userInfo)) return []
      try {
        const uInfo = new UserInfo(userInfo)
        const permsByWorkspace = uInfo.permissionsByWorkspace
        const perms = _.uniq(permsByWorkspace[workspaceId])

        return perms.map((perm) => {
          const foundPerm = allPerms.find((p) => p.permId === perm)
          const permWithSource = userInfo.permsWithSource
            ?.filter((p) => p.permId === perm)
            ?.filter((p) => p.workspaceId === workspaceId)

          const permSources = permWithSource?.map((p) => p.permSource) ?? []

          if (_.isNil(foundPerm)) {
            return {
              permId: perm,
              name: 'Unknown',
              desc: 'Unknown',
              permSources,
              expiresAt: null,
            } as Perm
          }
          return {
            ...foundPerm,
            permSources,
            expiresAt: permWithSource?.[0]?.expiresAt,
          }
        })
      } catch (e) {
        return []
      }
    },
    [userInfo, allPerms]
  )

  const userPerms = enrichedPerms.filter((perm) =>
    (perm.permSources ?? []).includes(PermSource.USER)
  )

  useEffect(() => {
    if (userInfo) {
      setEnrichedPerms(getEnrichedPerms(userInfo.workspace.id))
    }
  }, [userInfo, allPerms, getEnrichedPerms])

  useEffect(() => {
    const fetchData = async () => {
      const allPerms = await FetchAllPerms()
      setAllPerms(allPerms)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const filteredPerms = _.cloneDeep(allPerms)
    // staging and prod should have sensitive perms filtered to admin writers
    if (!authUser?.IsInternalAdminWriter && environment !== Env.LOCAL) {
      _.remove(filteredPerms, (d) =>
        isSensitiveUserPerm(d.permId as Permission)
      )
    } else if (
      // allow granting sensitive data perms in non prod
      environment === Env.PRODUCTION
    ) {
      _.remove(filteredPerms, (d) =>
        isSensitiveCustomerDataPerm(d.permId as Permission)
      )
    }
    if (_.isNil(authUser) || _.isNil(userEmail) || !isInternalUser(userEmail)) {
      _.remove(filteredPerms, (d) => isInternalOnlyPerm(d.permId as Permission))
    }
    _.remove(filteredPerms, (d) => isRestrictedPerm(d.permId as Permission))

    const enrichedPermsSet = new Set(enrichedPerms.map((p) => p.permId))
    const filteredOptions = filteredPerms.filter(
      (p) => !enrichedPermsSet.has(p.permId as Permission)
    )
    setPermOptions(filteredOptions)

    const filteredSensitiveDataPerms = _.cloneDeep(allPerms)
      .filter((p) => isSensitiveCustomerDataPerm(p.permId as Permission))
      .filter((p) => !enrichedPermsSet.has(p.permId as Permission))
    setSensitiveDataPermOptions(filteredSensitiveDataPerms)
  }, [authUser, allPerms, userEmail, enrichedPerms, permEmail])

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      workspaceForm.setFieldsValue({ workspace: userInfo?.workspace?.id })
    }
  }, [userInfo, workspaceForm])

  useEffect(() => {
    // if current workspace has parent ID, find parent and get all children
    // if current workspace has no parent ID, filter all workspaces to find if parentID is current workspace ID
    const parentChildWorkspaces: Workspace[] = []
    if (userInfo?.workspace?.parentId) {
      const parentWorkspace = workspaces.find(
        (w) => w.id === userInfo?.workspace?.parentId
      )
      if (parentWorkspace) {
        parentChildWorkspaces.push(parentWorkspace)
        parentChildWorkspaces.push(
          ...workspaces.filter((w) => w.parentId === parentWorkspace.id)
        )
      }
    } else {
      const ws = workspaces.filter(
        (w) => w.parentId === userInfo?.workspace?.id
      )
      parentChildWorkspaces.push(...ws)
    }
  }, [selectedWorkspace, userInfo, userPerms.length, workspaces])

  const handleSubmitEmail = async (): Promise<void> => {
    if (_.isNil(inputEmail)) {
      displayErrorMessage('Please enter an email address')
      return
    }

    setLoading(true)
    const email = inputEmail.trim().toLowerCase()
    const emailValid = emailAddresses.parseOneAddress(email)
    if (!emailValid) {
      message.error('Please enter a valid email address')
      setLoading(false)
      return
    }

    setPermEmail(email)
    await fetchAllData(email)
    location.state = { email }
    setLoading(false)
  }

  const handleChangeWorkspace = async (): Promise<void> => {
    if (_.isNil(permEmail)) {
      displayErrorMessage('User not set')
      return
    }
    const values = await workspaceForm.validateFields()
    if (_.isNil(values.workspace)) {
      void message.error('No workspace selected')
      return
    }
    setLoading(true)
    if (_.isNil(userInfo) || userInfo.id.length === 0) {
      console.error('User not found while changing workspace')
      return
    }
    try {
      await MoveToWorkspace(
        values.workspace,
        [userInfo.id],
        moveWithPermsChecked
      )
      displaySuccessMessage(
        `User workspace updated to ${selectedWorkspace?.clientName}`,
        5
      )
    } catch (error) {
      displayErrorMessage('Failed to update user workspace', 10)
    } finally {
      setLoading(false)
      await fetchAllData(permEmail)
    }
  }

  const [isSensitivePermDialogOpen, setIsSensitivePermDialogOpen] =
    useState(false)
  const [sensitivePermReason, setSensitivePermReason] = useState<string>('')
  const minSensitivePermReasonLength = 25

  const handleAddPerms = async (): Promise<void> => {
    if (_.isNil(permEmail)) {
      displayErrorMessage('User not set')
      return
    }
    const values = await workspaceForm.validateFields()
    if (_.isNil(values.permissions)) {
      void message.error('No permissions selected')
      return
    }
    setLoading(true)
    try {
      if (_.isNil(userInfo)) {
        displayErrorMessage('User not found while adding perms')
        return
      }
      if (_.isNil(permWorkspace)) {
        displayErrorMessage('Workspace not found while adding perms')
        return
      }
      const resp = await AddPermsToUser({
        userEmail: userInfo.id,
        perms: values.permissions,
        workspaceId: permWorkspace.id,
        sensitiveDataPermReason: sensitivePermReason,
      })
      if (!_.isEmpty(resp)) {
        void message.success('Perms added successfully')
      } else {
        void message.error('Unable to add perms')
      }
    } catch (error) {
      const errMsg = error instanceof Error ? error.message : 'Unknown error'
      void message.error(errMsg)
    }
    workspaceForm.resetFields()
    setLoading(false)
    setSelectedPerms([])
    setSensitivePermReason('')
    await fetchAllData(permEmail)
  }

  const handleAddingSensitiveDataPerm = async (): Promise<void> => {
    if (_.isNil(permEmail)) {
      displayErrorMessage('User not set')
      return
    }
    try {
      if (_.isNil(userInfo)) {
        console.error('User not found while adding perms')
        return
      }
      if (_.isNil(permWorkspace)) {
        console.error('Workspace not found while adding perms')
        return
      }
      if (_.isNil(selectedSensitiveDataPerm)) {
        displayErrorMessage('No sensitive data perm selected')
        return
      }

      await grantSensitiveDataPerm({
        userEmail: userInfo.id,
        perms: [selectedSensitiveDataPerm],
        workspaceId: permWorkspace.id,
        sensitiveDataPermReason: sensitivePermReason,
        sensitiveDataPermGrantCategory,
        expiresAt: addSeconds(
          new Date(),
          SensitiveDataPermExpiryTimeOptionToSeconds[sensitivePermExpiry]
        ).toISOString(),
      })

      displaySuccessMessage(`${selectedSensitiveDataPerm} added successfully`)
    } catch (error) {
      displayErrorMessage('Unable to add sensitive data perm')
    }
    setSensitivePermReason('')
    setSelectedSensitiveDataPerm(undefined)
    setIsSensitivePermDialogOpen(false)
    await fetchAllData(permEmail)
  }

  const deleteUserPerm = async (permId: Permission): Promise<void> => {
    if (_.isNil(userInfo)) {
      return
    }
    Modal.confirm({
      title: `Are you sure you want to revoke ${allPerms.find(
        (p) => p.permId === permId
      )?.name}?`,
      content: (
        <p>
          This will revoke this permission for <b>{userInfo.id}</b> tied to
          workspace <b>{permWorkspace?.clientName}</b>
        </p>
      ),
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      width: 600,
      onOk: async () => {
        try {
          if (_.isNil(permWorkspace)) {
            displayErrorMessage('No workspace selected')
            return
          }
          if (_.isNil(permEmail)) {
            displayErrorMessage('User not set')
            return
          }
          setLoading(true)
          const deleted = await DeleteUserPerm(
            userInfo.id,
            permId,
            permWorkspace.id
          )

          if (typeof deleted === 'boolean' && !deleted) {
            void message.error('Unable to delete perm')
          } else if (typeof deleted === 'object' && _.isEmpty(deleted)) {
            void message.error('Unable to delete perm')
          } else {
            void message.success('Perm deleted successfully')
          }
          await fetchAllData(permEmail)
          setLoading(false)
        } catch (error) {
          const errMsg =
            error instanceof Error
              ? error.message
              : 'Unable to delete user perm'
          void message.error(errMsg)
        }
      },
    })
  }

  const deleteUser = async (): Promise<void> => {
    if (_.isNil(userInfo)) {
      return
    }

    Modal.confirm({
      title: `Are you sure you want to delete ${userInfo.id}?`,
      content: `This is non-reversible. It will delete the user from Auth0 and remove their access from Harvey.`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          setLoading(true)
          const deleted = await Services.Backend.Post(
            `internal_admin/user/delete`,
            {
              userEmail: userInfo.id,
            },
            { maxRetryCount: 0 }
          )
          if (deleted) {
            await message.success('User deleted successfully')
            navigate('/settings/internal_admin/user-inspector')
          } else {
            await message.error('Unable to delete user')
          }
          setLoading(false)
        } catch (error) {
          const errMsg =
            error instanceof Error ? error.message : 'Unable to delete user'
          void message.error(errMsg)
        }
      },
    })
  }

  const isSelfInternalUser =
    authUser.IsInternalUser && userInfo?.id === authUser.id

  const userRoles = _.uniq(
    enrichedPerms?.flatMap((perm) => {
      return perm.permSources?.filter((source) => source !== PermSource.USER)
    })
  )

  const onPermWorkspaceChange = (workspace: Workspace) => {
    setPermWorkspace(workspace)
    setEnrichedPerms(getEnrichedPerms(workspace.id))
  }

  const resetProductTour = async () => {
    if (_.isEmpty(userInfo) || !authUser.isUserManagement) {
      displayErrorMessage(
        'You do not have permissions to reset the product tour'
      )
      return
    }
    const result = await ResetUserProductTour(userInfo.id)
    if (result.updatedUsers === 0) {
      displayWarningMessage('Product tour was not reset')
    } else {
      displaySuccessMessage(
        `Product tour reset for ${result.updatedUsers} user`
      )
    }
  }

  const resetOnboarding = async () => {
    if (_.isEmpty(userInfo) || !authUser.isUserManagement) {
      displayErrorMessage('You do not have permissions to reset the onboarding')
      return
    }
    await ResetOnboarding(userInfo.id)
    displaySuccessMessage('Onboarding reset for user')
  }

  const resetButton = (
    isDisabled: boolean,
    featureText: string,
    resetFn: () => void
  ) => (
    <Button
      disabled={isDisabled}
      tooltip={
        !isDisabled ? `This user has not completed the ${featureText}` : ''
      }
    >
      <Dialog>
        <DialogTrigger>Reset {featureText}</DialogTrigger>
        <ConfirmationDialog
          title={`Reset ${featureText}`}
          description={`Are you sure you want to reset the ${featureText} for this user?`}
          cta={{
            label: 'Reset',
            onClick: () => resetFn(),
          }}
          secondaryCta={{
            label: 'Cancel',
            onClick: () => {},
          }}
          variant="destructive"
          showCloseIcon={false}
        />
      </Dialog>
    </Button>
  )

  if (_.isNil(authUser) || !authUser?.IsInternalAdminReader) return <></>

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <Card className="px-4 pb-2">
          <div className="mt-4">
            <Label className="mb-4">User email</Label>
            <div className="mb-8 mt-2 flex w-96 flex-row items-center">
              <Input
                value={inputEmail ?? ''}
                onChange={(e) => setInputEmail(e.target.value)}
              />
              <TailwindButton
                variant="default"
                onClick={() => {
                  void handleSubmitEmail()
                }}
                className="ml-4"
                disabled={_.isNil(inputEmail) || inputEmail.trim().length === 0}
              >
                Inspect
              </TailwindButton>
            </div>
            {userInfoError && (
              <Banner
                title="User not found"
                description="User not found in the system. Please check the email and try again."
                className="mb-2"
                cta={{
                  label: 'Go back',
                  variant: 'outline',
                  onClick: () => {
                    navigate('/settings/internal_admin/users')
                  },
                }}
              />
            )}
            {loading ? (
              <Skeleton rows={6} rowHeight="h-5" />
            ) : (
              <>
                {!_.isEmpty(userInfo) && !_.isNil(userInfo.deletedAt) && (
                  <Alert>
                    <AlertTitle>User deleted</AlertTitle>
                    <AlertDescription>
                      This user has been deleted from Auth0 and Harvey.
                    </AlertDescription>
                  </Alert>
                )}
                {!_.isEmpty(userInfo) && (
                  <Card className="p-4">
                    <Accordion type="single" collapsible>
                      <AccordionItem className="border-0" value="info">
                        <AccordionTrigger className="p-0">
                          Raw user info
                        </AccordionTrigger>
                        <AccordionContent className="pt-4">
                          <pre>{JSON.stringify(userInfo, null, 2)}</pre>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </Card>
                )}
                {!_.isEmpty(userInfo) &&
                  (authUser.isUserManagement || isSelfInternalUser) &&
                  _.isNil(userInfo.deletedAt) && (
                    <div className="space-y-4 pt-8">
                      <h1 className="text-lg font-semibold">
                        User identifiers
                      </h1>
                      <div className="mb-2 mt-6 flex flex-col space-y-2">
                        <div className="flex items-center space-x-2">
                          <Label>User email:</Label>{' '}
                          <Badge variant="secondary">{userInfo.id}</Badge>
                        </div>
                        <div className="flex items-center space-x-2">
                          <Label>Psuedonymized email:</Label>{' '}
                          <Badge variant="secondary">
                            {userInfo.pseudonymizedId}
                          </Badge>
                        </div>
                        <div className="flex items-center space-x-2">
                          <Label>User id:</Label>{' '}
                          <Badge variant="secondary">{userInfo.dbId}</Badge>
                        </div>
                      </div>
                      <Separator />
                      <h1 className="text-lg font-semibold">
                        {authUser.isUserManagement
                          ? 'User workspace'
                          : 'Permissions'}
                      </h1>
                      <div className="space-y-2">
                        <span className="flex items-center space-x-2">
                          <h6 className="font-semibold">
                            Current user workspace:{' '}
                          </h6>
                          <TailwindButton
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                              navigate(
                                `/settings/internal_admin/workspaces/${userInfo.workspace.id}`
                              )
                            }}
                          >
                            {userInfo.workspace.clientName}
                          </TailwindButton>
                        </span>
                        <Form
                          form={workspaceForm}
                          disabled={loading}
                          layout="vertical"
                          initialValues={{ workspace: userInfo.workspace.slug }}
                          className="mt-4"
                        >
                          {authUser.isUserManagement && (
                            <>
                              <Form.Item
                                name="workspace"
                                label={
                                  <h4 className="text-md font-semibold">
                                    Update user workspace
                                  </h4>
                                }
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="label"
                                  options={workspaces
                                    .filter((w) => !w.deletedAt)
                                    .map((workspace) => ({
                                      value: workspace.id,
                                      label: workspace.clientName,
                                    }))}
                                  filterOption={(input: string, option: any) =>
                                    option?.label
                                      ?.toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  virtual={false}
                                  onChange={(value) => {
                                    setSelectedWorkspace(
                                      workspaces.find((w) => w.id === value)
                                    )
                                  }}
                                />
                              </Form.Item>
                              <div className="-mt-2 pb-4 pl-1">
                                <Checkbox
                                  label="Move with user permissions"
                                  checked={moveWithPermsChecked}
                                  onCheckedChange={() =>
                                    setMoveWithPermsChecked(
                                      !moveWithPermsChecked
                                    )
                                  }
                                />
                              </div>
                              <Form.Item>
                                <TailwindButton
                                  variant="default"
                                  onClick={() =>
                                    setWorkspaceUpdateModalOpen(true)
                                  }
                                  disabled={
                                    _.isNil(selectedWorkspace) ||
                                    userInfo.workspace.id ===
                                      selectedWorkspace.id
                                  }
                                >
                                  Update
                                </TailwindButton>
                              </Form.Item>
                              <Dialog
                                open={workspaceUpdateModalOpen}
                                onOpenChange={() =>
                                  setWorkspaceUpdateModalOpen(
                                    !workspaceUpdateModalOpen
                                  )
                                }
                              >
                                <DialogContent>
                                  <DialogHeader>
                                    <div className="flex items-center space-x-1 text-sm">
                                      <span>Updating workspace for</span>{' '}
                                      <span className="text-sm font-semibold">
                                        {userInfo.id}
                                      </span>
                                    </div>
                                  </DialogHeader>
                                  <DialogDescription>
                                    <p>
                                      This action moves{' '}
                                      <span className="mt-0.5 text-sm font-semibold">
                                        {userInfo.id}
                                      </span>{' '}
                                      to{' '}
                                      <span className="text-sm font-semibold">
                                        {selectedWorkspace?.clientName}
                                      </span>{' '}
                                      workspace.
                                    </p>
                                    <ul className="p-4">
                                      <li>
                                        <p>
                                          This doesnt move any existing user
                                          data or history from{' '}
                                          <span className="text-sm font-semibold">
                                            {userInfo.workspace.clientName}
                                          </span>{' '}
                                          to{' '}
                                          <span className="text-sm font-semibold">
                                            {selectedWorkspace?.clientName}
                                          </span>
                                          .
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          Any new query events created by{' '}
                                          <span className="text-sm font-semibold">
                                            {userInfo.id}
                                          </span>{' '}
                                          will be in the{' '}
                                          <span className="text-sm font-semibold">
                                            {selectedWorkspace?.clientName}
                                          </span>{' '}
                                          workspace.
                                        </p>
                                      </li>
                                      {moveWithPermsChecked ? (
                                        <Alert
                                          className="mt-4"
                                          variant="destructive"
                                        >
                                          <AlertTitle className="-mt-1 flex justify-between">
                                            <p>Move with permissions</p>
                                            <Checkbox
                                              checked={moveWithPermsAcknowledge}
                                              onCheckedChange={() =>
                                                setMoveWithPermsAcknowledge(
                                                  !moveWithPermsAcknowledge
                                                )
                                              }
                                            />
                                          </AlertTitle>
                                          <AlertDescription>
                                            {`Please acknowledge you want to move ${userEmail} with their current user permissions tied to their current user workspace ${userInfo.workspace.clientName}.
                                          This only copies over non-sensitive and non-internal perms.`}
                                          </AlertDescription>
                                        </Alert>
                                      ) : (
                                        <li>
                                          Any permissions for{' '}
                                          <span className="text-sm font-semibold">
                                            {userInfo.id} will not
                                          </span>{' '}
                                          be moved.
                                        </li>
                                      )}
                                    </ul>
                                  </DialogDescription>
                                  <DialogFooter>
                                    <TailwindButton
                                      variant="destructive"
                                      onClick={() => {
                                        setWorkspaceUpdateModalOpen(false)
                                        setMoveWithPermsAcknowledge(false)
                                      }}
                                    >
                                      Cancel
                                    </TailwindButton>
                                    <TailwindButton
                                      disabled={
                                        moveWithPermsChecked &&
                                        !moveWithPermsAcknowledge
                                      }
                                      onClick={() => {
                                        void handleChangeWorkspace()
                                        setWorkspaceUpdateModalOpen(false)
                                        setMoveWithPermsAcknowledge(false)
                                        setMoveWithPermsChecked(false)
                                      }}
                                    >
                                      Update
                                    </TailwindButton>
                                  </DialogFooter>
                                </DialogContent>
                              </Dialog>
                            </>
                          )}
                          <div className="pt-2">
                            <Separator />
                            <div className="flex w-full flex-row justify-between pt-4">
                              <h1>
                                <span className="mr-1 text-lg font-semibold">
                                  User permissions tied to
                                </span>
                                <span className="text-lg font-semibold">
                                  {permWorkspace?.clientName}
                                </span>
                              </h1>

                              <Tooltip>
                                <TooltipTrigger>
                                  <WorkspaceDropdown
                                    workspaces={userWorkspaces.filter(
                                      (w) => !w.deletedAt
                                    )}
                                    selected={permWorkspace}
                                    setSelected={onPermWorkspaceChange}
                                  />
                                </TooltipTrigger>
                                <TooltipContent side="left">
                                  <p>
                                    Manage permissions for{' '}
                                    {permWorkspace?.clientName}
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <div className="mb-2 mt-6">
                              <p>
                                User permissions for <b>{permEmail}</b> are
                                granted on a per-workspace basis and are tied to{' '}
                                <b>{permWorkspace?.clientName}</b>.{' '}
                                {userWorkspaces.length > 1 && (
                                  <>
                                    To grant permissions tied to a different
                                    workspace, choose it from the dropdown on
                                    the top right.
                                  </>
                                )}
                              </p>
                            </div>
                            {!_.isEmpty(userInfo) && (
                              <div className="flex items-center pb-2 pt-4">
                                <h6 className="font-semibold">User roles:</h6>
                                {userRoles.length === 0 ? (
                                  <div className="ml-2">
                                    <p>No roles assigned to {userEmail}</p>
                                  </div>
                                ) : (
                                  <div className="ml-3 pb-1">
                                    {userRoles.map((roleId, index) => (
                                      <TailwindButton
                                        key={index}
                                        variant="secondary"
                                        size="sm"
                                        className="mr-1"
                                        onClick={() => {
                                          navigate(
                                            `${BaseAppPath.Settings}/internal_admin/role-inspector`,
                                            {
                                              state: {
                                                roleId,
                                              },
                                            }
                                          )
                                        }}
                                      >
                                        {roleId}
                                      </TailwindButton>
                                    ))}
                                  </div>
                                )}
                              </div>
                            )}
                            <Form.Item
                              name="permissions"
                              label={
                                <h6>
                                  <span className="text-md mr-1 font-semibold">
                                    Add user permissions tied to
                                  </span>
                                  <span className="text-md font-semibold">
                                    {permWorkspace?.clientName}
                                  </span>
                                </h6>
                              }
                              className="mt-4"
                            >
                              <Select
                                mode="multiple"
                                placeholder="Add user permissions"
                                virtual={false}
                                style={{ width: '100%' }}
                                optionFilterProp="label"
                                options={_.sortBy(permOptions, 'name').map(
                                  (perm) => {
                                    return {
                                      label: `${perm.name} - ${perm.permId}`,
                                      value: perm.permId,
                                    }
                                  }
                                )}
                                onChange={(value) => setSelectedPerms(value)}
                              />
                            </Form.Item>
                            <Form.Item>
                              <TailwindButton
                                variant="default"
                                onClick={() => {
                                  if (
                                    selectedPerms.some(
                                      isSensitiveCustomerDataPerm
                                    )
                                  ) {
                                    setIsSensitivePermDialogOpen(true)
                                  } else {
                                    void handleAddPerms()
                                  }
                                }}
                                disabled={_.isEmpty(selectedPerms)}
                              >
                                Add
                              </TailwindButton>
                            </Form.Item>
                            {((environment === Env.PRODUCTION &&
                              SENSITIVE_DATA_PERM_GRANTER_EMAILS.has(
                                authUser.id
                              )) ||
                              environment !== Env.PRODUCTION) && (
                              <div className="pb-4">
                                <p className="mt-4 text-base font-semibold">
                                  Sensitive data permissions
                                </p>
                                <TailwindSelect
                                  value={selectedSensitiveDataPerm}
                                  onValueChange={(p) =>
                                    setSelectedSensitiveDataPerm(
                                      p as Permission
                                    )
                                  }
                                >
                                  <SelectTrigger className="mt-1 w-full min-w-[200px]">
                                    <SelectValue placeholder="Select sensitive perm" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    {sensitiveDataPermOptions.map(
                                      (permOption) => (
                                        <SelectItem
                                          key={permOption.permId}
                                          value={permOption.permId}
                                        >
                                          {permOption.name}
                                        </SelectItem>
                                      )
                                    )}
                                    {_.isEmpty(sensitiveDataPermOptions) && (
                                      <SelectItem disabled value="">
                                        No sensitive data perms available to
                                        grant
                                      </SelectItem>
                                    )}
                                  </SelectContent>
                                </TailwindSelect>
                                <TailwindButton
                                  className="mt-6"
                                  disabled={_.isEmpty(
                                    selectedSensitiveDataPerm
                                  )}
                                  onClick={() => {
                                    setIsSensitivePermDialogOpen(true)
                                  }}
                                >
                                  Add
                                </TailwindButton>
                              </div>
                            )}
                            <Dialog open={isSensitivePermDialogOpen}>
                              <DialogContent showCloseIcon={false}>
                                <DialogHeader>
                                  <DialogTitle>
                                    Adding sensitive data permission
                                  </DialogTitle>
                                </DialogHeader>
                                <DialogDescription>
                                  Granting{' '}
                                  <span className="font-semibold">
                                    {selectedSensitiveDataPerm}
                                  </span>
                                  , please choose a category for why this perm
                                  is granted along with a justification. Include
                                  explicit customer consent, links to tickets
                                  and other relevant information.
                                </DialogDescription>
                                <div>
                                  <Label>Category</Label>
                                  <TailwindSelect
                                    value={sensitiveDataPermGrantCategory}
                                    onValueChange={(c) =>
                                      setSensitiveDataPermGrantCategory(c)
                                    }
                                  >
                                    <SelectTrigger className="mt-1 w-full">
                                      <SelectValue>
                                        {sensitiveDataPermGrantCategory}
                                      </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                      {SENSITIVE_PERM_GRANT_CATEGORY.map(
                                        (value) => (
                                          <SelectItem key={value} value={value}>
                                            {value}
                                          </SelectItem>
                                        )
                                      )}
                                    </SelectContent>
                                  </TailwindSelect>
                                </div>
                                <div className="mt-2">
                                  <Label>Justification</Label>
                                  <Textarea
                                    placeholder="Enter the reason for adding this permission, include any link and customer requests"
                                    value={sensitivePermReason}
                                    onChange={(e) =>
                                      setSensitivePermReason(e.target.value)
                                    }
                                    className="w-full"
                                  />
                                  <span className="pt-2 text-xs text-muted">
                                    Minimum {minSensitivePermReasonLength}{' '}
                                    characters
                                  </span>
                                </div>
                                <div className="space-y-1">
                                  <Label>Expiry Time</Label>
                                  <TailwindSelect
                                    value={sensitivePermExpiry}
                                    onValueChange={(t) =>
                                      setSensitivePermExpiry(
                                        t as SensitiveDataPermExpiryTime
                                      )
                                    }
                                  >
                                    <SelectTrigger className="w-full">
                                      <SelectValue>
                                        {sensitivePermExpiry}
                                      </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.entries(
                                        SensitiveDataPermExpiryTime
                                      ).map(([key, value]) => (
                                        <SelectItem key={key} value={value}>
                                          {value}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </TailwindSelect>
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <Button
                                      variant="outline"
                                      onClick={() => {
                                        setIsSensitivePermDialogOpen(false)
                                        setSensitivePermReason('')
                                        setSensitivePermExpiry(
                                          SensitiveDataPermExpiryTime.TEN_MINUTES
                                        )
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </DialogClose>
                                  <DialogClose asChild>
                                    <Button
                                      variant="default"
                                      onClick={async () => {
                                        void handleAddingSensitiveDataPerm()
                                        setIsSensitivePermDialogOpen(false)
                                      }}
                                      disabled={
                                        _.isEmpty(sensitivePermReason) ||
                                        sensitivePermReason.length <
                                          minSensitivePermReasonLength
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                        </Form>
                        <Separator />
                        <h1 className="text-lg font-semibold">
                          Current Permissions
                        </h1>
                        <UserPermissionsTable
                          perms={enrichedPerms ?? []}
                          onDeletePerm={deleteUserPerm}
                        />
                        {selectedWorkspace &&
                          queryCapRules &&
                          authUser.isReadEngInternal &&
                          userInfo.permissions.includes(
                            Permission.VAULT_REVIEW_CREATE
                          ) && (
                            <div id="query-caps">
                              <Separator />
                              <h1 className="text-lg font-semibold">
                                Query cap rules
                              </h1>
                              <QueryCapRulesTable
                                queryCapRules={queryCapRules}
                                workspace={selectedWorkspace}
                                userInfo={new UserInfo(userInfo)}
                              />
                            </div>
                          )}
                        {authUser.isUserManagement && (
                          <div className="my-4 space-y-2">
                            <Separator />
                            <h1 className="text-lg font-semibold">
                              User state management
                            </h1>
                            <div className="flex items-center gap-2">
                              {resetButton(
                                !(
                                  userInfo.settings?.v1ProductTourCompleted ==
                                  true
                                ),
                                'product tour',
                                resetProductTour
                              )}
                              {resetButton(
                                !userInfo.settings?.completedOnboarding,
                                'onboarding',
                                resetOnboarding
                              )}
                            </div>
                          </div>
                        )}
                        {authUser.isUserManagement && (
                          <div className="my-4 space-y-2">
                            <Separator />
                            <h1 className="text-lg font-semibold">
                              Danger zone
                            </h1>
                            <Card className="border-destructive bg-primary">
                              <CardHeader className="text-lg font-semibold text-destructive">
                                Delete user
                              </CardHeader>
                              <CardContent>
                                <div className="text-md mb-4 font-semibold text-destructive">
                                  This will delete the user from the Harvey and
                                  Auth0. This action cannot be undone.
                                </div>
                                <TailwindButton
                                  variant="destructive"
                                  onClick={() => void deleteUser()}
                                  disabled={loading}
                                >
                                  Delete
                                </TailwindButton>
                              </CardContent>
                            </Card>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        </Card>
      </SettingsLayout>
    </>
  )
}

export default UserInspector
