import React, { useState } from 'react'
import { useInterval } from 'react-use'

import { formatDistanceToNow } from 'date-fns'
import { useShallow } from 'zustand/react/shallow'

import { Maybe } from 'types'

import { TodayOption, readableFormat } from 'utils/date-utils'

import { DOT_SEPARATOR } from 'components/vault/utils/vault'
import {
  getEtaDisplayString,
  getFileCountHeaderString,
} from 'components/vault/utils/vault-helpers'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const QueryDetailBreadcrumb = ({
  numFiles,
  processedFileIds,
  isLoading,
  completedAt,
  pausedAt,
  failedAt,
  eta,
  startedAt,
  projectId,
  title,
  creatorUserEmail,
}: {
  numFiles: number
  processedFileIds?: string[]
  isLoading: boolean
  completedAt: Maybe<Date>
  pausedAt: Maybe<Date>
  failedAt: Maybe<Date>
  eta: Maybe<Date>
  startedAt: Maybe<Date>
  projectId: Maybe<string>
  title: string
  creatorUserEmail?: string
}) => {
  const sharedProjectIds = useVaultStore(useShallow((s) => s.sharedProjectIds))
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((s) => s.permissionsByProjectId)
  )
  const isSharedProject = isProjectShared(
    sharedProjectIds,
    permissionsByProjectId,
    projectId ?? undefined
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minuteCounter, setMinuteCounter] = useState(0)
  useInterval(
    () => {
      setMinuteCounter((prev) => prev + 1)
    },
    isLoading ? 60000 : null
  ) // Every 1 minute update the component to refresh the relative time

  const prefix = getFileCountHeaderString({
    numFiles,
    processedFileIds,
  })
  const createdByText =
    creatorUserEmail && isSharedProject ? `Created by ${creatorUserEmail}` : ''
  const loadingText = eta
    ? getEtaDisplayString(eta, true)
    : startedAt
    ? `Processing started ${formatDistanceToNow(startedAt, {
        addSuffix: true,
      })}`
    : ''
  const suffix = isLoading
    ? loadingText
    : pausedAt
    ? `Paused ${readableFormat(pausedAt, TodayOption.showTime, 'short')}`
    : failedAt
    ? `Failed ${readableFormat(failedAt, TodayOption.showTime, 'short')}`
    : completedAt
    ? `Answered ${readableFormat(completedAt, TodayOption.showTime, 'short')}`
    : ''
  return (
    <div className="mr-4">
      <p className="truncate text-xl font-medium">{title}</p>

      <div className="flex items-center gap-1">
        <p className="truncate text-xs text-muted">
          {[prefix, createdByText, suffix].filter(Boolean).join(DOT_SEPARATOR)}
        </p>
      </div>
    </div>
  )
}

export default QueryDetailBreadcrumb
