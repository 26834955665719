// Height of the 'Sources' header
export const HEADER_HEIGHT = 36
// Height of the 'Files' and 'Databases' header under 'Sources'
export const SOURCE_HEADER_HEIGHT = 24
// Minimum height of the dropzone section without causing overflow
export const BASE_DROPZONE_HEIGHT = 187
// Height of a single database button
export const BASE_VERTICAL_PADDING = 24

export const calculateAssistantSourceHeight = () => {
  // We fix the height so when user moves on to files uploaded view,
  // or knowledge source view, the area doesn't jump.
  return (
    SOURCE_HEADER_HEIGHT +
    BASE_DROPZONE_HEIGHT +
    HEADER_HEIGHT +
    BASE_VERTICAL_PADDING
  )
}
