import React, { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { isNil } from 'lodash'
import _ from 'lodash'
import { CloudUploadIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { RelatedQuestion } from 'openapi/models/RelatedQuestion'
import { useIntegrationsStore } from 'stores/integrations-store'
import { FileTypeToExtension } from 'types/file'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayWarningMessage } from 'utils/toast'
import { QUERY_MUST_HAVE_X_LEN_HELP_TEXT } from 'utils/tooltip-texts'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { AssistantChatStreamHandler } from 'components/assistant/hooks/use-assistant-chat'
import { AssistantDraftStreamHandler } from 'components/assistant/hooks/use-assistant-draft'
import { useAssistantMidThreadFileUpload } from 'components/assistant/hooks/use-assistant-mid-thread-file-upload'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  getQueryLimit,
  isQueryValid,
} from 'components/assistant/utils/assistant-helpers'
import {
  ACCEPTED_FILE_TYPES,
  MIN_QUERY_LENGTH,
} from 'components/assistant/utils/constants'
import AddMoreFiles from 'components/common/add-more-files'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import QueryLimitTooltip from 'components/common/query-limit-tooltip/query-limit-tooltip'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Card, CardContent } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { ScrollArea } from 'components/ui/scroll-area'
import { Textarea, TextareaProps } from 'components/ui/text-area'

import { AssistantCreateCopy } from './assistant-create-copy'
import AssistantFiles from './assistant-files'
import AssistantFollowUps from './assistant-follow-ups'
import { AssistantLoadPromptButton } from './assistant-load-prompt-button'
import { AssistantMode } from './assistant-mode-select'

interface Props extends TextareaProps {
  inputRef?: React.MutableRefObject<HTMLTextAreaElement | null>
  isStreaming?: boolean
  prevMessageId?: string
  relatedQuestions?: RelatedQuestion[]
  useChat?: AssistantChatStreamHandler
  useDraft?: AssistantDraftStreamHandler
  isFinalizingStream?: boolean
  isRevision?: boolean
  onAsk?: () => void
}

const AssistantReplyInput = ({
  inputRef,
  isStreaming,
  onBlur,
  prevMessageId,
  relatedQuestions,
  useChat,
  useDraft,
  isFinalizingStream,
  isRevision,
  onAsk,
}: Props) => {
  const { mode } = useParams()
  const trackEvent = useAssistantAnalytics()
  const assistantMode =
    mode === AssistantMode.ASSIST ? AssistantMode.ASSIST : AssistantMode.DRAFT

  const [
    pendingMessage,
    query,
    eventId,
    documentsUploading,
    documents,
    setQuery,
    messages,
    getCurrentThreadMessages,
    setPendingMessage,
    isEventOwner,
    knowledgeSource,
    isReadOnly,
  ] = useAssistantStore(
    useShallow((s) => [
      s.pendingMessage,
      s.query,
      s.eventId,
      s.documentsUploading,
      s.documents,
      s.setQuery,
      s.messages,
      s.getCurrentThreadMessages,
      s.setPendingMessage,
      s.isEventOwner,
      s.knowledgeSource,
      s.isReadOnly,
    ])
  )

  const {
    zipFiles,
    followupDocuments,
    hasUploadedFiles,
    filesAttachedCount,
    handleRemoveFile,
    onUploadFromComputer,
    onUploadFromSharepoint,
  } = useAssistantMidThreadFileUpload(assistantMode)

  const navigate = useNavigateWithQueryParams()
  const [queryPreview, setQueryPreview] = React.useState<string | null>(null)

  const [setSharepointPickerOpenState] = useIntegrationsStore(
    useShallow((s) => [s.setSharepointPickerOpenState])
  )

  const { eventId: currentEventId } = useParams()
  const authCallback = useCallback(
    (eventId: string) => {
      if (eventId && eventId !== currentEventId) {
        navigate(`/assistant/${mode}/${eventId}`)
      }
      setQuery('')
    },
    [mode, navigate, currentEventId, setQuery]
  )

  const handleSetQuery = (q: string) => {
    if (q.length > queryLimit && q.length >= query.length) {
      displayWarningMessage(
        `Query length cannot exceed ${queryLimit} characters`
      )
    } else {
      setQuery(q)
    }
  }

  const handleAsk = useCallback(
    async (prompt: string) => {
      if (isRevision) {
        trackEvent('Add Revision Confirmed', {
          query_length: query.length,
          thread_number: getCurrentThreadMessages().length + 1,
          message_number: messages.length + 1,
        })
      }
      if (mode === AssistantMode.ASSIST) {
        if (!useChat) throw new Error('useChat is required')
        await useChat.createChatReply(prompt, prevMessageId, authCallback)
      } else {
        if (!useDraft) throw new Error('useDraft is required')
        useDraft.createDraftEditRequest(prompt, prevMessageId, authCallback)
      }
      if (onAsk) onAsk()
    },
    [
      isRevision,
      mode,
      authCallback,
      trackEvent,
      query.length,
      getCurrentThreadMessages,
      messages.length,
      useChat,
      prevMessageId,
      useDraft,
      onAsk,
    ]
  )

  useEffect(() => {
    // when we finish streaming and there is a pending message
    if (!isStreaming && pendingMessage) {
      const { query } = pendingMessage
      setPendingMessage(null)
      // initialize next query
      void handleAsk(query!)
    }
  }, [isStreaming, pendingMessage, handleAsk, setPendingMessage])

  const handleAskClick = () => {
    handleSubmitQuery(query)
  }

  const handleSubmitQuery = (prompt: string) => {
    if (isFinalizingStream) {
      const pendingMessage = {
        query: prompt,
        isLoading: true,
        sources: [],
      }
      setPendingMessage(pendingMessage)
    } else {
      void handleAsk(prompt)
    }
  }

  const hasDocuments = !!(documents.length || documentsUploading.length)
  const queryLimit = getQueryLimit(hasDocuments, true)
  const isAskHarveyDisabled = !isQueryValid(query, queryLimit)

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const mergedRefs = (ref: HTMLTextAreaElement) => {
    textareaRef.current = ref
    if (inputRef) inputRef.current = ref
  }

  // This is a workaround to prevent the textarea from blurring when the load prompt popover is opened
  const blurInput = useRef(true)
  const handleTextareaBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!blurInput.current) return
    const isPromptButtonClick = e.relatedTarget?.id === 'load-prompt'
    if (isPromptButtonClick) blurInput.current = false
    else onBlur?.(e)
  }
  const handleLoadPromptPopoverChange = (isOpen: boolean) => {
    if (!isOpen) {
      blurInput.current = true
      textareaRef.current?.focus()
    }
  }

  const placeholder =
    mode === AssistantMode.DRAFT
      ? 'Describe how to change the draft'
      : 'Ask a follow-up question'

  const getDisabledText = () => {
    if (query.trim().length < MIN_QUERY_LENGTH) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`at least ${MIN_QUERY_LENGTH}`)
    } else if (query.trim().length > queryLimit) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`fewer than ${queryLimit}`)
    } else {
      return ''
    }
  }

  const textareaDisabled = isStreaming && !isFinalizingStream
  const isAssist = mode === AssistantMode.ASSIST
  const showUploadButton = isAssist && isNil(knowledgeSource)

  const uploadFromSharepointClick = () => {
    setSharepointPickerOpenState({
      acceptedFileTypes: _.flatMap(
        ACCEPTED_FILE_TYPES,
        (type) => FileTypeToExtension[type] ?? []
      ),
      onUploadFromSharepoint: onUploadFromSharepoint,
    })
  }
  const authUser = useAuthUser()
  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(authUser)

  const inputComponent = (
    <Card className="border-none" id="assistant-input">
      <CardContent
        className={cn(
          'relative rounded p-0 focus-within:ring-1 focus-within:ring-ring',
          'before:pointer-events-none before:absolute before:inset-0 before:z-10 before:rounded before:ring-ring focus-within:before:ring-1'
        )}
        id="assistant-text-input"
      >
        <Label className="sr-only" htmlFor="assistant-follow-up-input">
          Follow up
        </Label>
        <Textarea
          id="assistant-follow-up-input"
          className={cn(
            'max-h-60 min-h-0 resize-none rounded-[3px] rounded-b-none border-b-0 p-6 pb-0 ring-inset focus-visible:ring-0',
            {
              'p-2 pb-1 text-xs placeholder:text-xs':
                mode === AssistantMode.DRAFT,
              'disabled:cursor-default': queryPreview,
            }
          )}
          disabled={textareaDisabled}
          isFluid
          onChange={(e) => handleSetQuery(e.target.value)}
          onBlur={handleTextareaBlur}
          placeholder={queryPreview || placeholder}
          ref={mergedRefs}
          value={queryPreview ? '' : query}
        />
        <div
          className={cn('absolute right-6 -mt-1', {
            'right-2 -mt-4': mode === AssistantMode.DRAFT,
          })}
        >
          <QueryLimitTooltip query={query} queryLimit={queryLimit} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={cn(
            'flex cursor-text justify-end rounded-b border border-t-0 border-input p-6 pt-5',
            {
              'p-2': mode === AssistantMode.DRAFT,
              'rounded-b-none': hasUploadedFiles,
            }
          )}
          onClick={() => textareaRef.current?.focus()}
        >
          <AssistantLoadPromptButton
            setQuery={handleSetQuery}
            setQueryPreview={setQueryPreview}
            eventKind={
              mode === AssistantMode.ASSIST
                ? EventKind.ASSISTANT_CHAT
                : EventKind.ASSISTANT_DRAFT
            }
            handleLoadPromptPopoverChange={handleLoadPromptPopoverChange}
            disabled={isStreaming}
          />
          {showUploadButton && (
            <AddMoreFiles
              onUploadFromComputer={onUploadFromComputer}
              handleSharePointClick={
                sharepointEnabled ? uploadFromSharepointClick : undefined
              }
              leadingIcon={CloudUploadIcon}
              buttonVariant="ghost"
              buttonText="Upload"
              buttonSize="sm"
              align="start"
            />
          )}
          <AskHarveyButton
            className={cn(
              'pointer-events-auto ml-auto w-auto shrink-0 whitespace-nowrap font-semibold',
              {
                'text-xs': mode === AssistantMode.DRAFT,
              }
            )}
            id="assistant-submit"
            handleSubmit={handleAskClick}
            tooltip={getDisabledText()}
            disabled={isAskHarveyDisabled}
            size="sm"
            inputRef={textareaRef}
            // don't enforce client matter selection for replies
            shouldEnforceClientMatterSelection={false}
          />
        </div>
        {isAssist && hasUploadedFiles && (
          <div className="rounded-md rounded-t-none border border-t-0 border-input">
            <ScrollArea className="h-full" maxHeight="max-h-52">
              <p className="px-4 pt-4 font-medium">Files</p>
              <AssistantFiles
                className="px-4"
                files={followupDocuments}
                uploadingFiles={documentsUploading}
                handleRemoveFile={handleRemoveFile}
                zipFiles={zipFiles}
              />
            </ScrollArea>
            <div className="flex items-center justify-between border-t px-4 py-3">
              <p className="text-xs">
                {`${filesAttachedCount} ${pluralize(
                  'file',
                  filesAttachedCount
                )} attached`}
              </p>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )

  if (isReadOnly) return null

  return isEventOwner ? (
    <div id="assistant-follow-up">
      {relatedQuestions && (
        <AssistantFollowUps
          questions={relatedQuestions}
          onSelectQuestion={handleSubmitQuery}
        />
      )}
      {inputComponent}
    </div>
  ) : (
    <AssistantCreateCopy eventId={eventId} mode={mode as AssistantMode} />
  )
}

export default AssistantReplyInput
