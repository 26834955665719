/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkflowRenderComponentBlocks = {
    ANSWER: 'Answer'
} as const;
export type WorkflowRenderComponentBlocks = typeof WorkflowRenderComponentBlocks[keyof typeof WorkflowRenderComponentBlocks];


export function instanceOfWorkflowRenderComponentBlocks(value: any): boolean {
    for (const key in WorkflowRenderComponentBlocks) {
        if (Object.prototype.hasOwnProperty.call(WorkflowRenderComponentBlocks, key)) {
            if (WorkflowRenderComponentBlocks[key as keyof typeof WorkflowRenderComponentBlocks] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkflowRenderComponentBlocksFromJSON(json: any): WorkflowRenderComponentBlocks {
    return WorkflowRenderComponentBlocksFromJSONTyped(json, false);
}

export function WorkflowRenderComponentBlocksFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowRenderComponentBlocks {
    return json as WorkflowRenderComponentBlocks;
}

export function WorkflowRenderComponentBlocksToJSON(value?: WorkflowRenderComponentBlocks | null): any {
    return value as any;
}

export function WorkflowRenderComponentBlocksToJSONTyped(value: any, ignoreDiscriminator: boolean): WorkflowRenderComponentBlocks {
    return value as WorkflowRenderComponentBlocks;
}

