/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Streaming Protocol (via websockets)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Scope of the server message
 * @export
 */
export const ServerMessageScope = {
    VAULT: 'VAULT'
} as const;
export type ServerMessageScope = typeof ServerMessageScope[keyof typeof ServerMessageScope];


export function instanceOfServerMessageScope(value: any): boolean {
    for (const key in ServerMessageScope) {
        if (Object.prototype.hasOwnProperty.call(ServerMessageScope, key)) {
            if (ServerMessageScope[key as keyof typeof ServerMessageScope] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ServerMessageScopeFromJSON(json: any): ServerMessageScope {
    return ServerMessageScopeFromJSONTyped(json, false);
}

export function ServerMessageScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerMessageScope {
    return json as ServerMessageScope;
}

export function ServerMessageScopeToJSON(value?: ServerMessageScope | null): any {
    return value as any;
}

export function ServerMessageScopeToJSONTyped(value: any, ignoreDiscriminator: boolean): ServerMessageScope {
    return value as ServerMessageScope;
}

