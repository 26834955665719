import React from 'react'

import { UploadCloudIcon } from 'lucide-react'

import Icon from 'components/ui/icon/icon'

interface VaultUploadInsetProps {
  isDragActive: boolean
}

const VaultUploadInset = ({ isDragActive }: VaultUploadInsetProps) => {
  if (!isDragActive) return null

  return (
    <div className="absolute inset-0 z-50 flex items-center justify-center bg-primary">
      <div className="flex flex-col items-center justify-center space-y-4">
        <Icon icon={UploadCloudIcon} className="size-[64px] stroke-inactive" />
        <div className="flex flex-col items-center space-y-2 pt-6">
          <p className="text-xl font-semibold">Drop to upload</p>
          <p className="text-lg text-muted">
            Attach files to your Vault project
          </p>
        </div>
      </div>
    </div>
  )
}

export default VaultUploadInset
