/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration of contracts tasks
 * @export
 */
export const ContractsTask = {
    EXTRACT: 'extract',
    CREATE_TERM: 'create_term',
    SMART_FILTER: 'smart_filter'
} as const;
export type ContractsTask = typeof ContractsTask[keyof typeof ContractsTask];


export function instanceOfContractsTask(value: any): boolean {
    for (const key in ContractsTask) {
        if (Object.prototype.hasOwnProperty.call(ContractsTask, key)) {
            if (ContractsTask[key as keyof typeof ContractsTask] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ContractsTaskFromJSON(json: any): ContractsTask {
    return ContractsTaskFromJSONTyped(json, false);
}

export function ContractsTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsTask {
    return json as ContractsTask;
}

export function ContractsTaskToJSON(value?: ContractsTask | null): any {
    return value as any;
}

export function ContractsTaskToJSONTyped(value: any, ignoreDiscriminator: boolean): ContractsTask {
    return value as ContractsTask;
}

