import React, { useMemo, useState } from 'react'

import { InfoIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'

import { TodayOption, readableFormat } from 'utils/date-utils'
import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import useRecentQueries from 'components/vault/hooks/use-recent-queries'
import { NUM_ALL_QUERIES_TO_FETCH } from 'components/vault/utils/vault'
import { RerunVaultReviewQueries } from 'components/vault/utils/vault-fetcher'
import { getExistingCompletedVaultReviewQueries } from 'components/vault/utils/vault-helpers'
import {
  VaultExtraSocketState,
  useVaultStore,
} from 'components/vault/utils/vault-store'
import {
  getQueryUsageStringWithUnit,
  useVaultUsageStore,
} from 'components/vault/utils/vault-usage-store'

const VaultReviewQuerySelectionDialog: React.FC = () => {
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const recentlyUploadedFileIds = useVaultStore(
    (s) => s.recentlyUploadedFileIds
  )
  const currentProject = useVaultStore((s) => s.currentProject)
  const currentProjectMetadata = useVaultStore((s) => s.currentProjectMetadata)

  const isReviewQuerySelectionDialogOpen = useVaultStore(
    (s) => s.isReviewQuerySelectionDialogOpen
  )
  const reviewQueryCapExplanation = useVaultUsageStore(
    (state) => state.reviewQueryCapExplanation
  )
  const reviewQueryDenominator = useVaultUsageStore(
    (state) => state.reviewQueryDenominator
  )
  const reviewQueryUsage = useVaultUsageStore((state) => state.reviewQueryUsage)
  const reviewQueryLimit = useVaultUsageStore((state) => state.reviewQueryLimit)
  const reviewQueryRemaining = reviewQueryLimit
    ? reviewQueryLimit - reviewQueryUsage
    : null
  const reviewQueryLimitUnitLevel = useVaultUsageStore(
    (state) => state.reviewQueryLimitUnitLevel
  )
  const reviewQueryUnit = useVaultUsageStore((state) => state.reviewQueryUnit)

  const setIsReviewQuerySelectionDialogOpen = useVaultStore(
    (s) => s.setIsReviewQuerySelectionDialogOpen
  )
  const markInProgressReviewTask = useVaultStore(
    (s) => s.markInProgressReviewTask
  )
  const setReviewTask = useVaultStore((s) => s.setReviewTask)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedQueries, setSelectedQueries] = useState<string[]>([])

  const handleCheckboxChange = (queryId: string) => {
    if (selectedQueries.includes(queryId)) {
      setSelectedQueries(selectedQueries.filter((q) => q !== queryId))
    } else {
      setSelectedQueries([...selectedQueries, queryId])
    }
  }

  const onDismiss = () => {
    setIsReviewQuerySelectionDialogOpen(false)
    setSelectedQueries([])
  }

  const handleRunQueries = async () => {
    setIsSubmitting(true)
    selectedQueries.forEach((queryId) => {
      const queryState = queryIdToState[queryId]
      const newFileIds = [
        ...(queryState?.fileIds || []),
        ...recentlyUploadedFileIds,
      ]
      markInProgressReviewTask(queryId)
      // Follow similar logic as generateNNResponse to update the review task
      setReviewTask({
        // It's not a dry run if we are retrying the review query
        dryRun: false,
        queryId: queryId,
        isLoading: true,
        headerText: 'Processing',
        eta: null,
        numFiles: newFileIds.length,
        fileIds: newFileIds,
        // Showing new start time to indicate that the task is in progress
        startedAt: new Date(),
        // Clear the paused and failed times
        pausedAt: null,
        failedAt: null,
      })
    })
    const pendingQueryUsageByQueryId: { [key: string]: number } =
      selectedQueries.reduce(
        (acc, queryId: string) => {
          acc[queryId] =
            (queryIdToState[queryId]?.numQuestions || 0) *
            recentlyUploadedFileIds.length
          return acc
        },
        {} as { [key: string]: number }
      )
    const pendingFileUsageByQueryId = selectedQueries.reduce(
      (acc, queryId) => {
        acc[queryId] = recentlyUploadedFileIds.length
        return acc
      },
      {} as { [key: string]: number }
    )
    await RerunVaultReviewQueries({
      requestType: 'extra_files',
      queryIds: selectedQueries,
      fileIds: recentlyUploadedFileIds,
      pendingQueryUsageByQueryId: pendingQueryUsageByQueryId,
      pendingFileUsageByQueryId: pendingFileUsageByQueryId,
      projectId: currentProject!.id,
    })
    setIsSubmitting(false)
    onDismiss()
  }

  const selectedQueriesUsage = useMemo(() => {
    let currentUsage = 0
    for (const queryId of selectedQueries) {
      const query = queryIdToState[queryId]
      if (!query) continue
      if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.CELL) {
        currentUsage += query.numQuestions * recentlyUploadedFileIds.length
      } else if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.FILE) {
        currentUsage += recentlyUploadedFileIds.length
      }
    }
    return currentUsage
  }, [
    queryIdToState,
    selectedQueries,
    recentlyUploadedFileIds.length,
    reviewQueryLimitUnitLevel,
  ])

  const selectedQueriesUsageString =
    selectedQueriesUsage > 0
      ? `Uses ${getQueryUsageStringWithUnit(
          selectedQueriesUsage,
          reviewQueryUnit,
          reviewQueryDenominator
        )}`
      : ''

  const { historyData } = useRecentQueries({
    projectId: currentProjectMetadata.id,
    maxQueries: NUM_ALL_QUERIES_TO_FETCH,
    hasInProgressHistoryEvents: false,
  })

  const reviewQueries = getExistingCompletedVaultReviewQueries(
    historyData?.events || [],
    new Set(recentlyUploadedFileIds)
  )

  const areSelectedQueriesUsageExceedingLimit = reviewQueryRemaining
    ? selectedQueriesUsage > reviewQueryRemaining
    : false

  const isRunQueriesDisabled =
    areSelectedQueriesUsageExceedingLimit ||
    selectedQueries.length === 0 ||
    isSubmitting

  const getRunQueriesDisabledTooltip = () => {
    if (areSelectedQueriesUsageExceedingLimit) {
      return 'Selected queries exceed your review query limit'
    } else if (selectedQueries.length === 0) {
      return 'Please select at least one query to run'
    }
    return undefined
  }

  if (reviewQueries.length === 0) {
    return null
  }

  return (
    <Dialog
      open={isReviewQuerySelectionDialogOpen}
      onOpenChange={setIsReviewQuerySelectionDialogOpen}
    >
      <DialogContent showCloseIcon>
        <DialogHeader>
          <DialogTitle>Add to existing queries</DialogTitle>
          <DialogDescription>
            Select existing review queries to add these files to
          </DialogDescription>
        </DialogHeader>
        <div className="mt-2 space-y-2">
          <ScrollArea maxHeight="max-h-64 pr-2">
            {reviewQueries.map((query, index) => {
              return (
                <div
                  key={`${query.id}-container`}
                  className="mt-1 flex cursor-pointer items-center justify-between space-x-2"
                  data-testid={`vault-review-query-selection-dialog--option-${index}`}
                >
                  <Checkbox
                    id={query.id.toString()}
                    checked={selectedQueries.includes(query.id.toString())}
                    onCheckedChange={() => {
                      handleCheckboxChange(query.id.toString())
                    }}
                    checkboxClassName="disabled:cursor-default"
                    label={(query.metadata as VaultExtraSocketState).title}
                  />
                  <Badge
                    key={`${query.id}-badge`}
                    variant="secondary"
                    className={cn('h-6 rounded-full border-0 text-muted')}
                  >
                    <p className="text-xs">
                      {readableFormat(
                        new Date(query.updatedAt),
                        TodayOption.showTime
                      )}
                    </p>
                  </Badge>
                </div>
              )
            })}
          </ScrollArea>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {selectedQueries.length > 0 &&
              selectedQueriesUsageString.length > 0 && (
                <>
                  <p>{selectedQueriesUsageString}</p>
                  <Tooltip delayDuration={200}>
                    <TooltipTrigger>
                      <Icon icon={InfoIcon} className="text-muted" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-sm text-start">
                      {reviewQueryCapExplanation}
                    </TooltipContent>
                  </Tooltip>
                </>
              )}
          </div>

          <Button
            variant="default"
            disabled={isRunQueriesDisabled}
            onClick={handleRunQueries}
            tooltip={getRunQueriesDisabledTooltip()}
          >
            {isSubmitting ? (
              <div className="flex items-center">
                <Spinner size="xxs" className="top-3 mr-2" />
                <p>Adding to query…</p>
              </div>
            ) : (
              <p>Run queries</p>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultReviewQuerySelectionDialog
