/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Streaming Protocol (via websockets)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Annotation } from './Annotation';
import {
    AnnotationFromJSON,
    AnnotationFromJSONTyped,
    AnnotationToJSON,
    AnnotationToJSONTyped,
} from './Annotation';

/**
 * 
 * @export
 * @interface HallucinationWarningAnnotation
 */
export interface HallucinationWarningAnnotation extends Annotation {
}

/**
 * Check if a given object implements the HallucinationWarningAnnotation interface.
 */
export function instanceOfHallucinationWarningAnnotation(value: object): value is HallucinationWarningAnnotation {
    return true;
}

export function HallucinationWarningAnnotationFromJSON(json: any): HallucinationWarningAnnotation {
    return HallucinationWarningAnnotationFromJSONTyped(json, false);
}

export function HallucinationWarningAnnotationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HallucinationWarningAnnotation {
    return json;
}

export function HallucinationWarningAnnotationToJSON(json: any): HallucinationWarningAnnotation {
    return HallucinationWarningAnnotationToJSONTyped(json, false);
}

export function HallucinationWarningAnnotationToJSONTyped(value?: HallucinationWarningAnnotation | null, ignoreDiscriminator: boolean = false): any {
    return value;
}

