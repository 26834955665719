/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the workspace eyes-off tiers.
 * @export
 */
export const WorkspaceEyesOffTier = {
    EYES_OFFSTRICT: 'eyes_off:strict',
    EYES_OFFBASIC: 'eyes_off:basic',
    EYES_OFFDISABLED: 'eyes_off:disabled'
} as const;
export type WorkspaceEyesOffTier = typeof WorkspaceEyesOffTier[keyof typeof WorkspaceEyesOffTier];


export function instanceOfWorkspaceEyesOffTier(value: any): boolean {
    for (const key in WorkspaceEyesOffTier) {
        if (Object.prototype.hasOwnProperty.call(WorkspaceEyesOffTier, key)) {
            if (WorkspaceEyesOffTier[key as keyof typeof WorkspaceEyesOffTier] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkspaceEyesOffTierFromJSON(json: any): WorkspaceEyesOffTier {
    return WorkspaceEyesOffTierFromJSONTyped(json, false);
}

export function WorkspaceEyesOffTierFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceEyesOffTier {
    return json as WorkspaceEyesOffTier;
}

export function WorkspaceEyesOffTierToJSON(value?: WorkspaceEyesOffTier | null): any {
    return value as any;
}

export function WorkspaceEyesOffTierToJSONTyped(value: any, ignoreDiscriminator: boolean): WorkspaceEyesOffTier {
    return value as WorkspaceEyesOffTier;
}

