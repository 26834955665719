import React, { useEffect, useRef, useState } from 'react'

import _ from 'lodash'
import { BookmarkPlus } from 'lucide-react'

import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { getUniquePracticeAreaTaxonomies } from 'utils/user-profile-helpers'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { useUserProfileStore } from 'components/common/user-profile-store'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { MultiSelect } from 'components/ui/multi-select'
import { Spinner } from 'components/ui/spinner'

import { Example, LibraryItemKind } from './library-types'
import {
  getDocumentTypes,
  getCategories,
  getPracticeAreas,
} from './library.helpers'
import { useLibraryData } from './use-library-data'

type Props = {
  eventId: string
  dialogOnly?: boolean
  onOpenChange?: (isOpen: boolean) => void
  saveExampleDisabled?: boolean
}

export const LibrarySaveExample: React.FC<Props> = ({
  eventId,
  dialogOnly,
  onOpenChange,
  saveExampleDisabled,
}) => {
  const { trackEvent } = useAnalytics()
  const [dialogOpen, setDialogOpen] = useState(!!dialogOnly)
  const [saveLoading, setSaveLoading] = useState(false)
  const onDialogOpenChange = (isOpen: boolean) => {
    setDialogOpen(isOpen)
    onOpenChange?.(isOpen)
  }

  const handleSaveExample = async (
    params: Partial<
      Pick<
        Example,
        | 'name'
        | 'categories'
        | 'practiceAreas'
        | 'documentTypes'
        | 'visibilityScope'
      >
    >
  ) => {
    const { name, documentTypes, categories, practiceAreas, visibilityScope } =
      params
    setSaveLoading(true)
    let result = false
    try {
      const example = await Services.Backend.Post<Example>('library/example', {
        name: name?.trim(),
        sourceEventId: eventId,
        categories,
        practiceAreas,
        documentTypes,
        visibilityScope: visibilityScope,
      })

      if (!_.isEmpty(example)) {
        Services.HoneyComb.Record({
          metric: 'ui.library_example_saved',
          item_id: example.id,
          task_type: example.eventKind,
          starred: example.starred,
          workspace_id: example.workspaceId,
          user_id: example.userId,
          visibility_scope: example.visibilityScope,
          categories: example.categories,
          practice_areas: example.practiceAreas,
          document_types: example.documentTypes,
        })
        trackEvent('Library Item Created', {
          item_id: example.id,
          event_id: example.eventId,
          kind: 'example',
          task_type: example.eventKind,
          categories: example.categories,
          practice_areas: example.practiceAreas,
          document_types: example.documentTypes,
          visibility_scope: example.visibilityScope,
          entry_point: example.eventKind,
        })
        displaySuccessMessage('Saved example successfully', 1)
        onDialogOpenChange(false)
        result = true
      }
    } finally {
      setSaveLoading(false)
    }
    return result
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={onDialogOpenChange}>
      {!dialogOnly && (
        <DialogTrigger disabled={saveExampleDisabled} asChild>
          <Button data-testid="library-save-example" variant="outline">
            <Icon icon={BookmarkPlus} className="mr-1" />
            Save example
          </Button>
        </DialogTrigger>
      )}
      {dialogOpen && (
        <LibrarySaveExampleDialog
          handleSaveExample={handleSaveExample}
          saveLoading={saveLoading}
        />
      )}
    </Dialog>
  )
}

type SaveExampleDialogProps = {
  saveLoading: boolean
  handleSaveExample: (
    params: Partial<
      Pick<
        Example,
        | 'name'
        | 'categories'
        | 'practiceAreas'
        | 'documentTypes'
        | 'visibilityScope'
      >
    >
  ) => Promise<boolean>
  editExample?: Partial<
    Pick<
      Example,
      | 'id'
      | 'name'
      | 'categories'
      | 'practiceAreas'
      | 'documentTypes'
      | 'visibilityScope'
    >
  >
}

export const LibrarySaveExampleDialog: React.FC<SaveExampleDialogProps> = (
  props: SaveExampleDialogProps
) => {
  const { editExample, handleSaveExample, saveLoading } = props

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const userProfile = useUserProfileStore((s) => s.userProfile)

  const [name, setName] = useState('')
  const [categories, setCategories] = useState<string[]>([])
  const [documentTypes, setDocumentTypes] = useState<string[]>([])
  const [createdCategories, setCreatedCategories] = useState<string[]>([])
  const [createdPracticeAreas, setCreatedPracticeAreas] = useState<string[]>([])
  const [createdDocumentTypes, setCreatedDocumentTypes] = useState<string[]>([])
  const [visibilityScope, setVisibilityScope] = useState<LibraryVisbilityScope>(
    LibraryVisbilityScope.WORKSPACE
  )

  const { items: examples, isLoading: isExamplesLoading } = useLibraryData(
    LibraryItemKind.EXAMPLE
  )
  const { items: prompts, isLoading: isPromptsLoading } = useLibraryData(
    LibraryItemKind.PROMPT
  )

  const practiceAreaOptions = _.uniq([
    ...getPracticeAreas(examples),
    ...getPracticeAreas(prompts),
    ...createdPracticeAreas,
  ]).map((practiceArea) => ({
    text: practiceArea,
    value: practiceArea,
  }))

  const defaultPracticeAreas = getUniquePracticeAreaTaxonomies(
    userProfile,
    new Set(practiceAreaOptions.map((p) => p.value))
  )

  const [practiceAreas, setPracticeAreas] =
    useState<string[]>(defaultPracticeAreas)

  // Don't reset example on poll refresh
  const prevEditExampleId = useRef<string>('')
  useEffect(() => {
    const editExampleId = editExample?.id ?? ''
    if (prevEditExampleId.current !== editExampleId) {
      setName(editExample?.name ?? '')
      setCategories(editExample?.categories ?? [])
      setDocumentTypes(editExample?.documentTypes ?? [])
      setPracticeAreas(editExample?.practiceAreas ?? [])
      setVisibilityScope(
        editExample?.visibilityScope ?? LibraryVisbilityScope.WORKSPACE
      )
    }
    prevEditExampleId.current = editExampleId
  }, [editExample])

  const hasChanges = editExample
    ? name !== editExample.name ||
      categories !== editExample.categories ||
      practiceAreas !== editExample.practiceAreas ||
      documentTypes !== editExample.documentTypes
    : name || categories || practiceAreas || documentTypes

  const isSaveDisabled =
    isExamplesLoading ||
    !name.trim() ||
    !categories.length ||
    !practiceAreas.length
  let saveDisabledTooltip = ''
  if (isExamplesLoading) {
    saveDisabledTooltip = 'Loading examples'
  } else if (!name.trim()) {
    saveDisabledTooltip = 'Name is required'
  } else if (!categories.length) {
    saveDisabledTooltip = 'Category is required'
  } else if (!practiceAreas.length) {
    saveDisabledTooltip = 'Practice area is required'
  } else {
    saveDisabledTooltip = ''
  }

  const internalSaveExample = async () => {
    if (saveDisabledTooltip) {
      displayErrorMessage(saveDisabledTooltip)
      return
    }
    await handleSaveExample({
      name,
      categories,
      documentTypes,
      practiceAreas,
      visibilityScope,
    })
    trackEvent(`Library Item ${editExample ? 'Edited' : 'Created'}`, {
      itemType: LibraryItemKind.EXAMPLE,
      practice_areas: practiceAreas,
      categories: categories,
      document_types: documentTypes,
      visibility_scope: visibilityScope,
    })
  }

  const handleCreateCategory = (newCategory: string) => {
    setCreatedCategories(_.uniq([...createdCategories, newCategory]))
    setCategories([...categories, newCategory])
  }

  const handleCreatePracticeArea = (newPracticeArea: string) => {
    setCreatedPracticeAreas(_.uniq([...createdPracticeAreas, newPracticeArea]))
    setPracticeAreas([...practiceAreas, newPracticeArea])
  }

  const handleCreateDocumentType = (newDocumentType: string) => {
    setCreatedDocumentTypes(_.uniq([...createdDocumentTypes, newDocumentType]))
    setDocumentTypes([...documentTypes, newDocumentType])
  }

  const categoryOptions = _.uniq([
    ...getCategories(examples),
    ...getCategories(prompts),
    ...createdCategories,
  ]).map((category) => ({
    text: category,
    value: category,
  }))

  const documentTypeOptions = _.uniq([
    ...getDocumentTypes(examples),
    ...createdDocumentTypes,
  ]).map((documentType) => ({
    text: documentType,
    value: documentType,
  }))

  const containerRef = useRef<HTMLDivElement>(null)

  const disableContentEdit =
    editExample &&
    editExample.visibilityScope === LibraryVisbilityScope.HARVEY &&
    !userInfo.IsLibraryCreateHarveyItemsUser

  const disableTagEdit = saveLoading || isExamplesLoading || isPromptsLoading

  const isGlobalExample = visibilityScope === LibraryVisbilityScope.HARVEY
  const dialogTitle = `${editExample ? 'Edit' : 'Save'} example`

  return (
    <DialogContent
      aria-label="Save example"
      ref={containerRef}
      preventClose={!!hasChanges}
    >
      <h2 className="text-lg font-semibold">{dialogTitle}</h2>
      <div>
        <Label htmlFor="name">Name</Label>
        <Input
          id="name"
          data-testid="save-example-name"
          className="h-8"
          placeholder="Enter a short, descriptive name"
          value={name}
          maxLength={128}
          onChange={(e) => setName(e.target.value)}
          disabled={disableContentEdit}
        />
      </div>

      <div>
        <Label id="category">Category</Label>
        <MultiSelect
          ariaLabelledBy="category"
          containerRef={containerRef}
          disabled={disableTagEdit}
          placeholder="Select categories"
          sortedEntries={categoryOptions}
          selectedValues={categories}
          setSelectedValues={setCategories}
          className="w-full"
          popoverContentClassName="max-w-full w-[--radix-popover-trigger-width] min-w-[200px] p-0"
          createNew={{
            onCreateNew: handleCreateCategory,
            createNewLabel: 'Create new',
          }}
        />
      </div>

      <div>
        <Label id="practice-area">Practice Area</Label>
        <MultiSelect
          ariaLabelledBy="practice-area"
          containerRef={containerRef}
          disabled={disableTagEdit}
          placeholder="Select practice areas"
          sortedEntries={practiceAreaOptions}
          selectedValues={practiceAreas}
          setSelectedValues={setPracticeAreas}
          className="w-full"
          popoverContentClassName="max-w-full w-[--radix-popover-trigger-width] min-w-[200px] p-0"
          createNew={{
            onCreateNew: handleCreatePracticeArea,
            createNewLabel: 'Create new',
          }}
        />
      </div>

      <div>
        <Label id="document-type">Document Type</Label>
        <MultiSelect
          ariaLabelledBy="document-type"
          containerRef={containerRef}
          disabled={disableTagEdit}
          placeholder="Select document types"
          sortedEntries={documentTypeOptions}
          selectedValues={documentTypes}
          setSelectedValues={setDocumentTypes}
          className="w-full"
          popoverContentClassName="max-w-full w-[--radix-popover-trigger-width] min-w-[200px] p-0"
          createNew={{
            onCreateNew: handleCreateDocumentType,
            createNewLabel: 'Create new',
          }}
        />
      </div>

      {userInfo.IsLibraryCreateHarveyItemsUser && !editExample && (
        <div>
          <Label className="sr-only" htmlFor="example-visibility">
            Example Visibility
          </Label>
          <Checkbox
            id="example-visibility"
            onClick={() =>
              setVisibilityScope(
                isGlobalExample
                  ? LibraryVisbilityScope.WORKSPACE
                  : LibraryVisbilityScope.HARVEY
              )
            }
            label="Global Harvey example"
            checked={isGlobalExample}
          />
        </div>
      )}

      <div className="flex justify-between space-x-2">
        <div>
          <BasicTransition show={saveLoading}>
            <div className="flex items-center">
              <Spinner size="xxs" />
              Saving…
            </div>
          </BasicTransition>
        </div>

        {/* Action Buttons */}
        <div className="flex space-x-2">
          <DialogClose disabled={saveLoading} asChild>
            <Button variant="ghost">Cancel</Button>
          </DialogClose>
          <Button
            data-testid="save-example-button"
            onClick={internalSaveExample}
            disabled={isSaveDisabled}
            tooltip={saveDisabledTooltip}
          >
            Save
          </Button>
        </div>
      </div>
    </DialogContent>
  )
}
