import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import { Transition } from '@headlessui/react'
import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'

import { useEventSharing } from 'hooks/use-event-sharing'
import { WordSection } from 'utils/docx'
import {
  getHrvyInfoMetadata as HrvyInfoMetadataType,
  getSourceClicked,
  HrvyInfoElement,
} from 'utils/source'
import { AnnotationById, Source, TaskType } from 'utils/task'
import { displaySuccessMessage } from 'utils/toast'
import { cn, findScrollingContainer, isAzureBlobPdf } from 'utils/utils'

import AssistantLoadingState from 'components/assistant/components/assistant-loading-state'
import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import {
  AssistantThreadContent,
  AssistantThreadLayout,
} from 'components/assistant/components/assistant-thread-layout'
import { useIsCuatrecasas } from 'components/assistant/hooks/use-is-cuatrecasas'
import { useIsPwcTax } from 'components/assistant/hooks/use-is-pwc-tax'
import { useAssistantFileStore } from 'components/assistant/stores/assistant-file-store'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { AssistantMessage } from 'components/assistant/types'
import { handleExport as handleExportHelper } from 'components/assistant/utils/assistant-export'
import {
  EXAMPLE_MESSAGE_ID,
  FILE_ID_PARAM,
  MESSAGE_ID_PARAM,
  REMOVE_PARAMS,
  RESET_PARAM,
  REVISION_ID_PARAM,
  SOURCE_ID_PARAM,
  getSourceDocumentId,
  incrementReset,
  sourceUrlModifier,
} from 'components/assistant/utils/assistant-helpers'
import {
  isVaultKnowledgeSource,
  KnowledgeSourceConfig,
} from 'components/assistant/utils/assistant-knowledge-sources'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import ExportDialog from 'components/common/export/export-dialog'
import {
  ExportOptionGroup,
  ExportOptionValues,
} from 'components/common/export/types'
import FullscreenLoading from 'components/common/fullscreen-loading'
import PdfPushSheet from 'components/common/pdf-viewer/pdf-push-sheet'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import SourcePopover from 'components/common/source-popover'
import { useNavigationQueryState } from 'components/common/use-navigation-query-state'
import {
  CUATRECASAS_HELP,
  PWC_DISCLAIMER_TEXT,
} from 'components/research/constants'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { SkeletonBlock } from 'components/ui/skeleton'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { getVaultFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import AssistantCreateThreadDropdown from './assistant-create-thread-dropdown'
import {
  AssistantEditMessageQueryInput,
  EditMessageQueryInputProps,
} from './assistant-edit-message-query-input'
import AssistantHeaderDropdown from './assistant-header-dropdown'
import AssistantHeaderSubtitle from './assistant-header-subtitle'
import AssistantQuestion from './assistant-question'
import AssistantShareMenu from './assistant-share-menu'

interface Props {
  children: React.ReactNode
  messages: AssistantMessage[]
  // The sources associated with the current message(s) to match
  // the citations in the push sheet header
  sources: Source[]
  title?: React.ReactNode
  useDiffView?: boolean
}

enum ExportRevision {
  SELECTED = 'selected',
  ALL = 'all',
}

const AssistantThread = ({
  children,
  messages,
  sources,
  title,
  useDiffView,
}: Props) => {
  const userInfo = useAuthUser()
  const { mode, eventId: eventIdParam } = useParams()
  const isExample = eventIdParam === EXAMPLE_MESSAGE_ID
  const isDraft = mode === AssistantMode.DRAFT
  const eventId = useAssistantStore((s) => s.eventId)
  const streamingMessage = useAssistantStore((s) => s.streamingMessage)
  const { recordExport } = useQueryAnalytics(mode ?? EventKind.ASSISTANT)

  const documents = useAssistantStore((s) => s.documents)
  const documentsUploading = useAssistantStore((s) => s.documentsUploading)
  const userCaption = useAssistantStore((s) => s.userCaption)
  const knowledgeSource = useAssistantStore((s) => s.knowledgeSource)
  const isPwcTax = useIsPwcTax()
  const isCuatrecasas = useIsCuatrecasas()
  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)
  const creatorUserEmail = useAssistantStore((s) => s.eventOwnerUserEmail)

  const [folderIdToVaultFileIds, fileIdToVaultFile, parentIdToVaultFolderIds] =
    useVaultStore(
      useShallow((s) => [
        s.folderIdToVaultFileIds,
        s.fileIdToVaultFile,
        s.parentIdToVaultFolderIds,
      ])
    )

  const { sharingStatus } = useEventSharing(eventId)
  const isSharedThread = (sharingStatus?.permissionsByUser || []).length > 0

  const sourceDocuments = useMemo(() => {
    if (isVaultSource && knowledgeSource.folderId) {
      const vaultSourceFileIds = new Set(knowledgeSource.fileIds)
      const vaultFolderFileIds = getVaultFileIds({
        folderId: knowledgeSource.folderId,
        parentIdToVaultFolderIds,
        folderIdToVaultFileIds,
      })
      return vaultFolderFileIds
        .map((fileId) => fileIdToVaultFile[fileId] as UploadedFile)
        .filter((file) => vaultSourceFileIds.has(file.id))
    }
    return documents
  }, [
    documents,
    folderIdToVaultFileIds,
    fileIdToVaultFile,
    parentIdToVaultFolderIds,
    knowledgeSource,
    isVaultSource,
  ])

  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  const revisionId = searchParams.get(REVISION_ID_PARAM)

  const selectedMessage = messages.find((m) => m.messageId === revisionId)
  const exportTitle = userCaption || messages[0]?.caption
  const handleExport = async (exportValues: ExportOptionValues) => {
    const includeAnnotation = !!exportValues.includeAnnotation
    const showEdits = !!exportValues.showEdits
    const additionalSections: WordSection[] = (() => {
      if (isPwcTax) {
        return [
          { content: `<br/>`, type: 'html' },
          {
            content: `## Disclaimer\n\n${PWC_DISCLAIMER_TEXT}`,
            type: 'markdown',
          },
        ]
      }
      if (isCuatrecasas) {
        return [
          { content: `<br/>`, type: 'html' },
          {
            content: `<h2>Disclaimer</h2>${CUATRECASAS_HELP}`,
            type: 'html',
          },
        ]
      }
      return []
    })()
    const taskType = isDraft
      ? TaskType.ASSISTANT_DRAFT
      : TaskType.ASSISTANT_CHAT
    const postfix =
      isDraft && exportValues.revisions === ExportRevision.ALL
        ? ' All Revisions'
        : ''

    await handleExportHelper({
      eventId: String(eventId),
      messages: messages,
      documents: sourceDocuments,
      includeAnnotation,
      taskType,
      exportTitle: `${exportTitle}${postfix}`,
      additionalSections,
      exportMessageIndex:
        exportValues.revisions === ExportRevision.SELECTED && selectedMessage
          ? messages.indexOf(selectedMessage)
          : undefined,
      showEdits: showEdits,
    })
    recordExport(String(eventId), 'word', includeAnnotation)
  }
  const hasSources = messages.some((m) => !!m?.sources.length)
  const exportOptions: ExportOptionGroup[] = []
  if (isDraft && messages.length > 1) {
    exportOptions.push({
      id: 'revisions',
      name: 'Revisions',
      options: [
        {
          label: 'Selected revision',
          value: ExportRevision.SELECTED,
        },
        {
          label: 'All revisions',
          value: ExportRevision.ALL,
        },
      ],
      type: 'radio',
      defaultValue: ExportRevision.SELECTED,
    })
    exportOptions.push({
      id: 'showEdits',
      name: 'Edits',
      options: [{ label: 'Show edits', value: !!useDiffView }],
      type: 'toggle',
      defaultValue: !!useDiffView,
    })
  }

  const isStreaming = !!streamingMessage
  const isHistoryLoading =
    !isExample && !isStreaming && (!messages.length || eventIdParam !== eventId)
  if ((isStreaming && !title) || isHistoryLoading) {
    title = <SkeletonBlock className="mb-0.5 h-6 w-80" />
  }

  const { projects: vaultProjects } = useVaultProjects(
    isVaultSource ? knowledgeSource.folderId : '',
    {
      isEnabled:
        !isHistoryLoading &&
        isVaultSource &&
        !!knowledgeSource.folderId &&
        knowledgeSource.fileIds &&
        // Attempt to load project and metadata again if we don't have all
        // of the files. This could happen if we only fetched the parent folder,
        // but the files are actually part of descendant folders.
        knowledgeSource.fileIds.length !== sourceDocuments.length,
    }
  )
  const vaultProject = isVaultSource
    ? vaultProjects.find((project) => project.id === knowledgeSource.folderId)
    : undefined
  const knowledgeSourceLabel = knowledgeSource
    ? isVaultSource
      ? vaultProject?.name
      : KnowledgeSourceConfig[knowledgeSource.type]?.label
    : ''

  const { state: locationState } = useLocation()
  const isCopied = locationState?.isCopied
  useEffect(() => {
    if (isCopied && !isHistoryLoading) {
      const copyNoun = isDraft ? 'Draft' : 'Thread'
      displaySuccessMessage(`${copyNoun} copied`, 5)
      window.history.replaceState(null, '')
    }
  }, [isCopied, isDraft, isHistoryLoading])

  const navigationState = useNavigationQueryState()
  const locationTitle = navigationState?.caption
  if (locationTitle) {
    title = locationTitle
  }
  const isTitleString = typeof title === 'string'

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)
  const defaultResizablePanelSizes = [50, 50]
  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  const getDocument = useAssistantFileStore((s) => s.getDocument)

  const pushSheet = useMemo(
    () =>
      fileId && (
        <PdfPushSheet
          documents={documents}
          eventId={eventId}
          fileId={fileId}
          getDocument={getDocument}
          messages={messages.map((m) => ({
            messageId: m.messageId,
            sources: m.sources,
          }))}
          sources={sources}
        />
      ),
    [fileId, sources, getDocument, messages, documents, eventId]
  )

  return (
    <AppMain>
      <AppHeader
        title={isTitleString ? String(title) : ''}
        titleComponent={!isTitleString ? title : null}
        subtitleComponent={
          <AssistantHeaderSubtitle
            isLoading={isHistoryLoading}
            numDocs={sourceDocuments.length + documentsUploading.length}
            numFollowups={messages.length - 1}
            sourceLabel={knowledgeSourceLabel}
            creatorUserEmail={creatorUserEmail}
            isSharedThread={isSharedThread}
          />
        }
        breadcrumbs={<RouterBreadcrumbs removeParams={REMOVE_PARAMS} />}
        actions={
          <div className="inline-flex space-x-2">
            <AssistantCreateThreadDropdown disabled={!!streamingMessage} />
            {userInfo.IsEventCreateSharesUser && <AssistantShareMenu />}
            <ExportDialog
              hasSources={hasSources}
              optionGroups={exportOptions}
              onExport={handleExport}
              disabled={!messages.length || isStreaming}
            />
            <AssistantHeaderDropdown
              disabled={!!streamingMessage || isExample}
            />
          </div>
        }
      />
      {isHistoryLoading && <FullscreenLoading isLoading />}
      <AssistantThreadLayout>
        <ResizablePanelGroup
          direction="horizontal"
          ref={resizablePanelGroupRef}
        >
          <ResizablePanel
            id="thread"
            defaultSize={fileId ? defaultResizablePanelSizes[0] : 100}
            minSize={20}
            order={1}
          >
            {children}
          </ResizablePanel>
          {fileId && (
            <>
              <ResizableHandle withHandle onDoubleClick={resetLayout} />
              <ResizablePanel
                id="viewer"
                // Move behind resizable handle
                className="z-0"
                defaultSize={defaultResizablePanelSizes[1]}
                minSize={20}
                order={2}
              >
                {pushSheet}
              </ResizablePanel>
            </>
          )}
        </ResizablePanelGroup>
      </AssistantThreadLayout>
    </AppMain>
  )
}

export default AssistantThread

interface ThreadMessageProps {
  children: (fn: HrvyInfoMetadataType) => React.ReactNode
  className?: string
  footer?: React.ReactNode
  message?: AssistantMessage
  onCancel?: () => void
  sidebar?: React.ReactNode
  edit?: EditMessageQueryInputProps
  allAnnotations?: AnnotationById
  lastLoadingMessage?: string
}

export const ThreadMessage = React.forwardRef<
  HTMLDivElement,
  ThreadMessageProps
>(
  (
    {
      children,
      className,
      footer,
      message,
      onCancel,
      sidebar,
      edit,
      allAnnotations,
      lastLoadingMessage,
    },
    ref
  ) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const fileId = searchParams.get(FILE_ID_PARAM)
    const sourceId = searchParams.get(SOURCE_ID_PARAM)
    const resetSource = searchParams.get(RESET_PARAM)

    const [documents, eventId] = useAssistantStore(
      useShallow((s) => [s.documents, s.eventId])
    )
    const { mode } = useParams()
    const isDraft = mode === AssistantMode.DRAFT

    const handleSetActiveFileId = (
      messageId: string,
      fileId: Maybe<string>,
      sourceId?: string
    ) => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        if (fileId) {
          newParams.set(MESSAGE_ID_PARAM, messageId)
          newParams.set(FILE_ID_PARAM, fileId)
          if (sourceId) newParams.set(SOURCE_ID_PARAM, sourceId)
        } else {
          newParams.delete(MESSAGE_ID_PARAM)
          newParams.delete(FILE_ID_PARAM)
          newParams.delete(SOURCE_ID_PARAM)
        }
        if (sourceId === newParams.get(SOURCE_ID_PARAM)) {
          newParams.set(RESET_PARAM, incrementReset(resetSource))
        } else {
          newParams.delete(RESET_PARAM)
        }
        return newParams
      })
    }

    const { annotations, isLoading, response } = message || {
      annotations: {},
      isLoading: true,
      response: '',
    }
    const [showLoadingBar, setShowLoadingBar] = useState(
      isLoading || !!lastLoadingMessage
    )
    useEffect(() => {
      let loadingBarTimeout: ReturnType<typeof setTimeout> | null = null
      if (lastLoadingMessage) {
        loadingBarTimeout = setTimeout(() => {
          setShowLoadingBar(false)
        }, 250)
      }
      return () => {
        if (loadingBarTimeout) {
          clearTimeout(loadingBarTimeout)
        }
      }
    }, [lastLoadingMessage])

    const citationRefs = useRef<{
      [documentId: string]: { [sourceId: string]: HrvyInfoElement }
    }>({})

    // Restore scroll area position when file viewer is opened
    const scrollPosition = useRef({
      scrollHeight: 0,
      scrollPercent: 0,
    })

    useEffect(() => {
      // TODO: Investigate why the citation refs seem to reset; remove setTimeout
      setTimeout(() => {
        if (fileId && sourceId) {
          const citationEl = citationRefs.current[fileId]?.[sourceId]
          const scrollingContainer = findScrollingContainer(citationEl)

          if (
            scrollingContainer &&
            scrollingContainer.scrollHeight !==
              scrollPosition.current.scrollHeight
          ) {
            // Calculate new scroll position as percentage of new height
            const newPosition =
              scrollPosition.current.scrollPercent *
              scrollingContainer.scrollHeight

            // Center it within the scrolling container
            const newScrollTop =
              newPosition - scrollingContainer.offsetHeight / 2

            scrollingContainer.scrollTo(0, newScrollTop)
          }
        }
      })
    }, [fileId, sourceId, resetSource])

    const getHrvyInfoMetadata = (identifier: string) => {
      const source = getSourceClicked(identifier, allAnnotations ?? annotations)
      if (!source || !message) {
        return
      }

      const sourceDocumentId = getSourceDocumentId(source)

      const isPdfUrl = isAzureBlobPdf(source.documentUrl)
      const sourceUrl = isPdfUrl
        ? ''
        : source.documentUrl
        ? sourceUrlModifier(source.documentUrl)
        : ''

      const onClick = (e: React.MouseEvent) => {
        // Prevent opening the source document when clicking on a url source
        if (source.documentUrl && !isPdfUrl) return

        const currentTarget = e.currentTarget as HTMLElement
        const scrollingContainer = findScrollingContainer(currentTarget)
        if (scrollingContainer) {
          const scrollRect = scrollingContainer.getBoundingClientRect()
          const clickTop =
            e.pageY + scrollingContainer.scrollTop - scrollRect.top
          scrollPosition.current = {
            scrollHeight: scrollingContainer.scrollHeight,
            scrollPercent: clickTop / scrollingContainer.scrollHeight,
          }
        }
        handleSetActiveFileId(message.messageId, sourceDocumentId, source.id)
      }

      const hoverContent = (
        <SourcePopover source={source} onClick={onClick} url={sourceUrl} />
      )

      const citationRef = (el: HrvyInfoElement) => {
        if (!sourceDocumentId || !source.id) return
        if (!citationRefs.current[sourceDocumentId]) {
          citationRefs.current[sourceDocumentId] = {}
        }
        citationRefs.current[sourceDocumentId][source.id] = el
      }

      return {
        onClick,
        hoverContent,
        isSelected: sourceDocumentId === fileId && source.id === sourceId,
        ref: citationRef,
        eventData: {
          event_id: eventId,
          event_kind:
            mode === AssistantMode.DRAFT
              ? EventKind.ASSISTANT_DRAFT
              : EventKind.ASSISTANT_CHAT,
          num_docs: documents.length,
        },
        url: sourceUrl,
      }
    }

    const showEditQueryBox =
      !!edit?.editingMessage &&
      edit.editingMessage.messageId === message?.messageId

    return (
      <div
        className={cn(
          'border-t py-12 first-of-type:border-0 first-of-type:pt-8 last-of-type:pb-16',
          className
        )}
        id={message?.messageId}
        ref={ref}
      >
        {isDraft ? (
          <AssistantThreadContent
            contentId={`assistant-message-content-${message?.messageId}`}
            hasSidebar={!!sidebar}
            sidebar={sidebar}
          >
            {isLoading && isEmpty(response) ? (
              <AssistantLoadingState onCancel={onCancel} />
            ) : (
              children(getHrvyInfoMetadata)
            )}
          </AssistantThreadContent>
        ) : (
          <>
            <AssistantThreadContent
              contentId={`assistant-message-content-${message?.messageId}`}
              hasSidebar={!!sidebar}
              sidebar={sidebar}
            >
              <div
                className={cn('relative rounded-md bg-secondary', {
                  'rounded-b-none': showLoadingBar,
                })}
              >
                <div className="p-4 pt-6">
                  <h2 className="mb-2 text-sm font-medium">Query</h2>
                  {showEditQueryBox ? (
                    <AssistantEditMessageQueryInput {...edit} />
                  ) : (
                    <AssistantQuestion
                      message={message}
                      onEditMessage={edit?.setEditingMessage}
                    />
                  )}
                </div>
                <Transition
                  className="absolute z-0 w-full"
                  show={!!showLoadingBar}
                  unmount
                  enter="transition-transform duration-250"
                  enterFrom="-translate-y-full"
                  enterTo="translate-y-0"
                  leave="transition-transform transition-opacity duration-250"
                  leaveFrom="translate-y-0 opacity-100"
                  leaveTo="-translate-y-full opacity-0"
                >
                  <div className="border-t">
                    <AssistantLoadingState
                      className="rounded-t-none"
                      message={lastLoadingMessage}
                      onCancel={onCancel}
                    />
                  </div>
                </Transition>
              </div>

              <div
                className={cn('duration-250 px-4 pt-6 transition-transform', {
                  'translate-y-8': showLoadingBar,
                })}
              >
                {children(getHrvyInfoMetadata)}
              </div>
            </AssistantThreadContent>
            {footer && (
              <AssistantThreadContent
                className={cn('duration-250 mt-10 transition-transform', {
                  'translate-y-8': showLoadingBar,
                })}
                hasSidebar={!!sidebar}
              >
                <div className="px-4">{footer}</div>
              </AssistantThreadContent>
            )}
          </>
        )}
      </div>
    )
  }
)
ThreadMessage.displayName = 'ThreadMessage'
