/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowSelectInputBlockBlockParams
 */
export interface WorkflowSelectInputBlockBlockParams {
    /**
     * 
     * @type {string}
     * @memberof WorkflowSelectInputBlockBlockParams
     */
    headerText: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowSelectInputBlockBlockParams
     */
    options: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSelectInputBlockBlockParams
     */
    placeholder?: string;
}

/**
 * Check if a given object implements the WorkflowSelectInputBlockBlockParams interface.
 */
export function instanceOfWorkflowSelectInputBlockBlockParams(value: object): value is WorkflowSelectInputBlockBlockParams {
    if (!('headerText' in value) || value['headerText'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function WorkflowSelectInputBlockBlockParamsFromJSON(json: any): WorkflowSelectInputBlockBlockParams {
    return WorkflowSelectInputBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowSelectInputBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowSelectInputBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'headerText': json['header_text'],
        'options': json['options'],
        'placeholder': json['placeholder'] == null ? undefined : json['placeholder'],
    };
}

export function WorkflowSelectInputBlockBlockParamsToJSON(json: any): WorkflowSelectInputBlockBlockParams {
    return WorkflowSelectInputBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowSelectInputBlockBlockParamsToJSONTyped(value?: WorkflowSelectInputBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'header_text': value['headerText'],
        'options': value['options'],
        'placeholder': value['placeholder'],
    };
}

