import React, { useCallback } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'

import { useAuth0 } from '@auth0/auth0-react'
import { formatDistanceToNow, addSeconds } from 'date-fns'
import _ from 'lodash'
import { UploadCloudIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { VaultFile } from 'openapi/models/VaultFile'
import { useGeneralStore } from 'stores/general-store'
import { useIntegrationsStore } from 'stores/integrations-store'

import { onDrop } from 'utils/dropzone'
import { bytesToReadable, mbToBytes, mbToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { cn } from 'utils/utils'

import AddMoreFiles from 'components/common/add-more-files'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import VaultCreateFileUpload from 'components/vault/components/new-project/vault-create-file-upload'
import VaultUploadedFileList from 'components/vault/components/new-project/vault-uploaded-file-list'
import VaultCreateDisclaimer from 'components/vault/components/vault-create-disclaimer'
import VaultUploadInset from 'components/vault/components/vault-upload-inset'
import { VaultDialogHeader } from 'components/vault/dialogs/vault-dialog-header'
import DuplicateFilesModal from 'components/vault/dialogs/vault-duplicate-files-modal'
import {
  ACCEPTED_FILE_TYPES,
  ETA_TOOLTIP_TEXT,
  FileToUpload,
  MAX_EXCEL_FILE_SIZE_IN_MB,
  maxFileSizeInMb,
  maxTotalFileSizeInMb,
} from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { FetchJobQueueEta } from 'components/vault/utils/vault-fetcher'
import {
  createFoldersAndFiles,
  sumFileSizesInBytes,
  handleDroppedFilesOrDeletedFiles,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

const VaultUploadFilesDialog: React.FC = () => {
  const userInfo = useAuthUser()
  const { getAccessTokenSilently } = useAuth0()
  const { trackEvent } = useAnalytics()

  const addFolderIdToFilesUploading = useGeneralStore(
    (s) => s.addFolderIdToFilesUploading
  )
  const removeFolderIdFromFilesUploading = useGeneralStore(
    (s) => s.removeFolderIdFromFilesUploading
  )

  const recordFileDrop = useDropzoneTrack('VAULT_FOLDER')

  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const folderIdToVaultFileIds = useVaultStore((s) => s.folderIdToVaultFileIds)
  const folderIdToVaultFolder = useVaultStore((s) => s.folderIdToVaultFolder)
  const isUploadFilesDialogOpen = useVaultStore(
    (s) => s.isUploadFilesDialogOpen
  )
  const currentUploadFilesFolderId = useVaultStore(
    (s) => s.currentUploadFilesFolderId
  )
  const currentProjectMetadata = useVaultStore((s) => s.currentProjectMetadata)
  const currentProject = useVaultStore((s) => s.currentProject)
  const setIsUploadFilesDialogOpen = useVaultStore(
    (s) => s.setIsUploadFilesDialogOpen
  )
  const upsertVaultFolders = useVaultStore((s) => s.upsertVaultFolders)
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const updateProjectMetadata = useVaultStore((s) => s.updateProjectMetadata)
  const updateProjectMetadataLastFileUploadedAt = useVaultStore(
    (s) => s.updateProjectMetadataLastFileUploadedAt
  )
  const setIsDuplicateModalOpen = useVaultStore(
    (s) => s.setIsDuplicateModalOpen
  )

  const filesToUpload = useVaultCreateProjectStore((s) => s.filesToUpload)
  const isSubmitting = useVaultCreateProjectStore((s) => s.isSubmitting)
  const isDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.isDropzoneLoading
  )
  const totalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.totalFileSizeInBytes
  )
  const setDroppedFiles = useVaultCreateProjectStore((s) => s.setDroppedFiles)
  const setFilesToUpload = useVaultCreateProjectStore((s) => s.setFilesToUpload)
  const setIsSubmitting = useVaultCreateProjectStore((s) => s.setIsSubmitting)
  const setIsDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.setIsDropzoneLoading
  )
  const setTotalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.setTotalFileSizeInBytes
  )
  const setDuplicateFiles = useVaultCreateProjectStore(
    (s) => s.setDuplicateFiles
  )

  const currentFolder = currentUploadFilesFolderId
    ? folderIdToVaultFolder[currentUploadFilesFolderId]
    : undefined
  const existingVaultFileNames = React.useMemo(
    () =>
      (
        (currentFolder?.id
          ? folderIdToVaultFileIds[currentFolder.id] ?? []
          : []
        )
          .map((fileId) => fileIdToVaultFile[fileId])
          .filter(Boolean) as VaultFile[]
      ).map((file) => file.name),
    [currentFolder, fileIdToVaultFile, folderIdToVaultFileIds]
  )
  const namesForExistingVaultFilesAndFilesToUpload = React.useMemo(
    () => [
      ...existingVaultFileNames,
      ...filesToUpload.map((file) => file.name),
    ],
    [existingVaultFileNames, filesToUpload]
  )
  const vaultFilesCountLimit = userInfo.workspace.getVaultFilesCountLimit(
    userInfo.vaultFeature
  )

  const onFileDrop = async (acceptedFiles: File[]) => {
    setIsDropzoneLoading(false)
    const { duplicates } = await handleDroppedFilesOrDeletedFiles({
      latestDroppedFiles: acceptedFiles,
      hasFolderName: false,
      currentTotalSizeInBytes: totalFileSizeInBytes,
      setTotalFileSizeInBytes: setTotalFileSizeInBytes,
      setFilesToUpload: setFilesToUpload,
      namesForExistingVaultFilesAndFilesToUpload:
        namesForExistingVaultFilesAndFilesToUpload,
      existingFilesToUpload: filesToUpload,
    })
    recordFileDrop(acceptedFiles)

    if (duplicates.length > 0) {
      setDuplicateFiles(duplicates)
      setIsDuplicateModalOpen(true)
    }
  }

  const onFileDelete = useCallback(
    async (file: FileToUpload) => {
      const filesToUploadAfterDeletion = filesToUpload.filter(
        (f) => f.name !== file.name
      )
      const currentTotalSizeInBytes = await sumFileSizesInBytes(
        filesToUploadAfterDeletion.map((file) => file.file)
      )
      await handleDroppedFilesOrDeletedFiles({
        latestDroppedFiles: [],
        hasFolderName: false,
        currentTotalSizeInBytes: currentTotalSizeInBytes,
        setTotalFileSizeInBytes: setTotalFileSizeInBytes,
        setFilesToUpload: setFilesToUpload,
        checkForDuplicates: false,
        namesForExistingVaultFilesAndFilesToUpload:
          namesForExistingVaultFilesAndFilesToUpload,
        existingFilesToUpload: filesToUpload.filter(
          (f) => f.name !== file.name
        ),
      })
    },
    [
      filesToUpload,
      setFilesToUpload,
      setTotalFileSizeInBytes,
      namesForExistingVaultFilesAndFilesToUpload,
    ]
  )

  const onVaultFileDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event?: DropEvent
  ) => {
    event?.stopPropagation()
    setIsDropzoneLoading(true)
    return onDrop({
      acceptedFiles,
      fileRejections,
      currentFileCount: filesToUpload.length,
      maxFiles: vaultFilesCountLimit,
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      maxFileSize: mbToBytes(maxFileSizeInMb(userInfo)),
      maxExcelFileSize: mbToBytes(MAX_EXCEL_FILE_SIZE_IN_MB),
      maxZipFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
      maxTotalFileSizeProps: {
        maxTotalFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
        currentTotalFileSize: await sumFileSizesInBytes(
          filesToUpload.map((file) => file.file)
        ),
      },
      shouldSkipPasswordProtectionCheck: true,
      handleAcceptedFiles: onFileDrop,
      handleRejectedFiles: () => {
        setIsDropzoneLoading(false)
      },
    })
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: onVaultFileDrop,
    maxFiles: vaultFilesCountLimit,
    noClick: true,
  })

  const dismissDialog = useCallback(() => {
    setIsSubmitting(false)
    setIsUploadFilesDialogOpen(false)
    setDroppedFiles([])
    setTotalFileSizeInBytes(0)
    setFilesToUpload([])
  }, [
    setIsSubmitting,
    setIsUploadFilesDialogOpen,
    setDroppedFiles,
    setTotalFileSizeInBytes,
    setFilesToUpload,
  ])

  const handleUploadFiles = async () => {
    if (!currentUploadFilesFolderId || filesToUpload.length === 0) {
      displayErrorMessage('Something went wrong. Could not upload files.')
      return
    }
    trackEvent('Vault Files Uploaded', {
      num_files: filesToUpload.length,
      total_size: totalFileSizeInBytes,
    })
    setIsSubmitting(true)
    try {
      const areAllFilesProcessed =
        currentProjectMetadata.completedFiles ===
        currentProjectMetadata.totalFiles
      if (areAllFilesProcessed) {
        const uploadTimestamp = new Date().toISOString()
        updateProjectMetadataLastFileUploadedAt(uploadTimestamp)
      }
      const accessToken = await getAccessTokenSilently()
      await createFoldersAndFiles({
        accessToken,
        filesToUpload,
        rootFolderId: currentUploadFilesFolderId,
        projectId: currentProject?.id ?? '',
        prefix: '',
        areAllFilesProcessed,
        upsertVaultFolders,
        upsertVaultFiles,
        updateProjectMetadata,
        addFolderIdToFilesUploading,
        removeFolderIdFromFilesUploading,
        navigateHandler: dismissDialog,
        currentUserId: userInfo.id,
      })
    } catch (e) {
      console.error(e)
      displayErrorMessage('Something went wrong. Could not upload files.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const { data: jobQueueEtaInSeconds } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.JobQueueEtaQuery, currentProjectMetadata.id],
    queryFn: () => FetchJobQueueEta(currentProjectMetadata.id),
    enabled: !!currentProjectMetadata.id && isUploadFilesDialogOpen,
    // Fetch job queue ETA and update ETA on the UI every minute
    refetchInterval: 60_000,
    select: (data) =>
      _.isNumber(data.etaInSeconds) ? Math.round(data.etaInSeconds) : undefined,
  })

  // Project size (total size of files in the project + total size of files being uploaded)
  const projectTotalSizeInBytes = currentProjectMetadata.folderSize
  const currentProjectTotalSizeInBytes =
    projectTotalSizeInBytes + totalFileSizeInBytes
  const isProjectSizeExceeded =
    currentProjectTotalSizeInBytes > mbToBytes(maxTotalFileSizeInMb(userInfo))

  // Project file count (total number of files in the project + number of files being uploaded)
  const projectTotalFiles = currentProjectMetadata.totalFiles
  const currentProjectTotalFiles = projectTotalFiles + filesToUpload.length
  const isProjectFileCountExceeded =
    currentProjectTotalFiles > vaultFilesCountLimit
  const hasFilesToUpload = totalFileSizeInBytes > 0

  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(userInfo)
  const setSharepointPickerOpenState = useIntegrationsStore(
    useShallow((s) => s.setSharepointPickerOpenState)
  )

  const onUploadFromSharePoint = async (files: File[]) => {
    setIsDropzoneLoading(true)
    await onVaultFileDrop(files, [])
    setIsDropzoneLoading(false)
  }

  const handleSharePointClick = async () => {
    setSharepointPickerOpenState({
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      onUploadFromSharepoint: onUploadFromSharePoint,
    })
  }

  return (
    <Dialog open={isUploadFilesDialogOpen}>
      <DialogContent className="max-w-screen-md " showCloseIcon={false}>
        <div
          {...getRootProps()}
          className="relative flex size-full h-[431px] flex-col "
        >
          <input {...getInputProps()} onClick={(e) => e.stopPropagation()} />

          {isDragActive && <VaultUploadInset isDragActive={isDragActive} />}
          <VaultDialogHeader
            dialogTitle="Upload files"
            dialogDescription="Add additional files to"
            currentFolder={currentFolder || null}
          />
          <div className="space-y-4 py-2">
            <VaultCreateDisclaimer />

            {filesToUpload.length === 0 || isDragActive ? (
              <div className="pb-10">
                <VaultCreateFileUpload
                  acceptedFileTypes={ACCEPTED_FILE_TYPES}
                  onUploadFromSharePoint={onUploadFromSharePoint}
                  sharepointEnabled={sharepointEnabled}
                  open={open}
                />
              </div>
            ) : (
              <div className="flex h-full flex-col space-y-2">
                <VaultUploadedFileList
                  height={340}
                  filesToUpload={filesToUpload}
                  isSubmitting={isSubmitting}
                  onFileDelete={onFileDelete}
                />
                {isDropzoneLoading && (
                  <div className="flex h-6 items-center justify-center">
                    <Spinner size="xs" />
                    <p className="text-xs">Adding...</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <DialogFooter>
          <div className="mt-6 flex w-full items-center justify-between self-end">
            {hasFilesToUpload && (
              <div className="flex h-10 flex-wrap  items-center  justify-start gap-2">
                <Metadata
                  value={
                    <>
                      {currentProjectTotalFiles.toLocaleString()}{' '}
                      <span className="text-secondary">
                        / {vaultFilesCountLimit.toLocaleString()} files
                      </span>
                    </>
                  }
                  tooltip={`The total number of files in this project and the number of files you're uploading. Max ${pluralizeFiles(
                    vaultFilesCountLimit
                  )} for the whole project.`}
                  className={
                    isProjectFileCountExceeded ? 'text-destructive' : ''
                  }
                />
                <Metadata
                  value={
                    <>
                      {bytesToReadable(currentProjectTotalSizeInBytes)} /{' '}
                      <span className="text-secondary">
                        {mbToReadable(maxTotalFileSizeInMb(userInfo))} storage
                      </span>
                    </>
                  }
                  tooltip={`The total size of the files in this project and the files you're uploading. Max ${mbToReadable(
                    maxTotalFileSizeInMb(userInfo)
                  )} total for the whole project.`}
                  className={cn('', {
                    'text-destructive': isProjectSizeExceeded,
                  })}
                />
                {jobQueueEtaInSeconds !== undefined &&
                  jobQueueEtaInSeconds > 0 && (
                    <Metadata
                      value={
                        formatDistanceToNow(
                          addSeconds(new Date(), jobQueueEtaInSeconds)
                        ) + ' to process'
                      }
                      tooltip={ETA_TOOLTIP_TEXT}
                    />
                  )}
              </div>
            )}
            <div className="flex grow justify-end space-x-2 ">
              <Button
                variant="ghost"
                disabled={isSubmitting}
                onClick={dismissDialog}
                className="whitespace-nowrap"
              >
                Cancel
              </Button>
              {hasFilesToUpload && (
                <AddMoreFiles
                  onUploadFromComputer={open}
                  handleSharePointClick={handleSharePointClick}
                  leadingIcon={UploadCloudIcon}
                  side="top"
                  align="start"
                  buttonText="Upload more"
                  buttonSize="default"
                  disabled={
                    filesToUpload.length >= vaultFilesCountLimit ||
                    isDropzoneLoading
                  }
                />
              )}
              <Button
                disabled={
                  isDropzoneLoading ||
                  isSubmitting ||
                  isProjectSizeExceeded ||
                  isProjectFileCountExceeded ||
                  filesToUpload.length === 0
                }
                onClick={handleUploadFiles}
                data-testid="vault-upload-files-dialog--upload-button"
                className="whitespace-nowrap"
              >
                {isSubmitting ? 'Adding…' : 'Add to project'}
              </Button>
            </div>
          </div>
          <DuplicateFilesModal
            namesForExistingVaultFilesAndFilesToUpload={
              namesForExistingVaultFilesAndFilesToUpload
            }
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const Metadata = ({
  value,
  tooltip,
  className,
}: {
  value: React.ReactNode
  tooltip?: string
  className?: string
}) => {
  const pTag = <p className="text-xs">{value}</p>
  return (
    <div className={cn('flex items-start', className)}>
      {tooltip ? (
        <Tooltip>
          <TooltipTrigger className="text-left">{pTag}</TooltipTrigger>
          <TooltipContent side="top" className="max-w-96">
            {tooltip}
          </TooltipContent>
        </Tooltip>
      ) : (
        pTag
      )}
    </div>
  )
}

export default VaultUploadFilesDialog
