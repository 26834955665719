import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultUser } from 'models/vault'

interface State {
  vaultAddOnUsers: VaultUser[]
  totalSeats: number
}

interface Action {
  setVaultAddOnUsers: (addOnUsers: VaultUser[]) => void
}

export const useVaultManagementStore = create(
  devtools(
    immer<State & Action>((set) => ({
      vaultAddOnUsers: [],
      totalSeats: 0,
      setVaultAddOnUsers: (addOnUsers) =>
        set((state) => {
          state.vaultAddOnUsers = addOnUsers
          state.totalSeats = addOnUsers.length
        }),
    }))
  )
)
