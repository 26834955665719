/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TranscriptType } from './TranscriptType';
import {
    TranscriptTypeFromJSON,
    TranscriptTypeFromJSONTyped,
    TranscriptTypeToJSON,
    TranscriptTypeToJSONTyped,
} from './TranscriptType';
import type { TranscriptsDocument } from './TranscriptsDocument';
import {
    TranscriptsDocumentFromJSON,
    TranscriptsDocumentFromJSONTyped,
    TranscriptsDocumentToJSON,
    TranscriptsDocumentToJSONTyped,
} from './TranscriptsDocument';

/**
 * 
 * @export
 * @interface TranscriptsQuestionRequest
 */
export interface TranscriptsQuestionRequest {
    /**
     * 
     * @type {string}
     * @memberof TranscriptsQuestionRequest
     */
    question: string;
    /**
     * 
     * @type {Array<TranscriptsDocument>}
     * @memberof TranscriptsQuestionRequest
     */
    documents: Array<TranscriptsDocument>;
    /**
     * 
     * @type {TranscriptType}
     * @memberof TranscriptsQuestionRequest
     */
    documentType: TranscriptType;
}



/**
 * Check if a given object implements the TranscriptsQuestionRequest interface.
 */
export function instanceOfTranscriptsQuestionRequest(value: object): value is TranscriptsQuestionRequest {
    if (!('question' in value) || value['question'] === undefined) return false;
    if (!('documents' in value) || value['documents'] === undefined) return false;
    if (!('documentType' in value) || value['documentType'] === undefined) return false;
    return true;
}

export function TranscriptsQuestionRequestFromJSON(json: any): TranscriptsQuestionRequest {
    return TranscriptsQuestionRequestFromJSONTyped(json, false);
}

export function TranscriptsQuestionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsQuestionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'question': json['question'],
        'documents': ((json['documents'] as Array<any>).map(TranscriptsDocumentFromJSON)),
        'documentType': TranscriptTypeFromJSON(json['document_type']),
    };
}

export function TranscriptsQuestionRequestToJSON(json: any): TranscriptsQuestionRequest {
    return TranscriptsQuestionRequestToJSONTyped(json, false);
}

export function TranscriptsQuestionRequestToJSONTyped(value?: TranscriptsQuestionRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'question': value['question'],
        'documents': ((value['documents'] as Array<any>).map(TranscriptsDocumentToJSON)),
        'document_type': TranscriptTypeToJSON(value['documentType']),
    };
}

