import React from 'react'
import { ComponentPropsWithoutRef } from 'react'

import { TabsTrigger } from 'components/ui/tabs'

export const WSTabsTrigger = ({
  children,
  ...props
}: ComponentPropsWithoutRef<typeof TabsTrigger>) => (
  // This matches the Assistant tabs trigger styling, but it's also not
  // completely consistent with all tab usage in our system.
  <TabsTrigger {...props} variant="minimal" className="px-1 pb-2 font-medium">
    {children}
  </TabsTrigger>
)
