import React, { useState } from 'react'

import _ from 'lodash'
import { Check, InfoIcon } from 'lucide-react'

import { Maybe } from 'types'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import { IntegrationDefinition } from './integration-definitions'

interface IntegrationEnableConfirmationDialogProps {
  integrationDefinition: IntegrationDefinition
  onConfirm: (enable: boolean, resourceUrl: Maybe<string>) => void
  open: boolean
  onOpenChange: (open: boolean) => void
}

const IntegrationEnableConfirmationDialog: React.FC<
  IntegrationEnableConfirmationDialogProps
> = ({ integrationDefinition, open, onOpenChange, onConfirm }) => {
  const [resourceUrl, setResourceUrl] = useState<Maybe<string>>()

  const onOpenUpdate = (open: boolean) => {
    setResourceUrl(undefined)
    onOpenChange(open)
  }

  const enableButtonDisabled =
    !_.isNil(integrationDefinition.resourceUrlProps) &&
    integrationDefinition.resourceUrlProps.required &&
    !resourceUrl

  return (
    <Dialog open={open} onOpenChange={onOpenUpdate}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Enable {integrationDefinition.name}</DialogTitle>
        </DialogHeader>
        <div className="flex items-center justify-center py-4">
          <integrationDefinition.Logo className="size-16" />
        </div>
        <div className="my-4 space-y-2 pb-2">
          <p className="font-semibold">Allow anyone in your workspace to</p>
          <ul className="list-none space-y-2">
            {integrationDefinition.enablementContent.map((content, index) => (
              <li key={index} className="flex items-start">
                <span className="mr-2 mt-1">
                  <Icon icon={Check} />
                </span>
                <p>{content}</p>
              </li>
            ))}
          </ul>
        </div>
        {!_.isNil(integrationDefinition.resourceUrlProps) && (
          <div className="my-4 space-y-2 pb-8">
            <div className="flex items-center pb-1">
              <Label className="font-semibold">
                Resource URL
                {integrationDefinition.resourceUrlProps.required && (
                  <span className="text-destructive">*</span>
                )}
              </Label>
              <Tooltip>
                <TooltipTrigger tabIndex={-1}>
                  <Icon
                    icon={InfoIcon}
                    size="small"
                    className="ml-0.5 mt-0.5"
                  />
                </TooltipTrigger>
                <TooltipContent className="w-56">
                  <p>{integrationDefinition.resourceUrlProps.tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </div>
            <Input
              value={resourceUrl || ''}
              onChange={(e) => setResourceUrl(e.target.value)}
              required={integrationDefinition.resourceUrlProps.required}
              placeholder={
                integrationDefinition.resourceUrlProps.inputPlaceholder
              }
            />
          </div>
        )}
        <DialogFooter className="mt-4">
          <DialogClose asChild>
            <Button onClick={() => onOpenUpdate(false)} variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              onClick={() => onConfirm(true, resourceUrl)}
              disabled={enableButtonDisabled}
            >
              Enable integration
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default IntegrationEnableConfirmationDialog
