import React from 'react'

import _ from 'lodash'

import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

import { LibraryItem, LibraryItemKind } from './library-types'

interface LibraryItemDeleteDialogProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  libraryItem: LibraryItem
  taskLabelLookup: Record<string, string>
  deleteItem: (id: string) => Promise<void>
  kind: LibraryItemKind
}

const LibraryItemDeleteDialog: React.FC<LibraryItemDeleteDialogProps> = ({
  modalOpen,
  setModalOpen,
  libraryItem,
  deleteItem,
  kind,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const { trackEvent } = useAnalytics()
  const kindLower = kind.toLowerCase()
  const kindPretty = _.startCase(kindLower)

  const onDeleteSubmit = async () => {
    setIsDeleting(true)
    await deleteItem(libraryItem.id)
    Services.HoneyComb.Record({
      metric: `ui.library_${kindLower}_deleted`,
      item_id: libraryItem.id,
      task_type: libraryItem.eventKind,
      starred: libraryItem.starred,
      workspace_id: libraryItem.workspaceId,
      user_id: libraryItem.userId,
      visibility_scope: libraryItem.visibilityScope,
      categories: libraryItem.categories,
      practice_areas: libraryItem.practiceAreas,
      document_types: libraryItem.documentTypes,
    })
    trackEvent(`Library Item Deleted`, {
      item_id: libraryItem.id,
      event_id: libraryItem.eventId,
      kind: kindPretty,
      task_type: libraryItem.eventKind,
      categories: libraryItem.categories,
      practice_areas: libraryItem.practiceAreas,
      document_types: libraryItem.documentTypes,
      visibility_scope: libraryItem.visibilityScope,
      starred: libraryItem.starred,
    })
    setModalOpen(false)
    setIsDeleting(false)
  }

  const onCancelClick = () => {
    Services.HoneyComb.Record({
      metric: `ui.library_delete_${kindLower}_dialog_cancelled`,
      item_id: libraryItem.id,
      task_type: libraryItem.eventKind,
      starred: libraryItem.starred,
      workspace_id: libraryItem.workspaceId,
      user_id: libraryItem.userId,
      visibility_scope: libraryItem.visibilityScope,
      categories: libraryItem.categories,
      practice_areas: libraryItem.practiceAreas,
      document_types: libraryItem.documentTypes,
    })
    trackEvent(`Library Item Delete Dialog Cancelled`, {
      item_id: libraryItem.id,
      event_id: libraryItem.eventId,
      kind: kindPretty,
      task_type: libraryItem.eventKind,
      categories: libraryItem.categories,
      practice_areas: libraryItem.practiceAreas,
      document_types: libraryItem.documentTypes,
      visibility_scope: libraryItem.visibilityScope,
      starred: libraryItem.starred,
    })
    setModalOpen(false)
  }

  const onOpenChange = (open: boolean) => {
    Services.HoneyComb.Record({
      metric: `ui.library_delete_${kind}_dialog_${
        open ? 'opened' : 'dismissed'
      }`,
      item_id: libraryItem.id,
      task_type: libraryItem.eventKind,
      starred: libraryItem.starred,
      workspace_id: libraryItem.workspaceId,
      user_id: libraryItem.userId,
      visibility_scope: libraryItem.visibilityScope,
      categories: libraryItem.categories,
      practice_areas: libraryItem.practiceAreas,
      document_types: libraryItem.documentTypes,
    })
    trackEvent(`Library Item Delete Dialog ${open ? 'Opened' : 'Dismissed'}`, {
      item_id: libraryItem.id,
      event_id: libraryItem.eventId,
      kind: kindPretty,
      task_type: libraryItem.eventKind,
      categories: libraryItem.categories,
      practice_areas: libraryItem.practiceAreas,
      document_types: libraryItem.documentTypes,
      visibility_scope: libraryItem.visibilityScope,
      starred: libraryItem.starred,
    })
    setModalOpen(open)
  }

  const name = libraryItem.name?.trim()
  const query = libraryItem.query?.trim()
  const { categories, practiceAreas, visibilityScope } = libraryItem

  const hasCategoryAndPracticeArea =
    visibilityScope === LibraryVisbilityScope.WORKSPACE ||
    visibilityScope === LibraryVisbilityScope.HARVEY

  return (
    <Dialog open={modalOpen} onOpenChange={onOpenChange}>
      <DialogContent showCloseIcon={false}>
        <div className="space-y-4">
          <h2 className="text-lg font-semibold">Delete {kindLower}</h2>

          <div className="space-y-1.5">
            <p className="font-semibold">Name</p>
            <p className="line-clamp-1">{name}</p>
          </div>
          <div className="space-y-1.5">
            <p className="font-semibold">{kindPretty} text</p>
            <p className="line-clamp-6">{query}</p>
          </div>
          {hasCategoryAndPracticeArea && (
            <>
              <div className="space-y-1.5">
                <p className="font-semibold">Categories</p>
                {categories.length ? (
                  <p>{categories.join(', ')}</p>
                ) : (
                  <p className="text-muted">None set</p>
                )}
              </div>
              <div className="space-y-1.5">
                <p className="font-semibold">Practice area</p>
                {practiceAreas.length ? (
                  <p>{practiceAreas.join(', ')}</p>
                ) : (
                  <p className="text-muted">None set</p>
                )}
              </div>
            </>
          )}

          <div className="flex justify-end space-x-2 pt-2">
            <Button variant="ghost" onClick={onCancelClick}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              disabled={isDeleting}
              onClick={onDeleteSubmit}
              className="*:text-sm"
            >
              {isDeleting ? (
                <div className="flex items-center">
                  <Spinner size="xxs" className="mr-2" />
                  Deleting…
                </div>
              ) : (
                <span>Delete</span>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LibraryItemDeleteDialog
