import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { HTTPError } from 'ky'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'

import { useAuthUser } from 'components/common/auth-context'

import { NUM_ALL_QUERIES_TO_FETCH } from './vault'
import { SubscribeToVaultFolderUpdates } from './vault-fetcher'
import { useVaultSharingStore } from './vault-sharing-store'
import { useVaultStore } from './vault-store'

export const useVaultFolderUpdatesSubscription = (projectId: string) => {
  const userInfo = useAuthUser()
  const queryClient = useQueryClient()
  const exampleProjectIds = useVaultStore((state) => state.exampleProjectIds)
  const projectMetadata = useVaultStore(
    useShallow((state) => state.allFoldersMetadata[projectId])
  )
  const isExampleProject = exampleProjectIds.has(projectId)
  const isProjectLayoutLoading = useVaultStore(
    (state) => state.isProjectLayoutLoading
  )
  const permissionsByProjectId = useVaultSharingStore(
    (state) => state.permissionsByProjectId
  )
  const upsertVaultFiles = useVaultStore((state) => state.upsertVaultFiles)
  const deleteVaultFiles = useVaultStore((state) => state.deleteVaultFiles)
  const upsertVaultFolders = useVaultStore((state) => state.upsertVaultFolders)
  const deleteVaultFolders = useVaultStore((state) => state.deleteVaultFolders)
  const addToProjectsMetadata = useVaultStore(
    (state) => state.addToProjectsMetadata
  )
  const setPermissionsForProjectId = useVaultSharingStore(
    (state) => state.setPermissionsForProjectId
  )

  const revokeProjectPermission = useCallback(() => {
    setPermissionsForProjectId({
      projectId,
      userId: userInfo.dbId,
      workspaceId: userInfo.workspace.id,
      permissions: null,
    })
    deleteVaultFiles(
      projectMetadata?.descendantFiles?.map((file) => file.id) ?? [],
      projectId
    )
    deleteVaultFolders(
      projectMetadata?.descendantFolders?.map((folder) => folder.id) ?? [],
      projectId
    )
  }, [
    setPermissionsForProjectId,
    userInfo.dbId,
    userInfo.workspace.id,
    projectId,
    deleteVaultFiles,
    deleteVaultFolders,
    projectMetadata,
  ])

  const lastUpdatedAt = useRef(new Date())
  const isFilesProcessing = useMemo(() => {
    // If there are incomplete files, we are already polling for the update, no need to poll content updates again
    return projectMetadata?.completedFiles !== projectMetadata?.totalFiles
  }, [projectMetadata])
  const skipContentUpdates = isFilesProcessing
  const subscribeToVaultFolderUpdates = useCallback(async () => {
    try {
      const response = await SubscribeToVaultFolderUpdates(
        projectId,
        lastUpdatedAt.current,
        skipContentUpdates
      )
      if (
        !response.hasContentUpdates &&
        !response.hasEventsUpdates &&
        !response.hasSharingUpdates
      ) {
        // No updates found, so we can skip the rest of the logic
        return
      }
      lastUpdatedAt.current = new Date()
      if (response.hasContentUpdates) {
        upsertVaultFiles(
          (response.updatedFiles ?? []).filter((file) => !file.deletedAt),
          projectId
        )
        upsertVaultFolders(
          (response.updatedFolders ?? []).filter((folder) => !folder.deletedAt),
          userInfo.dbId,
          isExampleProject,
          projectId
        )
        deleteVaultFiles(
          (response.updatedFiles ?? [])
            .filter((file) => file.deletedAt)
            .map((file) => file.id),
          projectId
        )
        deleteVaultFolders(
          (response.updatedFolders ?? [])
            .filter((folder) => folder.deletedAt)
            .map((folder) => folder.id),
          projectId
        )
        addToProjectsMetadata(
          (response.updatedProjectMetadata ?? []).reduce(
            (acc, metadata) => {
              acc[metadata.id] = metadata
              return acc
            },
            {} as Record<string, VaultFolderMetadata>
          )
        )
      }
      if (response.hasEventsUpdates) {
        await queryClient.invalidateQueries({
          queryKey: [
            HarvQueryKeyPrefix.VaultHistoryQuery,
            projectId,
            NUM_ALL_QUERIES_TO_FETCH,
          ],
        })
      }
      if (response.hasSharingUpdates && response.updatedSharing) {
        if (response.updatedSharing.folderId === projectId) {
          setPermissionsForProjectId({
            projectId,
            userId: userInfo.dbId,
            workspaceId: userInfo.workspace.id,
            permissions: response.updatedSharing.shareStatus,
          })
        } else {
          console.error('Updated sharing folder id is not the project id')
        }
      }
    } catch (error) {
      if (error instanceof HTTPError && error.response.status === 403) {
        revokeProjectPermission()
        return
      }
      throw error
    }
  }, [
    projectId,
    userInfo.dbId,
    isExampleProject,
    upsertVaultFiles,
    upsertVaultFolders,
    deleteVaultFiles,
    deleteVaultFolders,
    addToProjectsMetadata,
    queryClient,
    setPermissionsForProjectId,
    userInfo.workspace.id,
    revokeProjectPermission,
    skipContentUpdates,
  ])

  // Track failures and stop polling after 5 consecutive failures
  const failureCount = useRef(0)
  const isPolling = useRef(false)
  const permissions = useMemo(
    () => permissionsByProjectId[projectId],
    [permissionsByProjectId, projectId]
  )
  useEffect(() => {
    if (!userInfo.IsVaultViewSharesUser) {
      // Don't poll if the user is not the vault view shares user
      return
    }
    if (isProjectLayoutLoading) {
      // Don't poll if the vault project detail page is still loading
      return
    }
    if (
      !(
        (permissions?.permissionsByWorkspace &&
          permissions.permissionsByWorkspace.length > 0) ||
        (permissions?.permissionsByUser &&
          permissions.permissionsByUser.length > 0)
      )
    ) {
      // Don't poll folder updates if project is not shared
      return
    }

    const intervalId = setInterval(async () => {
      if (document.visibilityState !== 'visible') {
        // Don't poll if the tab is not visible
        return
      }
      if (!navigator.onLine) {
        // Don't poll if the network is offline
        return
      }
      if (!isPolling.current) {
        isPolling.current = true
        try {
          // This call might take up to 45 seconds to finish if no updates are found.
          await subscribeToVaultFolderUpdates()
        } catch (error) {
          console.error('Error subscribing to vault folder updates', error)
          failureCount.current += 1
          if (failureCount.current >= 5) {
            console.info(
              'Stopping vault folder updates polling due to repeated failures'
            )
            clearInterval(intervalId)
          }
        }
        isPolling.current = false
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [
    subscribeToVaultFolderUpdates,
    userInfo.IsVaultViewSharesUser,
    isProjectLayoutLoading,
    permissions,
  ])
}
