import React from 'react'

import { Permission, UserInfo } from 'models/user-info'
import { IntegrationType } from 'openapi/models/IntegrationType'

import SharePointIcon from 'components/ui/icons/sharepoint-icon'

export interface ResourceUrlProps {
  required: boolean
  tooltip: string
  inputPlaceholder?: string
}

export interface IntegrationDefinition {
  name: string
  permission: Permission
  Logo: React.ComponentType<{ className?: string }>
  title: React.ComponentType<{ className?: string }>
  description: string
  available: (userInfo: UserInfo) => boolean
  enablementContent: string[]
  resourceUrlProps?: ResourceUrlProps
}

export const IntegrationDefinitions: Record<
  IntegrationType,
  IntegrationDefinition
> = {
  [IntegrationType.SHAREPOINT]: {
    name: 'SharePoint',
    permission: Permission.SHAREPOINT_INTEGRATION,
    Logo: SharePointIcon,
    title: ({ className }) => (
      <p className={className}>
        <span className="font-semibold">SharePoint</span>{' '}
        <span className="text-secondary"> (with OneDrive)</span>
      </p>
    ),
    description:
      'Connect your work account to upload files directly from SharePoint or OneDrive.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(IntegrationType.SHAREPOINT) &&
        userInfo.permissions.includes(Permission.SHAREPOINT_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their SharePoint and OneDrive accounts from Harvey.',
      'Upload files from SharePoint and OneDrive directly into Harvey.',
    ],
    resourceUrlProps: {
      required: true,
      tooltip:
        'The resource URL is the URL of SharePoint or OneDrive site you want to connect to. It should start with https://',
      inputPlaceholder: 'https://acme.sharepoint.com/',
    },
  },
}
