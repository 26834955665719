import { type User } from '@auth0/auth0-react'
import _ from 'lodash'

import type { UserSettings } from 'models/user-settings'
import { DefaultAdminPermission } from 'openapi/models/DefaultAdminPermission'
import { DefaultBasePermission } from 'openapi/models/DefaultBasePermission'
import { DefaultRole } from 'openapi/models/DefaultRole'
import { IntegrationPermission } from 'openapi/models/IntegrationPermission'
import { InternalOnlyPermission } from 'openapi/models/InternalOnlyPermission'
import { Permission as OpenApiPermission } from 'openapi/models/Permission'
import { RestrictedPermission } from 'openapi/models/RestrictedPermission'
import { SensitiveCustomerDataPermission } from 'openapi/models/SensitiveCustomerDataPermission'
import { SensitiveDataPermGranterEmail } from 'openapi/models/SensitiveDataPermGranterEmail'
import { SensitiveUserPermission } from 'openapi/models/SensitiveUserPermission'
import { UserInfoUserProfile } from 'openapi/models/UserInfoUserProfile'
import { WorkspaceEyesOffTier } from 'openapi/models/WorkspaceEyesOffTier'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'
import Services from 'services'
import { Maybe } from 'types'

import { TaskType } from 'utils/task'

import { useUserProfileStore } from 'components/common/user-profile-store'

import { PermWithSource } from './perms'
import {
  WelcomeScreenType,
  Workspace,
  WorkspaceKind,
  type RawWorkspace,
  type WelcomeScreen,
} from './workspace'

export const Permission: Record<string, OpenApiPermission> = {
  BASE: OpenApiPermission.CREATECOMPLETION,
  ORGANIZATION_ADMIN: OpenApiPermission.ADMINORGANIZATION,
  TERRITORY_ADMIN: OpenApiPermission.ADMINTERRITORY,
  INTERNAL_ADMIN_READ: OpenApiPermission.READALL_WORKSPACES,
  INTERNAL_ADMIN_WRITE: OpenApiPermission.WRITEALL_WORKSPACES,
  DOCUMENT_QA: OpenApiPermission.CREATEDOCUMENT_QA,
  MULTI_DOC_QA: OpenApiPermission.CREATEMULTI_DOC_QA,
  CORPUS_QA: OpenApiPermission.CREATECORPUS_QA,
  REDLINES: OpenApiPermission.CREATEREDLINES,
  SEC_EDGAR_QA: OpenApiPermission.CREATESEC_EDGAR_QA,
  SEC_EDGAR_ALL: OpenApiPermission.CREATEEDGAR_ALL_FILINGS,
  HMRC_QA: OpenApiPermission.CREATEHMRC_QA,
  JAPAN_TAX_QA: OpenApiPermission.CREATEJAPAN_TAX_QA,
  NETHERLANDS_TAX_QA: OpenApiPermission.CREATENETHERLANDS_TAX_QA,
  INDIA_TAX_QA: OpenApiPermission.CREATEINDIA_TAX_QA,
  IRELAND_TAX_QA: OpenApiPermission.CREATEIRELAND_TAX_QA,
  AUSTRALIA_TAX_QA: OpenApiPermission.CREATEAUSTRALIA_TAX_QA,
  SWEDEN_TAX_QA: OpenApiPermission.CREATESWEDEN_TAX_QA,
  KOREA_TAX_QA: OpenApiPermission.CREATEKOREA_TAX_QA,
  ITALY_TAX_QA: OpenApiPermission.CREATEITALY_TAX_QA,
  UAE_TAX_QA: OpenApiPermission.CREATEUAE_TAX_QA,
  SWITZERLAND_TAX_QA: OpenApiPermission.CREATESWITZERLAND_TAX_QA,
  PILLAR_TWO_TAX_QA: OpenApiPermission.CREATEPILLAR_TWO_TAX_QA,
  WWTS_QA: OpenApiPermission.CREATEWWTS_QA,
  FEEDBACK: OpenApiPermission.CREATEFEEDBACK,
  TRANSLATION: OpenApiPermission.CREATETRANSLATION,
  TRANSLATION_API: OpenApiPermission.CREATETRANSLATION_API,
  COMPANY_PROFILE: OpenApiPermission.CREATECOMPANY_PROFILE,
  HISTORY: OpenApiPermission.READHISTORY,
  EXAMPLES_READ: OpenApiPermission.READEXAMPLES,
  EXAMPLES_WRITE: OpenApiPermission.WRITEEXAMPLES,
  INTERNET_BROWSING: OpenApiPermission.CREATEINTERNET_BROWSING,
  OGC_REVIEW: OpenApiPermission.CREATEOGC_REVIEW,
  MANAGE_USERS: OpenApiPermission.MANAGEUSERS,
  READ_STATUS_BAR: OpenApiPermission.READSTATUS_BAR,
  DRAFTING: OpenApiPermission.CREATEDRAFTING,
  RESPONSE_COMPARE_USER: OpenApiPermission.DECIDERESPONSE_COMPARISON,
  RESPONSE_COMPARE_ADMIN: OpenApiPermission.CREATERESPONSE_COMPARISON,
  DOC_COMPARE: OpenApiPermission.DOCUMENT_COMPARISON,
  PWC_TAX_FEEDBACK_EXPORT: OpenApiPermission.CREATEPWC_TAX_FEEDBACK_EXPORT,
  MEMOS_QA: OpenApiPermission.CREATEMEMOS_QA,
  CONTRACTS: OpenApiPermission.CREATECONTRACTS,
  EURLEX_QA: OpenApiPermission.CREATEEURLEX_QA,
  USA_CASE_LAW: OpenApiPermission.CREATEUSA_CASE_LAW,
  FRANCE_CASE_LAW: OpenApiPermission.CREATEFRANCE_CASE_LAW,
  CLIENT_MATTERS_READ: OpenApiPermission.READCLIENT_MATTERS,
  CLIENT_MATTERS_WRITE: OpenApiPermission.WRITECLIENT_MATTERS,
  TRANSCRIPTS: OpenApiPermission.TRANSCRIPTS,
  AUS_BREACH_REPORTING: OpenApiPermission.AUS_BREACH_REPORTING,
  CUATRECASAS: OpenApiPermission.CREATECUATRECASAS,
  FROM_COUNSEL: OpenApiPermission.CREATEFROM_COUNSEL,
  READ_SENSITIVE_DATA: OpenApiPermission.READALL_SENSITIVE,
  ADVANCED_DOC_QA: OpenApiPermission.CREATEADVANCED_DOC_QA,
  ASSISTANT_DRAFT: OpenApiPermission.CREATEASSISTANT_DRAFT,
  ASSISTANT_V2_SURVEY_FEEDBACK:
    OpenApiPermission.CREATEASSISTANT_V2_SURVEY_FEEDBACK,
  LEGACY_OPEN_ENDED: OpenApiPermission.LEGACY_OPEN_ENDED,
  OPEN_ENDED_BRIDGE_ROUTING: OpenApiPermission.USEOPEN_ENDED_BRIDGE_ROUTING,
  PWC_DEALS: OpenApiPermission.PWC_DEALS,
  DILIGENCE_TRANSCRIPTS: OpenApiPermission.CREATEDILIGENCE_TRANSCRIPTS,
  DEALS_DISCOVERY_ASYNC: OpenApiPermission.USEDEALS_DISCOVERY_ASYNC_IMPL,
  DEALS_DISCOVERY_FROM_VAULT: OpenApiPermission.USEDEALS_DISCOVERY_FROM_VAULT,
  PWC_HR_WORKFORCE_API: OpenApiPermission.CREATEPWC_HR_WORKFORCE_API,
  VAULT_CREATE: OpenApiPermission.CREATEVAULT,
  VAULT_REVIEW_CREATE: OpenApiPermission.CREATEVAULT_REVIEW,
  VAULT_INTERNAL_ONLY_CREATE: OpenApiPermission.CREATEVAULT_INTERNAL_ONLY,
  VAULT_KNOWLEDGE_SOURCE: OpenApiPermission.USEVAULT_KNOWLEDGE_SOURCE,
  VAULT_SANDBOX_PROJECT_CREATE: OpenApiPermission.CREATEVAULT_SANDBOX_PROJECT,
  VAULT_WORKFLOW_REPS_WARRANTIES:
    OpenApiPermission.CREATEVAULT_WORKFLOW_REPS_WARRANTIES,
  VAULT_LARGE_FILE_SIZE: OpenApiPermission.CREATEVAULT_LARGE_FILE_SIZE,
  VAULT_V2: OpenApiPermission.CREATEVAULT_V2,
  VAULT_WORKFLOW_ADMIN: OpenApiPermission.CREATEVAULT_WORKFLOW_ADMIN,
  VAULT_PROJECT_CLIENT_MATTER: OpenApiPermission.USEVAULT_PROJECT_CLIENT_MATTER,
  VAULT_EVENTS_DUAL_WRITE: OpenApiPermission.CREATEVAULT_EVENTS_DUAL_WRITE,
  LIBRARY_USER: OpenApiPermission.CREATEUSER_LIBRARY_ITEMS,
  LIBRARY_ADMIN: OpenApiPermission.CREATEWORKSPACE_LIBRARY_ITEMS,
  LIBRARY_PRIVATE_PROMPTS: OpenApiPermission.CREATEPRIVATE_LIBRARY_PROMPTS,
  LIBRARY_CREATE_HARVEY_ITEMS: OpenApiPermission.CREATEHARVEY_LIBRARY_ITEMS,
  VIEWTOUR_CLIENT_MATTER: OpenApiPermission.VIEWTOUR_CLIENT_MATTER,
  CREATE_ASSISTANT_V2_SURVEY_FEEDBACK:
    OpenApiPermission.CREATEASSISTANT_V2_SURVEY_FEEDBACK,
  LIBRARY_EXAMPLES: OpenApiPermission.USELIBRARY_EXAMPLES,
  SKIP_RATE_LIMIT: OpenApiPermission.USESKIP_RATE_LIMIT,
  READ_WORKSPACE_HISTORY: OpenApiPermission.READWORKSPACE_HISTORY,
  CLIENT_ADMIN_ADD_USERS: OpenApiPermission.CLIENT_ADMINADD_USERS,
  CLIENT_ADMIN_REMOVE_USERS: OpenApiPermission.CLIENT_ADMINREMOVE_USERS,
  CLIENT_ADMIN_VIEW_USERS: OpenApiPermission.CLIENT_ADMINVIEW_USERS,
  CLIENT_ADMIN_VAULT_ADD_USERS: OpenApiPermission.CLIENT_ADMINVAULT_ADD_USERS,
  CLIENT_ADMIN_VAULT_REMOVE_USERS:
    OpenApiPermission.CLIENT_ADMINVAULT_REMOVE_USERS,
  CREATE_ASSISTANT_DRAFT: OpenApiPermission.CREATEASSISTANT_DRAFT,
  CREATE_CUATRECASAS: OpenApiPermission.CREATECUATRECASAS,
  CREATE_FROM_COUNSEL: OpenApiPermission.CREATEFROM_COUNSEL,
  ICERTIS_FIELD_EXTRACTION_API:
    OpenApiPermission.CREATEICERTIS_FIELD_EXTRACTION_API,
  VIEW_USAGE_DASHBOARD: OpenApiPermission.VIEWUSAGE_DASHBOARD,
  VIEW_USAGE_DASHBOARD_V2: OpenApiPermission.VIEWUSAGE_DASHBOARD_V2,
  VIEW_EXPORT_TIMEZONE_SELECTION:
    OpenApiPermission.VIEWEXPORT_TIMEZONE_SELECTION,
  VIEW_WORKSPACE_INFO: OpenApiPermission.VIEWWORKSPACE_INFO,
  DELETE_USER_HISTORY: OpenApiPermission.DELETEUSER_HISTORY,
  DELETE_WORKSPACE_HISTORY: OpenApiPermission.DELETEWORKSPACE_HISTORY,
  DELETE_WORKSPACE_HISTORY_BULK: OpenApiPermission.DELETEWORKSPACE_HISTORY_BULK,
  OPEN_ENDED_BRIDGE_ROUTING_FULL:
    OpenApiPermission.USEOPEN_ENDED_BRIDGE_ROUTING_FULL,
  READ_WORKSPACE_VAULT: OpenApiPermission.READWORKSPACE_VAULT,
  USE_ASSISTANT_V2: OpenApiPermission.USEASSISTANT_V2,
  USE_HARVEY_FOR_WORD: OpenApiPermission.USEHARVEY_FOR_WORD,
  USE_HARVEY_FOR_COPILOT: OpenApiPermission.USEHARVEY_FOR_COPILOT,
  USE_HARVEY_FOR_CUSTOM_ENGINE_COPILOT:
    OpenApiPermission.USEHARVEY_FOR_CUSTOM_ENGINE_COPILOT,
  USE_COMPETITIVE_ANALYSIS: OpenApiPermission.USECOMPETITIVE_ANALYSIS,
  USE_SWEDEN_CASE_LAW: OpenApiPermission.USESWEDEN_CASE_LAW,
  USE_SINGAPORE_CASE_LAW: OpenApiPermission.USESINGAPORE_CASE_LAW,
  USE_MACFARLANES_KS: OpenApiPermission.USEMACFARLANES_KS,
  READ_ENG_INTERNAL: OpenApiPermission.READENG_INTERNAL,
  WRITE_ENG_INTERNAL: OpenApiPermission.WRITEENG_INTERNAL,
  USE_VAULT_SHARING: OpenApiPermission.USEVAULT_SHARING,
  USE_EVENT_SHARING: OpenApiPermission.USEEVENT_SHARING,
  CREATE_VAULT_SHARES: OpenApiPermission.CREATEVAULT_SHARES,
  CREATE_EVENT_SHARES: OpenApiPermission.CREATEEVENT_SHARES,
  MANAGE_SHARING: OpenApiPermission.CLIENT_ADMINMANAGE_SHARING,
  VIEW_HELP_CENTER: OpenApiPermission.VIEWHELP_CENTER,
  READ_SOME_SENSITIVE_DATA: OpenApiPermission.READSOME_SENSITIVE_DATA,
  DILIGENCE_UK_TAX: OpenApiPermission.CREATEDILIGENCE_UK_TAX,
  ICERTIS_REVIEW_API: OpenApiPermission.CREATEICERTIS_REVIEW_API,
  CREATE_VALS_API: OpenApiPermission.CREATEVALS_API,
  ASYNC_FIELD_EXTRACTION_API:
    OpenApiPermission.CREATEASYNC_FIELD_EXTRACTION_API,
  ASSISTANT_TAX_KNOWLEDGE_SOURCE: OpenApiPermission.USEASSISTANT_TAX_KS,
  DISALLOW_CLIENT_MATTERS: OpenApiPermission.USEDISALLOWED_CLIENT_MATTERS,
  ASSISTANT_RESEARCH_KNOWLEDGE_SOURCE:
    OpenApiPermission.USEASSISTANT_RESEARCH_KS,
  CLIENT_ADMIN_API_TOKEN_MGMT: OpenApiPermission.CLIENT_ADMINAPI_TOKEN_MGMT,
  READ_MESSAGE_LEVEL_CREATED_TIMESTAMP:
    OpenApiPermission.READMESSAGE_LEVEL_CREATED_TIMESTAMP,
  SHAREPOINT_INTEGRATION: OpenApiPermission.USESHAREPOINT_INTEGRATION,
  INTEGRATION_ADMIN: OpenApiPermission.CLIENT_ADMININTEGRATIONS,
  CREATE_USER_PROFILES: OpenApiPermission.CREATEUSER_PROFILES,
  VIEW_WORD_ADD_IN_ANNOUNCEMENT: OpenApiPermission.VIEWWORD_ADD_IN_ANNOUNCEMENT,
  USE_ASSISTANT_LOCALIZATION: OpenApiPermission.USEASSISTANT_LOCALIZATION,
  USE_PASSWORD_PROTECTED_DOCS: OpenApiPermission.USEPASSWORD_PROTECTED_DOCS,
  USE_DISCOVER_TAB: OpenApiPermission.USEDISCOVER_TAB,
  USE_PERMISSION_BUNDLES: OpenApiPermission.USEPERMISSION_BUNDLES,
} as const
export type Permission = (typeof Permission)[keyof typeof Permission]

type OpenApiPermissionMapping = {
  [K in OpenApiPermission]: boolean
}

export const checkPermissionsCoverage = (
  permissionObject: Record<string, OpenApiPermission>
): OpenApiPermissionMapping => {
  const coverage: Partial<OpenApiPermissionMapping> = {}

  Object.values(OpenApiPermission).forEach((permission) => {
    coverage[permission] = Object.values(permissionObject).includes(permission)
  })

  return coverage as OpenApiPermissionMapping
}

export const SENSITIVE_USER_PERMS: Set<Permission> = new Set(
  Object.values(SensitiveUserPermission) as Permission[]
)

export const SENSITIVE_WORKSPACE_PERMS: Set<Permission> = new Set(
  Object.values(SensitiveUserPermission) as Permission[]
)

export const SENSITIVE_CUSTOMER_DATA_PERMS: Set<Permission> = new Set(
  Object.values(SensitiveCustomerDataPermission) as Permission[]
)

export const INTERNAL_ONLY_PERMS: Set<Permission> = new Set(
  Object.values(InternalOnlyPermission) as Permission[]
)

export const RESTRICTED_PERMS: Set<Permission> = new Set(
  Object.values(RestrictedPermission) as Permission[]
)

export const DEFAULT_BASE_PERMS: Set<Permission> = new Set(
  Object.values(DefaultBasePermission) as Permission[]
)

export const DEFAULT_ADMIN_PERMS: Set<Permission> = new Set(
  Object.values(DefaultAdminPermission) as Permission[]
)

export const SENSITIVE_DATA_PERM_GRANTER_EMAILS: Set<string> = new Set(
  Object.values(SensitiveDataPermGranterEmail)
)

export const INTEGRATION_PERMS: Set<Permission> = new Set(
  Object.values(IntegrationPermission) as Permission[]
)

export const isInternalOnlyPerm = (perm_id: Permission) => {
  return INTERNAL_ONLY_PERMS.has(perm_id)
}

export const isSensitiveUserPerm = (perm_id: Permission) => {
  return SENSITIVE_USER_PERMS.has(perm_id)
}

export const isSensitiveWorkspacePerm = (perm_id: Permission) => {
  return SENSITIVE_WORKSPACE_PERMS.has(perm_id)
}

export const isSensitivePerm = (perm_id: Permission) => {
  return isSensitiveUserPerm(perm_id) || isSensitiveWorkspacePerm(perm_id)
}

export const isRestrictedPerm = (perm_id: Permission) => {
  return RESTRICTED_PERMS.has(perm_id)
}

export const isSensitiveCustomerDataPerm = (perm_id: Permission) => {
  return SENSITIVE_CUSTOMER_DATA_PERMS.has(perm_id)
}

export const isInternalUser = (email: string) => {
  return email.endsWith('@harvey.ai')
}

export const isIntegrationPerm = (permission: Permission) => {
  return INTEGRATION_PERMS.has(permission)
}

export const API_USER_SENTINEL = 'svc-user'

// TODO(@jhoong) we should use the UserInfo type from the generated openapi code
export interface RawUserInfo {
  dbId: string
  id: string // email
  pseudonymizedId: string
  permissions: Permission[]
  workspace: RawWorkspace
  permsWithSource: PermWithSource[]
  settings?: UserSettings
  deletedAt?: Maybe<string>
  createdAt?: Maybe<string>
  administrableWorkspaces?: number[]
  backendVersion?: string
  fetchErr?: Error
  requiresOnboardingNewUser: boolean
  requiresOnboardingExistingUser: boolean
  userProfile: Maybe<UserInfoUserProfile>
}

export class UserInfo {
  dbId: string
  id: string
  pseudonymizedId: string
  permissions: Permission[]
  permsWithSource: PermWithSource[]
  workspace: Workspace
  settings: UserSettings
  deletedAt?: Maybe<string>
  createdAt?: Maybe<string>
  permsByWorkspace?: Record<number, Permission[]>
  backendVersion?: string
  fetchErr?: Error
  requiresOnboardingNewUser: boolean
  requiresOnboardingExistingUser: boolean
  userProfile: Maybe<UserInfoUserProfile>

  constructor(raw: RawUserInfo) {
    this.dbId = raw.dbId
    this.id = raw.id
    this.pseudonymizedId = raw.pseudonymizedId
    this.permissions = raw.permissions
    this.permsWithSource = raw.permsWithSource
    this.workspace = new Workspace(raw.workspace)
    this.settings = raw.settings ?? {}
    this.deletedAt = raw.deletedAt
    this.createdAt = raw.createdAt
    this.backendVersion = raw.backendVersion
    this.fetchErr = raw.fetchErr
    this.requiresOnboardingNewUser = raw.requiresOnboardingNewUser
    this.requiresOnboardingExistingUser = raw.requiresOnboardingExistingUser
    this.userProfile = raw.userProfile
  }

  get IsAuthorized(): boolean {
    return (
      this.workspace.id !== -1 &&
      Object.values(Permission).some((p) => this.permissions.includes(p))
    )
  }

  get HasCompletedProductTour(): boolean {
    return this.settings.productTourCompleted ?? false
  }

  get HasCompletedV1WelcomeModal(): boolean {
    return this.settings.v1WelcomeModalCompleted ?? false
  }

  get HasCompletedV1ProductTour(): boolean {
    return this.settings.v1ProductTourCompleted ?? false
  }

  get WelcomeScreens() {
    return this.settings.welcomeScreens
  }

  get WelcomeScreensToShow(): WelcomeScreen[] {
    const userWs = this.settings?.welcomeScreens ?? {}
    const wsToShow = this.workspace.welcomeScreens.filter((ws) => {
      // check userWs to see if it has a key matching ws.id
      return !(ws.id in userWs)
    })

    return wsToShow
  }

  get IsInternalUser(): boolean {
    return isInternalUser(this.id)
  }

  get IsTestUser(): boolean {
    return this.id.endsWith('@test.com') || this.id.endsWith('.harvey-test.org')
  }

  get IsBaseUser(): boolean {
    return this.permissions.includes(Permission.BASE)
  }

  get IsReadSensitiveDataUser(): boolean {
    return (
      this.permissions.includes(Permission.READ_SENSITIVE_DATA) &&
      this.IsInternalUser
    )
  }

  get isReadSomeSensitiveDataUser(): boolean {
    return (
      this.permissions.includes(Permission.READ_SOME_SENSITIVE_DATA) &&
      this.IsInternalUser
    )
  }

  get isSensitiveDataReader(): boolean {
    return this.IsReadSensitiveDataUser || this.isReadSomeSensitiveDataUser
  }

  get IsOrganizationAdmin(): boolean {
    return this.permissions.includes(Permission.ORGANIZATION_ADMIN)
  }

  get IsTerritoryAdmin(): boolean {
    return (
      this.IsOrganizationAdmin ||
      this.permissions.includes(Permission.TERRITORY_ADMIN)
    )
  }

  get IsInternalAdminWriter(): boolean {
    return (
      this.IsInternalUser &&
      this.permissions.includes(Permission.INTERNAL_ADMIN_WRITE)
    )
  }

  get IsInternalAdminReader(): boolean {
    return (
      this.IsInternalAdminWriter ||
      (this.IsInternalUser &&
        (this.permissions.includes(Permission.INTERNAL_ADMIN_READ) ||
          this.permissions.includes(Permission.MANAGE_USERS)))
    )
  }

  get IsDocQaUser(): boolean {
    return this.permissions.includes(Permission.DOCUMENT_QA)
  }

  get IsMultiDocQaUser(): boolean {
    return this.permissions.includes(Permission.MULTI_DOC_QA)
  }

  get IsCorpusQaUser(): boolean {
    return this.permissions.includes(Permission.CORPUS_QA)
  }

  get isDocumentUser(): boolean {
    return this.IsDocQaUser || this.IsMultiDocQaUser || this.IsCorpusQaUser
  }

  get IsAssistantUser(): boolean {
    return (
      this.IsAssistantV2User ||
      this.isDocumentUser ||
      this.permissions.includes(Permission.BASE)
    )
  }

  get IsTranslationUser(): boolean {
    return this.permissions.includes(Permission.TRANSLATION)
  }

  get IsDraftingUser(): boolean {
    return this.permissions.includes(Permission.DRAFTING)
  }

  get IsResponseComparisonUser(): boolean {
    return (
      this.permissions.includes(Permission.RESPONSE_COMPARE_USER) ||
      this.permissions.includes(Permission.RESPONSE_COMPARE_ADMIN)
    )
  }

  get IsResponseComparisonAdmin(): boolean {
    return this.permissions.includes(Permission.RESPONSE_COMPARE_ADMIN)
  }

  get IsPWCTaxFeedbackExportUser(): boolean {
    return (
      this.IsInternalUser &&
      this.permissions.includes(Permission.PWC_TAX_FEEDBACK_EXPORT)
    )
  }

  get IsHistoryUser(): boolean {
    return (
      this.workspace.couldHistory &&
      this.permissions.includes(Permission.HISTORY)
    )
  }

  get IsVaultUser(): boolean {
    return this.permissions.includes(Permission.VAULT_CREATE)
  }

  get IsVaultReviewUser(): boolean {
    return this.permissions.includes(Permission.VAULT_REVIEW_CREATE)
  }

  get IsVaultInternalOnlyUser(): boolean {
    return this.permissions.includes(Permission.VAULT_INTERNAL_ONLY_CREATE)
  }

  get IsVaultCreateSandboxProjectUser(): boolean {
    return this.permissions.includes(Permission.VAULT_SANDBOX_PROJECT_CREATE)
  }

  get IsVaultKnowledgeSourceUser(): boolean {
    return (
      this.permissions.includes(Permission.VAULT_KNOWLEDGE_SOURCE) &&
      this.IsVaultUser
    )
  }

  /**
   * User can see "Shared with you" tab in Vault home and view shared projects
   */
  get IsVaultViewSharesUser(): boolean {
    return (
      (this.permissions.includes(Permission.USE_VAULT_SHARING) ||
        this.permissions.includes(Permission.VAULT_CREATE)) &&
      this.workspace.sharingSettings.vault.enabled
    )
  }

  /**
   * User can share projects with others in addition to viewing shared projects
   */
  get IsVaultCreateSharesUser(): boolean {
    return (
      this.permissions.includes(Permission.CREATE_VAULT_SHARES) &&
      this.permissions.includes(Permission.VAULT_CREATE) &&
      this.workspace.sharingSettings.vault.enabled
    )
  }

  /** User can share/update shares for events */
  get IsEventCreateSharesUser(): boolean {
    return (
      this.permissions.includes(Permission.CREATE_EVENT_SHARES) &&
      this.workspace.sharingSettings.assistant.enabled
    )
  }

  get IsVaultSharingManagementUser(): boolean {
    return (
      this.permissions.includes(Permission.MANAGE_SHARING) &&
      this.workspace.hasVaultAddOn
    )
  }

  get IsAssistantSharingManagementUser(): boolean {
    return (
      this.permissions.includes(Permission.MANAGE_SHARING) &&
      this.IsAssistantUser
    )
  }

  get IsSharingManagementUser(): boolean {
    return (
      this.IsVaultSharingManagementUser || this.IsAssistantSharingManagementUser
    )
  }

  get IsVaultWorkflowRepsWarrantiesUser(): boolean {
    return this.permissions.includes(Permission.VAULT_WORKFLOW_REPS_WARRANTIES)
  }

  get IsVaultLargeFileSizeUser(): boolean {
    return this.permissions.includes(Permission.VAULT_LARGE_FILE_SIZE)
  }

  get IsVaultV2User(): boolean {
    return this.IsVaultUser && this.permissions.includes(Permission.VAULT_V2)
  }

  get IsVaultDualWriteUser(): boolean {
    return (
      this.IsVaultUser &&
      this.permissions.includes(Permission.VAULT_EVENTS_DUAL_WRITE)
    )
  }

  get IsVaultWorkflowAdminUser(): boolean {
    return (
      this.IsInternalUser &&
      this.IsVaultUser &&
      this.permissions.includes(Permission.VAULT_WORKFLOW_ADMIN)
    )
  }

  get IsVaultProjectClientMatterUser(): boolean {
    return (
      this.permissions.includes(Permission.VAULT_PROJECT_CLIENT_MATTER) &&
      this.IsVaultUser
    )
  }

  get IsLibraryUser(): boolean {
    return (
      this.permissions.includes(Permission.LIBRARY_USER) ||
      this.permissions.includes(Permission.LIBRARY_ADMIN)
    )
  }

  get IsLibraryExamplesUser(): boolean {
    return this.permissions.includes(Permission.LIBRARY_EXAMPLES)
  }

  get IsLibraryAdmin(): boolean {
    return this.permissions.includes(Permission.LIBRARY_ADMIN)
  }

  get IsLibraryPrivatePromptUser(): boolean {
    return (
      this.permissions.includes(Permission.LIBRARY_PRIVATE_PROMPTS) ||
      this.permissions.includes(Permission.LIBRARY_ADMIN)
    )
  }

  get IsLibraryCreateHarveyItemsUser(): boolean {
    return this.permissions.includes(Permission.LIBRARY_CREATE_HARVEY_ITEMS)
  }

  get nonDbId(): string {
    return this.workspace.isEyesOff ? this.pseudonymizedId : this.id
  }

  get IsEDGARQAUser(): boolean {
    return this.permissions.includes(Permission.SEC_EDGAR_QA)
  }

  get IsRedlinesUser(): boolean {
    return this.permissions.includes(Permission.REDLINES)
  }

  get IsHMRCQAUser(): boolean {
    return this.permissions.includes(Permission.HMRC_QA)
  }

  get IsMemosUser(): boolean {
    return this.permissions.includes(Permission.MEMOS_QA)
  }

  get IsUsaCaseLawUser(): boolean {
    return this.permissions.includes(Permission.USA_CASE_LAW)
  }

  get IsFranceCaseLawUser(): boolean {
    return this.permissions.includes(Permission.FRANCE_CASE_LAW)
  }

  get IsEurLexUser(): boolean {
    return this.permissions.includes(Permission.EURLEX_QA)
  }

  get IsJapanTaxQAUser(): boolean {
    return this.permissions.includes(Permission.JAPAN_TAX_QA)
  }

  get IsKoreaTaxQAUser(): boolean {
    return this.permissions.includes(Permission.KOREA_TAX_QA)
  }

  get IsItalyTaxQAUser(): boolean {
    return this.permissions.includes(Permission.ITALY_TAX_QA)
  }

  get IsUAETaxQAUser(): boolean {
    return this.permissions.includes(Permission.UAE_TAX_QA)
  }

  get IsNetherlandsTaxQAUser(): boolean {
    return this.permissions.includes(Permission.NETHERLANDS_TAX_QA)
  }

  get IsIndiaTaxQAUser(): boolean {
    return this.permissions.includes(Permission.INDIA_TAX_QA)
  }

  get IsAustraliaTaxQaUser(): boolean {
    return this.permissions.includes(Permission.AUSTRALIA_TAX_QA)
  }

  get IsCuatrecasasUser(): boolean {
    return this.permissions.includes(Permission.CUATRECASAS)
  }

  get IsFromCounselUser(): boolean {
    return this.permissions.includes(Permission.FROM_COUNSEL)
  }

  get IsPillarTwoTaxQAUser(): boolean {
    return this.permissions.includes(Permission.PILLAR_TWO_TAX_QA)
  }

  get IsRegionalTaxQAUser(): boolean {
    return (
      this.IsHMRCQAUser ||
      this.IsJapanTaxQAUser ||
      this.IsNetherlandsTaxQAUser ||
      this.IsIndiaTaxQAUser ||
      this.IsAustraliaTaxQaUser ||
      this.IsPillarTwoTaxQAUser ||
      this.isIrelandTaxQaUser ||
      this.isSwedenTaxQaUser ||
      this.isSwitzerlandTaxQaUser ||
      this.IsKoreaTaxQAUser ||
      this.IsItalyTaxQAUser ||
      this.IsUAETaxQAUser ||
      this.IsWWTSQAUser
    )
  }

  get IsAusBreachReportingUser(): boolean {
    return this.permissions.includes(Permission.AUS_BREACH_REPORTING)
  }

  get IsWWTSQAUser(): boolean {
    return this.permissions.includes(Permission.WWTS_QA)
  }

  get IsCompanyProfileUser(): boolean {
    return this.permissions.includes(Permission.COMPANY_PROFILE)
  }

  get IsFeedbackUser(): boolean {
    return this.permissions.includes(Permission.FEEDBACK)
  }

  get IsInternetBrowsingUser(): boolean {
    return this.permissions.includes(Permission.INTERNET_BROWSING)
  }

  get IsOGCReviewUser(): boolean {
    return this.permissions.includes(Permission.OGC_REVIEW)
  }

  get IsDocumentComparisonUser(): boolean {
    return this.permissions.includes(Permission.DOC_COMPARE)
  }

  get IsAssistantDraftUser(): boolean {
    return this.permissions.includes(Permission.CREATE_ASSISTANT_DRAFT)
  }

  get IsAssistantV2User(): boolean {
    return this.permissions.includes(Permission.USE_ASSISTANT_V2)
  }

  get IsAssistantV2SurveyUser(): boolean {
    return this.permissions.includes(
      Permission.CREATE_ASSISTANT_V2_SURVEY_FEEDBACK
    )
  }

  get IsHarveyV1ResearchUser(): boolean {
    // Harvey employees should have access to Research, and the assumption is that they will also have access to any underlying products
    // External people with access to Tax Q&A should also have access to Research (this is a small group right now)
    // No external people should have access to SEC in Research
    return (
      this.IsRegionalTaxQAUser ||
      this.permissions.includes(Permission.SEC_EDGAR_QA) ||
      this.permissions.includes(Permission.MEMOS_QA) ||
      this.permissions.includes(Permission.EURLEX_QA) ||
      this.permissions.includes(Permission.USA_CASE_LAW) ||
      this.permissions.includes(Permission.FRANCE_CASE_LAW) ||
      this.permissions.includes(Permission.AUS_BREACH_REPORTING) ||
      this.permissions.includes(Permission.CUATRECASAS) ||
      this.permissions.includes(Permission.FROM_COUNSEL)
    )
  }

  get isIrelandTaxQaUser(): boolean {
    return this.permissions.includes(Permission.IRELAND_TAX_QA)
  }

  get isSwedenTaxQaUser(): boolean {
    return this.permissions.includes(Permission.SWEDEN_TAX_QA)
  }

  get isSwitzerlandTaxQaUser(): boolean {
    return this.permissions.includes(Permission.SWITZERLAND_TAX_QA)
  }

  get IsHarveyV1WorkflowUser(): boolean {
    // true if the user has permission for any workflows
    const WorkflowPermissions = [
      Permission.COMPANY_PROFILE,
      Permission.CONTRACTS,
      Permission.DILIGENCE_TRANSCRIPTS,
      Permission.DOC_COMPARE,
      Permission.DRAFTING,
      Permission.OGC_REVIEW,
      Permission.PWC_DEALS,
      Permission.REDLINES,
      Permission.TRANSCRIPTS,
      Permission.TRANSLATION,
    ]

    return WorkflowPermissions.some((perm) => this.permissions.includes(perm))

    // XXX: Something funky going on w/ module resolution here, TBD
    // return Object.values(WorkflowTypeToDetails()).some((workflow) =>
    //   this.permissions.includes(workflow.permission as Permission)
    // )
  }

  get IsPwcUser(): boolean {
    const emailDomains = [
      'pwc.com',
      'hk.pwc.com',
      'cn.pwc.com',
      'au.pwc.com',
      'pwc.ch',
      'tiangandpartners.com',
      'avocats.pwc.com',
      'pwc.lu',
      'pwclegal.lu',
      'lhlaw.com.br',
      'pwclegal.mu',
      'david-baias.ro',
      'pwc.onmicrosoft.com',
    ]

    if (this.workspace?.slug) {
      return this.workspace.slug.startsWith('pwc')
    } else {
      const thisEmailDomain = this.id.split('@')[1]
      return emailDomains.includes(thisEmailDomain)
    }
  }

  get isUserManagement(): boolean {
    return (
      this.IsInternalUser &&
      (this.permissions.includes(Permission.MANAGE_USERS) ||
        this.IsInternalAdminWriter)
    )
  }

  get isReadEngInternal(): boolean {
    return (
      this.IsInternalUser &&
      this.permissions.includes(Permission.READ_ENG_INTERNAL)
    )
  }

  get isWriteEngInternal(): boolean {
    return (
      this.IsInternalUser &&
      this.permissions.includes(Permission.WRITE_ENG_INTERNAL)
    )
  }

  get isClientMattersReadUser(): boolean {
    return (
      this.permissions.includes(Permission.CLIENT_MATTERS_READ) ||
      this.permissions.includes(Permission.CLIENT_MATTERS_WRITE)
    )
  }

  get isClientMattersWriteUser(): boolean {
    return this.permissions.includes(Permission.CLIENT_MATTERS_WRITE)
  }

  get isClientMattersManagementUser(): boolean {
    return (
      this.isClientMattersWriteUser ||
      (this.IsTerritoryAdmin && this.isClientMattersReadUser) ||
      this.isUserManagement
    )
  }

  get isContractsUser(): boolean {
    return this.permissions.includes(Permission.CONTRACTS)
  }

  get isTranscriptsUser(): boolean {
    return this.permissions.includes(Permission.TRANSCRIPTS)
  }

  get isDiligenceUser(): boolean {
    return this.permissions.includes(Permission.PWC_DEALS)
  }

  get isCompetitiveAnalysisUser(): boolean {
    return this.permissions.includes(Permission.USE_COMPETITIVE_ANALYSIS)
  }

  get isDiligenceTranscriptsUser(): boolean {
    return this.permissions.includes(Permission.DILIGENCE_TRANSCRIPTS)
  }

  get isDealsDiscoveryAsyncUser(): boolean {
    return this.permissions.includes(Permission.DEALS_DISCOVERY_ASYNC)
  }

  get isDealsDiscoveryFromVaultUser(): boolean {
    return this.permissions.includes(Permission.DEALS_DISCOVERY_FROM_VAULT)
  }

  get isSkipRateLimitUser(): boolean {
    return this.permissions.includes(Permission.USESKIP_RATE_LIMIT)
  }

  get canSeeSaveExample(): boolean {
    return !!this.IsLibraryExamplesUser && !!this.IsLibraryAdmin
  }

  // gated on having history perm
  get IsWorkspaceHistoryReader(): boolean {
    return (
      this.permissions.includes(Permission.READ_WORKSPACE_HISTORY) &&
      this.permissions.includes(Permission.HISTORY)
    )
  }

  get IsClientAdminAddUsers(): boolean {
    return this.permissions.includes(Permission.CLIENT_ADMIN_ADD_USERS)
  }

  get IsClientAdminRemoveUsers(): boolean {
    return this.permissions.includes(Permission.CLIENT_ADMIN_REMOVE_USERS)
  }

  get IsClientAdminViewUsers(): boolean {
    return this.permissions.includes(Permission.CLIENT_ADMIN_VIEW_USERS)
  }

  get isSettingsTabVisible(): boolean {
    return (
      this.IsInternalAdminReader ||
      this.IsWorkspaceInfoViewer ||
      this.isClientMattersReadUser ||
      this.IsWorkspaceHistoryReader ||
      this.IsClientAdminViewUsers ||
      this.IsUsageDashboardViewer ||
      this.IsUsageDashboardV2Viewer ||
      this.IsSharingManagementUser
    )
  }

  get IsWorkspaceInfoViewer(): boolean {
    return this.permissions.includes(Permission.VIEW_WORKSPACE_INFO)
  }

  get IsUsageDashboardViewer(): boolean {
    return this.permissions.includes(Permission.VIEW_USAGE_DASHBOARD)
  }

  get IsUsageDashboardV2Viewer(): boolean {
    return this.permissions.includes(Permission.VIEW_USAGE_DASHBOARD_V2)
  }

  get IsDeleteWorkspaceHistoryUser(): boolean {
    return (
      this.permissions.includes(Permission.DELETE_WORKSPACE_HISTORY) &&
      this.IsHistoryUser
    )
  }

  get IsAssistantTaxKnowledgeSourceUser(): boolean {
    return (
      this.permissions.includes(Permission.ASSISTANT_TAX_KNOWLEDGE_SOURCE) ||
      this.permissions.includes(Permission.ASSISTANT_RESEARCH_KNOWLEDGE_SOURCE)
    )
  }

  get isDiscoverTabUser(): boolean {
    return this.permissions.includes(Permission.USE_DISCOVER_TAB)
  }

  get isDeleteUserHistoryUser(): boolean {
    return (
      this.permissions.includes(Permission.DELETE_USER_HISTORY) &&
      this.IsHistoryUser
    )
  }

  get IsDeleteWorkspaceHistoryBulkUser(): boolean {
    return this.permissions.includes(Permission.DELETE_WORKSPACE_HISTORY_BULK)
  }

  get IsViewHelpCenterUser(): boolean {
    return this.permissions.includes(Permission.VIEW_HELP_CENTER)
  }

  get IsAssistantResearchKSUser(): boolean {
    return (
      this.IsHarveyV1ResearchUser &&
      this.permissions.includes(Permission.ASSISTANT_RESEARCH_KNOWLEDGE_SOURCE)
    )
  }

  get IsSwedenCaseLawUser(): boolean {
    return this.permissions.includes(Permission.USE_SWEDEN_CASE_LAW)
  }

  get IsMacfarlanesUser(): boolean {
    return this.permissions.includes(Permission.USE_MACFARLANES_KS)
  }

  get IsSingaporeCaseLawUser(): boolean {
    return this.permissions.includes(Permission.USE_SINGAPORE_CASE_LAW)
  }

  get permissionsByWorkspace(): Record<number, Permission[]> {
    if (this.permsByWorkspace) {
      return this.permsByWorkspace
    }
    const permsByWorkspace: Record<number, Permission[]> = {}
    this.permsWithSource.forEach((perm) => {
      if (!permsByWorkspace[perm.workspaceId]) {
        permsByWorkspace[perm.workspaceId] = []
      }
      permsByWorkspace[perm.workspaceId].push(perm.permId)
    })
    this.permsByWorkspace = permsByWorkspace
    return permsByWorkspace
  }

  get vaultFeature(): WorkspaceFeature | undefined {
    const vaultAddOnRoleId = `${this.workspace.slug}-${DefaultRole.VAULT_ADD_ON}`
    if (
      this.permsWithSource.some(
        (perm) =>
          perm.permId === Permission.VAULT_REVIEW_CREATE &&
          perm.permSource === vaultAddOnRoleId
      )
    ) {
      return WorkspaceFeature.VAULT_ADD_ON
    }
    return undefined
  }

  get isDisallowClientMattersUser(): boolean {
    return (
      this.permissions.includes(Permission.DISALLOW_CLIENT_MATTERS) &&
      this.isClientMattersReadUser
    )
  }

  get isIntegrationAdmin(): boolean {
    return this.permissions.includes(Permission.INTEGRATION_ADMIN)
  }

  get isAnyIntegrationUser(): boolean {
    return this.permissions.some((perm) => isIntegrationPerm(perm))
  }

  get isCreateUserProfilesUser(): boolean {
    return this.permissions.includes(Permission.CREATE_USER_PROFILES)
  }

  get isWordAddInUser(): boolean {
    return this.permissions.includes(Permission.USE_HARVEY_FOR_WORD)
  }

  get canViewWordAddInAnnouncement(): boolean {
    return this.permissions.includes(Permission.VIEW_WORD_ADD_IN_ANNOUNCEMENT)
  }

  get hasPermissionBundles(): boolean {
    return this.permissions.includes(Permission.USE_PERMISSION_BUNDLES)
  }

  SetWorkspace(workspace: Workspace, userInfo: Maybe<UserInfo>) {
    if (!userInfo?.isSensitiveDataReader) {
      // read sensitive users get their original perms for any workspace selected
      this.permissions = _.uniq(this.permissionsByWorkspace[workspace.id] ?? []) // if no perms for workspace, set to empty array
    }
    this.workspace = workspace
  }

  GetHelpPanel(taskType: TaskType): WelcomeScreen | undefined {
    return this.WelcomeScreensToShow.find((data: any) => {
      return (
        data.type === WelcomeScreenType.HELP_PANEL &&
        // TODO data.taskType not a boolean and should not be negated
        // eslint-disable-next-line
        (!data?.taskType || data?.taskType === taskType)
      )
    })
  }

  GetInterstitial(): WelcomeScreen | undefined {
    return this.WelcomeScreensToShow.find((data: any) => {
      return data.type === WelcomeScreenType.INTERSTITIAL
    })
  }

  GetDisallowedClientMatterInterstitial(): WelcomeScreen | undefined {
    return this.WelcomeScreensToShow.find((data: any) => {
      return (
        data.type === WelcomeScreenType.DISALLOWED_CLIENT_MATTER_INTERSTITIAL
      )
    })
  }

  get RequiresOnboardingNewUser(): boolean {
    return (
      (this.requiresOnboardingNewUser ?? false) && this.isCreateUserProfilesUser
    )
  }

  get RequiresOnboardingExistingUser(): boolean {
    return (
      (this.requiresOnboardingExistingUser ?? false) &&
      this.isCreateUserProfilesUser
    )
  }
}

const providerId = function (user: User | undefined): string | undefined {
  const subParts = user?.sub?.split('|')
  return subParts?.[subParts.length - 1]?.trim()?.toLowerCase()
}

export const userId = function (user: User | undefined): string | undefined {
  let userId = user?.email?.trim()?.toLowerCase()
  if (_.isNil(userId)) {
    userId = providerId(user)
  }

  return userId
}

export const slugFromEmailDomain = function (
  email: string | undefined
): string {
  // email can be undefined, null, or empty.
  if (!email) {
    return ''
  }

  const domain = email.split('@')[1]
  const slug = domain.split('.')[0]
  return slug
}

// TODO: deprecate this
export function CreateUserInfoLite(
  user: User | undefined,
  exception: Error | undefined
): UserInfo {
  const id = userId(user) ?? ''

  return new UserInfo({
    dbId: '',
    id,
    pseudonymizedId: id,
    permissions: [],
    permsWithSource: [],
    workspace: new Workspace({
      id: -1,
      slug: slugFromEmailDomain(id),
      domains: [],
      kind: WorkspaceKind.ORGANIZATION,
      friendlyName: '',
      clientName: '',
      eyesOffTier: WorkspaceEyesOffTier.EYES_OFFBASIC,
    }),
    settings: {},
    fetchErr: exception,
    requiresOnboardingNewUser: false,
    requiresOnboardingExistingUser: false,
    userProfile: null,
  })
}

export async function FetchUserInfo(user?: User): Promise<UserInfo> {
  return Services.Backend.Get<RawUserInfo>('user_info', { throwOnError: true })
    .then((response) => new UserInfo(response))
    .then((userInfo) => {
      // Fetch the user profile to update the state
      useUserProfileStore.getState().setUserProfileFromUserInfo(userInfo)
      return userInfo
    })
    .catch((e) => {
      Services.HoneyComb.Record({
        metric: 'frontend.auth.user_info_fetch_failed',
        error: 'Failed to fetch user info from backend',
        user_id: user?.email,
        user_raw_id: user?.sub,
        user_nickname: user?.nickname,
      })
      // Print out some debugging info so that it's a little more clear
      // why things failed.
      if (!user?.email) {
        console.warn('Auth email is empty. Please contact support.')
      }
      return CreateUserInfoLite(user, e)
    })
}
