import { useState, useEffect, useCallback } from 'react'

import { fetchAllWorkspaceUsersV2, RawUserEnriched } from 'models/users'

const useWorkspaceUsersEnriched = ({
  workspaceId,
}: {
  workspaceId: number
}) => {
  const [workspaceUsersEnriched, setWorkspaceUsersEnriched] = useState<
    RawUserEnriched[]
  >([])

  const fetchWorkspaceUsersEnriched = useCallback(async () => {
    if (!workspaceId) return
    const allUsers = await fetchAllWorkspaceUsersV2(workspaceId)
    setWorkspaceUsersEnriched(allUsers)
  }, [workspaceId])

  useEffect(() => {
    void fetchWorkspaceUsersEnriched()
  }, [fetchWorkspaceUsersEnriched])
  return { workspaceUsersEnriched, fetchWorkspaceUsersEnriched }
}

export default useWorkspaceUsersEnriched
