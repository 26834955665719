import { formatDuration } from 'date-fns'

import { EM_DASH } from 'utils/utils'
import { parseIsoDurationString } from 'utils/utils'

import { ReviewCellResponse } from 'components/vault/utils/vault'

import { StrictCellRenderer } from './cell-renderer-types'

export class DurationCellRenderer extends StrictCellRenderer {
  renderCell(response: ReviewCellResponse) {
    if (!response.value) {
      return EM_DASH
    }

    const duration = parseIsoDurationString(response.value)
    if (Object.keys(duration).length === 0) {
      return response.value
    }

    let durationFormat
    try {
      // @ts-expect-error -- Intl.DurationFormat is not supported in all browsers
      durationFormat = new Intl.DurationFormat(navigator.language, {
        style: 'long',
      }).format(duration)
    } catch (error) {
      console.warn(error)
      durationFormat = formatDuration(duration)
    }

    return durationFormat
  }
}
