import React, { useState } from 'react'

import _ from 'lodash'
import { AlertCircleIcon } from 'lucide-react'

import { IntegrationType } from 'openapi/models/IntegrationType'

import { Alert, AlertTitle, AlertDescription } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'

interface IntegrationResourceUrlDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm: (resourceUrl: string) => Promise<void>
  resourceUrl: string
  integrationType: IntegrationType
}

const IntegrationResourceUrlDialog: React.FC<
  IntegrationResourceUrlDialogProps
> = ({ open, onOpenChange, onConfirm, resourceUrl, integrationType }) => {
  const [url, setUrl] = useState(resourceUrl)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      await onConfirm(url)
      onOpenChange(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  const isUrlChanged =
    url.trim().toLowerCase() !== resourceUrl.trim().toLowerCase()

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update Resource URL</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Updating the resource URL will update which{' '}
          {_.startCase(integrationType)} instance your workspace will connect
          to. This will require all users to re-authenticate with{' '}
          {_.startCase(integrationType)}.
        </DialogDescription>
        <Input
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter resource URL"
        />

        <Alert variant="destructive">
          <Icon icon={AlertCircleIcon} className="size-4" />
          <AlertTitle>Warning</AlertTitle>
          <AlertDescription>
            This update will remove access for all users who have connected{' '}
            {_.startCase(integrationType)} to Harvey previously and require them
            to re-authenticate.
          </AlertDescription>
        </Alert>

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting || !isUrlChanged}
          >
            {isSubmitting ? 'Updating…' : 'Update'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default IntegrationResourceUrlDialog
