import { useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { useShallow } from 'zustand/react/shallow'

import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { FileType } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantFileUpload } from 'components/assistant/hooks/use-assistant-file-upload'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  ACCEPTED_FILE_TYPES,
  NUM_MAX_FILES,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_ZIP_FILE_SIZE,
} from 'components/assistant/utils/constants'

export const useAssistantMidThreadFileUpload = (
  assistantMode: AssistantMode
) => {
  const [documents, documentsUploading] = useAssistantStore(
    useShallow((s) => [s.documents, s.documentsUploading])
  )
  const [hasUploadedMore, setHasUploadedMore] = useState(false)
  const [zipFiles, setZipFiles] = useState<File[]>([])
  const [existingDocuments, setExistingDocuments] = useState<UploadedFile[]>([])
  const [followupDocuments, setFollowupDocuments] = useState<UploadedFile[]>([])
  const trackEvent = useAssistantAnalytics()

  const { handleFileUpload, handleRemoveFile } =
    useAssistantFileUpload(assistantMode)

  // we need to keep track of the new documents uploaded mid-thread
  useEffect(() => {
    if (hasUploadedMore) {
      const newFollowupDocuments = documents.filter(
        (doc) =>
          !existingDocuments.some((existingDoc) => existingDoc.id === doc.id)
      )
      setFollowupDocuments(newFollowupDocuments)
    }
  }, [documents, existingDocuments, hasUploadedMore])

  const handleFileUploadWithZip = async (
    files: File[],
    fileUploadSource: FileUploadSource
  ) => {
    setZipFiles([])
    await handleFileUpload(files, fileUploadSource)
  }

  const onAssistantDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    fileUploadSource: FileUploadSource
  ) => {
    setZipFiles(acceptedFiles.filter((file) => file.type === FileType.ZIP))

    return onDrop({
      acceptedFiles,
      fileRejections,
      currentFileCount: documents.length + documentsUploading.length,
      maxFiles: NUM_MAX_FILES,
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      maxFileSize: mbToBytes(MAX_FILE_SIZE),
      maxZipFileSize: mbToBytes(MAX_ZIP_FILE_SIZE),
      maxTotalFileSizeProps: {
        maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE),
        currentTotalFileSize: documents.reduce(
          (total, file) => total + (file.size ?? 0),
          0
        ),
      },
      handleAcceptedFiles: (files) =>
        handleFileUploadWithZip(files, fileUploadSource),
    }).finally(() => {
      setZipFiles([])
    })
  }

  const { open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) =>
      onAssistantDrop(acceptedFiles, fileRejections, FileUploadSource.COMPUTER),
    maxFiles: NUM_MAX_FILES,
    noClick: true,
  })

  const onUploadFromComputer = useCallback(() => {
    trackEvent('Upload Files Clicked')
    if (!hasUploadedMore) {
      setExistingDocuments(documents)
      setHasUploadedMore(true)
    }
    open()
  }, [documents, open, trackEvent, hasUploadedMore])

  const hasUploadedFiles =
    followupDocuments.length > 0 ||
    documentsUploading.length > 0 ||
    zipFiles.length > 0

  const filesAttachedCount =
    followupDocuments.length + documentsUploading.length

  const onUploadFromSharepoint = async (files: File[]) => {
    trackEvent('Upload from sharepoint Clicked')
    if (!hasUploadedMore) {
      setExistingDocuments(documents)
      setHasUploadedMore(true)
    }
    await onAssistantDrop(files, [], FileUploadSource.SHAREPOINT)
  }

  return {
    zipFiles,
    followupDocuments,
    hasUploadedFiles,
    filesAttachedCount,
    handleFileUpload: handleFileUploadWithZip,
    handleRemoveFile,
    onUploadFromComputer,
    onUploadFromSharepoint,
  }
}
