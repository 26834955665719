import { User } from '@auth0/auth0-react'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { FetchUserInfo, UserInfo } from 'models/user-info'
import {
  CreateUserProfileRequest,
  CreateUserProfileRequestToJSON,
} from 'openapi/models/CreateUserProfileRequest'
import { CreateUserProfileResponse } from 'openapi/models/CreateUserProfileResponse'
import { LanguageCode } from 'openapi/models/LanguageCode'
import { PracticeArea } from 'openapi/models/PracticeArea'
import { Profession } from 'openapi/models/Profession'
import { Title } from 'openapi/models/Title'
import {
  UpdateUserProfileRequest,
  UpdateUserProfileRequestToJSON,
} from 'openapi/models/UpdateUserProfileRequest'
import { UpdateUserProfileResponse } from 'openapi/models/UpdateUserProfileResponse'
import { UserInfoUserProfile } from 'openapi/models/UserInfoUserProfile'
import Services from 'services'
import { Maybe } from 'types'

interface State {
  userProfile: UserInfoUserProfile | null
  requiresOnboardingNewUser: boolean
  requiresOnboardingExistingUser: boolean
  shouldSkipOnboarding: boolean | null
}

export type ProfileData = {
  primaryProfession: Profession
  practiceAreas: Maybe<PracticeArea[]>
  title: Maybe<Title>
  yoe: Maybe<number>
  preferredLanguage: LanguageCode
}
interface Action {
  createUserProfile: (user: User, profile_data: ProfileData) => Promise<void>
  editUserProfile: (user: User, profile_data: ProfileData) => Promise<void>
  fetchUserProfile: (user?: User) => Promise<UserInfoUserProfile | null>
  skipOnboarding: () => void
  setUserProfileFromUserInfo: (userInfo: UserInfo) => void
}

const initialState: State = {
  userProfile: null,
  requiresOnboardingNewUser: false,
  requiresOnboardingExistingUser: false,
  shouldSkipOnboarding: false,
}

export const useUserProfileStore = create(
  devtools(
    immer<State & Action>((set, get) => ({
      ...initialState,
      createUserProfile: async (user: User, profile_data: ProfileData) => {
        const request: CreateUserProfileRequest = {
          primaryProfession: profile_data.primaryProfession,
          practiceAreas: profile_data.practiceAreas,
          title: profile_data.title,
          yoe: profile_data.yoe,
          preferredLanguage: profile_data.preferredLanguage,
        }
        try {
          await Services.Backend.Post<CreateUserProfileResponse>(
            'user_profiles',
            CreateUserProfileRequestToJSON(request),
            {
              throwOnError: true,
            }
          )
          // TODO(@jhoong): We should be able to update the state without fetching the user info again
          await get().fetchUserProfile(user)
        } catch (error) {
          // If this errors, we don't want to block the user from continuing
          // If the user loads Harvey again, we'll show the onboarding flow again
          console.error('Error creating user profile', error)
          get().skipOnboarding()
        }
      },
      editUserProfile: async (user: User, profile_data: ProfileData) => {
        const request: UpdateUserProfileRequest = {
          primaryProfession: profile_data.primaryProfession,
          practiceAreas: profile_data.practiceAreas,
          title: profile_data.title,
          yoe: profile_data.yoe,
          preferredLanguage: profile_data.preferredLanguage,
        }
        try {
          await Services.Backend.Put<UpdateUserProfileResponse>(
            'user_profiles',
            UpdateUserProfileRequestToJSON(request)
          )
          await get().fetchUserProfile(user)
        } catch (error) {
          console.error('Error updating user profile', error)
          throw error
        }
      },
      skipOnboarding: () => {
        // In case something goes wrong with profile creation, we don't want to block the user from continuing
        set(() => ({
          shouldSkipOnboarding: true,
        }))
      },
      fetchUserProfile: async (user?: User) => {
        const userInfo = await FetchUserInfo(user)
        get().setUserProfileFromUserInfo(userInfo)
        return get().userProfile
      },
      setUserProfileFromUserInfo: (userInfo: UserInfo) => {
        set(() => ({
          userProfile: userInfo.userProfile,
          requiresOnboardingNewUser: userInfo.requiresOnboardingNewUser,
          requiresOnboardingExistingUser:
            userInfo.requiresOnboardingExistingUser,
        }))
      },
    }))
  )
)
