import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Check } from 'lucide-react'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'

import { PracticeArea } from 'openapi/models/PracticeArea'
import { Title } from 'openapi/models/Title'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { PrimaryLanguageStepType } from 'components/common/flows/profile-setup/primary-language-step'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import {
  markV1ProductTourAsCompleted,
  runHarveyV1ProductTour,
} from 'components/common/product-tours/run-product-tour'
import ProfileMadlibs from 'components/common/profile/profile-madlibs'
import {
  ProfileData,
  useUserProfileStore,
} from 'components/common/user-profile-store'
import { Button } from 'components/ui/button'
import { DialogFooter } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

import { ProfileSetupFlowType } from './profile-setup'

export type ProfileCompleteStepType = Step<
  PrimaryLanguageStepType['stepOutput']
>

const ProfileCompleteStep: React.FC = () => {
  const { exit, goBack } = useFlowControls<ProfileSetupFlowType>()
  const { user } = useAuth0()
  const userInfo = useAuthUser()
  const stepInput = useFlowStepInput<ProfileCompleteStepType>()
  const createUserProfile = useUserProfileStore((s) => s.createUserProfile)
  const [isCreatingProfile, setIsCreatingProfile] = React.useState(true)
  const { trackEvent } = useAnalytics()
  const navigate = useNavigate()

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent(
      'profile_setup_profile_complete_step_viewed',
      preparedEventProperties
    )
  }, [stepInput, trackEvent])

  React.useEffect(() => {
    if (!user) return
    const profileData: ProfileData = {
      primaryProfession: stepInput.primaryProfession,
      practiceAreas: hasPracticeAreas(stepInput)
        ? stepInput.practiceAreas
        : undefined,
      title: hasTitle(stepInput) ? stepInput.title : undefined,
      yoe: hasYoe(stepInput) ? stepInput.yoe : undefined,
      preferredLanguage: stepInput.preferredLanguage,
    }
    createUserProfile(user, profileData)
      .then(() => {
        // Add .5 second delay before completing, otherwise it looks too glitchy
        setTimeout(() => {
          setIsCreatingProfile(false)
        }, 500)
      })
      .catch((error) => {
        console.error('Error creating user profile', error)
      })
  }, [stepInput, createUserProfile, user])

  const handleContinue = () => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_completed', preparedEventProperties)

    if (user && !userInfo.HasCompletedV1ProductTour) {
      runHarveyV1ProductTour(userInfo, navigate)
      markV1ProductTourAsCompleted()
    }

    exit()
  }

  return (
    <>
      <div className="mx-auto flex size-full max-w-[400px] flex-col items-center justify-between pb-3 pt-[90px]">
        <div className="flex flex-col items-center gap-4">
          {isCreatingProfile ? (
            <div className="flex size-16 items-center justify-center">
              <Spinner className="size-9" />
            </div>
          ) : (
            <div className="flex size-16 items-center justify-center rounded-full border">
              <Check className="size-9" />
            </div>
          )}
          <h1 className="text-xl font-medium">
            {isCreatingProfile
              ? 'Creating your profile…'
              : 'Your profile is ready'}
          </h1>
          {!isCreatingProfile && (
            <ProfileMadlibs
              primaryProfession={stepInput.primaryProfession}
              title={hasTitle(stepInput) ? stepInput.title : undefined}
              practiceAreas={
                hasPracticeAreas(stepInput)
                  ? stepInput.practiceAreas
                  : undefined
              }
              yoe={hasYoe(stepInput) ? stepInput.yoe : undefined}
              preferredLanguage={stepInput.preferredLanguage}
              textAlign="center"
            />
          )}
          {!isCreatingProfile && (
            <p className="mt-10 text-center text-xs text-secondary">
              You can change your answers anytime on your Profile page.
            </p>
          )}
        </div>
      </div>
      <DialogFooter className="flex w-full flex-row items-center justify-between border-t p-4">
        <div className="flex flex-1 items-center justify-end gap-3">
          <Button onClick={goBack} variant="outline">
            Back
          </Button>
          <Button onClick={handleContinue} disabled={isCreatingProfile}>
            Continue to Harvey
          </Button>
        </div>
      </DialogFooter>
    </>
  )
}

function hasPracticeAreas(
  input: { practiceAreas?: PracticeArea[] } | any
): input is { practiceAreas: PracticeArea[] } {
  return (
    (input as { practiceAreas?: PracticeArea[] }).practiceAreas !== undefined
  )
}

function hasTitle(
  input: { title?: Title } | { yoe?: number }
): input is { title: Title } {
  return (input as { title?: Title }).title !== undefined
}

function hasYoe(
  input: { yoe?: number } | { title?: Title }
): input is { yoe: number } {
  return (input as { yoe?: number }).yoe !== undefined
}

export default ProfileCompleteStep
