/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TaxPracticeAreaOptions = {
    CONTROVERSY_AND_DISPUTE_RESOLUTION: 'CONTROVERSY_AND_DISPUTE_RESOLUTION',
    CORPORATE: 'CORPORATE',
    INDIRECT: 'INDIRECT',
    INTERNATIONAL: 'INTERNATIONAL',
    MERGERS_AND_ACQUISITION: 'MERGERS_AND_ACQUISITION',
    REAL_ESTATE: 'REAL_ESTATE',
    TRANSFER_PRICING: 'TRANSFER_PRICING'
} as const;
export type TaxPracticeAreaOptions = typeof TaxPracticeAreaOptions[keyof typeof TaxPracticeAreaOptions];


export function instanceOfTaxPracticeAreaOptions(value: any): boolean {
    for (const key in TaxPracticeAreaOptions) {
        if (Object.prototype.hasOwnProperty.call(TaxPracticeAreaOptions, key)) {
            if (TaxPracticeAreaOptions[key as keyof typeof TaxPracticeAreaOptions] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TaxPracticeAreaOptionsFromJSON(json: any): TaxPracticeAreaOptions {
    return TaxPracticeAreaOptionsFromJSONTyped(json, false);
}

export function TaxPracticeAreaOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxPracticeAreaOptions {
    return json as TaxPracticeAreaOptions;
}

export function TaxPracticeAreaOptionsToJSON(value?: TaxPracticeAreaOptions | null): any {
    return value as any;
}

export function TaxPracticeAreaOptionsToJSONTyped(value: any, ignoreDiscriminator: boolean): TaxPracticeAreaOptions {
    return value as TaxPracticeAreaOptions;
}

