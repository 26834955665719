import React from 'react'
import { useMount } from 'react-use'

import { InfoIcon } from 'lucide-react'

import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'

import { useAuthUser } from 'components/common/auth-context'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { DOT_SEPARATOR } from 'components/vault/utils/vault'
import { FetchVaultReviewQueryUsage } from 'components/vault/utils/vault-fetcher'
import { getVaultProjects } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

const VaultHomeHeader = () => {
  const userInfo = useAuthUser()
  const allFolderIdToVaultFolder = useVaultStore(
    (state) => state.allFolderIdToVaultFolder
  )
  const rootVaultFolderIds = useVaultStore((s) => s.rootVaultFolderIds)
  const reviewQueryCapExplanation = useVaultUsageStore(
    (state) => state.reviewQueryCapExplanation
  )
  const reviewQueryCapExplanationMore = useVaultUsageStore(
    (state) => state.reviewQueryCapExplanationMore
  )
  const reviewQueryUsageDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryUsageDisplayString
  )
  const reviewQueryLimitDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryLimitDisplayString
  )
  const reviewQueryLimit = useVaultUsageStore((state) => state.reviewQueryLimit)
  const reviewQueryLimitUnitDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryLimitUnitDisplayString
  )
  const reviewQueryLimitTimeFrame = useVaultUsageStore(
    (state) => state.reviewQueryLimitTimeFrame
  )
  const reviewQueryLimitLevel = useVaultUsageStore(
    (state) => state.reviewQueryLimitLevel
  )
  const setReviewQueryUsage = useVaultUsageStore(
    (state) => state.setReviewQueryUsage
  )
  const setReviewQueryLimit = useVaultUsageStore(
    (state) => state.setReviewQueryLimit
  )
  const setReviewQueryLimitUnitLevel = useVaultUsageStore(
    (state) => state.setReviewQueryLimitUnitLevel
  )
  const setReviewQueryLimitTimeFrame = useVaultUsageStore(
    (state) => state.setReviewQueryLimitTimeFrame
  )
  const setReviewQueryLimitLevel = useVaultUsageStore(
    (state) => state.setReviewQueryLimitLevel
  )

  const isVaultSharingEnabled = userInfo.IsVaultViewSharesUser

  const numProjects = getVaultProjects({
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    userId: userInfo.dbId,
  }).length

  const projectsCountLimit = userInfo.workspace.getVaultProjectsCountLimit(
    userInfo.vaultFeature
  )
  const projectDisplayText =
    numProjects === 0
      ? 'No active projects'
      : `${numProjects} of ${projectsCountLimit} projects created`
  const reviewQueryDisplayText =
    userInfo.IsVaultReviewUser && reviewQueryLimit !== null
      ? `${reviewQueryUsageDisplayString} of ${reviewQueryLimitDisplayString} total ${reviewQueryLimitUnitDisplayString}${
          reviewQueryLimitTimeFrame === QueryCapRuleTimeFrame.CALENDAR_MONTH
            ? ' this month'
            : ''
        }${
          reviewQueryLimitLevel === QueryCapRuleLevel.PER_WORKSPACE
            ? ' for entire workspace'
            : ''
        }`
      : ''
  const displayText = [projectDisplayText, reviewQueryDisplayText]
    .filter(Boolean)
    .join(DOT_SEPARATOR)

  useMount(async () => {
    if (userInfo.IsVaultReviewUser) {
      // Fetch review query usage whenever we are displaying them to make sure we have the latest data
      const response = await FetchVaultReviewQueryUsage()
      setReviewQueryUsage(response.usage)
      setReviewQueryLimit(response.limit)
      setReviewQueryLimitUnitLevel(response.unitLevel)
      setReviewQueryLimitTimeFrame(response.timeFrame)
      setReviewQueryLimitLevel(response.level)
    }
  })

  return (
    <div>
      <p className="line-clamp-2 text-xl font-medium">Vault</p>
      <p className="truncate text-xs text-muted">
        Store and analyze thousands of documents
      </p>
      <div className="line-clamp-2 text-xs text-muted">
        {!isVaultSharingEnabled && displayText}
        {!isVaultSharingEnabled && reviewQueryDisplayText.length > 0 && (
          <Tooltip delayDuration={200}>
            <TooltipTrigger className="ml-1 translate-y-0.5">
              <Icon icon={InfoIcon} size="small" />
            </TooltipTrigger>
            <TooltipContent className="max-w-sm text-start">
              {[reviewQueryCapExplanation, reviewQueryCapExplanationMore].join(
                ' '
              )}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default VaultHomeHeader
