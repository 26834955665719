import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { RotateCw, Trash } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'

import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import { GenerateNNResponseProps } from 'components/vault/utils/vault'
import {
  clearReviewErrorsV1,
  isError,
  retryReview,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const ErrorsPopover = ({
  generateNNResponse,
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const { projectId, queryId } = useParams()
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const currentProjectFilesById = useMemo(
    () =>
      new Map(
        currentProjectMetadata.descendantFiles?.map((file) => [
          file.id,
          file,
        ]) ?? []
      ),
    [currentProjectMetadata]
  )
  const queryIdToState = useVaultStore((state) => state.queryIdToState)
  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )
  const setReviewTask = useVaultStore((state) => state.setReviewTask)
  const markHistoryTaskAsFromStreaming = useVaultStore(
    (state) => state.markHistoryTaskAsFromStreaming
  )

  const isLoading = queryIdToState[queryId!]?.isLoading ?? false
  const projectFileIds = new Set<string>(currentProjectFilesById.keys())
  const processedFileIds =
    queryIdToReviewState[queryId!]?.processedFileIds ?? []
  const suppressedFileIds =
    queryIdToReviewState[queryId!]?.suppressedFileIds ?? []
  const filesWithErrors = (
    Object.keys(queryIdToReviewState[queryId!]?.errors ?? {})
      .filter((id) =>
        isError({ projectFileIds, processedFileIds, suppressedFileIds, id })
      )
      .map((id) => currentProjectFilesById.get(id))
      .filter(Boolean) as VaultFile[]
  ).sort((a, b) => a.name.localeCompare(b.name))

  const [isOpen, setIsOpen] = useState(false)

  if (filesWithErrors.length === 0) {
    return null
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm">
          <AlertIcon size="small" className="mr-1" />
          <p className="text-xs">
            {filesWithErrors.length} error
            {filesWithErrors.length > 1 ? 's' : ''}
          </p>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-96 p-0">
        <ScrollArea maxHeight="max-h-96">
          {filesWithErrors.map((file) => (
            <div
              key={file.id}
              className="flex items-center justify-between gap-2 border-b px-4 py-2 last:border-b-0"
            >
              <p key={file.id} className="truncate text-start text-xs">
                {file.name}
              </p>
              <div className="flex shrink-0 items-center gap-2">
                <Button
                  variant="ghost"
                  size="xsIcon"
                  disabled={isLoading}
                  tooltip={
                    isLoading
                      ? 'You can clear the error after the query is finished processing'
                      : undefined
                  }
                  onClick={async () => {
                    await clearReviewErrorsV1({
                      setReviewTask,
                      queryId: queryId!,
                      fileIds: [file.id],
                      queryIdToReviewState,
                    })
                  }}
                >
                  <Icon icon={Trash} size="small" />
                </Button>
                <Button
                  variant="ghost"
                  size="xsIcon"
                  disabled={isLoading}
                  tooltip={
                    isLoading
                      ? 'You can retry after the query is finished processing'
                      : undefined
                  }
                  onClick={async () => {
                    setIsOpen(false)
                    await retryReview({
                      generateNNResponse,
                      setReviewTask,
                      markHistoryTaskAsFromStreaming,
                      projectId: projectId!,
                      queryId: queryId!,
                      fileIds: [file.id],
                      queryIdToState,
                      queryIdToReviewState,
                    })
                  }}
                >
                  <Icon icon={RotateCw} size="small" />
                </Button>
              </div>
            </div>
          ))}
        </ScrollArea>
        <div className="flex justify-end gap-2 border-t px-4 py-2">
          <Button
            variant="ghost"
            size="sm"
            className="text-xs"
            disabled={isLoading}
            tooltip={
              isLoading
                ? 'You can clear errors after the query is finished processing'
                : undefined
            }
            onClick={async () => {
              setIsOpen(false)
              await clearReviewErrorsV1({
                setReviewTask,
                queryId: queryId!,
                fileIds: filesWithErrors.map((f) => f.id),
                queryIdToReviewState,
              })
            }}
          >
            <Icon icon={Trash} size="small" className="mr-1" />
            Clear errors
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="text-xs"
            disabled={isLoading}
            tooltip={
              isLoading
                ? 'You can retry after the query is finished processing'
                : undefined
            }
            onClick={async () => {
              setIsOpen(false)
              await retryReview({
                generateNNResponse,
                setReviewTask,
                markHistoryTaskAsFromStreaming,
                projectId: projectId!,
                queryId: queryId!,
                fileIds: filesWithErrors.map((f) => f.id),
                queryIdToState,
                queryIdToReviewState,
              })
            }}
          >
            <Icon icon={RotateCw} size="small" className="mr-1" />
            Retry all
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default ErrorsPopover
