import * as React from 'react'
import { Control, Controller } from 'react-hook-form'

import { Input } from 'components/ui/input'

interface ProfileExperienceFormProps {
  control: Control<any>
}

export const FORM_HEADING =
  'How many years of experience do you have in your field?'
export const FORM_DESCRIPTION = 'This won’t change the content you see.'

export const MAX_YOE = 99
export const MIN_YOE = 0

const ProfileExperienceForm: React.FC<ProfileExperienceFormProps> = ({
  control,
}) => {
  return (
    <div>
      <div className="mb-2 text-xs text-secondary">Enter numbers only.</div>
      <Controller
        name="yoe"
        control={control}
        rules={{ required: true, min: MIN_YOE, max: MAX_YOE }}
        render={({ field }) => (
          <Input
            className="max-w-32"
            type="number"
            inputMode="numeric"
            value={field.value || ''}
            pattern="[0-9]*"
            max={MAX_YOE}
            min={MIN_YOE}
            onChange={(e) => {
              e.target.validity.valid && field.onChange(e.target.value)
            }}
          />
        )}
      />
    </div>
  )
}

export default ProfileExperienceForm
