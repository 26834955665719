// diligence-store.ts
import _ from 'lodash'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { uploadFile } from 'api'
import {
  DiligenceDocument,
  DiligenceDocumentFromJSON,
  instanceOfDiligenceDocument,
} from 'openapi/models/DiligenceDocument'
import { DiligenceDocumentLabel } from 'openapi/models/DiligenceDocumentLabel'
import { DiligenceFollowUpQuestionRequestToJSON } from 'openapi/models/DiligenceFollowUpQuestionRequest'
import {
  DiligenceFollowUpQuestionResponse,
  DiligenceFollowUpQuestionResponseFromJSON,
  instanceOfDiligenceFollowUpQuestionResponse,
} from 'openapi/models/DiligenceFollowUpQuestionResponse'
import { DiligenceProcessSectionsRequestToJSON } from 'openapi/models/DiligenceProcessSectionsRequest'
import {
  DiligenceSection,
  DiligenceSectionFromJSON,
  instanceOfDiligenceSection,
} from 'openapi/models/DiligenceSection'
import { DiligenceTaskType } from 'openapi/models/DiligenceTaskType'
import { SocketMessageResponse } from 'openapi/models/SocketMessageResponse'

import { displayFileUploadError } from 'utils/dropzone'
import { createFileName } from 'utils/file-utils'
import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'
import { InitSocketAndSendQuery } from 'utils/use-harvey-socket'

import { VaultKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { fetchDiscoveryReportAsync } from 'components/workflows/workflow/discovery/common/fetch-report-async'

import { sanitizeForLoggingDiligence } from './sanitize-for-logging'
import { DiligenceTaxonomy } from './types'

export interface FollowUpQAPair {
  clientSideId: string
  question: string
  answer: Partial<SocketMessageResponse>
  isStreaming: boolean
}

export interface DiligenceState {
  availableSections: DiligenceSection[]
  availableTaxonomies: DiligenceTaxonomy[]
  currentFollowUpInput: string
  documents: DiligenceDocument[]
  followUpQAPairs: FollowUpQAPair[]
  isRunningProcessSections: boolean
  isStreamingFollowUp: boolean
  isUploadingDocuments: boolean
  knowledgeSource: VaultKnowledgeSource | null
  queryId: string
  sections: DiligenceSection[] // Contains only the sections and tasks of the generated or loaded report
  selectedTaxonomies: DiligenceTaxonomy[]
  viewingSection: DiligenceSection | null
}

export interface DiligenceActions {
  setter: HarveySocketSetter
  reset: () => void
  removeDocument: (fileName: string) => void
  setDocuments: (documents: DiligenceDocument[]) => void
  setKnowledgeSource: (knowledgeSource: VaultKnowledgeSource | null) => void
  setAvailableSections: (sections: DiligenceSection[]) => void
  setSections: (sections: DiligenceSection[]) => void
  setDocumentTypes: (fileName: string, types: DiligenceDocumentLabel[]) => void
  setIsUploadingDocuments: (isUploading: boolean) => void
  handleNewFiles: (
    files: File[],
    fileToPasswordMap?: Record<string, string>
  ) => Promise<void>
  runProcessSections: (
    sections: DiligenceSection[],
    initSocketAndSendQuery: InitSocketAndSendQuery
  ) => void
  runSectionsCompletedCallback: () => void
  runAsyncProcessSections: (sections: DiligenceSection[]) => Promise<void>
  updateSection: (sectionToUpdate: DiligenceSection) => void
  setViewingSection: (section: DiligenceSection) => void
  setSelectedTaxonomies: (taxonomies: DiligenceTaxonomy[]) => void
  setAvailableTaxonomies: (taxonomies: DiligenceTaxonomy[]) => void
  setCurrentFollowUpInput: (query: string) => void
  addNewFollowUpQuestion: (question: string) => void
  updateCurrentFollowUpAnswer: (
    answerContent: string,
    socketMessage: Partial<SocketMessageResponse>
  ) => void
  runFollowUpCompletedCallback: () => void
  runFollowUpQuestion: (
    query: string,
    reportSelectedText: string,
    initSocketAndSendQuery: InitSocketAndSendQuery
  ) => void
  startFollowUpStreaming: () => void
  setQueryId: (queryId: string) => void
  setIsRunningProcessSections: (isRunning: boolean) => void
}

const initialState: DiligenceState = {
  availableSections: [],
  availableTaxonomies: [],
  currentFollowUpInput: '',
  documents: [],
  followUpQAPairs: [],
  isRunningProcessSections: false,
  isStreamingFollowUp: false,
  isUploadingDocuments: false,
  knowledgeSource: null,
  queryId: '',
  sections: [],
  selectedTaxonomies: [],
  viewingSection: null,
}

export const useDiligenceStore = create(
  devtools(
    immer<DiligenceState & DiligenceActions>((set, get) => ({
      ...initialState,
      reset: () => {
        set({ ...initialState })
      },
      setDocuments: (documents: DiligenceDocument[]) => set({ documents }),
      setKnowledgeSource: (knowledgeSource: VaultKnowledgeSource | null) => {
        set((state) => {
          state.knowledgeSource = knowledgeSource
        })
      },
      setAvailableSections: (sections: DiligenceSection[]) =>
        set({ availableSections: sections }),
      setSections: (sections: DiligenceSection[]) => set({ sections }),
      setViewingSection: (section: DiligenceSection) =>
        set({ viewingSection: section }),
      setSelectedTaxonomies: (taxonomies: DiligenceTaxonomy[]) =>
        set({ selectedTaxonomies: taxonomies }),
      setAvailableTaxonomies: (taxonomies: DiligenceTaxonomy[]) =>
        set({ availableTaxonomies: taxonomies }),
      setDocumentTypes: (fileName: string, types: DiligenceDocumentLabel[]) =>
        set((state) => ({
          documents: state.documents.map((doc) =>
            doc.file.name === fileName ? { ...doc, types } : doc
          ),
        })),
      removeDocument: (fileName: string) =>
        set((state) => ({
          documents: state.documents.filter(
            (doc) => doc.file.name !== fileName
          ),
        })),
      setIsUploadingDocuments: (isUploading: boolean) =>
        set(() => ({ isUploadingDocuments: isUploading })),
      handleNewFiles: async (
        files: File[],
        fileToPasswordMap?: Record<string, string>
      ) => {
        // If a password map is provided, ensure all files have a password
        if (!_.isEmpty(fileToPasswordMap)) {
          files.forEach((file) => {
            if (!fileToPasswordMap[file.name]) {
              fileToPasswordMap[file.name] = '' // Set empty string if password not found
            }
          })
        }

        // Get existing names for deduplication
        const existingNamesSet = new Set(
          get().documents?.map((doc) => doc.file.name) ?? []
        )

        // Prepare files with unique names
        const newFiles = files.map((file) => ({
          name: createFileName(file.name, existingNamesSet),
          file,
        }))

        // Add to UI eagerly
        const documentsToAdd = newFiles.map(({ name, file }) => ({
          isLoading: true,
          file: {
            id: '',
            name,
            path: '',
            url: '',
            size: file.size,
            pdfkitId: '',
          },
        }))
        set((state) => {
          state.documents = [...state.documents, ...documentsToAdd]
        })

        // Upload files
        try {
          const uploadResults = await Promise.all(
            newFiles.map(async ({ name, file }) => {
              try {
                const uploadedFile = await uploadFile(
                  file,
                  true,
                  !_.isEmpty(fileToPasswordMap)
                    ? { password: fileToPasswordMap[file.name] }
                    : undefined
                )

                return { name, file, uploadedFile, error: null }
              } catch (error: any) {
                const errorMessage = error.message || 'Failed to upload file'
                return {
                  name,
                  file,
                  uploadedFile: null,
                  errorMessage: errorMessage,
                }
              }
            })
          )

          // Handle rejected files, rejected by backend
          const rejectedFiles = uploadResults.filter(
            ({ uploadedFile }) => uploadedFile === null
          )
          if (rejectedFiles.length > 0) {
            // Group files by error message using an object
            const errorGroups: { [key: string]: string[] } = {}

            rejectedFiles.forEach(({ name, errorMessage }) => {
              const errorMsg = errorMessage || 'Failed to upload file'
              if (!errorGroups[errorMsg]) {
                errorGroups[errorMsg] = []
              }
              errorGroups[errorMsg].push(name)
            })

            // Display each error group
            Object.entries(errorGroups).forEach(([errorMessage, filenames]) => {
              displayFileUploadError(
                filenames,
                errorMessage,
                uploadResults.length > rejectedFiles.length
              )
            })

            // Remove rejected files from UI
            set((state) => {
              state.documents = state.documents.filter(
                (doc) => !rejectedFiles.some((rf) => rf.name === doc.file.name)
              )
            })
          }

          // Update successful files in UI
          set((state) => {
            state.documents = state.documents.map((doc) => {
              const uploaded = uploadResults.find(
                ({ name }) => name === doc.file.name
              )
              if (!uploaded?.uploadedFile) return doc

              return {
                ...doc,
                isLoading: false,
                file: {
                  ...doc.file,
                  ...uploaded.uploadedFile,
                },
              }
            })
            state.isUploadingDocuments = false
          })
        } catch (error) {
          set((state) => {
            state.isUploadingDocuments = false
          })
          throw error
        }
      },
      addNewFollowUpQuestion: (question: string) =>
        set((state) => {
          state.followUpQAPairs.push({
            clientSideId: `qa_${Date.now()}_${state.followUpQAPairs.length}`,
            question,
            answer: {},
            isStreaming: true,
          })
        }),
      updateCurrentFollowUpAnswer: (
        answerContent: string,
        socketMessage: Partial<SocketMessageResponse>
      ) =>
        set((state) => {
          const currentQA =
            state.followUpQAPairs[state.followUpQAPairs.length - 1]
          // Transform the json data in the socket response into just the answer content string
          const newAnswerData = { ...socketMessage, response: answerContent }
          if (currentQA) {
            currentQA.answer = newAnswerData
          }
        }),
      runFollowUpCompletedCallback: () =>
        set((state) => {
          const currentQA =
            state.followUpQAPairs[state.followUpQAPairs.length - 1]
          if (currentQA) {
            currentQA.isStreaming = false
          }
          state.isStreamingFollowUp = false
          state.currentFollowUpInput = ''
        }),
      setCurrentFollowUpInput: (query: string) =>
        set({ currentFollowUpInput: query }),
      runProcessSections: (
        sectionsToProcess: DiligenceSection[],
        initSocketAndSendQuery: InitSocketAndSendQuery
      ) => {
        sectionsToProcess.forEach((section) => {
          section.isLoading = true
        })

        set((state) => {
          state.sections = sectionsToProcess
          state.isRunningProcessSections = true
        })

        initSocketAndSendQuery({
          query: DiligenceTaskType.PROCESS_SECTIONS,
          additionalRequestParams: DiligenceProcessSectionsRequestToJSON({
            sections: sectionsToProcess,
            documents: get().documents,
          }) as unknown as Record<string, any>,
        })
      },
      runAsyncProcessSections: async (
        sectionsToProcess: DiligenceSection[]
      ) => {
        sectionsToProcess.forEach((section) => {
          section.isLoading = true
        })

        set((state) => {
          state.sections = sectionsToProcess
          state.isRunningProcessSections = true
        })

        const knowledgeSource = get().knowledgeSource

        let result
        try {
          result = await fetchDiscoveryReportAsync({
            sections: sectionsToProcess,
            documents: get().documents,
            vaultDocuments: knowledgeSource
              ? {
                  folderId: knowledgeSource.folderId!,
                  fileIds: knowledgeSource.fileIds!,
                }
              : undefined,
          })
        } catch (error) {
          set((state) => {
            state.sections = []
            state.isRunningProcessSections = false
          })

          throw error
        }

        set((state) => {
          state.queryId = result.eventId
        })
      },
      runFollowUpQuestion: (
        query: string,
        reportText: string,
        initSocketAndSendQuery: InitSocketAndSendQuery
      ) => {
        get().addNewFollowUpQuestion(query)
        get().startFollowUpStreaming()
        const knowledgeSource = get().knowledgeSource

        initSocketAndSendQuery({
          query: DiligenceTaskType.FOLLOW_UP_QUESTION,
          additionalRequestParams: DiligenceFollowUpQuestionRequestToJSON({
            query,
            reportSelectedText: reportText,
            documents: get().documents,
            eventId: get().queryId,
            vaultDocuments: knowledgeSource
              ? {
                  folderId: knowledgeSource.folderId!,
                  fileIds: knowledgeSource.fileIds!,
                }
              : undefined,
          }) as unknown as Record<string, any>,
        })
      },
      runSectionsCompletedCallback: () => {
        set((state) => {
          state.sections.forEach((section) => {
            section.isLoading = false
          })
          state.isRunningProcessSections = false
        })
      },
      startFollowUpStreaming: () => set({ isStreamingFollowUp: true }),
      updateSection: (sectionToUpdate: DiligenceSection) =>
        set((state) => {
          const index = state.sections.findIndex(
            (existingSection) => existingSection.title === sectionToUpdate.title
          )
          if (index !== -1) state.sections[index] = sectionToUpdate
          else
            console.error(
              'Section not found',
              sanitizeForLoggingDiligence(sectionToUpdate),
              sanitizeForLoggingDiligence(state.sections)
            )
        }),
      setter: (messageData: Partial<HarveySocketTask>) => {
        const upsertDocument = (docToUpdate: DiligenceDocument) =>
          set((state) => {
            const index = state.documents.findIndex(
              (doc) => doc.file.name === docToUpdate.file.name
            )
            if (index !== -1) state.documents[index] = docToUpdate
            else state.documents.push(docToUpdate)
          })

        const updateSectionIfViewingOrComplete = (
          sectionToUpdate: DiligenceSection
        ) => {
          get().updateSection(sectionToUpdate)
        }

        const updateFollowUpAnswer = (
          answer: DiligenceFollowUpQuestionResponse
        ) => {
          get().updateCurrentFollowUpAnswer(answer.content, messageData)
        }

        const responseHandlers = [
          {
            transformer: DiligenceDocumentFromJSON,
            instanceOf: instanceOfDiligenceDocument,
            handler: upsertDocument,
          },
          {
            transformer: DiligenceSectionFromJSON,
            instanceOf: instanceOfDiligenceSection,
            handler: updateSectionIfViewingOrComplete,
          },
          {
            transformer: DiligenceFollowUpQuestionResponseFromJSON,
            instanceOf: instanceOfDiligenceFollowUpQuestionResponse, // the response is markdown string, instanceOfDiligenceFollowUpAnswer
            handler: updateFollowUpAnswer,
          },
        ]

        // TODO: Make this common, can accept a list of handlers
        const handleResponse = (response: string) => {
          let parsedResponse: any = response
          try {
            parsedResponse = JSON.parse(response)
          } catch (e) {
            parsedResponse = response
          }
          responseHandlers.some(({ transformer, instanceOf, handler }) => {
            try {
              const transformed = transformer(parsedResponse)
              if (instanceOf(transformed!)) {
                handler(transformed as any)
                return true // Breaks the loop
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
            return false
          })
        }

        try {
          if (messageData.queryId) {
            set((state) => ({
              queryId: state.queryId || messageData.queryId,
            }))
          }

          if (!messageData.response) return
          const responses = Array.isArray(messageData.response)
            ? messageData.response
            : [messageData.response]
          responses.forEach(handleResponse)
        } catch (e) {
          console.error('Error handling response', e)
        }
      },
      setQueryId: (queryId: string) => set({ queryId }),
      setIsRunningProcessSections: (isRunning: boolean) =>
        set({ isRunningProcessSections: isRunning }),
    }))
  )
)

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('diligence-store', useDiligenceStore)
}
