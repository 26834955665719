/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying supported languages (IETF language tags).
 * @export
 */
export const LanguageCode = {
    EN_GB: 'en-gb',
    EN_US: 'en-us',
    ZH: 'zh',
    ES_ES: 'es-es',
    ES_419: 'es-419',
    DE: 'de',
    FR: 'fr',
    AR: 'ar',
    PT: 'pt',
    RU: 'ru',
    JA: 'ja',
    HI: 'hi',
    KO: 'ko',
    IT: 'it',
    NL: 'nl',
    TR: 'tr',
    SV: 'sv',
    PL: 'pl',
    HE: 'he',
    MS: 'ms',
    SR: 'sr'
} as const;
export type LanguageCode = typeof LanguageCode[keyof typeof LanguageCode];


export function instanceOfLanguageCode(value: any): boolean {
    for (const key in LanguageCode) {
        if (Object.prototype.hasOwnProperty.call(LanguageCode, key)) {
            if (LanguageCode[key as keyof typeof LanguageCode] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LanguageCodeFromJSON(json: any): LanguageCode {
    return LanguageCodeFromJSONTyped(json, false);
}

export function LanguageCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageCode {
    return json as LanguageCode;
}

export function LanguageCodeToJSON(value?: LanguageCode | null): any {
    return value as any;
}

export function LanguageCodeToJSONTyped(value: any, ignoreDiscriminator: boolean): LanguageCode {
    return value as LanguageCode;
}

