import React, { useState } from 'react'

import _ from 'lodash'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Textarea } from 'components/ui/text-area'

interface WorkspaceUpdateRoleDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: { name: string; description: string }) => void
  initialName?: string
  initialDescription?: string
}

const WorkspaceUpdateRoleDialog = ({
  isOpen,
  onClose,
  onSubmit,
  initialName = '',
  initialDescription = '',
}: WorkspaceUpdateRoleDialogProps) => {
  const [name, setName] = useState(initialName)
  const [description, setDescription] = useState(initialDescription)

  const invalidState =
    _.isEmpty(name) ||
    (name === initialName && description === initialDescription)

  const handleSubmit = () => {
    if (invalidState) return
    onSubmit({ name, description })
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent showCloseIcon>
        <DialogHeader>
          <DialogTitle>Update Role</DialogTitle>
          <DialogDescription>
            Update the name and description of the {initialName} role.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-2">
          <Label>Name</Label>
          <Input
            placeholder={initialName}
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={24}
          />
        </div>

        <div className="space-y-2">
          <Label>Description</Label>
          <Textarea
            placeholder={initialDescription}
            className="resize-none"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              variant="default"
              onClick={handleSubmit}
              disabled={invalidState}
            >
              Submit
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceUpdateRoleDialog
