import * as React from 'react'

import {
  StackValue,
  useModalFlowNavigatorContext,
  useModalFlowStepContext,
} from './modal-flow-navigator'
import { Flow, FlowControls, FlowRouterProps, Step } from './modal-flow-types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFlowStepInput<CurrentStep extends Step<any, any>>() {
  const { stepInput } = useModalFlowStepContext()
  return stepInput as CurrentStep['stepInput']
}

export function useFlowControls<
  CurrentFlow extends Flow,
>(): FlowControls<CurrentFlow> {
  const { stack, setStack } = useModalFlowNavigatorContext()

  const enterFlow = React.useCallback<FlowControls<CurrentFlow>['enterFlow']>(
    (flow, step, data) => {
      setStack(() => [
        {
          flow,
          stepIndex: 0,
          stepInput: data,
          step: step as string,
        },
      ])
    },
    [setStack]
  )

  const navigateToStep = React.useCallback<
    FlowControls<CurrentFlow>['navigateToStep']
  >(
    (step, data) => {
      setStack((old) => {
        const currentView = old[old.length - 1]

        const nextStep: StackValue = {
          ...currentView,
          stepIndex: currentView.stepIndex + 1,
          step: step as string,
          stepInput: { ...currentView.stepInput, ...data },
        }

        return [...old, nextStep]
      })
    },
    [setStack]
  )

  const goBack = React.useCallback<FlowControls<CurrentFlow>['goBack']>(() => {
    setStack((old) => old.slice(0, old.length - 1))
  }, [setStack])

  const exit = React.useCallback<FlowControls<CurrentFlow>['exit']>(() => {
    const currentView = stack[stack.length - 1] as StackValue | undefined

    if (!currentView) {
      throw new Error('Tried to call exit without an open flow.')
    }

    setStack([])
  }, [setStack, stack])

  return React.useMemo(
    () => ({
      goBack,
      enterFlow,
      navigateToStep,
      exit,
    }),
    [enterFlow, exit, goBack, navigateToStep]
  )
}

export function useFlowEntryPointNavigator<FlowToEnter extends Flow>(
  flow: string
) {
  const { enterFlow } = useFlowControls()
  return React.useCallback(
    <S extends keyof FlowToEnter['steps']>(
      step: S,
      data: FlowToEnter['steps'][S]['stepInput']
    ) => {
      enterFlow(flow, step as string, data)
    },
    [enterFlow, flow]
  )
}

export const FlowRouter = <F extends Flow>({
  componentMap,
}: FlowRouterProps<F>) => {
  const { step } = useModalFlowStepContext()
  const StepComponent = componentMap[step]

  return <StepComponent />
}
