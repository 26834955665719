import React from 'react'

import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import Markdown from 'components/common/markdown/markdown'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowInputComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams }) => {
  const content = blockParams.answer.response
  const headerText = blockParams.answer.headerText

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText text={headerText} />
      </AssistantWorkflowHarveyComponent>
      <Markdown content={content} className="rounded-md bg-secondary p-4" />
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams }) => {
  const headerText = blockParams.answer.headerText

  // TODO: Implement input box
  return (
    <AssistantWorkflowInputComponent>
      <p>{headerText}</p>
    </AssistantWorkflowInputComponent>
  )
}
