import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { FetchWorkspaces } from 'models/workspace'

import { useAuthUser } from 'components/common/auth-context'

export const useWorkspaces = () => {
  const userInfo = useAuthUser()
  const { data, isFetching, error } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.AllWorkspacesQuery],
    queryFn: async () => {
      const data = await FetchWorkspaces()
      return data.map((workspace) => ({
        id: workspace.id,
        name: workspace.friendlyName ?? workspace.clientName,
      }))
    },
    enabled: userInfo.IsInternalAdminReader,
  })

  return { data, isFetching, error }
}
