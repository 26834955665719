import React, { useState } from 'react'

import _ from 'lodash'

import {
  RescheduleWorkspaceOffboard,
  WorkspaceOffboardJob,
} from 'models/workspace'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Alert, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Spinner } from 'components/ui/spinner'

import {
  MAX_GRACE_PERIOD,
  MAX_GRACE_PERIOD_DATA_CLEANUP,
  MIN_GRACE_PERIOD,
  MIN_GRACE_PERIOD_DATA_CLEANUP,
} from './workspace-offboard'

interface WorkspaceOffboardRescheduleModalProps {
  open: boolean
  workspaceId: number
  onOpenChange: (open: boolean) => void
  onRescheduleSuccess: () => void
  selectedJob: WorkspaceOffboardJob | undefined
}

const WorkspaceOffboardRescheduleModal = ({
  open,
  workspaceId,
  onOpenChange,
  onRescheduleSuccess,
  selectedJob,
}: WorkspaceOffboardRescheduleModalProps) => {
  const [updatedGracePeriod, setUpdatedGracePeriod] = useState(30)
  const [rescheduleOffboardAcknowledge, setRescheduleOffboardAcknowledge] =
    useState(false)
  const [isRescheduling, setIsRescheduling] = useState(false)

  // This is a quick fix to allow rescheduling of data cleanup job with different limits
  const minGracePeriod =
    selectedJob?.jobType === 'WORKSPACE_OFFBOARD_DATA_CLEANUP'
      ? MIN_GRACE_PERIOD_DATA_CLEANUP
      : MIN_GRACE_PERIOD

  const maxGracePeriod =
    selectedJob?.jobType === 'WORKSPACE_OFFBOARD_DATA_CLEANUP'
      ? MAX_GRACE_PERIOD_DATA_CLEANUP
      : MAX_GRACE_PERIOD

  const handleUpdatedGracePeriodChange = (value: string) => {
    const parsedValue = parseInt(value, 10)
    if (value === '') {
      setUpdatedGracePeriod(0)
    } else if (isNaN(parsedValue)) {
      displayErrorMessage('Grace period must be a number')
      setUpdatedGracePeriod(30)
    } else if (parsedValue < minGracePeriod || parsedValue > maxGracePeriod) {
      displayErrorMessage(
        `Grace period must be between ${minGracePeriod} and ${maxGracePeriod}`
      )
      setUpdatedGracePeriod(30)
    } else {
      setUpdatedGracePeriod(parsedValue)
    }
  }

  const handleOffboardRescheduleSubmit = async () => {
    if (
      updatedGracePeriod < minGracePeriod ||
      updatedGracePeriod > maxGracePeriod
    ) {
      displayErrorMessage(
        `Grace period must be between ${minGracePeriod} and ${maxGracePeriod}`
      )
      return
    }
    if (_.isNil(selectedJob?.jobId)) {
      displayErrorMessage('No job selected')
      return
    }
    setIsRescheduling(true)
    try {
      await RescheduleWorkspaceOffboard(
        workspaceId,
        updatedGracePeriod,
        selectedJob.jobId
      )
      onRescheduleSuccess()
      displaySuccessMessage('Workspace offboard job rescheduled successfully.')
    } catch (error) {
      displayErrorMessage('Failed to reschedule workspace offboard job.')
    } finally {
      setIsRescheduling(false)
      onOpenChange(false)
      setRescheduleOffboardAcknowledge(false)
    }
  }

  const dateText =
    updatedGracePeriod === 0
      ? 'TODAY'
      : `in ${updatedGracePeriod} days from today`

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[600px]">
        <DialogTitle>
          <div className="text-destructive">Reschedule Workspace Offboard</div>
        </DialogTitle>
        <DialogDescription>
          <p>
            This will change the time when the workspace offboard jobs will be
            initiated. Minimum grace period is {minGracePeriod} day and maximum
            is {maxGracePeriod} days.
          </p>
          {!isRescheduling && (
            <>
              <div className="mb-4 flex items-center space-x-3 p-2">
                <p className="text-stone-500 bol">
                  New grace period from today:
                </p>
                <Input
                  className="w-30 h-8"
                  value={updatedGracePeriod}
                  onChange={(e) =>
                    handleUpdatedGracePeriodChange(e.target.value)
                  }
                />
              </div>
              <Alert className="h-17 mt-4" variant="destructive">
                <AlertTitle className="-mt-2 flex justify-between">
                  Please acknowledge you want to reschedule this workspace
                  offboard to {dateText}.
                  <Checkbox
                    checked={rescheduleOffboardAcknowledge}
                    onCheckedChange={() =>
                      setRescheduleOffboardAcknowledge(
                        !rescheduleOffboardAcknowledge
                      )
                    }
                    checkboxClassName="border border-destructive"
                  />
                </AlertTitle>
              </Alert>
            </>
          )}
          {isRescheduling && (
            <div className="mt-4 flex items-center">
              <Spinner className="mr-2" />
              <span>This operation could take up to a minute...</span>
            </div>
          )}
        </DialogDescription>
        <DialogFooter>
          <Button
            className="btn"
            variant="secondary"
            onClick={() => {
              setRescheduleOffboardAcknowledge(false)
              onOpenChange(false)
            }}
            disabled={isRescheduling}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={handleOffboardRescheduleSubmit}
            disabled={!rescheduleOffboardAcknowledge || isRescheduling}
            variant="destructive"
          >
            Reschedule
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceOffboardRescheduleModal
