import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import {
  AsteriskSquare,
  ChevronsLeft,
  ChevronsRight,
  Cog,
  HelpCircle,
  History,
  InfoIcon,
  Layers,
  LibraryBigIcon,
  LogOut,
  SunMoon,
  UserRound,
  Waypoints,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { Theme } from 'types/ui-constants'

import { cn } from 'utils/utils'

import { REMOVE_PARAMS as ASSISTANT_PARAMS } from 'components/assistant/utils/assistant-helpers'
import { BaseAppPath, SettingsPath } from 'components/base-app-path'
import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { CLIENT_MATTER_SIDEBAR_HELP_TEXT } from 'components/client-matters/client-matter-utils'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import HarveyAnnouncements from 'components/common/announcements/harvey-announcements'
import { useAuthUser } from 'components/common/auth-context'
import HarveyLogo from 'components/common/harvey-logo'
import HarveyWordMark from 'components/common/harvey-word-mark'
import { useThemeSwitcher } from 'components/common/use-theme-switcher'
import { useUserProfileStore } from 'components/common/user-profile-store'
import { LibraryFilterKey as LIBRARY_PARAMS } from 'components/library/library-filter-store'
import { REMOVE_PARAMS as RESEARCH_PARAMS } from 'components/research/util'
import { REMOVE_PARAMS as EXPERIMENT_PARAMS } from 'components/settings/experiment/utils/experiment-utils'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import Link from 'components/ui/link/link'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import { Separator } from 'components/ui/separator'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { REMOVE_PARAMS as VAULT_PARAMS } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

import NavItem from './nav-item'

interface SidebarProps {}

// Matches tailwind's `md` size
const MD_MEDIA_QUERY = '(max-width: 768px)'

const Sidebar: React.FC<SidebarProps> = () => {
  const { logout } = useAuth0()
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )
  const setVaultError = useVaultStore((s) => s.setError)
  const [
    isSidebarOpen,
    revertSidebarOpen,
    setIsSidebarOpen,
    setIsSidebarOpenAndToggle,
  ] = useGeneralStore(
    useShallow((s) => [
      s.isSidebarOpen,
      s.revertSidebarOpen,
      s.setIsSidebarOpen,
      s.setIsSidebarOpenAndToggle,
    ])
  )
  const userProfile = useUserProfileStore((s) => s.userProfile)
  const navigate = useNavigate()

  useThemeSwitcher()

  const [theme, setTheme] = useGeneralStore(
    useShallow((s) => [s.theme, s.setTheme])
  )

  const handleToggleTheme = () => {
    setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK)
  }

  const handleProfileClick = () => {
    navigate(SettingsPath.Profile)
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia(MD_MEDIA_QUERY)

    const handleMediaChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setIsSidebarOpenAndToggle(false)
      } else {
        revertSidebarOpen()
      }
    }
    mediaQuery.addEventListener('change', handleMediaChange)

    // Default to open state on mount, so if the screen is initially too small but they
    // resize it to be larger, the sidebar will pop out again.
    setIsSidebarOpen(true)
    if (mediaQuery.matches) setIsSidebarOpenAndToggle(false)

    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange)
    }
  }, [revertSidebarOpen, setIsSidebarOpen, setIsSidebarOpenAndToggle])

  const removeParams = [
    ...VAULT_PARAMS,
    ...RESEARCH_PARAMS,
    ...ASSISTANT_PARAMS,
    ...EXPERIMENT_PARAMS,
    ...Object.values(LIBRARY_PARAMS),
  ]

  const navItems = [
    {
      label: 'Assistant',
      icon: <AsteriskSquare />,
      href: BaseAppPath.Assistant,
      available: userInfo.IsAssistantUser,
    },
    {
      label: 'Research',
      icon: <Layers />,
      href: BaseAppPath.Research,
      available: userInfo.IsHarveyV1ResearchUser,
    },
    {
      label: 'Vault',
      icon: <FolderShieldIcon />,
      href: BaseAppPath.Vault,
      available: userInfo.IsVaultUser,
      onClick: () => {
        setVaultError(null)
      },
    },
    {
      label: 'Workflows',
      icon: <Waypoints />,
      href: BaseAppPath.Workflows,
      available: userInfo.IsHarveyV1WorkflowUser,
      comingSoon: !userInfo.IsHarveyV1WorkflowUser,
    },
    {
      label: 'History',
      icon: <History />,
      href: BaseAppPath.History,
      available: userInfo.IsHistoryUser,
    },
    {
      label: 'Library',
      icon: <LibraryBigIcon />,
      href: BaseAppPath.Library,
      available: userInfo.IsLibraryUser,
    },
  ]

  const [searchParams] = useSearchParams()

  const handleHelpClick = () => {
    trackEvent('Help Center Clicked')
  }

  const handleLogoutClick = () => {
    trackEvent('User Logged Out')
    localStorage.clear()
    sessionStorage.clear()

    const isCopilotPlugin = searchParams.get('isCopilotPlugin') === 'true'
    if (isCopilotPlugin) {
      window.location.href = `${window.location.origin}/wordaddin/copilotauth.html?logout=true`
    } else {
      logout({ returnTo: window.location.origin })
    }
  }

  const hasCustomClientMatterHelpText =
    userInfo.workspace.hasClientMatterHelpText ?? false // if hasCustomClientMatterHelpText is true, then userInfo is not undefined

  const cmTooltipText = selectedClientMatter ? (
    <>
      {`${selectedClientMatter.name} is selected to associate with every query.`}
      {hasCustomClientMatterHelpText ? (
        <>
          <br />
          <br />
          {userInfo.workspace.clientMatterHelpText}
        </>
      ) : (
        ''
      )}
    </>
  ) : hasCustomClientMatterHelpText ? (
    `${userInfo.workspace.clientMatterHelpText}`
  ) : (
    CLIENT_MATTER_SIDEBAR_HELP_TEXT
  )

  const frontendBranchName = process.env.REACT_APP_BRANCH_NAME
  const displayEngInternalInfo =
    userInfo.isReadEngInternal || frontendBranchName !== 'production'
  const frontendVersion = process.env.REACT_APP_REVISION ?? 'local'
  const backendVersion = userInfo.backendVersion ?? 'unknown'

  return (
    <div
      className={cn(
        'safe-h-screen group z-50 flex shrink-0 flex-col justify-between border-r bg-secondary transition',
        { 'w-fit': !isSidebarOpen, 'w-48': isSidebarOpen }
      )}
    >
      <ScrollArea>
        <div
          className={cn('flex h-16 w-full items-center justify-between px-3', {
            'justify-center': !isSidebarOpen,
          })}
        >
          {isSidebarOpen && (
            <Link to="/" className="ml-4" aria-label="Go to home page">
              <HarveyWordMark
                className="h-6 cursor-pointer fill-primary"
                aria-hidden="true"
              />
            </Link>
          )}

          {isSidebarOpen && (
            <Button
              size="smIcon"
              variant="ghost"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              aria-label="Close sidebar"
            >
              <ChevronsLeft
                data-testid="sidebar-toggle"
                size={20}
                className="text-muted"
              />
            </Button>
          )}

          {!isSidebarOpen && (
            <div className="relative flex items-center justify-center">
              <HarveyLogo className="pointer-events-none absolute size-5 transition group-hover:opacity-0" />
              <Button
                size="smIcon"
                variant="ghost"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                aria-label="Open sidebar"
              >
                <ChevronsRight
                  data-testid="sidebar-toggle"
                  size={20}
                  className="text-muted opacity-0 transition group-hover:opacity-100"
                />
              </Button>
            </div>
          )}
        </div>

        {isSidebarOpen &&
          userInfo.isClientMattersReadUser &&
          (hasCustomClientMatterHelpText ? (
            <div
              id="client-matter-select"
              className="flex flex-row items-center px-4"
            >
              {/* 159px = 192px (w-48) - 1px (border-r) - 32px (px-4) */}
              <ClientMatterSelect
                aria-label="Client matter"
                className="w-[159px]"
              />
              <Tooltip delayDuration={250}>
                <TooltipTrigger>
                  <InfoIcon className="ml-2 text-muted" size={14} />
                </TooltipTrigger>
                <TooltipContent side="right" className="mb-2 w-auto max-w-80">
                  {cmTooltipText}
                </TooltipContent>
              </Tooltip>
            </div>
          ) : (
            <Tooltip delayDuration={1000}>
              <TooltipTrigger asChild>
                <div id="client-matter-select" className="px-4">
                  {/* 159px = 192px (w-48) - 1px (border-r) - 32px (px-4) */}
                  <ClientMatterSelect className="w-[159px]" />
                </div>
              </TooltipTrigger>
              <TooltipContent className="mb-2 w-72" side="right">
                {cmTooltipText}
              </TooltipContent>
            </Tooltip>
          ))}

        <div className="mt-4 grid grid-cols-1 gap-1 px-4">
          {navItems
            .filter((item) => item.available)
            .map((item) => (
              <NavItem
                key={item.label}
                label={item.label}
                icon={item.icon}
                href={item.href}
                comingSoon={item.comingSoon}
                isSidebarOpen={isSidebarOpen}
                removeParams={removeParams}
                onClick={item.onClick}
              />
            ))}
        </div>
      </ScrollArea>

      {/* Bottom Section */}
      <div className="mb-4 px-4">
        <HarveyAnnouncements userInfo={userInfo!} />
        {/* <Integration /> */}

        <div className="mt-4 grid grid-cols-1 gap-1">
          {userInfo.IsViewHelpCenterUser && (
            <NavItem
              label="Help"
              icon={<HelpCircle />}
              href="http://help.harvey.ai/"
              isSidebarOpen={isSidebarOpen}
              removeParams={removeParams}
              isExternalLink={true}
              onClick={handleHelpClick}
            />
          )}

          {userInfo.isSettingsTabVisible && (
            <NavItem
              label="Settings"
              icon={<Cog />}
              href={BaseAppPath.Settings}
              isSidebarOpen={isSidebarOpen}
              removeParams={removeParams}
            />
          )}
          <Popover>
            <PopoverTrigger asChild>
              <button
                className="flex h-8 w-full items-center space-x-2 rounded-md px-2.5 transition hover:bg-secondary-hover focus:outline-none focus-visible:ring focus-visible:ring-ring"
                data-testid="user-dropdown"
              >
                <span className="flex size-4 shrink-0 items-center justify-center rounded-full bg-interactive text-[10px] text-primary-inverse">
                  {userInfo.id[0].toUpperCase()}
                </span>
                {isSidebarOpen && (
                  <p className="truncate text-secondary">{userInfo.id}</p>
                )}
              </button>
            </PopoverTrigger>
            <PopoverContent align="start" className="w-40 px-0 pb-2 pt-4">
              <div className="flex flex-col gap-2 px-4">
                <div>
                  <p className="text-xs text-muted">Signed in as</p>
                  <p className="truncate">{userInfo.id}</p>
                </div>
                {displayEngInternalInfo && (
                  <>
                    {frontendBranchName && (
                      <div>
                        <p className="text-xs text-muted">Frontend branch</p>
                        <p className="truncate">{frontendBranchName}</p>
                      </div>
                    )}
                    <div>
                      <p className="text-xs text-muted">Frontend version</p>
                      <p className="truncate">{frontendVersion}</p>
                    </div>
                    <div>
                      <p className="text-xs text-muted">Backend version</p>
                      <p className="truncate">{backendVersion}</p>
                    </div>
                  </>
                )}
              </div>
              <Separator className="my-2" />
              <div className="flex flex-col">
                {!!userProfile && (
                  <Button
                    className="flex w-full justify-start gap-2 rounded-none px-4 text-left"
                    variant="ghost"
                    onClick={handleProfileClick}
                  >
                    <Icon icon={UserRound} /> Profile
                  </Button>
                )}
                <Button
                  className="flex w-full justify-start gap-2 rounded-none px-4 text-left"
                  variant="ghost"
                  onClick={handleToggleTheme}
                >
                  <Icon icon={SunMoon} /> Use{' '}
                  {theme === Theme.DARK ? Theme.LIGHT : Theme.DARK} mode
                </Button>
                <Button
                  className="flex w-full items-center justify-start gap-2 rounded-none px-4 text-left"
                  variant="ghost"
                  onClick={handleLogoutClick}
                >
                  <Icon icon={LogOut} />
                  Sign out
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
