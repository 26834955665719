import {
  WorkflowAnswerRenderBlockBlockParams,
  instanceOfWorkflowAnswerRenderBlockBlockParams,
} from 'openapi/models/WorkflowAnswerRenderBlockBlockParams'
import {
  WorkflowFileUploadInputBlockBlockParams,
  instanceOfWorkflowFileUploadInputBlockBlockParams,
} from 'openapi/models/WorkflowFileUploadInputBlockBlockParams'
import {
  WorkflowFileUploadInputBlockOutput,
  instanceOfWorkflowFileUploadInputBlockOutput,
} from 'openapi/models/WorkflowFileUploadInputBlockOutput'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import {
  WorkflowSelectInputBlockBlockParams,
  instanceOfWorkflowSelectInputBlockBlockParams,
} from 'openapi/models/WorkflowSelectInputBlockBlockParams'
import {
  WorkflowSelectInputBlockOutput,
  instanceOfWorkflowSelectInputBlockOutput,
} from 'openapi/models/WorkflowSelectInputBlockOutput'

import {
  AssistantWorkflowAnswerRenderer,
  AssistantWorkflowAnswerRendererInputBox,
} from './blocks/assistant-workflow-answer-renderer'
import {
  AssistantWorkflowFileUploadInput,
  AssistantWorkflowFileUploadThread,
} from './blocks/assistant-workflow-file-upload-input'
import {
  AssistantWorkflowSelectInput,
  AssistantWorkflowSelectThread,
} from './blocks/assistant-workflow-select-input'

export type AssistantWorkflowComponent<K extends keyof BlockTypeMap> = React.FC<
  AssistantWorkflowComponentParams<
    BlockTypeMap[K]['blockParams'],
    BlockTypeMap[K]['outputData']
  >
>

export interface BlockValidators {
  blockParams: (params: any) => boolean
  outputData?: (data: any) => boolean
}

export interface AssistantWorkflowComponentParams<TBlockParams, TOutputData> {
  stepIdx: number
  onCompleted: (result: TOutputData) => void
  blockParams: TBlockParams
  outputData: TOutputData | null
}

export interface BlockTypeMap {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    blockParams: WorkflowFileUploadInputBlockBlockParams
    outputData: WorkflowFileUploadInputBlockOutput | null
  }
  [WorkflowInputComponentBlocks.SELECT]: {
    blockParams: WorkflowSelectInputBlockBlockParams
    outputData: WorkflowSelectInputBlockOutput | null
  }
  [WorkflowRenderComponentBlocks.ANSWER]: {
    blockParams: WorkflowAnswerRenderBlockBlockParams
    outputData: null
  }
}

export const blockValidators: {
  [K in keyof BlockTypeMap]: BlockValidators
} = {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    blockParams: instanceOfWorkflowFileUploadInputBlockBlockParams,
    outputData: instanceOfWorkflowFileUploadInputBlockOutput,
  },
  [WorkflowInputComponentBlocks.SELECT]: {
    blockParams: instanceOfWorkflowSelectInputBlockBlockParams,
    outputData: instanceOfWorkflowSelectInputBlockOutput,
  },
  [WorkflowRenderComponentBlocks.ANSWER]: {
    blockParams: instanceOfWorkflowAnswerRenderBlockBlockParams,
  },
}

export interface WorkflowComponentEntry<K extends keyof BlockTypeMap> {
  InputComponent: AssistantWorkflowComponent<K>
  ThreadComponent: AssistantWorkflowComponent<K>
  validators: BlockValidators
}

export const AssistantWorkflowStepNameToDefinition: {
  [K in keyof BlockTypeMap]: WorkflowComponentEntry<K>
} = {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    InputComponent: AssistantWorkflowFileUploadInput,
    ThreadComponent: AssistantWorkflowFileUploadThread,
    validators: blockValidators[WorkflowInputComponentBlocks.FILE_UPLOAD],
  },
  [WorkflowInputComponentBlocks.SELECT]: {
    InputComponent: AssistantWorkflowSelectInput,
    ThreadComponent: AssistantWorkflowSelectThread,
    validators: blockValidators[WorkflowInputComponentBlocks.SELECT],
  },
  [WorkflowRenderComponentBlocks.ANSWER]: {
    InputComponent: AssistantWorkflowAnswerRendererInputBox,
    ThreadComponent: AssistantWorkflowAnswerRenderer,
    validators: blockValidators[WorkflowRenderComponentBlocks.ANSWER],
  },
}
