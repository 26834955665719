/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the query cap rule time frame.
 * @export
 */
export const QueryCapRuleTimeFrame = {
    CALENDAR_MONTH: 'CALENDAR_MONTH',
    ANNUAL: 'ANNUAL',
} as const;
export type QueryCapRuleTimeFrame = typeof QueryCapRuleTimeFrame[keyof typeof QueryCapRuleTimeFrame];


export function instanceOfQueryCapRuleTimeFrame(value: any): boolean {
    for (const key in QueryCapRuleTimeFrame) {
        if (Object.prototype.hasOwnProperty.call(QueryCapRuleTimeFrame, key)) {
            if (QueryCapRuleTimeFrame[key as keyof typeof QueryCapRuleTimeFrame] === value) {
                return true;
            }
        }
    }
    return false;
}

export function QueryCapRuleTimeFrameFromJSON(json: any): QueryCapRuleTimeFrame {
    return QueryCapRuleTimeFrameFromJSONTyped(json, false);
}

export function QueryCapRuleTimeFrameFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryCapRuleTimeFrame {
    return json as QueryCapRuleTimeFrame;
}

export function QueryCapRuleTimeFrameToJSON(value?: QueryCapRuleTimeFrame | null): any {
    return value as any;
}

export function QueryCapRuleTimeFrameToJSONTyped(value: any, ignoreDiscriminator: boolean): QueryCapRuleTimeFrame {
    return value as QueryCapRuleTimeFrame;
}

