/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiligenceFollowUpQuestionResponse
 */
export interface DiligenceFollowUpQuestionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DiligenceFollowUpQuestionResponse
     */
    isFollowUpQuestion: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiligenceFollowUpQuestionResponse
     */
    content: string;
}

/**
 * Check if a given object implements the DiligenceFollowUpQuestionResponse interface.
 */
export function instanceOfDiligenceFollowUpQuestionResponse(value: object): value is DiligenceFollowUpQuestionResponse {
    if (!('isFollowUpQuestion' in value) || value['isFollowUpQuestion'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function DiligenceFollowUpQuestionResponseFromJSON(json: any): DiligenceFollowUpQuestionResponse {
    return DiligenceFollowUpQuestionResponseFromJSONTyped(json, false);
}

export function DiligenceFollowUpQuestionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceFollowUpQuestionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isFollowUpQuestion': json['is_follow_up_question'],
        'content': json['content'],
    };
}

export function DiligenceFollowUpQuestionResponseToJSON(json: any): DiligenceFollowUpQuestionResponse {
    return DiligenceFollowUpQuestionResponseToJSONTyped(json, false);
}

export function DiligenceFollowUpQuestionResponseToJSONTyped(value?: DiligenceFollowUpQuestionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'is_follow_up_question': value['isFollowUpQuestion'],
        'content': value['content'],
    };
}

