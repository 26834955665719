import * as React from 'react'

import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'

import { cn } from 'utils/utils'

import OverflowHint, { OverflowHintSide } from './overflow-hint'

type ScrollAreaProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaPrimitive.Root
> & {
  maxHeight?: string | number
  scrollBarTop?: number
  hasHorizontalScroll?: boolean
  overflowHintSide?: OverflowHintSide
  isFullHeight?: boolean
  tabIndex?: number
  // Only use this if you cannot use tailwind classes to style the component, e.g. you need to dynamically set the max height
  style?: React.CSSProperties
}

const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      className,
      children,
      maxHeight,
      scrollBarTop,
      hasHorizontalScroll,
      overflowHintSide,
      isFullHeight,
      style,
      tabIndex = 0,
      ...props
    },
    ref
  ) => {
    const innerChild = (
      innerRef:
        | React.ForwardedRef<HTMLDivElement>
        | ((el: HTMLDivElement) => void),
      innerClassName?: string,
      onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
    ) => {
      const mergedScrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
        if (props.onScroll) props.onScroll(e)
        if (onScroll) onScroll(e)
      }
      return (
        <ScrollAreaPrimitive.Root
          data-scroll-area
          className={cn('relative overflow-hidden', innerClassName)}
          {...props}
        >
          <ScrollAreaPrimitive.Viewport
            tabIndex={tabIndex}
            className={cn(
              'scroll-area-viewport h-full w-full rounded-[inherit] outline-none',
              { '*:h-full': isFullHeight },
              maxHeight
            )}
            onScroll={mergedScrollHandler}
            style={style}
            ref={innerRef}
          >
            {/* This style override is necessary due to issues with the default
            `-webkit-overflow-scrolling: touch` used in Radix UI's ScrollArea component
            (https://github.com/radix-ui/primitives/blob/b32a93318cdfce383c2eec095710d35ffbd33a1c/packages/react/scroll-area/src/ScrollArea.tsx#L154).
            Since iOS 13+, this property is no longer needed and causes issues where
            the ScrollArea might hide the AppHeader in our app-layout.tsx on iOS devices. */}
            <style
              dangerouslySetInnerHTML={{
                __html: `[data-radix-scroll-area-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:auto;}[data-radix-scroll-area-viewport]::-webkit-scrollbar{display:none}`,
              }}
            />
            {children}
          </ScrollAreaPrimitive.Viewport>
          <ScrollBar
            orientation="vertical"
            thumbStyle={scrollBarTop ? { top: `${scrollBarTop}px` } : undefined}
            style={{ height: `calc(100% - ${scrollBarTop}px)` }}
          />
          {hasHorizontalScroll && <ScrollBar orientation="horizontal" />}
          <ScrollAreaPrimitive.Corner />
        </ScrollAreaPrimitive.Root>
      )
    }

    if (overflowHintSide) {
      return (
        <OverflowHint
          className={className}
          direction={hasHorizontalScroll ? 'horizontal' : 'vertical'}
          side={overflowHintSide}
        >
          {(overflowRef, onScroll) => {
            const mergedRefs = (el: HTMLDivElement) => {
              if (typeof ref === 'function') {
                ref(el)
              } else if (ref) {
                ref.current = el
              }
              overflowRef.current = el
            }
            return innerChild(mergedRefs, 'h-full', onScroll)
          }}
        </OverflowHint>
      )
    }
    return innerChild(ref, className)
  }
)

ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

type ScrollBarProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaPrimitive.ScrollAreaScrollbar
> & {
  thumbStyle?: React.CSSProperties
}

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  ScrollBarProps
>(({ className, thumbStyle, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    forceMount
    ref={ref}
    orientation={orientation}
    className={cn(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' &&
        'h-full w-2.5 border-l border-l-transparent p-[1px]',
      orientation === 'horizontal' &&
        'h-2.5 border-t border-t-transparent p-[1px]',
      className
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb
      className={cn(
        'relative rounded-full bg-scrollbar',
        orientation === 'vertical' && 'flex-1'
      )}
      style={thumbStyle}
    />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
))
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

export { ScrollArea, ScrollBar }
