import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import { PracticeArea } from 'openapi/models/PracticeArea'

import Modal from './components/modal'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import ProfilePracticeAreasForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
} from 'components/common/profile/profile-practice-areas-form'

import { JobTitleStepType } from './job-title-step'
import { ProfileSetupFlowType } from './profile-setup'
import { YearsExpStepType } from './years-exp-step'

export type PracticeAreaStepType = Step<
  JobTitleStepType['stepOutput'] | YearsExpStepType['stepOutput'],
  {
    practiceAreas: PracticeArea[]
  }
>

const practiceAreaSchema = z.object({
  practiceAreas: z.array(z.nativeEnum(PracticeArea)).min(1).max(3),
})

export type PracticeAreaSchema = z.infer<typeof practiceAreaSchema>

const PracticeAreaStep: React.FC = () => {
  const { goBack, navigateToStep } = useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<PracticeAreaStepType>()
  const form = useForm<PracticeAreaSchema>({
    resolver: zodResolver(practiceAreaSchema),
    defaultValues: {
      practiceAreas: [],
    },
  })
  const { trackEvent } = useAnalytics()

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent(
      'profile_setup_practice_area_step_viewed',
      preparedEventProperties
    )
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    navigateToStep('primary-language-step', {
      ...stepInput,
      practiceAreas: data.practiceAreas,
      stepCounter: stepInput.stepCounter + 1,
    })
  })

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      <ProfilePracticeAreasForm
        control={form.control}
        primaryProfession={stepInput.primaryProfession}
      />
    </Modal>
  )
}

export default PracticeAreaStep
