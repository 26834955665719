import React, { useEffect } from 'react'

import _ from 'lodash'
import { XCircle } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { ContractsDocument } from 'openapi/models/ContractsDocument'

import {
  EMPTY_QUERY_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
  SELECT_CONTRACT_DOCUMENT_TYPE_HELP_TEXT,
} from 'utils/tooltip-texts'

import AskHarveyButton from 'components/common/ask-harvey-button'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import { Input } from 'components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Spinner } from 'components/ui/spinner'
import Tag from 'components/ui/tag'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useContractsStore } from 'components/workflows/workflow/contracts/contracts-store'

type Props = {
  documents: ContractsDocument[]
  isLoading: boolean
  onCreateFilter: (query: string, type: string, termName: string) => void
  isFilterLoading: boolean
}

export const SmartFilter: React.FC<Props> = ({
  documents,
  isLoading,
  onCreateFilter,
  isFilterLoading,
}) => {
  const { filters, setFilters } = useContractsStore(
    useShallow((s) => ({
      filters: s.filters,
      setFilters: s.setFilters,
    }))
  )
  const [query, setQuery] = React.useState<string>('')
  const [selectedTerm, setSelectedTerm] = React.useState<string | null>(null)

  useEffect(() => {
    // When query completes, reset the query and selected term
    if (!isFilterLoading) {
      setQuery('')
      setSelectedTerm(null)
    }
  }, [isFilterLoading])

  const documentTypes = documents.reduce((acc, doc) => {
    if (doc.type && !acc.includes(doc.type)) {
      acc.push(doc.type)
    }
    return acc
  }, [] as string[])

  const termsByType = documents.reduce((acc: Record<string, string[]>, doc) => {
    if (doc.type) {
      acc[doc.type] = Object.keys(doc.extractedTerms)
    }
    return acc
  }, {})

  const getInitialDocumentType = () => {
    if (filters.length > 0) {
      return filters[0].userInput.contractType
    } else if (documentTypes.length === 1) {
      return documentTypes[0]
    } else {
      return null
    }
  }

  const [selectedDocumentType, setSelectedDocumentType] = React.useState<
    string | null
  >(getInitialDocumentType())

  const localOnCreateFilter = () => {
    if (!selectedDocumentType || !selectedTerm) return
    onCreateFilter(query, selectedDocumentType, selectedTerm)
  }

  const askHarveyDisabled =
    isLoading ||
    _.isEmpty(query.trim()) ||
    isFilterLoading ||
    !selectedDocumentType ||
    !selectedTerm

  const getTooltipContent = () => {
    if (query.length === 0) {
      return EMPTY_QUERY_HELP_TEXT
    } else if (!selectedDocumentType) {
      return SELECT_CONTRACT_DOCUMENT_TYPE_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (isFilterLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }

  const isDocumentSelectorDisabled = filters.length > 0
  const termsToShow = selectedDocumentType
    ? termsByType[selectedDocumentType] ?? []
    : []

  return (
    <Card>
      <CardHeader>
        <CardTitle>Smart filter</CardTitle>
        <CardDescription>
          Use Harvey to find the contracts you need
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mt-4 flex items-start space-x-2">
          <Tooltip>
            <TooltipTrigger>
              <Select
                disabled={isDocumentSelectorDisabled}
                onValueChange={(value) => {
                  setSelectedDocumentType(value)
                  setSelectedTerm(null)
                }}
                value={selectedDocumentType ?? undefined}
              >
                <SelectTrigger className="h-8 w-32 text-nowrap lg:w-48">
                  <SelectValue placeholder="Document type" />
                </SelectTrigger>
                <SelectContent>
                  {documentTypes.map((type) => (
                    <SelectItem key={type} value={type}>
                      {`${type}s`}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </TooltipTrigger>
            {isDocumentSelectorDisabled && (
              <TooltipContent>
                Reset the search filters to change document type
              </TooltipContent>
            )}
          </Tooltip>

          <Tooltip>
            <TooltipTrigger>
              <Select
                key={
                  // This key forces the component to re-render when the term changes
                  selectedDocumentType + (selectedTerm ?? '') || 'default-key'
                }
                onValueChange={setSelectedTerm}
                value={selectedTerm ?? undefined}
              >
                <SelectTrigger className="h-8 w-32 text-nowrap lg:w-48">
                  <SelectValue placeholder="Document term" />
                </SelectTrigger>
                <SelectContent>
                  {termsToShow.map((term: string) => (
                    <SelectItem key={term} value={term}>
                      {term}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </TooltipTrigger>
            {!selectedDocumentType && (
              <TooltipContent>
                Select a document type to see the terms
              </TooltipContent>
            )}
          </Tooltip>

          <div className="grow">
            <div className="relative flex items-center">
              <Input
                className="h-8 w-full"
                placeholder="Provide filter criteria for this document term, e.g. New York law…"
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />

              <BasicTransition
                show={isFilterLoading}
                className="absolute right-1"
              >
                <Spinner size="xs" />
              </BasicTransition>
            </div>
            <div className="mt-2">
              <div className="flex flex-wrap items-start space-x-2">
                <Button
                  variant="dashed"
                  size="sm"
                  onClick={() => {
                    setFilters([])
                  }}
                  disabled={filters.length === 0}
                >
                  <XCircle size={12} className="mr-1" />
                  Reset
                </Button>
                {filters.map((filter, i) => (
                  <Tag
                    onDismiss={() => {
                      setFilters(filters.filter((_, index) => index !== i))
                      setQuery(filter.userInput.userQuery)
                      setSelectedTerm(filter.userInput.term)
                    }}
                    key={i}
                    text={filter.userInput.userQuery}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end">
        <div>
          <AskHarveyButton
            handleSubmit={localOnCreateFilter}
            disabled={askHarveyDisabled}
            tooltip={getTooltipContent()}
          />
        </div>
      </CardFooter>
    </Card>
  )
}
