import { useShallow } from 'zustand/react/shallow'

import { PermissionLevel } from 'types/sharing'

import { doesUserHavePermission } from 'utils/sharing-helpers'

import { useAuthUser } from 'components/common/auth-context'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

type UseSharingPermissionsProps = {
  projectId?: string
}

const useSharingPermissions = ({
  projectId,
}: UseSharingPermissionsProps): {
  doesCurrentUserHaveEditPermission: boolean
  doesCurrentUserHaveFullAccessPermission: boolean
  isOwner: boolean
  canCurrentUserCreateShares: boolean
} => {
  const userInfo = useAuthUser()

  const [currentProject] = useVaultStore(
    useShallow((s) => [
      projectId ? s.allFolderIdToVaultFolder[projectId] : undefined,
    ])
  )

  const currentUserPermissionLevel = useVaultSharingStore(
    useShallow((s) =>
      projectId ? s.currentUserPermissionByProjectId[projectId] : undefined
    )
  )

  if (!projectId) {
    return {
      doesCurrentUserHaveEditPermission: false,
      doesCurrentUserHaveFullAccessPermission: false,
      isOwner: false,
      canCurrentUserCreateShares: false,
    }
  }

  if (!currentProject) {
    return {
      doesCurrentUserHaveEditPermission: false,
      doesCurrentUserHaveFullAccessPermission: false,
      isOwner: false,
      canCurrentUserCreateShares: false,
    }
  }

  // If the current user is the owner of the project, they have full access
  const isOwner = currentProject.userId === userInfo.dbId

  if (isOwner) {
    return {
      doesCurrentUserHaveEditPermission: true,
      doesCurrentUserHaveFullAccessPermission: true,
      isOwner: true,
      canCurrentUserCreateShares: userInfo.IsVaultCreateSharesUser,
    }
  }

  const doesCurrentUserHaveEditPermission = doesUserHavePermission({
    currentPermissionLevel: currentUserPermissionLevel,
    requiredPermissionLevel: PermissionLevel.EDIT,
    isOwner,
  })

  const doesCurrentUserHaveFullAccessPermission = doesUserHavePermission({
    currentPermissionLevel: currentUserPermissionLevel,
    requiredPermissionLevel: PermissionLevel.FULL_ACCESS,
    isOwner,
  })

  return {
    doesCurrentUserHaveEditPermission,
    doesCurrentUserHaveFullAccessPermission,
    isOwner,
    canCurrentUserCreateShares:
      userInfo.IsVaultCreateSharesUser &&
      doesCurrentUserHaveFullAccessPermission,
  }
}

export default useSharingPermissions
