import React from 'react'

import { Row } from '@tanstack/react-table'
import _, { capitalize } from 'lodash'
import { MoreHorizontal, Star } from 'lucide-react'
import { Dict } from 'mixpanel-browser'
import { useShallow } from 'zustand/react/shallow'

import { UserInfo } from 'models/user-info'
import Services from 'services'

import { getRouteForEvent } from 'utils/routing'
import { useAllTaskLabelLookup } from 'utils/task-definitions'
import { EM_DASH, backendToLocalFull, backendToReadable, cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { SourceBadgeCell } from 'components/history/history-cells'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/ui/hover-card/hover-card'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import { useLibraryMetadataStore } from './library-metadata-store'
import { Example, LibraryItem } from './library-types'
import { getNavigateOptionsState } from './library.helpers'

interface CellProps {
  row: Row<LibraryItem>
  userInfo?: UserInfo
}

interface ExampleCellProps {
  row: Row<Example>
}

const BaseCell: React.FC<{
  text: string
  tooltipContent: string
  tooltipAlign: 'left' | 'right'
  wrapInBadge?: boolean
  'data-testid'?: string
}> = ({ text, tooltipContent, wrapInBadge, 'data-testid': dataTestId }) => {
  return (
    <Tooltip>
      <TooltipTrigger className="block w-full text-left">
        {wrapInBadge ? (
          <Badge data-testid={dataTestId} variant="secondary">
            <p className="line-clamp-1 break-all text-start text-sm">{text}</p>
          </Badge>
        ) : (
          <p
            data-testid={dataTestId}
            className="line-clamp-1 break-all text-sm"
          >
            {text}
          </p>
        )}
      </TooltipTrigger>
      <TooltipContent>{tooltipContent}</TooltipContent>
    </Tooltip>
  )
}

const LibraryUserNameCell: React.FC<CellProps> = ({ row }) => {
  const userName: string = row.getValue('userId')
  return (
    <BaseCell
      text={userName}
      tooltipContent={userName}
      tooltipAlign="right"
      data-testid="library-table--cell--user-name"
    />
  )
}

const LibraryStarredCell: React.FC<CellProps> = ({ row }) => {
  const [getFavoriteStatus, updateLibraryItemFavorite] =
    useLibraryMetadataStore(
      useShallow((s) => [s.getFavoriteStatus, s.updateLibraryItemFavorite])
    )
  const { trackEvent } = useAnalytics()

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="smIcon"
          aria-label="Favorite"
          onClick={async (event) => {
            const newValue = !getFavoriteStatus(row.original)
            event.stopPropagation()
            await updateLibraryItemFavorite(row.original.id, newValue)
            trackEvent('Library Item Starred', {
              item_id: row.original.id,
              event_id: row.original.eventId,
              kind: row.original.kind,
              task_type: row.original.eventKind,
              categories: row.original.categories,
              practice_areas: row.original.practiceAreas,
              document_types: row.original.documentTypes,
              visibility_scope: row.original.visibilityScope,
              starred: newValue,
              entry_point: 'library_table',
            })
          }}
          data-testid="library-table--cell--favorite"
        >
          {getFavoriteStatus(row.original) ? (
            <Star className="h-4 w-4 fill-gold stroke-gold" />
          ) : (
            <Star className="h-4 w-4 stroke-primary" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {getFavoriteStatus(row.original) ? 'Starred' : 'Not starred'}
      </TooltipContent>
    </Tooltip>
  )
}

const LibraryNameStarredCell: React.FC<CellProps> = ({ row }) => {
  return (
    <div className="group flex items-center">
      <span>{row.original?.name}</span>
      <div
        className={cn('transition-opacity duration-200', {
          'opacity-0 group-hover:opacity-100': !row.original?.starred,
          'opacity-100': row.original?.starred,
        })}
      >
        <LibraryStarredCell row={row} />
      </div>
    </div>
  )
}

const LibraryQueryCell: React.FC<CellProps> = ({ row }) => {
  const query = row.original?.query
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <p
          data-testid="library-table--cell--query"
          className="line-clamp-1 max-w-96 break-words text-left text-sm"
        >
          {query}
        </p>
      </TooltipTrigger>
      <TooltipContent className="max-w-2xl" align="start">
        <span className="line-clamp-8 text-[length:inherit]">{query}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const LibraryTimeCell: React.FC<CellProps> = ({ row }) => {
  const text = backendToReadable(row.getValue('created'))
  const tooltip = backendToLocalFull(row.getValue('created'))
  return (
    <BaseCell
      text={text}
      tooltipContent={tooltip}
      tooltipAlign="left"
      data-testid="library-table--cell--time-cell"
    />
  )
}

const LibraryCreatorCell: React.FC<CellProps> = ({ row }) => {
  const text = row.original?.createdByUserEmail
  const tooltip = row.original?.createdByUserEmail
  return (
    <BaseCell
      text={text || 'Unknown'}
      tooltipContent={tooltip || 'Unknown'}
      tooltipAlign="left"
      data-testid="library-table--cell--creator-cell"
    />
  )
}

const LibraryNameCell: React.FC<CellProps> = ({ row }) => {
  const name = row.original?.name
  const query = row.original?.query
  return (
    <HoverCard>
      <HoverCardTrigger className="block w-full text-left">
        <p
          data-testid="library-table--cell--name"
          className="line-clamp-1 text-left text-sm"
        >
          {name}
        </p>
      </HoverCardTrigger>
      <HoverCardContent
        className="flex max-w-3xl flex-col p-0"
        align="start"
        alignOffset={-12}
      >
        <div className="p-4 pb-3 text-xs font-semibold">{name}</div>
        <ScrollArea className="flex min-h-0 grow flex-col" isFullHeight>
          <div className="whitespace-pre-wrap p-4 pt-0 text-xs">{query}</div>
        </ScrollArea>
      </HoverCardContent>
    </HoverCard>
  )
}

const LibraryTaskTypeCell: React.FC<CellProps> = ({ row, userInfo }) => {
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const taskTypeLabel = taskLabelLookup[row.original?.eventKind]
  return (
    <BaseCell
      text={taskTypeLabel}
      tooltipContent={taskTypeLabel}
      tooltipAlign="left"
      wrapInBadge
      data-testid="library-table--cell--task-type"
    />
  )
}

const LibraryCategoryCell: React.FC<CellProps> = ({ row }) => {
  const categories = row.original?.categories
  const category = categories?.join(', ')

  return (
    <BaseCell
      text={category}
      tooltipContent={category}
      tooltipAlign="left"
      data-testid="library-table--cell--category"
    />
  )
}

const LibraryPracticeAreaCell: React.FC<CellProps> = ({ row }) => {
  const practiceAreas = row.original?.practiceAreas
  const practiceArea = practiceAreas?.join(', ')

  return (
    <BaseCell
      text={practiceArea}
      tooltipContent={practiceArea}
      tooltipAlign="left"
      data-testid="library-table--cell--practice-area"
    />
  )
}

const LibraryDocumentTypeCell: React.FC<ExampleCellProps> = ({ row }) => {
  const documentTypes = row.original?.documentTypes
  const documentType = documentTypes?.join(', ')

  if (_.isEmpty(documentType)) return null
  return (
    <BaseCell
      text={documentType}
      tooltipContent={documentType}
      tooltipAlign="left"
      data-testid="library-table--cell--document-type"
    />
  )
}

const LibrarySourceCell: React.FC<ExampleCellProps> = ({ row }) => {
  const sourceLabel = row.original?.source

  if (sourceLabel) {
    return <SourceBadgeCell icon={null} content={sourceLabel} />
  }
  return <p>{EM_DASH}</p>
}

const LibraryWorkspaceHiddenCell: React.FC<CellProps> = ({ row }) => {
  const getHiddenStatus = useLibraryMetadataStore((s) => s.getHiddenStatus)

  const workspaceHidden = getHiddenStatus(row.original)
  const libraryItemKind = row.original?.kind || 'Item'
  const tooltipContent = `${capitalize(libraryItemKind)} ${
    workspaceHidden ? 'hidden from' : 'visible to'
  } your users`
  return (
    <BaseCell
      text={workspaceHidden ? 'Hidden' : 'Shown'}
      tooltipContent={tooltipContent}
      tooltipAlign="left"
      data-testid="library-table--cell--workspace-hidden"
    />
  )
}

interface LibraryMenuCellProps extends CellProps {
  onDeleteItem?: (item: LibraryItem) => void
  onHideItem?: (item: LibraryItem) => void
  onEditItem?: (item: LibraryItem) => void
}

const LibraryMenuCell: React.FC<LibraryMenuCellProps> = ({
  row,
  userInfo,
  onDeleteItem,
  onHideItem,
  onEditItem,
}) => {
  const getHiddenStatus = useLibraryMetadataStore((s) => s.getHiddenStatus)
  const { trackEvent } = useAnalytics()

  if (_.isNil(userInfo)) {
    return null
  }

  const navigateOptions = userInfo.IsAssistantV2User
    ? getNavigateOptionsState(row.original)
    : undefined

  const eventLink = getRouteForEvent(
    {
      id: row.original.eventId,
      kind: row.original.eventKind,
      libraryItemKind: row.original.kind,
    },
    userInfo,
    navigateOptions
  )

  const handleOpenInNewTabClick = (
    event: React.MouseEvent,
    trackEvent: (event: string, properties: Dict) => void
  ): void => {
    event.stopPropagation()
    Services.HoneyComb.Record({
      metric: 'ui.library_open_in_new_tab',
      item_id: row.original.id,
      event_id: row.original.eventId,
      task_type: row.original.eventKind,
      starred: row.original.starred,
      workspace_id: row.original.workspaceId,
      user_id: row.original.userId,
      visibility_scope: row.original.visibilityScope,
      categories: row.original.categories,
      practice_areas: row.original.practiceAreas,
      document_types: row.original.documentTypes,
    })
    trackEvent('Library Item Loaded', {
      item_id: row.original.id,
      event_id: row.original.eventId,
      kind: row.original.kind,
      task_type: row.original.eventKind,
      categories: row.original.categories,
      practice_areas: row.original.practiceAreas,
      document_types: row.original.documentTypes,
      visibility_scope: row.original.visibilityScope,
      starred: row.original.starred,
      in_new_tab: true,
    })
    window.open(eventLink, '_blank')
  }

  const handleDeleteItemClick = (
    event: React.MouseEvent,
    row: Row<LibraryItem>
  ): void => {
    if (!onDeleteItem) {
      return
    }
    event.stopPropagation()
    onDeleteItem(row.original)
  }

  const handleHideItemClick = (
    event: React.MouseEvent,
    row: Row<LibraryItem>
  ): void => {
    if (!onHideItem) {
      return
    }
    event.stopPropagation()
    onHideItem(row.original)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" aria-label="More options">
          <Icon icon={MoreHorizontal} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onClick={(event) => handleOpenInNewTabClick(event, trackEvent)}
        >
          Open in new tab
        </DropdownMenuItem>
        {onHideItem && (
          <DropdownMenuItem
            onClick={(event) => {
              handleHideItemClick(event, row)
            }}
          >
            {getHiddenStatus(row.original) ? 'Show to team' : 'Hide from team'}
          </DropdownMenuItem>
        )}
        {onEditItem && (
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation()
              onEditItem(row.original)
            }}
          >
            Edit
          </DropdownMenuItem>
        )}
        {onDeleteItem && (
          <DropdownMenuItem
            onClick={(event) => {
              handleDeleteItemClick(event, row)
            }}
            className="text-destructive focus:text-destructive"
          >
            Delete
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export {
  LibraryUserNameCell,
  LibraryStarredCell,
  LibraryNameStarredCell,
  LibraryQueryCell,
  LibraryTimeCell,
  LibraryCreatorCell,
  LibraryNameCell,
  LibraryCategoryCell,
  LibraryTaskTypeCell,
  LibraryMenuCell,
  LibraryDocumentTypeCell,
  LibraryPracticeAreaCell,
  LibraryWorkspaceHiddenCell,
  LibrarySourceCell,
}
