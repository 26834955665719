import React from 'react'

import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
  AssistantWorkflowInputComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowSelectThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.SELECT
> = ({ blockParams, outputData }) => {
  const { headerText } = blockParams
  const isCompleted = !!outputData

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText text={headerText} />
      </AssistantWorkflowHarveyComponent>

      {isCompleted && (
        <AssistantWorkflowYouComponent>
          <AssistantWorkflowThreadText text={outputData.selected} />
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowSelectInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.SELECT
> = ({ blockParams, onCompleted, outputData }) => {
  const { options } = blockParams
  const isCompleted = !!outputData

  const onSelected = (value: any) => {
    const result = { selected: value }
    onCompleted(result)
  }

  // TODO: Implement input box
  return (
    <AssistantWorkflowInputComponent>
      <Select
        onValueChange={onSelected}
        defaultValue={outputData?.selected}
        disabled={isCompleted}
      >
        <SelectTrigger className="w-[200px]">
          <SelectValue placeholder={blockParams.placeholder} />
        </SelectTrigger>
        <SelectContent className="overflow-visible">
          {options.map((option: any, index: number) => (
            <SelectItem value={option} key={index}>
              {option}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </AssistantWorkflowInputComponent>
  )
}
