import { useCallback } from 'react'

import { displayErrorMessage } from 'utils/toast'

import { RetryFiles } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

const useRetryHandler = () => {
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const currentProjectId = useVaultStore((s) => s.currentProject?.id)
  const setRequiresProjectDataRefetch = useVaultStore(
    (s) => s.setRequiresProjectDataRefetch
  )

  const onRetryHandler = useCallback(
    async (fileIds: string[]) => {
      // Filter out invalid UUIDs
      const validFileIds = fileIds.filter((id) => {
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
        if (!uuidRegex.test(id)) {
          console.error(`Invalid UUID passed to retry handler: ${id}`)
          return false
        }
        return true
      })

      if (validFileIds.length === 0) {
        return
      }
      try {
        const response = await RetryFiles(validFileIds)
        if (response.retriedFiles.length > 0) {
          upsertVaultFiles(response.retriedFiles, currentProjectId)

          // Refetch the project to update the status (e.g. completed files count)
          setRequiresProjectDataRefetch(true)
        }
        if (
          response.failedFilesWithErrors &&
          response.failedFilesWithErrors.length > 0
        ) {
          if (response.failedFilesWithErrors.length === 1) {
            displayErrorMessage(
              'Failed to retry processing the file: ' +
                response.failedFilesWithErrors[0].error
            )
          } else {
            displayErrorMessage('Failed to retry processing the files')
          }
        }
      } catch (error) {
        console.error(error)
        displayErrorMessage('Failed to retry processing the file')
      }
    },
    [upsertVaultFiles, setRequiresProjectDataRefetch, currentProjectId]
  )

  return { onRetryHandler }
}

export default useRetryHandler
