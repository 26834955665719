/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TranscriptsWitness
 */
export interface TranscriptsWitness {
    /**
     * 
     * @type {string}
     * @memberof TranscriptsWitness
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TranscriptsWitness
     */
    startPage: number;
    /**
     * 
     * @type {number}
     * @memberof TranscriptsWitness
     */
    lineNumber: number;
}

/**
 * Check if a given object implements the TranscriptsWitness interface.
 */
export function instanceOfTranscriptsWitness(value: object): value is TranscriptsWitness {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('startPage' in value) || value['startPage'] === undefined) return false;
    if (!('lineNumber' in value) || value['lineNumber'] === undefined) return false;
    return true;
}

export function TranscriptsWitnessFromJSON(json: any): TranscriptsWitness {
    return TranscriptsWitnessFromJSONTyped(json, false);
}

export function TranscriptsWitnessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsWitness {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'startPage': json['start_page'],
        'lineNumber': json['line_number'],
    };
}

export function TranscriptsWitnessToJSON(json: any): TranscriptsWitness {
    return TranscriptsWitnessToJSONTyped(json, false);
}

export function TranscriptsWitnessToJSONTyped(value?: TranscriptsWitness | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'start_page': value['startPage'],
        'line_number': value['lineNumber'],
    };
}

