import React, { useState } from 'react'

import _ from 'lodash'
import { LaptopIcon, LucideIcon } from 'lucide-react'

import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'

import SharepointOneDriveLogo from './sharepoint-onedrive-logo'

interface AddMoreFilesProps {
  onUploadFromComputer: () => void
  handleSharePointClick?: () => void
  side?: 'bottom' | 'top'
  align?: 'start' | 'end'
  buttonText?: string
  leadingIcon?: LucideIcon
  buttonSize?: 'sm' | 'default'
  buttonVariant?: 'outline' | 'default' | 'ghost'
  disabled?: boolean
}

const AddMoreFiles: React.FC<AddMoreFilesProps> = ({
  onUploadFromComputer,
  handleSharePointClick,
  side = 'bottom',
  align = 'end',
  buttonText = 'Add more files',
  leadingIcon,
  buttonSize = 'sm',
  buttonVariant = 'outline',
  disabled = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const integrationFilePickerEnabled = !_.isNil(handleSharePointClick)

  return integrationFilePickerEnabled ? (
    <DropdownMenu onOpenChange={setIsDropdownOpen} open={isDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          className="pointer-events-auto whitespace-nowrap"
          size={buttonSize}
          variant={buttonVariant}
          disabled={disabled}
        >
          {leadingIcon && <Icon icon={leadingIcon} className="mr-1" />}
          {buttonText}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align={align} side={side} className="w-52">
        <DropdownMenuItem onClick={onUploadFromComputer}>
          <Icon icon={LaptopIcon} className="mr-2" />
          From your computer
        </DropdownMenuItem>
        {handleSharePointClick && (
          <DropdownMenuItem onClick={handleSharePointClick}>
            <SharepointOneDriveLogo wrap />
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Button
      className="pointer-events-auto whitespace-nowrap"
      size={buttonSize}
      variant={buttonVariant}
      onClick={onUploadFromComputer}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  )
}

export default AddMoreFiles
