import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'

import { useAuthUser } from 'components/common/auth-context'
import {
  markV1ProductTourAsCompleted,
  runHarveyV1ProductTour,
} from 'components/common/product-tours/run-product-tour'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

interface WelcomeMessageProps {}

const WelcomeMessage: React.FC<WelcomeMessageProps> = () => {
  const [isWelcomeMessageOpen, setIsWelcomeMessageOpen] = useState(false)
  const navigate = useNavigate()
  const userInfo = useAuthUser()
  const { user } = useAuth0()

  useEffect(() => {
    if (userInfo.GetInterstitial()) {
      // Prioritize any workspace welcome interstitials
      // TODO: Manage welcome dialogs in a single component
      return
    }
    if (user && !userInfo.HasCompletedV1ProductTour) {
      setIsWelcomeMessageOpen(true)
      markV1ProductTourAsCompleted()
    }
  }, [user, userInfo])

  const onOpenChange = (open: boolean) => {
    if (open === false) {
      runHarveyV1ProductTour(userInfo, navigate)
    }
    setIsWelcomeMessageOpen(open)
  }

  const onClick = () => {
    setIsWelcomeMessageOpen(false)

    if (user && !userInfo.HasCompletedV1ProductTour) {
      runHarveyV1ProductTour(userInfo, navigate)
    }
  }

  return (
    <Dialog open={isWelcomeMessageOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Welcome to Harvey</DialogTitle>
        </DialogHeader>
        <p>
          Professionals across legal, tax, and finance use Harvey every day to
          augment productivity in complex knowledge work. Whether drafting
          lengthy agreements, doing research across a wide spectrum of
          knowledge, or summarizing a multitude of documents, professionals
          trust Harvey to quickly deliver high quality work.
        </p>
        <Button className="mt-4" onClick={onClick}>
          Get started
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeMessage
