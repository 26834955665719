import React, { useCallback, useState } from 'react'

import { InputNumber, Select } from 'antd'
import _ from 'lodash'

import {
  PWCTaxFeedbackExportRequest,
  PWCTaxFeedbackExportRequestWorkspaceSubstrEnum,
} from 'openapi/models/PWCTaxFeedbackExportRequest'
import { PWCTaxFeedbackExportResponse } from 'openapi/models/PWCTaxFeedbackExportResponse'
import { TaxCorpusFilterIDs } from 'openapi/models/TaxCorpusFilterIDs'
import Services from 'services'

import { displayErrorMessage } from 'utils/toast'

import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Button as TailwindButton } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

const PWCTaxFeedbackExport: React.FC = () => {
  const availableCorpuses = Object.values(TaxCorpusFilterIDs)
  const [corpus, setCorpus] = useState<TaxCorpusFilterIDs>(
    TaxCorpusFilterIDs.UK_TAX
  )
  const [workspaceSubstr, setWorkspaceSubstr] =
    useState<PWCTaxFeedbackExportRequestWorkspaceSubstrEnum>('pwc')
  const [numDays, setNumDays] = useState<number>(7)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClickDownload = useCallback(async () => {
    setIsLoading(true)

    const requestBody: PWCTaxFeedbackExportRequest = {
      corpus,
      numDays,
      workspaceSubstr,
    }
    const response = await Services.Backend.Post<PWCTaxFeedbackExportResponse>(
      `pwc_tax_feedback_export/download`,
      requestBody
    )
    if (_.isEmpty(response) || _.isNil(response) || !response.fileDataB64) {
      displayErrorMessage('Error getting export')
    } else {
      // Convert base64 to array buffer
      const binaryString = window.atob(response.fileDataB64)
      const bytes = new Uint8Array(binaryString.length)
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      const arrayBuffer = bytes.buffer

      const blob = new Blob([arrayBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      const currentDate = new Date().toISOString().split('T')[0]
      link.download = `feedback-export-${corpus}-${currentDate}.xlsx`
      link.click()
      window.URL.revokeObjectURL(url)
    }
    setIsLoading(false)
  }, [corpus, numDays, workspaceSubstr, setIsLoading])

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <div className="space-x-4">
          <Select
            className="w-32"
            options={availableCorpuses.map((val) => {
              return { value: val, label: val }
            })}
            value={corpus}
            onChange={setCorpus}
          />
          <span>
            <span className="mr-1">Number of days:</span>
            <InputNumber
              min={1}
              max={300}
              value={numDays}
              onChange={(val) => setNumDays(val || 1)}
            />
          </span>
          <span>
            <span className="mr-1">Workspace:</span>
            <Select
              className="w-24"
              options={Object.values(
                PWCTaxFeedbackExportRequestWorkspaceSubstrEnum
              ).map((val) => {
                return { value: val, label: val }
              })}
              value={workspaceSubstr}
              onChange={setWorkspaceSubstr}
            />
          </span>
          <div className="float-right flex flex-row space-x-2">
            {isLoading && <Spinner size="xs" className="mt-2" />}
            <TailwindButton
              onClick={onClickDownload}
              disabled={isLoading}
              variant="default"
              size="sm"
            >
              Download export
            </TailwindButton>
          </div>
        </div>
      </SettingsLayout>
    </>
  )
}
export default PWCTaxFeedbackExport
