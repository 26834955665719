import React, { Dispatch, SetStateAction } from 'react'

import { RefreshCw } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { HistoryTypeEnum } from 'models/helpers/history-helper'
import { Workspace } from 'models/workspace'
import Services from 'services'

import { readableFormat, TodayOption } from 'utils/date-utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

import HistoryExportDropdown from './history-export-dropdown'
import { useHistoryStore } from './history-store'

interface HistoryStatusProps {
  type: HistoryTypeEnum
  workspace: Workspace
  setWorkspace?: Dispatch<SetStateAction<Workspace>>
  workspaces?: Workspace[]
}

const HistoryStatus: React.FC<HistoryStatusProps> = ({ type, workspace }) => {
  const {
    isLoading,
    isHistoryPending,
    isLoadingMore,
    earliestLoadedTime,
    latestLoadedTime,
    lastUpdatedTime,
    dataSource,
    fetchHistoryUpdate,
  } = useHistoryStore(
    useShallow((s) => ({
      dataSource: s.dataSource,
      isLoading: s.isLoading,
      isHistoryPending: s.isHistoryPending,
      isLoadingMore: s.isLoadingMore,
      earliestLoadedTime: s.earliestLoadedTime,
      latestLoadedTime: s.latestLoadedTime,
      lastUpdatedTime: s.lastUpdatedTime,
      fetchHistoryUpdate: s.fetchHistoryUpdate,
    }))
  )

  const { trackEvent } = useAnalytics()
  const onClickRefreshButton = React.useCallback(() => {
    Services.HoneyComb.Record({
      metric: 'ui.history_refresh_button_clicked',
      type: type,
      workspace_slug: workspace.slug,
      earliest_loaded_time: earliestLoadedTime,
      latest_loaded_time: latestLoadedTime,
      last_updated_time: lastUpdatedTime,
    })
    trackEvent('History Table Refreshed', {
      type: type,
      earliest_loaded_time: earliestLoadedTime,
      latest_loaded_time: latestLoadedTime,
      last_updated_time: lastUpdatedTime,
    })
    fetchHistoryUpdate()
  }, [
    type,
    workspace.slug,
    earliestLoadedTime,
    latestLoadedTime,
    lastUpdatedTime,
    trackEvent,
    fetchHistoryUpdate,
  ])

  // Status elements are the loading spinner, refresh button, and status message.
  const statusElements = React.useMemo(() => {
    const elements = []
    if (isLoading) {
      elements.push(
        <div key="spinner" className="flex shrink-0 items-center">
          <Spinner size="xs" className="mx-2 shrink-0" />
        </div>
      )
    } else {
      elements.push(
        <div key="refresh" className="flex shrink-0 items-center">
          <Button
            size="xsIcon"
            variant="ghost"
            aria-label="Refresh history"
            // The margin is adjusted to align the refresh button with the spinner.
            className="-my-1 mx-1 text-muted"
            onClick={onClickRefreshButton}
          >
            <RefreshCw className="h-4 w-4 shrink-0" />
          </Button>
        </div>
      )
    }
    if (isHistoryPending || isLoadingMore) {
      elements.push(
        <p className="text-muted" key="status">
          Retrieving history
        </p>
      )
    } else if (lastUpdatedTime) {
      elements.push(
        <p className="text-muted" key="status">
          Last updated on{' '}
          {readableFormat(lastUpdatedTime, TodayOption.showTime)}
        </p>
      )
    } else {
      elements.push(
        <p className="text-muted" key="status">
          Unable to retrieve history
        </p>
      )
    }
    return elements
  }, [
    isLoading,
    isHistoryPending,
    isLoadingMore,
    lastUpdatedTime,
    onClickRefreshButton,
  ])

  if (type === HistoryTypeEnum.WORKSPACE) {
    return (
      <div className="flex h-8 flex-row-reverse items-center gap-2">
        <HistoryExportDropdown
          workspace={workspace}
          isLoading={isLoading}
          events={dataSource}
        />
        <div className="flex items-center">{statusElements}</div>
      </div>
    )
  } else {
    return <div className="flex h-8 items-center">{statusElements}</div>
  }
}

export default HistoryStatus
